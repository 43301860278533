export enum OPERATION_TYPE {
  PROFILE_MATCHING = 1,
}

export enum OPERATION_REQUEST_ITEM_TYPE {
  ZIP_CODES = 1,
} 

const OPERATION_TYPE_TO_REQUEST_ITEM_TYPE_MAPPING = {
  [OPERATION_TYPE.PROFILE_MATCHING]: OPERATION_REQUEST_ITEM_TYPE.ZIP_CODES
}

export function getRequestItemType(operationType: number): OPERATION_REQUEST_ITEM_TYPE | undefined {
  return OPERATION_TYPE_TO_REQUEST_ITEM_TYPE_MAPPING[operationType as OPERATION_TYPE];
}

export enum OPERATION_STATE {
  ACTIVE = 1,
  PAUSED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  ERRORED = 5,
}

export enum OPERATION_TRIGGER_TYPE {
  END_TO_END_WORKFLOW = 1,
  USER = 2,
}

export enum OPERATION_REQUEST_ITEM_TYPE {
  POSTAL_CODE_ID = 1,
}
