import { useParams } from "react-router-dom";
import { useGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import { extractPostalCodeIds } from "../../shared/utils";
import { useGetAddressesByPostalCodeIds } from "../../../../../shared/domains/addresses/graphql/hooks/useGetAddressesByPostalCodeIds";
import { Card } from "../../../../../shared/components/card/Card";
import { classNames } from "../../../../../shared/utils";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Badge,
  BarList,
  Bold,
  Button,
  Callout,
  Divider,
  AccordionList,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";
import { useGetAddressesAndPersonsByPostalCodeIds } from "../../../../../shared/domains/addresses/graphql/hooks/useGetAddressesAndPersonsByPostalCodeIds";
import {
  ArchiveBoxIcon,
  CheckBadgeIcon,
  CurrencyPoundIcon,
  EyeIcon,
  HomeIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HomeModernIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/solid";
import Dropdown from "../../../../../shared/components/dropdown/Dropdown";

const SingleProfileMatchingOperationPersons = () => {
  let { operationId } = useParams<{ operationId: string }>();
  const { singleParentOperation, singleParentOperationRequestItems } = useGetSingleParentOperation({
    parentOperationId: Number(operationId),
  });
  const postalCodeIds = extractPostalCodeIds({
    operationRequestItems: singleParentOperationRequestItems,
  });
  const { addressesAndPersons } = useGetAddressesAndPersonsByPostalCodeIds({ postalCodeIds });

  return (
    <>
      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  onChange={() => {}}
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (0)
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all profiles that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={
                [
                  // {
                  //   icon: HandThumbDownIcon,
                  //   name: "Mark as Rejected",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: ArchiveBoxIcon,
                  //   name: "Mark as Duplicate",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: HandThumbUpIcon,
                  //   name: "Mark as Contender",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: CheckBadgeIcon,
                  //   name: "Mark as Verified",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: EyeIcon,
                  //   name: "Mark as Pending Review",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: HomeIcon,
                  //   name: "De-Link Address",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: ShieldExclamationIcon,
                  //   name: "Get Profiles",
                  //   function: async () => {},
                  // },
                ]
              }
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {addressesAndPersons.map(address => {
          return (
            <>
              <Card className={classNames("max-w-md mx-auto ")}>
                <Flex justifyContent="between" className="mb-2">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                      />
                    </div>
                  </div>

                  <Badge color="gray" icon={HomeModernIcon}>
                    {address.provider.name}
                  </Badge>
                </Flex>
                <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                  <Metric>{address.full_address}</Metric>
                </Flex>

                <Flex className="w-full">
                  <Bold className="mt-6 w-full pt-4 border-t">Potential Owners</Bold>
                </Flex>

                <AccordionList className="mt-6">
                  {address.person_to_addresses.map((personToAddress, idx) => {
                    return (
                      <Accordion
                        key={personToAddress.person.id}
                        className={classNames(" overflow-visible, w-full	")}
                      >
                        <AccordionHeader className="w-full">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between w-full">
                              <div className=" h-5 items-center mr-3">
                                <input
                                  type="checkbox"
                                  onClick={event => event.stopPropagation()}
                                  onChange={event => {
                                    event.stopPropagation();
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                              </div>
                              <Flex className="flex-col justify-start items-start">
                                <Text>
                                  {personToAddress.person.first_name}{" "}
                                  {personToAddress.person.last_name}
                                </Text>
                                <p className="text-xs text-gray-400">
                                  {personToAddress.person.full_name}
                                </p>
                              </Flex>

                              <div className="flex">
                                <div>
                                  <Badge size="xs" color="orange" className="mr-2">
                                    <span className="text-xs">
                                      {personToAddress.link_relationship_status.name}
                                    </span>
                                  </Badge>
                                  <Badge size="xs" color="orange" className="mr-2">
                                    <span className="text-xs">
                                      {personToAddress.person.stated_address_source.name}
                                    </span>
                                  </Badge>
                                  <Badge
                                    size="xs"
                                    color={personToAddress.person.is_organisation ? "red" : "green"}
                                    className="mr-2"
                                  >
                                    <span className="text-xs">
                                      {personToAddress.person.is_organisation
                                        ? "Organisation"
                                        : "Person"}
                                    </span>
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="text-left	 text-gray-400 text-xs">
                                {personToAddress.person.stated_address}
                              </div>
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody className="overflow-visible	">
                          <Text className="flex flex-row">
                            <Bold>Stated Address</Bold>
                          </Text>

                          <Text className="mt-2"> {personToAddress.person.stated_address}</Text>
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
                </AccordionList>
              </Card>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SingleProfileMatchingOperationPersons;
