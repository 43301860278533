import { useParams } from "react-router-dom";
import { useGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import { Card } from "../../../../../shared/components/card/Card";
import { RiBookOpenLine, RiDatabase2Line } from "@remixicon/react";
import dayjs from "dayjs";
import { Badge } from "../../../../../shared/components/badge/Badge";
import { DataTable } from "../components/DataTable";
import { getColumns } from "../components/Columns";

const SingleProfileMatchingOperationGeneral = () => {
  let { operationId } = useParams<{ operationId: string }>();
  const { singleParentOperation, singleParentOperationRequestItems } = useGetSingleParentOperation({
    parentOperationId: Number(operationId),
  });
  const columns = getColumns();

  return (
    <>
      {/* <div className="border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-800 dark:bg-gray-950 sm:p-6 lg:p-8"> */}
      <header className="">
        <div className="mt-8 w-full  md:items-stretch md:space-x-4">
          <Card className="w-full ">
            <h3 className=" text-sm font-medium text-gray-900 dark:text-gray-50">
              <a href="#" className="focus:outline-none">
                {/* Extend link to entire card */}
                <span className="absolute inset-0" aria-hidden={true} />
                Description
              </a>
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-500">
              This profile matching operation was triggered at{" "}
              {dayjs(singleParentOperation?.created_at).format("DD/MM/YYYY MM:ss")}. Its purpose is
              to acquire profiles for the given zipcodes by pulling addresses, persons and profiles.
              Its current progress status is{" "}
              <Badge>{singleParentOperation?.operation_progress_status?.name}</Badge> and its
              currently <Badge>{singleParentOperation?.operation_state.name}</Badge>.{" "}
              {singleParentOperation?.external_event_id ? <></> : <></>}
            </p>
          </Card>
        </div>
      </header>
      <Card className="mt-4 sm:mt-6 lg:mt-10">
        <h3 className=" text-sm font-medium text-gray-900 dark:text-gray-50">
          <a href="#" className="focus:outline-none">
            {/* Extend link to entire card */}
            <span className="absolute inset-0" aria-hidden={true} />
            Requested Items
          </a>
        </h3>
        <div className="mt-5">
          <DataTable
            data={singleParentOperationRequestItems}
            columns={columns}
            // onRowClick={row => {
            //   history.push(`/workspaces/${row.original.id}/general`);
            // }}
          />
        </div>
      </Card>
      {/* </div> */}
    </>
  );
};

export default SingleProfileMatchingOperationGeneral;
