import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { singleProfileOperationNavigation } from "../data";
import { cx } from "../../../../../shared/utils";
import { Sidebar } from "../../../../../shared/navigation/components/Sidebar";
import {
  TabNavigation,
  TabNavigationLink,
} from "../../../../../shared/components/tabNavigation/TabNavigation";
import useScroll from "../../../../../shared/hooks/UseScroll";
import { ChevronLeft } from "lucide-react";
import {
  DropdownMenuContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../../shared/components/dropdownMenu/DropdownMenu";
import { Button } from "../../../../../shared/components/button/Button";
import { RiMore2Fill } from "@remixicon/react";
import { Badge } from "../../../../../shared/components/badge/Badge";
import { useGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import {
  RiCheckboxCircleFill,
  RiLoader2Fill,
  RiProgress5Fill,
  RiProgress7Fill,
  RiProgress6Fill,
  RiProgress4Fill,
  RiProgress3Fill,
  RiProgress2Fill,
  RiProgress1Fill,
} from "@remixicon/react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../shared/components/accordion/Accordion";
import { ProgressBar } from "../../../../../shared/components/progressBar/ProgressBar";
import { Card } from "../../../../../shared/components/card/Card";
import { useGetChildOperationsForParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetChildOperationsForParentOperation";
import { convertChildOperationsToSteps } from "../utils";
import { OPERATION_TRIGGER_TYPE, OPERATION_STATE } from "../../../../../shared/constants/models";
import dayjs from "dayjs";
import { orderBy } from "lodash";

export default function SingleProfileMatchingOperationLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const location = useLocation();
  const pathname = location.pathname;

  let { operationId } = useParams<{ operationId: string }>();
  const navigate = useNavigate();
  const { singleParentOperation } = useGetSingleParentOperation({
    parentOperationId: Number(operationId),
  });

  const { childOperations } = useGetChildOperationsForParentOperation({
    parentOperationId: Number(operationId),
  });
  const steps = convertChildOperationsToSteps(childOperations);
  const dateOrderedSteps = orderBy(steps, ["createdOn"], ["asc"]);

  return (
    <>
      <header
        className={cx(
          "fixed inset-x-0 top-0 isolate z-50 flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 transition-all md:grid md:grid-cols-[200px_auto_200px] md:px-6 dark:border-gray-900 dark:bg-gray-925",
          "h-20"
        )}
      >
        <div
          onClick={() => navigate(-1)}
          className="cursor-pointer flex-nowrap items-center gap-0.5 flex"
          aria-hidden="true"
        >
          <ChevronLeft />
        </div>
        <div className="flex flex-col  sm:flex-row sm:space-y-0 sm:space-x-4 items-center justify-center">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-50">
            Profile Matching Operation - {singleParentOperation?.id}
          </h1>
          <div className="flex flex-row space-x-2">
            <Badge>{singleParentOperation?.operation_progress_status?.name}</Badge>
            <Badge>{singleParentOperation?.operation_state?.name}</Badge>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end ">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                variant="ghost"
                className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
              >
                <RiMore2Fill
                  className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                  aria-hidden="true"
                />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-36 -mt-2 z-[500]">
              <DropdownMenuItem>Pause</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* <StepProgress steps={steps} /> */}
        {/* <Button variant="ghost" className="ml-auto w-fit" asChild>
        <a href="/reports">Skip to dashboard</a>
      </Button> */}
      </header>
      <div className="mx-10">
        <Card className="mt-24">
          <h3 className="font-semibold text-gray-900 dark:text-gray-50">Profile Matching</h3>
          <p className="mt-1 text-sm/6 text-gray-500 dark:text-gray-500">
            This operation acquires the addresses, persons and profiles for a given set of zipcodes.
          </p>
          <div className="mt-6 flex items-center space-x-2">
            {dateOrderedSteps
              .filter(item => item.triggerTypeId === OPERATION_TRIGGER_TYPE.END_TO_END_WORKFLOW)
              .map((step, stepindex) => (
                <div key={stepindex} className="w-full truncate">
                  <ProgressBar
                    variant={
                      step.stateId === OPERATION_STATE.COMPLETED
                        ? "success"
                        : step.stateId === OPERATION_STATE.PAUSED
                        ? "pink"
                        : step.stateId === OPERATION_STATE.ERRORED
                        ? "error"
                        : step.stateId === OPERATION_STATE.CANCELLED
                        ? "neutral"
                        : step.stateId === OPERATION_STATE.ACTIVE
                        ? "orange"
                        : "default"
                    }
                    value={step.itemsCompletedPercentage}
                    className="[&>*]:h-1.5"
                  />
                  <div className="mt-2 flex items-center space-x-1 truncate">
                    {step.itemsCompletedPercentage === 100 ? (
                      <RiCheckboxCircleFill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 87 ? (
                      <RiProgress7Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 75 ? (
                      <RiProgress6Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 63 ? (
                      <RiProgress5Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 50 ? (
                      <RiProgress4Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 38 ? (
                      <RiProgress3Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : step.itemsCompletedPercentage >= 25 ? (
                      <RiProgress2Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    ) : (
                      <RiProgress1Fill
                        className={cx(
                          "size-4 shrink-0",
                          step.stateId === OPERATION_STATE.COMPLETED &&
                            "text-green-500 dark:text-green-500",
                          step.stateId === OPERATION_STATE.PAUSED &&
                            "text-pink-500 dark:text-pink-500",
                          step.stateId === OPERATION_STATE.ERRORED &&
                            "text-red-500 dark:text-red-500",
                          step.stateId === OPERATION_STATE.CANCELLED &&
                            "text-gray-500 dark:text-gray-500",
                          step.stateId === OPERATION_STATE.ACTIVE &&
                            "text-orange-500 dark:text-orange-500"
                        )}
                        aria-hidden={true}
                      />
                    )}
                    <p className="truncate text-xs text-gray-500 dark:text-gray-500">
                      {step.description} - {step.stateName?.toUpperCase()}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <Accordion type="single" collapsible defaultValue={undefined} className="mt-8 space-y-2">
            <AccordionItem
              value="item1"
              key="item1"
              className="rounded-md border border-gray-200 px-4 shadow-sm dark:border-gray-800"
            >
              <AccordionTrigger>Logs overview ({steps.length})</AccordionTrigger>
              <AccordionContent>
                <ul role="list" className="mt-2 space-y-6 pb-2">
                  {dateOrderedSteps.map((step, stepindex) => (
                    <li key={step.id} className="relative flex gap-x-3">
                      <div
                        className={cx(
                          stepindex === steps.length - 1 ? "h-6" : "-bottom-6",
                          "absolute left-0 top-0 flex w-6 justify-center"
                        )}
                      >
                        <span className="w-px bg-gray-200 dark:bg-gray-700" aria-hidden={true} />
                      </div>
                      <div className="flex items-start space-x-2.5">
                        <div className="relative flex size-6 flex-none items-center justify-center bg-white dark:bg-gray-950">
                          <div className="size-3 rounded-full border border-gray-300 bg-gray-100 ring-4 ring-white dark:border-gray-700 dark:bg-gray-800 dark:ring-gray-950" />
                        </div>
                        <div>
                          <p className="mt-0.5 text-sm font-medium text-gray-900 dark:text-gray-50">
                            {step.description} - {step.stateName?.toUpperCase()}
                          </p>

                          <p className="text-sm/6 text-gray-500 dark:text-gray-500">
                            Created on {dayjs(step.createdOn).format("DD/MM/YYYY MM:ss")}, Triggered
                            by {step.triggerTypeName}, {step.completedItems}/{step.totalItems} items{" "}
                            completed ({step.itemsCompletedPercentage}%)
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </Card>

        <TabNavigation className=" mt-8  items-center justify-between ">
          {singleProfileOperationNavigation.map(item => (
            <TabNavigationLink
              key={item.name}
              asChild
              active={pathname
                .toLowerCase()
                .includes(`operations/profile-matching/${operationId}${item.path}`)}
              className="px-5"
            >
              <Link to={`/operations/profile-matching/${operationId}${item.path}`}>
                {item.name}
              </Link>
            </TabNavigationLink>
          ))}
        </TabNavigation>
      </div>

      <main id="main-content" className="mx-10 my-5 ">
        {children}
      </main>
    </>
  );
}
