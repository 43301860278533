import { useGetAddressesByPostalCodeIdsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseGetAddressesByPostalCodeIds = ReturnType<typeof useGetAddressesByPostalCodeIds>;

export const useGetAddressesByPostalCodeIds = ({ postalCodeIds }: { postalCodeIds: number[] }) => {
  const { data, loading, error } = useGetAddressesByPostalCodeIdsQuery({
    variables: {
      postalCodeIds,
    },
  });

  return {
    addresses: data && Array.isArray(data.address) ? data.address : [],
    addressesError: error,
    addressesLoading: loading,
  };
};
