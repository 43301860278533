import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { ArrayElement } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import { Button } from "../../../../shared/components/button/Button";

import { RiMore2Fill } from "@remixicon/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import { UseGetParentOperations } from "../../../../shared/domains/operations/graphql/hooks/useGetParentOperations";
import {
  getBadgeValue,
  getOperationRequestItemTypeDisplayName,
  requestedItemsDisplay,
} from "../utils";
import { Badge } from "../../../../shared/components/badge/Badge";

const columnHelper = createColumnHelper<ArrayElement<UseGetParentOperations["operations"]>>();

export const getColumns = ({
  onManageClick,
  postalCodesMap,
}: {
  onManageClick: (row: Row<ArrayElement<UseGetParentOperations["operations"]>>) => void;
  postalCodesMap: { [key: number]: string };
}) =>
  [
    columnHelper.accessor("id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Id" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Id",
      },
      id: "Id",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.id}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_type.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Type" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Type",
      },
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.operation_type.name}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_request_items", {
      header: ({ column }) => <TableColumnHeader column={column} title="Requested Items" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Requested Items",
      },
      id: "requested_items",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <span className="font-normal">
              <span>
                {requestedItemsDisplay(
                  row.original.operation_request_items.map(
                    (item: any) => postalCodesMap[Number(item.operation_request_item_value)]
                  ),
                  getOperationRequestItemTypeDisplayName(row.original.operation_type.id)
                )}
              </span>
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_progress_status.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Progress" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Progress",
      },
      id: "step_progress",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <span className="font-normal"> {row.original.operation_progress_status?.name}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_state.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="State" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "State",
      },
      id: "state",
      cell: ({ row }) => {
        return (
          <Badge variant={getBadgeValue(row.original.operation_state?.name)}>
            {row.original.operation_state?.name}
          </Badge>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Actions",
      },
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2 z-50">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="ghost"
                  className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                >
                  <RiMore2Fill
                    className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-36 -mt-2 z-500">
                <DropdownMenuItem onClick={() => onManageClick(row)}>Manage</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseGetParentOperations["operations"]>>[];
