import { useParams } from "react-router-dom";
import { useGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import { extractPostalCodeIds } from "../../shared/utils";
import { useGetAddressesByPostalCodeIds } from "../../../../../shared/domains/addresses/graphql/hooks/useGetAddressesByPostalCodeIds";
import { Card } from "../../../../../shared/components/card/Card";
import { classNames } from "../../../../../shared/utils";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Badge,
  BarList,
  Bold,
  Button,
  Callout,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";
import {
  ArchiveBoxIcon,
  CheckBadgeIcon,
  CurrencyPoundIcon,
  HandThumbUpIcon,
  HomeModernIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { EyeIcon } from "lucide-react";
import Dropdown from "../../../../../shared/components/dropdown/Dropdown";

const SingleProfileMatchingOperationAddresses = () => {
  let { operationId } = useParams<{ operationId: string }>();
  const { singleParentOperation, singleParentOperationRequestItems } = useGetSingleParentOperation({
    parentOperationId: Number(operationId),
  });
  const postalCodeIds = extractPostalCodeIds({
    operationRequestItems: singleParentOperationRequestItems,
  });
  const { addresses } = useGetAddressesByPostalCodeIds({ postalCodeIds });

  return (
    <>
      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  onChange={() => {}}
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (0)
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all profiles that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={[
                {
                  icon: PencilIcon,
                  name: "Get Owners [DISABLED]",
                  function: async () => {},
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {addresses.map(address => {
          return (
            <>
              <Card className={classNames("max-w-md mx-auto ")}>
                <Flex justifyContent="between" className="mb-2">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                      />
                    </div>
                  </div>

                  <Badge color="gray" icon={HomeModernIcon}>
                    {address.provider.name}
                  </Badge>
                </Flex>
                <Flex justifyContent="start" className="space-x-1" alignItems="baseline">
                  <Metric>{address.full_address}</Metric>
                </Flex>
              </Card>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SingleProfileMatchingOperationAddresses;
