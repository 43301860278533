import { useGetSingleParentOperationQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseGetSingleParentOperation = ReturnType<typeof useGetSingleParentOperation>;

export const useGetSingleParentOperation = ({
  parentOperationId,
}: {
  parentOperationId: number;
}) => {
  const { data, loading, error } = useGetSingleParentOperationQuery({
    variables: {
      id: parentOperationId,
    },
  });

  return {
    singleParentOperation: data && data.operation_by_pk,
    singleParentOperationError: error,
    singleParentOperationLoading: loading,
    singleParentOperationRequestItems: data?.operation_by_pk?.operation_request_items ?? [],
  };
};
