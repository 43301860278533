import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../infrastructure/firebase";

interface RestrictedRouteProps {
  element: ReactElement;
}

const RestrictedRoute = ({ element }: RestrictedRouteProps) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null; // Or a loading spinner
  }

  return user ? <Navigate to="/operations" /> : element;
};

export default RestrictedRoute;
