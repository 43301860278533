import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

type OperationRequestItem = {
  parentOperationRequestItemTypeId: number;
  parentOperationRequestItemValue: number;
};

export interface CreateParentOperationAPIBody {
  parentOperationTypeId: number;
  parentOperationRequestItems: OperationRequestItem[];
}

export const createParentOperationAPI = async (data: CreateParentOperationAPIBody) => {
  const responses = await Promise.all(
    data.parentOperationRequestItems.map(async (item) => {
      const payload = {
        parentOperationTypeId: data.parentOperationTypeId,
        parentOperationRequestItems: [item],
      };

      const response = await meetmoreAPI.post("/parent-operations", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data;
    })
  );

  return responses;
};

export const useCreateParentOperationAPI = () => {
  let loadingToastId: string;

  return useMutation(createParentOperationAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Creating operation...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Operation created successfully!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
