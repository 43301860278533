import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ArrayElement } from "../../../../../shared/utils";
import { UseGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import { TableColumnHeader } from "../../../../../shared/components/table/TableColumnHeader";
import { Badge } from "../../../../../shared/components/badge/Badge";

/// create a type that is made of arrayelement of UseGetOperations["operations"]
type OperationRequestItem = ArrayElement<
  UseGetSingleParentOperation["singleParentOperationRequestItems"]
>;
const columnHelper = createColumnHelper<OperationRequestItem>();

export const getColumns = () =>
  [
    columnHelper.accessor("id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Id" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Id",
      },
      id: "Id",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.id}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_request_item_type.name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Data Type" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Name",
      },
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.operation_request_item_type.name}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("operation_request_item_value", {
      header: ({ column }) => <TableColumnHeader column={column} title="Value" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Value",
      },
      id: "Value",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.operation_request_item_value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("is_completed", {
      header: ({ column }) => <TableColumnHeader column={column} title="Completed" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Completed",
      },
      id: "Completed",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <Badge variant={row.original.is_completed ? "success" : "error"}>
              {row.original?.is_completed ? "Yes" : "No"}
            </Badge>
          </div>
        );
      },
    }),
  ] as ColumnDef<OperationRequestItem>[];
