import * as yup from "yup";

export const createOperationSchema = yup.object().shape({
  operationTypeId: yup.number().required("Operation Type is required"),
  operationValues:  yup
  .array()
  .of(
    yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .typeError("Please select at least one Value")
  )
  .min(1, "Please select at least one Value")
  .required("Please select at least one Value")
});

export type CreateOperationValues = {
  operationTypeId: number;
  operationValues: number[];
  operationRequestItemTypeId: number;
};
