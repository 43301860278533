import { useParams } from "react-router-dom";
import { useGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";
import { extractPostalCodeIds } from "../../shared/utils";
import { useGetAddressesByPostalCodeIds } from "../../../../../shared/domains/addresses/graphql/hooks/useGetAddressesByPostalCodeIds";
import { Card } from "../../../../../shared/components/card/Card";
import { classNames } from "../../../../../shared/utils";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionList,
  Badge,
  BarList,
  Bold,
  Button,
  Callout,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";
import {
  ArchiveBoxIcon,
  CheckBadgeIcon,
  CurrencyPoundIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HomeModernIcon,
} from "@heroicons/react/24/solid";
import { EyeIcon } from "lucide-react";
import Dropdown from "../../../../../shared/components/dropdown/Dropdown";
import { useGetAddressesAndPersonsByPostalCodeIds } from "../../../../../shared/domains/addresses/graphql/hooks/useGetAddressesAndPersonsByPostalCodeIds";
import { useNonOrgPersonAddressesByPostalCodeIds } from "../../../../../shared/domains/persons/graphql/hooks/useNonOrgPersonAddressesByPostalCodeIds";

const SingleProfileMatchingOperationProfiles = () => {
  let { operationId } = useParams<{ operationId: string }>();
  const { singleParentOperation, singleParentOperationRequestItems } = useGetSingleParentOperation({
    parentOperationId: Number(operationId),
  });
  const postalCodeIds = extractPostalCodeIds({
    operationRequestItems: singleParentOperationRequestItems,
  });
  const { nonOrgPersonAddresses } = useNonOrgPersonAddressesByPostalCodeIds({ postalCodeIds });

  return (
    <>
      <div className="sticky top-0 z-10 bg-orange-100 rounded-lg py-4 px-4 mt-4 w-full">
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="select-all"
                  aria-describedby="select-all-description"
                  name="select-all"
                  type="checkbox"
                  onChange={() => {}}
                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="select-all" className="font-medium text-gray-700">
                  Select All (0)
                </label>
                <p id="select-all-description" className="text-gray-500">
                  Selects all profiles that you can currently see
                </p>
              </div>
            </div>

            <Dropdown
              items={
                [
                  // {
                  //   icon: HandThumbDownIcon,
                  //   name: "Mark as Rejected",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: ArchiveBoxIcon,
                  //   name: "Mark as Duplicate",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: HandThumbUpIcon,
                  //   name: "Mark as Contender",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: CheckBadgeIcon,
                  //   name: "Mark as Verified",
                  //   function: async () => {},
                  // },
                  // {
                  //   icon: EyeIcon,
                  //   name: "Mark as Pending Review",
                  //   function: async () => {},
                  // },
                ]
              }
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-5 gap-x-5 mt-6">
        {nonOrgPersonAddresses.map(personAddress => {
          return (
            <>
              <Card className={classNames("max-w-md mx-auto ")}>
                <Flex justifyContent="between" className="mb-2">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                      />
                    </div>
                  </div>

                  <Badge color="gray" icon={HomeModernIcon}>
                    {personAddress.link_relationship_status.name}
                  </Badge>
                </Flex>
                <Flex
                  justifyContent="start"
                  flexDirection="col"
                  className="space-x-1"
                  alignItems="baseline"
                >
                  <Metric>
                    {personAddress.person.first_name} {personAddress.person.last_name}
                  </Metric>
                  <Text>{personAddress.person.full_name}</Text>
                  <Text>{personAddress.person.stated_address}</Text>
                </Flex>

                <Flex className="w-full">
                  <Bold className="mt-6 w-full pt-4 border-t">Potential Profiles</Bold>
                </Flex>

                <AccordionList className="mt-6">
                  {personAddress.person.digital_profile_to_people.map((profilePerson, idx) => {
                    return (
                      <Accordion
                        key={profilePerson.id}
                        className={classNames(" overflow-visible, w-full	")}
                      >
                        <AccordionHeader className="w-full">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between w-full">
                              <div className=" h-5 items-center mr-3">
                                <input
                                  type="checkbox"
                                  onClick={event => event.stopPropagation()}
                                  onChange={event => {
                                    event.stopPropagation();
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                              </div>
                              <Flex className="flex-col justify-start items-start">
                                <Text>
                                  {profilePerson.digital_profile?.full_name ??
                                    profilePerson.digital_profile.external_id}{" "}
                                </Text>
                                <div className="text-center	 text-gray-400 text-xs">
                                  {profilePerson.digital_profile?.secondary_subtitle}
                                </div>
                              </Flex>

                              <div className="flex ml-10">
                                <Badge size="xs" color="orange" className="mr-2">
                                  <span className="text-xs">
                                    {profilePerson.digital_profile.digital_profile_type.name}
                                  </span>
                                </Badge>
                              </div>
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody>
                          <a
                            href={`https://www.linkedin.com/in/${profilePerson.digital_profile.external_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className="my-4 rounded-md w-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                              Go to Linkedin
                            </button>
                          </a>

                          <Text className="mt-2">
                            Full Name: {profilePerson.digital_profile?.full_name ?? "Not Pulled"}
                          </Text>

                          <Text className="mt-2">
                            Primary Subtitle:{" "}
                            {profilePerson.digital_profile?.primary_subtitle ?? "Not Pulled"}
                          </Text>

                          <Text className="mt-2">
                            Secondary Subtitle:{" "}
                            {profilePerson.digital_profile?.secondary_subtitle ?? "Not Pulled"}
                          </Text>

                          <Text className="mt-2">
                            Summary: {profilePerson.digital_profile?.summary ?? "Not Pulled"}
                          </Text>
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
                </AccordionList>
              </Card>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SingleProfileMatchingOperationProfiles;
