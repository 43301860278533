import { useLazyQuery, useQuery } from "@apollo/client";
import { getPostalCodes } from "../queries/getPostalCodes";
import { GetPostalCodesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UsePostalCodes = ReturnType<typeof usePostalCodes>;


export const usePostalCodes = () => {
  const { data, loading, error } = useQuery<GetPostalCodesQuery>(getPostalCodes);

  return {
    postalCodes: data && Array.isArray(data.postal_code) ? data.postal_code : [],
    postalCodesError: error,
    postalCodesLoading: loading,
  };
};

export const usePostalCodesLazy = () => {
  const [fetchPostalCodes, { data, loading, error }] = useLazyQuery<GetPostalCodesQuery>(getPostalCodes);

  return {
    postalCodes: data && Array.isArray(data.postal_code) ? data.postal_code : [],
    postalCodesError: error,
    postalCodesLoading: loading,
    fetchPostalCodes,
  };
};
