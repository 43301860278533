import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnSort } from "@tanstack/react-table";
import { Button } from "../../../../shared/components/button/Button";
import { Plus } from "lucide-react";
import { DataTable } from "../components/DataTable";
import { useGetParentOperations } from "../../../../shared/domains/operations/graphql/hooks/useGetParentOperations";
import { useOperationsTypes } from "../../../../shared/domains/static/graphql/hooks/useGetOperationTypes";
import { getColumns } from "../components/Columns";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { PAGE_SIZE } from "../data";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { CreateOperationValues } from "../validation";
import { createOperationAPIBody, getLastPageOffset } from "../utils";
import { createParentOperationAPI } from "../../../../shared/domains/operations/rest/createParentOperationAPI";
import { usePostalCodes } from "../../../../shared/domains/postal_codes/graphql/hooks/useGetPostalCodes";

export default function Operations() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [triggerFilter] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { postalCodes } = usePostalCodes();

  const { operations, operationsCount, fetchOperations } = useGetParentOperations();
  const { operationTypes } = useOperationsTypes();

  const { offset, setOffset } = usePagination({
    pageSize: PAGE_SIZE,
    totalRecords: operationsCount,
  });

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  const postalCodesMap = postalCodes.reduce((acc: { [key: number]: string }, postalCode) => {
    acc[postalCode.id] = postalCode.code;
    return acc;
  }, {});

  const postalCodesArray = postalCodes.map(postalCode => ({
    label: postalCode.code,
    value: postalCode.id,
  }));

  const navigate = useNavigate();
  const columns = getColumns({
    onManageClick: row => {
      navigate(`/operations/profile-matching/${row.original.id}/general`);
    },
    postalCodesMap: postalCodesMap,
  });

  const handleCreateOperation = async (data: CreateOperationValues) => {
    await createParentOperationAPI(createOperationAPIBody(data));
    setIsDrawerOpen(false);
    const newOffset = getLastPageOffset(operationsCount, PAGE_SIZE);
    setOffset(newOffset);
    fetchOperations({ variables: { offset: newOffset, limit: PAGE_SIZE } });
  };

  useEffect(() => {
    fetchOperations({ variables: { offset, limit: PAGE_SIZE } });
  }, [offset, fetchOperations]);

  const isFiltered = false;

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Operations
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4">
          <Button onClick={() => setIsDrawerOpen(true)} className="inline-flex items-center">
            <Plus className="mr-2" />
            Add Operation
          </Button>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          setSorting={setSorting}
          sorting={sorting}
          isFiltered={isFiltered}
          triggerFilter={triggerFilter}
          resetFilters={() => {}}
          data={operations}
          columns={columns}
          offset={offset}
          onPaginationChange={handleOnPaginationChange}
          totalRecords={operationsCount}
        />
        <DataTableDrawer
          open={isDrawerOpen}
          onCreateOperation={handleCreateOperation}
          onOpenChange={setIsDrawerOpen}
          operationTypes={operationTypes}
          postalCodes={postalCodesArray}
        />
      </div>
    </>
  );
}
