import { gql } from "@apollo/client";

export const getParentOperations = gql`
  query getParentOperations($offset: Int!, $limit: Int!) {
    operation(
      offset: $offset
      limit: $limit
      where: { parent_operation_id: { _is_null: true } }
      order_by: { id: asc }
    ) {
      id
      operation_type {
        id
        name
      }
      parent_operation_id
      operation_request_items {
        id
        operation_request_item_value
      }
      operation_progress_status {
        id
        name
      }
      operation_state {
        id
        name
      }
      operation_state {
        name
      }
    }
    operation_aggregate(where: { parent_operation_id: { _is_null: true } }) {
      aggregate {
        count
      }
    }
  }
`;
