import { useNonOrgPersonAddressesByPostalCodeIdsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseNonOrgPersonAddressesByPostalCodeIds = ReturnType<
  typeof useNonOrgPersonAddressesByPostalCodeIds
>;

export const useNonOrgPersonAddressesByPostalCodeIds = ({
  postalCodeIds,
}: {
  postalCodeIds: number[];
}) => {
  const { data, loading, error } = useNonOrgPersonAddressesByPostalCodeIdsQuery({
    variables: {
      postalCodeIds,
    },
  });

  return {
    nonOrgPersonAddresses:
      data && Array.isArray(data.person_to_address) ? data.person_to_address : [],
    nonOrgPersonAddressesError: error,
    nonOrgPersonAddressesLoading: loading,
  };
};
