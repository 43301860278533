import {
  adminLayoutRoutes,
  adminNonLayoutRoutes,
  profileMatchingOperationRoutes,
  publicRoutes,
  restrictedRoutes,
} from "./shared/routing/routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ApolloWrapper from "./shared/infrastructure/graphql/meetmore/ApolloWrapper";
import { QueryClientProvider } from "react-query";
import { reactQueryClient } from "./shared/infrastructure/APIs/core/ReactQueryInstance";
import RestrictedRoute from "./shared/routing/restrictedRoute";
import AdminRoute from "./shared/routing/adminRoute";
import RootLayout from "./shared/layouts";
// import SingleWorkspaceLayout from "./modules/realtors/shared/layouts/SingleZipcodeLayout";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import SingleProfileMatchingOperationGeneral from "./modules/operations/profileMatchingOperation/singleProfileMatchingOperationGeneral/page/SingleProfileMatchingOperationGeneral";
import SingleProfileMatchingOperationLayout from "./modules/operations/profileMatchingOperation/shared/layouts/singleProfileMatchingOperationLayout";
// import SingleZipcodeLayout from "./modules/realtors/shared/layouts/SingleZipcodeLayout";

function App() {
  return (
    <Router>
      <QueryClientProvider client={reactQueryClient}>
        <ApolloWrapper>
          <CoreApp />
          <Toaster toastOptions={{ duration: 10000 }} position={"top-right"} />
        </ApolloWrapper>
      </QueryClientProvider>
    </Router>
  );
}

const CoreApp = () => {
  return (
    <>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.main />} />
        ))}

        {restrictedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<RestrictedRoute element={<route.main />} />}
          />
        ))}

        {adminLayoutRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <AdminRoute
                element={
                  <RootLayout>
                    <route.main />
                  </RootLayout>
                }
              />
            }
          />
        ))}

        {adminNonLayoutRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<AdminRoute element={<route.main />} />} />
        ))}

        {profileMatchingOperationRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <AdminRoute
                element={
                  <SingleProfileMatchingOperationLayout>
                    <route.main />
                  </SingleProfileMatchingOperationLayout>
                }
              />
            }
          />
        ))}

        {/* <Route path="*" element={<Navigate to="/not-found" />} /> */}
      </Routes>
    </>
  );
};

export default App;
