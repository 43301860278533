import { OPERATION_REQUEST_ITEM_TYPE } from "../../../../../shared/constants/models";
import { UseGetChildOperationsForParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetChildOperationsForParentOperation";
import { UseGetSingleParentOperation } from "../../../../../shared/domains/operations/graphql/hooks/useGetSingleParentOperation";

export const convertChildOperationsToSteps = (
  childOperations: UseGetChildOperationsForParentOperation["childOperations"]
) => {
  return childOperations.map(operation => {
    const completedRequestItems =
      operation.operation_request_items.filter(item => item.is_completed) ?? [];
    return {
      id: operation.id,
      stateId: operation.operation_state.id,
      stateName: operation.operation_state.name,
      triggerTypeId: operation.operation_trigger_type.id,
      triggerTypeName: operation.operation_trigger_type.name,
      description: operation.operation_type.name,
      itemsCompletedPercentage:
        operation.operation_request_items.length === 0
          ? 0
          : Math.floor(
              (completedRequestItems.length / operation.operation_request_items.length) * 100
            ),
      completedItems: completedRequestItems.length,
      totalItems: operation.operation_request_items.length,
      createdOn: operation.created_at,
    };
  });
};

export const extractPostalCodeIds = ({
  operationRequestItems,
}: {
  operationRequestItems: UseGetSingleParentOperation["singleParentOperationRequestItems"];
}) => {
  const postalCodeOperationRequestItems = operationRequestItems.filter(
    item => item.operation_request_item_type.id === OPERATION_REQUEST_ITEM_TYPE.POSTAL_CODE_ID
  );

  return postalCodeOperationRequestItems.map(item => Number(item.operation_request_item_value));
};
