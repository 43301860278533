import { useGetAddressesAndPersonsByPostalCodeIdsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseGetAddressesAndPersonsByPostalCodeIds = ReturnType<
  typeof useGetAddressesAndPersonsByPostalCodeIds
>;

export const useGetAddressesAndPersonsByPostalCodeIds = ({
  postalCodeIds,
}: {
  postalCodeIds: number[];
}) => {
  const { data, loading, error } = useGetAddressesAndPersonsByPostalCodeIdsQuery({
    variables: {
      postalCodeIds,
    },
  });

  return {
    addressesAndPersons: data && Array.isArray(data.address) ? data.address : [],
    addressesAndPersonsError: error,
    addressesAndPersonsLoading: loading,
  };
};
