import { OPERATION_REQUEST_ITEM_TYPE } from "../../../../shared/constants/models";
import { CreateParentOperationAPIBody } from "../../../../shared/domains/operations/rest/createParentOperationAPI";
import { CreateOperationValues } from "../validation";

export function createOperationAPIBody(
  {operationTypeId, operationValues, operationRequestItemTypeId  }: CreateOperationValues
): CreateParentOperationAPIBody {
  return {
    parentOperationTypeId: operationTypeId,
    parentOperationRequestItems: operationValues.map(value => ({
      parentOperationRequestItemTypeId: operationRequestItemTypeId,
      parentOperationRequestItemValue: value
    }))
  };
}

export function getOperationRequestItemTypeDisplayName(value: number): string | undefined {
  const key = Object.keys(OPERATION_REQUEST_ITEM_TYPE).find(
    key => OPERATION_REQUEST_ITEM_TYPE[key as keyof typeof OPERATION_REQUEST_ITEM_TYPE] === value
  );
  return key ? key.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) : undefined;
}

export function requestedItemsDisplay(arr: any[], type: string | undefined): string {
  if (arr.length < 3) {
    return `${type}: ${arr.join(', ')}`;
  } else {
    return `${arr.length} ${type}`;
  }
}


export function getLastPageOffset(operationsCount: number, pageSize: number): number {
  return Math.floor(operationsCount / pageSize) * pageSize;
}

export function getBadgeValue(status: string): "error" | "default" | "neutral" | "success" | "warning" {
  switch (status.toLowerCase()) {
    case 'active':
      return 'success';
    case 'paused':
      return 'warning';
    case 'completed':
      return 'neutral';
    case 'cancelled':
      return 'default';
    case 'errored':
      return 'error';
    default:
      return 'default';
  }
}
