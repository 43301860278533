import { gql } from "@apollo/client";

export const getOperationTypes = gql`
  query getOperationTypes {
    operation_type {
      id
      name
    }
  }
`;
