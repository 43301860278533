import { useQuery } from "@apollo/client";
import { getOperationTypes } from "../queries/getOperationTypes";
import { GetOperationTypesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseOperationsTypes = ReturnType<typeof useOperationsTypes>;

export const useOperationsTypes = () => {
  const { data, loading, error } = useQuery<GetOperationTypesQuery>(getOperationTypes);
  return {
    operationTypes: data && Array.isArray(data.operation_type) ? data.operation_type : [],
    operationTypesError: error,
    operationTypesLoading: loading,
  };
};
