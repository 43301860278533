import { useGetChildOperationsForParentOperationIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseGetChildOperationsForParentOperation = ReturnType<
  typeof useGetChildOperationsForParentOperation
>;

export const useGetChildOperationsForParentOperation = ({
  parentOperationId,
}: {
  parentOperationId: number;
}) => {
  const { data, loading, error } = useGetChildOperationsForParentOperationIdQuery({
    variables: {
      parentId: parentOperationId,
    },
  });

  return {
    childOperations: data && Array.isArray(data.operation) ? data.operation : [],
    childOperationsError: error,
    childOperationsLoading: loading,
  };
};
