import { gql } from "@apollo/client";

export const getPostalCodes = gql`
  query getPostalCodes {
    postal_code {
      code
      id
    }
  }
`;
