import { useEffect, useState } from "react";
import { Button } from "../../../../shared/components/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../../shared/components/drawer/Drawer";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseOperationsTypes } from "../../../../shared/domains/static/graphql/hooks/useGetOperationTypes";
import { createOperationSchema, CreateOperationValues } from "../validation";
import WindowedSelect from "react-windowed-select";
import { getRequestItemType, OPERATION_TYPE } from "../../../../shared/constants/models";
import { getOperationRequestItemTypeDisplayName } from "../utils";

interface DataTableDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  operationTypes: UseOperationsTypes["operationTypes"];
  onCreateOperation: (data: CreateOperationValues) => Promise<void>;
  postalCodes: { label: string; value: number; }[];
}

export type Values = {
  operationTypeId: number;
  operationValues: { label: string; value: number }[];
};

export function DataTableDrawer({
  open,
  onOpenChange,
  operationTypes,
  onCreateOperation,
  postalCodes,
}: DataTableDrawerProps) {
  const [operationValuesOptions, setOperationValuesOptions] = useState<{ label: string; value: number }[]>([]);
  const [operationTypeId, setOperationTypeId] = useState<number>();
  const [operationRequestItemType, setOperationRequestItemType] = useState<number>();
  const {
    handleSubmit,
    control: createOperationControl,
    formState: { errors: createOperationErrors },
    reset,
  } = useForm<Values>({
    resolver: yupResolver(createOperationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    const resetState = () => {
      setOperationTypeId(undefined);
      setOperationRequestItemType(undefined);
      reset({
        operationTypeId: undefined,
        operationValues: [],
      });
      setOperationValuesOptions([]);
    };
  
    const fetchData = async () => {
      if (operationTypeId === OPERATION_TYPE.PROFILE_MATCHING && postalCodes) {
        setOperationValuesOptions(postalCodes);
      }
    };
  
    if (!open) {
      resetState();
    } else {
      fetchData();
    }
  }, [open, reset, operationTypeId, postalCodes]);

  const handleCreateOperation = async (payload: Values) => {
    await onCreateOperation({
      operationTypeId: Number(payload.operationTypeId),
      operationValues: payload.operationValues.map(option => option.value),
      operationRequestItemTypeId: operationRequestItemType ?? 0,
    });
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className="flex flex-col h-full  max-h-[98vh] overflow-x-hidden sm:max-w-lg dark:bg-gray-925">
        <DrawerHeader className="-px-6 w-full">
          <DrawerTitle className="flex w-full items-center justify-between">
            <span>New Operation</span>
          </DrawerTitle>
        </DrawerHeader>
        <form onSubmit={handleSubmit(handleCreateOperation)} className="flex flex-col h-full">
          <DrawerBody className="flex-grow overflow-y-auto -mx-6 space-y-4 px-6 pb-6">
            <div>
              <Label htmlFor="operationType" className="font-medium">
                Operation Type
              </Label>
              <Controller
                name="operationTypeId"
                control={createOperationControl}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setOperationRequestItemType(getRequestItemType(Number(value)));
                      setOperationTypeId(Number(value));
                    }}
                    value={field.value?.toString()}
                  >
                    <SelectTrigger className="mt-2">
                      <SelectValue placeholder="Select an operation type" />
                    </SelectTrigger>
                    <SelectContent>
                      {operationTypes.map((type) => (
                        <SelectItem key={type.id} value={type.id?.toString()}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              {createOperationErrors.operationTypeId && (
                <ErrorMessage>{createOperationErrors.operationTypeId.message}</ErrorMessage>
              )}
            </div>
            {operationRequestItemType && <div>
              <Label htmlFor="operationValues" className="font-medium">
                {getOperationRequestItemTypeDisplayName(operationRequestItemType)}
              </Label>
                <Controller
                name="operationValues"
                control={createOperationControl}
                render={({ field }) => (
                  <WindowedSelect
                  windowThreshold={0} {...field}
                  options={operationValuesOptions}
                  isMulti
                  onChange={(selected) => {
                    field.onChange(selected);
                  } }
                  value={field.value}
                  placeholder={"Select operation values"}
                />
                )}
              />
              {createOperationErrors.operationValues && (
                <ErrorMessage>{createOperationErrors.operationValues.message}</ErrorMessage>
              )}
            </div>
            }
          </DrawerBody>
          <DrawerFooter className="flex-shrink-0 -mx-6 -mb-2 gap-2 bg-white px-6 dark:bg-gray-925">
            <DrawerClose>
              <Button type="button" variant="secondary" className="w-full">
                Cancel
              </Button>
            </DrawerClose>

            <Button disabled={!operationRequestItemType} type="submit" className="w-full sm:max-w-[4.5rem]">
              Create
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
