import { House, Tag, User } from "lucide-react";

export const topNavigation = [{ name: "Dashboard", path: "/dashboard", icon: House }];

export const operationNavigation = [
  {
    name: "Operations",
    path: "/operations",
    icon: Tag,
  },
] as const;


export const orgName = "MeetMore";
