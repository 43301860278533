import { ReactElement } from "react";
import SignIn from "../../../modules/authentication/signIn/page/SignIn";
import ForgotPassword from "../../../modules/authentication/forgotPassword/page/ForgotPass";
import AccessDenied from "../../../modules/authentication/accessDenied/page/AccessDenied";

// import Dashboard from "../../../modules/dashboard/page/Dashboard";
import Operations from "../../../modules/operations/operations/page/Operations";
import SingleProfileMatchingOperationGeneral from "../../../modules/operations/profileMatchingOperation/singleProfileMatchingOperationGeneral/page/SingleProfileMatchingOperationGeneral";
import SingleProfileMatchingOperationAddresses from "../../../modules/operations/profileMatchingOperation/singleProfileMatchingOperationAddresses/page/SingleProfileMatchingOperationAddresses";
import SingleProfileMatchingOperationPersons from "../../../modules/operations/profileMatchingOperation/singleProfileMatchingOperationPersons/page/SingleProfileMatchingOperationPersons";
import SingleProfileMatchingOperationProfiles from "../../../modules/operations/profileMatchingOperation/singleProfileMatchingOperationProfiles/page/SingleProfileMatchingOperationProfiles";

export interface Route {
  path: string;
  exact: boolean;
  main: (any: any) => ReactElement;
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/access-denied",
    main: AccessDenied,
    exact: true,
  },
];

// routes that can be access when user is inauthenticated
export const restrictedRoutes: Route[] = [
  {
    path: "/",
    main: SignIn,
    exact: true,
  },

  {
    path: "/forgot-password",
    main: ForgotPassword,
    exact: true,
  },
];

export const adminLayoutRoutes: Route[] = [
  // {
  //   path: "/dashboard",
  //   main: Dashboard,
  //   exact: true,
  // },
  {
    path: "/operations",
    main: Operations,
    exact: true,
  },
];

export const adminNonLayoutRoutes: Route[] = [
  {
    path: "/operations/profile-matching/:operationId",
    main: Operations,
    exact: true,
  },
];

export const profileMatchingOperationRoutes: Route[] = [
  {
    path: "/operations/profile-matching/:operationId/general",
    main: SingleProfileMatchingOperationGeneral,
    exact: true,
  },
  {
    path: "/operations/profile-matching/:operationId/addresses",
    main: SingleProfileMatchingOperationAddresses,
    exact: true,
  },
  {
    path: "/operations/profile-matching/:operationId/persons",
    main: SingleProfileMatchingOperationPersons,
    exact: true,
  },
  {
    path: "/operations/profile-matching/:operationId/profiles",
    main: SingleProfileMatchingOperationProfiles,
    exact: true,
  },
];
