import { useLazyQuery } from "@apollo/client";
import { getParentOperations } from "../queries/getParentOperations";
import { GetParentOperationsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseGetParentOperations = ReturnType<typeof useGetParentOperations>;

export const useGetParentOperations = () => {
  const [fetchOperations, { data, loading, error }] =
    useLazyQuery<GetParentOperationsQuery>(getParentOperations);

  return {
    operations: data && Array.isArray(data.operation) ? data.operation : [],
    parentOperations:
      data && Array.isArray(data.operation)
        ? data.operation.filter(operation => !operation.parent_operation_id)
        : [],
    operationsCount: data?.operation_aggregate.aggregate?.count ?? 0,
    operationsError: error,
    operationsLoading: loading,
    fetchOperations,
  };
};
