import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "_yoyo_log" */
export type _Yoyo_Log = {
  __typename?: '_yoyo_log';
  comment?: Maybe<Scalars['String']>;
  created_at_utc?: Maybe<Scalars['timestamp']>;
  hostname?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  migration_hash?: Maybe<Scalars['String']>;
  migration_id?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "_yoyo_log" */
export type _Yoyo_Log_Aggregate = {
  __typename?: '_yoyo_log_aggregate';
  aggregate?: Maybe<_Yoyo_Log_Aggregate_Fields>;
  nodes: Array<_Yoyo_Log>;
};

/** aggregate fields of "_yoyo_log" */
export type _Yoyo_Log_Aggregate_Fields = {
  __typename?: '_yoyo_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<_Yoyo_Log_Max_Fields>;
  min?: Maybe<_Yoyo_Log_Min_Fields>;
};


/** aggregate fields of "_yoyo_log" */
export type _Yoyo_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Yoyo_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "_yoyo_log". All fields are combined with a logical 'AND'. */
export type _Yoyo_Log_Bool_Exp = {
  _and?: InputMaybe<Array<_Yoyo_Log_Bool_Exp>>;
  _not?: InputMaybe<_Yoyo_Log_Bool_Exp>;
  _or?: InputMaybe<Array<_Yoyo_Log_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at_utc?: InputMaybe<Timestamp_Comparison_Exp>;
  hostname?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  migration_hash?: InputMaybe<String_Comparison_Exp>;
  migration_id?: InputMaybe<String_Comparison_Exp>;
  operation?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_yoyo_log" */
export enum _Yoyo_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  YoyoLogPkey = '_yoyo_log_pkey'
}

/** input type for inserting data into table "_yoyo_log" */
export type _Yoyo_Log_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at_utc?: InputMaybe<Scalars['timestamp']>;
  hostname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type _Yoyo_Log_Max_Fields = {
  __typename?: '_yoyo_log_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at_utc?: Maybe<Scalars['timestamp']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  migration_hash?: Maybe<Scalars['String']>;
  migration_id?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type _Yoyo_Log_Min_Fields = {
  __typename?: '_yoyo_log_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at_utc?: Maybe<Scalars['timestamp']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  migration_hash?: Maybe<Scalars['String']>;
  migration_id?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "_yoyo_log" */
export type _Yoyo_Log_Mutation_Response = {
  __typename?: '_yoyo_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<_Yoyo_Log>;
};

/** on_conflict condition type for table "_yoyo_log" */
export type _Yoyo_Log_On_Conflict = {
  constraint: _Yoyo_Log_Constraint;
  update_columns?: Array<_Yoyo_Log_Update_Column>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "_yoyo_log". */
export type _Yoyo_Log_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at_utc?: InputMaybe<Order_By>;
  hostname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migration_hash?: InputMaybe<Order_By>;
  migration_id?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _yoyo_log */
export type _Yoyo_Log_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "_yoyo_log" */
export enum _Yoyo_Log_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAtUtc = 'created_at_utc',
  /** column name */
  Hostname = 'hostname',
  /** column name */
  Id = 'id',
  /** column name */
  MigrationHash = 'migration_hash',
  /** column name */
  MigrationId = 'migration_id',
  /** column name */
  Operation = 'operation',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "_yoyo_log" */
export type _Yoyo_Log_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at_utc?: InputMaybe<Scalars['timestamp']>;
  hostname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "_yoyo_log" */
export type _Yoyo_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Yoyo_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Yoyo_Log_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at_utc?: InputMaybe<Scalars['timestamp']>;
  hostname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "_yoyo_log" */
export enum _Yoyo_Log_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAtUtc = 'created_at_utc',
  /** column name */
  Hostname = 'hostname',
  /** column name */
  Id = 'id',
  /** column name */
  MigrationHash = 'migration_hash',
  /** column name */
  MigrationId = 'migration_id',
  /** column name */
  Operation = 'operation',
  /** column name */
  Username = 'username'
}

export type _Yoyo_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Yoyo_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Yoyo_Log_Bool_Exp;
};

/** columns and relationships of "_yoyo_migration" */
export type _Yoyo_Migration = {
  __typename?: '_yoyo_migration';
  applied_at_utc?: Maybe<Scalars['timestamp']>;
  migration_hash: Scalars['String'];
  migration_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "_yoyo_migration" */
export type _Yoyo_Migration_Aggregate = {
  __typename?: '_yoyo_migration_aggregate';
  aggregate?: Maybe<_Yoyo_Migration_Aggregate_Fields>;
  nodes: Array<_Yoyo_Migration>;
};

/** aggregate fields of "_yoyo_migration" */
export type _Yoyo_Migration_Aggregate_Fields = {
  __typename?: '_yoyo_migration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<_Yoyo_Migration_Max_Fields>;
  min?: Maybe<_Yoyo_Migration_Min_Fields>;
};


/** aggregate fields of "_yoyo_migration" */
export type _Yoyo_Migration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Yoyo_Migration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "_yoyo_migration". All fields are combined with a logical 'AND'. */
export type _Yoyo_Migration_Bool_Exp = {
  _and?: InputMaybe<Array<_Yoyo_Migration_Bool_Exp>>;
  _not?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
  _or?: InputMaybe<Array<_Yoyo_Migration_Bool_Exp>>;
  applied_at_utc?: InputMaybe<Timestamp_Comparison_Exp>;
  migration_hash?: InputMaybe<String_Comparison_Exp>;
  migration_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "_yoyo_migration" */
export enum _Yoyo_Migration_Constraint {
  /** unique or primary key constraint on columns "migration_hash" */
  YoyoMigrationPkey = '_yoyo_migration_pkey'
}

/** input type for inserting data into table "_yoyo_migration" */
export type _Yoyo_Migration_Insert_Input = {
  applied_at_utc?: InputMaybe<Scalars['timestamp']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type _Yoyo_Migration_Max_Fields = {
  __typename?: '_yoyo_migration_max_fields';
  applied_at_utc?: Maybe<Scalars['timestamp']>;
  migration_hash?: Maybe<Scalars['String']>;
  migration_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type _Yoyo_Migration_Min_Fields = {
  __typename?: '_yoyo_migration_min_fields';
  applied_at_utc?: Maybe<Scalars['timestamp']>;
  migration_hash?: Maybe<Scalars['String']>;
  migration_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "_yoyo_migration" */
export type _Yoyo_Migration_Mutation_Response = {
  __typename?: '_yoyo_migration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<_Yoyo_Migration>;
};

/** on_conflict condition type for table "_yoyo_migration" */
export type _Yoyo_Migration_On_Conflict = {
  constraint: _Yoyo_Migration_Constraint;
  update_columns?: Array<_Yoyo_Migration_Update_Column>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};

/** Ordering options when selecting data from "_yoyo_migration". */
export type _Yoyo_Migration_Order_By = {
  applied_at_utc?: InputMaybe<Order_By>;
  migration_hash?: InputMaybe<Order_By>;
  migration_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _yoyo_migration */
export type _Yoyo_Migration_Pk_Columns_Input = {
  migration_hash: Scalars['String'];
};

/** select columns of table "_yoyo_migration" */
export enum _Yoyo_Migration_Select_Column {
  /** column name */
  AppliedAtUtc = 'applied_at_utc',
  /** column name */
  MigrationHash = 'migration_hash',
  /** column name */
  MigrationId = 'migration_id'
}

/** input type for updating data in table "_yoyo_migration" */
export type _Yoyo_Migration_Set_Input = {
  applied_at_utc?: InputMaybe<Scalars['timestamp']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "_yoyo_migration" */
export type _Yoyo_Migration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Yoyo_Migration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Yoyo_Migration_Stream_Cursor_Value_Input = {
  applied_at_utc?: InputMaybe<Scalars['timestamp']>;
  migration_hash?: InputMaybe<Scalars['String']>;
  migration_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "_yoyo_migration" */
export enum _Yoyo_Migration_Update_Column {
  /** column name */
  AppliedAtUtc = 'applied_at_utc',
  /** column name */
  MigrationHash = 'migration_hash',
  /** column name */
  MigrationId = 'migration_id'
}

export type _Yoyo_Migration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Yoyo_Migration_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Yoyo_Migration_Bool_Exp;
};

/** columns and relationships of "_yoyo_version" */
export type _Yoyo_Version = {
  __typename?: '_yoyo_version';
  installed_at_utc?: Maybe<Scalars['timestamp']>;
  version: Scalars['Int'];
};

/** aggregated selection of "_yoyo_version" */
export type _Yoyo_Version_Aggregate = {
  __typename?: '_yoyo_version_aggregate';
  aggregate?: Maybe<_Yoyo_Version_Aggregate_Fields>;
  nodes: Array<_Yoyo_Version>;
};

/** aggregate fields of "_yoyo_version" */
export type _Yoyo_Version_Aggregate_Fields = {
  __typename?: '_yoyo_version_aggregate_fields';
  avg?: Maybe<_Yoyo_Version_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<_Yoyo_Version_Max_Fields>;
  min?: Maybe<_Yoyo_Version_Min_Fields>;
  stddev?: Maybe<_Yoyo_Version_Stddev_Fields>;
  stddev_pop?: Maybe<_Yoyo_Version_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<_Yoyo_Version_Stddev_Samp_Fields>;
  sum?: Maybe<_Yoyo_Version_Sum_Fields>;
  var_pop?: Maybe<_Yoyo_Version_Var_Pop_Fields>;
  var_samp?: Maybe<_Yoyo_Version_Var_Samp_Fields>;
  variance?: Maybe<_Yoyo_Version_Variance_Fields>;
};


/** aggregate fields of "_yoyo_version" */
export type _Yoyo_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Yoyo_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type _Yoyo_Version_Avg_Fields = {
  __typename?: '_yoyo_version_avg_fields';
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "_yoyo_version". All fields are combined with a logical 'AND'. */
export type _Yoyo_Version_Bool_Exp = {
  _and?: InputMaybe<Array<_Yoyo_Version_Bool_Exp>>;
  _not?: InputMaybe<_Yoyo_Version_Bool_Exp>;
  _or?: InputMaybe<Array<_Yoyo_Version_Bool_Exp>>;
  installed_at_utc?: InputMaybe<Timestamp_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "_yoyo_version" */
export enum _Yoyo_Version_Constraint {
  /** unique or primary key constraint on columns "version" */
  YoyoVersionPkey = '_yoyo_version_pkey'
}

/** input type for incrementing numeric columns in table "_yoyo_version" */
export type _Yoyo_Version_Inc_Input = {
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "_yoyo_version" */
export type _Yoyo_Version_Insert_Input = {
  installed_at_utc?: InputMaybe<Scalars['timestamp']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type _Yoyo_Version_Max_Fields = {
  __typename?: '_yoyo_version_max_fields';
  installed_at_utc?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type _Yoyo_Version_Min_Fields = {
  __typename?: '_yoyo_version_min_fields';
  installed_at_utc?: Maybe<Scalars['timestamp']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "_yoyo_version" */
export type _Yoyo_Version_Mutation_Response = {
  __typename?: '_yoyo_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<_Yoyo_Version>;
};

/** on_conflict condition type for table "_yoyo_version" */
export type _Yoyo_Version_On_Conflict = {
  constraint: _Yoyo_Version_Constraint;
  update_columns?: Array<_Yoyo_Version_Update_Column>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "_yoyo_version". */
export type _Yoyo_Version_Order_By = {
  installed_at_utc?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _yoyo_version */
export type _Yoyo_Version_Pk_Columns_Input = {
  version: Scalars['Int'];
};

/** select columns of table "_yoyo_version" */
export enum _Yoyo_Version_Select_Column {
  /** column name */
  InstalledAtUtc = 'installed_at_utc',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "_yoyo_version" */
export type _Yoyo_Version_Set_Input = {
  installed_at_utc?: InputMaybe<Scalars['timestamp']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type _Yoyo_Version_Stddev_Fields = {
  __typename?: '_yoyo_version_stddev_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type _Yoyo_Version_Stddev_Pop_Fields = {
  __typename?: '_yoyo_version_stddev_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type _Yoyo_Version_Stddev_Samp_Fields = {
  __typename?: '_yoyo_version_stddev_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "_yoyo_version" */
export type _Yoyo_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Yoyo_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Yoyo_Version_Stream_Cursor_Value_Input = {
  installed_at_utc?: InputMaybe<Scalars['timestamp']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type _Yoyo_Version_Sum_Fields = {
  __typename?: '_yoyo_version_sum_fields';
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "_yoyo_version" */
export enum _Yoyo_Version_Update_Column {
  /** column name */
  InstalledAtUtc = 'installed_at_utc',
  /** column name */
  Version = 'version'
}

export type _Yoyo_Version_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<_Yoyo_Version_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Yoyo_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Yoyo_Version_Bool_Exp;
};

/** aggregate var_pop on columns */
export type _Yoyo_Version_Var_Pop_Fields = {
  __typename?: '_yoyo_version_var_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type _Yoyo_Version_Var_Samp_Fields = {
  __typename?: '_yoyo_version_var_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type _Yoyo_Version_Variance_Fields = {
  __typename?: '_yoyo_version_variance_fields';
  version?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  city: Scalars['String'];
  country: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  external_parcel_id?: Maybe<Scalars['String']>;
  external_provider_id?: Maybe<Scalars['String']>;
  full_address: Scalars['String'];
  id: Scalars['Int'];
  line_1: Scalars['String'];
  line_2?: Maybe<Scalars['String']>;
  line_3?: Maybe<Scalars['String']>;
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  /** An object relationship */
  postal_code?: Maybe<Postal_Code>;
  postal_code_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  provider: Provider;
  provider_id: Scalars['Int'];
  raw_postal_code: Scalars['String'];
  /** An array relationship */
  raw_responses: Array<Raw_Response>;
  /** An aggregate relationship */
  raw_responses_aggregate: Raw_Response_Aggregate;
  raw_state?: Maybe<Scalars['String']>;
  /** An object relationship */
  state?: Maybe<State>;
  state_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "address" */
export type AddressDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressPerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressPerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressRaw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressRaw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: InputMaybe<Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_Max_Order_By>;
  min?: InputMaybe<Address_Min_Order_By>;
  stddev?: InputMaybe<Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Address_Sum_Order_By>;
  var_pop?: InputMaybe<Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  county?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  external_parcel_id?: InputMaybe<String_Comparison_Exp>;
  external_provider_id?: InputMaybe<String_Comparison_Exp>;
  full_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  line_1?: InputMaybe<String_Comparison_Exp>;
  line_2?: InputMaybe<String_Comparison_Exp>;
  line_3?: InputMaybe<String_Comparison_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  postal_code?: InputMaybe<Postal_Code_Bool_Exp>;
  postal_code_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  raw_postal_code?: InputMaybe<String_Comparison_Exp>;
  raw_responses?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Bool_Exp>;
  raw_state?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<State_Bool_Exp>;
  state_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey',
  /** unique or primary key constraint on columns "external_provider_id", "provider_id" */
  UqAddressExternalProvider = 'uq_address_external_provider'
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  postal_code_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  state_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  external_parcel_id?: InputMaybe<Scalars['String']>;
  external_provider_id?: InputMaybe<Scalars['String']>;
  full_address?: InputMaybe<Scalars['String']>;
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  line_3?: InputMaybe<Scalars['String']>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  postal_code?: InputMaybe<Postal_Code_Obj_Rel_Insert_Input>;
  postal_code_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  raw_postal_code?: InputMaybe<Scalars['String']>;
  raw_responses?: InputMaybe<Raw_Response_Arr_Rel_Insert_Input>;
  raw_state?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State_Obj_Rel_Insert_Input>;
  state_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_parcel_id?: Maybe<Scalars['String']>;
  external_provider_id?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  line_3?: Maybe<Scalars['String']>;
  postal_code_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  raw_postal_code?: Maybe<Scalars['String']>;
  raw_state?: Maybe<Scalars['String']>;
  state_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_parcel_id?: InputMaybe<Order_By>;
  external_provider_id?: InputMaybe<Order_By>;
  full_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  line_1?: InputMaybe<Order_By>;
  line_2?: InputMaybe<Order_By>;
  line_3?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  raw_postal_code?: InputMaybe<Order_By>;
  raw_state?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_parcel_id?: Maybe<Scalars['String']>;
  external_provider_id?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  line_3?: Maybe<Scalars['String']>;
  postal_code_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  raw_postal_code?: Maybe<Scalars['String']>;
  raw_state?: Maybe<Scalars['String']>;
  state_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_parcel_id?: InputMaybe<Order_By>;
  external_provider_id?: InputMaybe<Order_By>;
  full_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  line_1?: InputMaybe<Order_By>;
  line_2?: InputMaybe<Order_By>;
  line_3?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  raw_postal_code?: InputMaybe<Order_By>;
  raw_state?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  external_parcel_id?: InputMaybe<Order_By>;
  external_provider_id?: InputMaybe<Order_By>;
  full_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  line_1?: InputMaybe<Order_By>;
  line_2?: InputMaybe<Order_By>;
  line_3?: InputMaybe<Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  postal_code?: InputMaybe<Postal_Code_Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  raw_postal_code?: InputMaybe<Order_By>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Order_By>;
  raw_state?: InputMaybe<Order_By>;
  state?: InputMaybe<State_Order_By>;
  state_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalParcelId = 'external_parcel_id',
  /** column name */
  ExternalProviderId = 'external_provider_id',
  /** column name */
  FullAddress = 'full_address',
  /** column name */
  Id = 'id',
  /** column name */
  Line_1 = 'line_1',
  /** column name */
  Line_2 = 'line_2',
  /** column name */
  Line_3 = 'line_3',
  /** column name */
  PostalCodeId = 'postal_code_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  RawPostalCode = 'raw_postal_code',
  /** column name */
  RawState = 'raw_state',
  /** column name */
  StateId = 'state_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_parcel_id?: InputMaybe<Scalars['String']>;
  external_provider_id?: InputMaybe<Scalars['String']>;
  full_address?: InputMaybe<Scalars['String']>;
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  line_3?: InputMaybe<Scalars['String']>;
  postal_code_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  raw_postal_code?: InputMaybe<Scalars['String']>;
  raw_state?: InputMaybe<Scalars['String']>;
  state_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_parcel_id?: InputMaybe<Scalars['String']>;
  external_provider_id?: InputMaybe<Scalars['String']>;
  full_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  line_3?: InputMaybe<Scalars['String']>;
  postal_code_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  raw_postal_code?: InputMaybe<Scalars['String']>;
  raw_state?: InputMaybe<Scalars['String']>;
  state_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  id?: Maybe<Scalars['Int']>;
  postal_code_id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  state_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalParcelId = 'external_parcel_id',
  /** column name */
  ExternalProviderId = 'external_provider_id',
  /** column name */
  FullAddress = 'full_address',
  /** column name */
  Line_1 = 'line_1',
  /** column name */
  Line_2 = 'line_2',
  /** column name */
  Line_3 = 'line_3',
  /** column name */
  PostalCodeId = 'postal_code_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  RawPostalCode = 'raw_postal_code',
  /** column name */
  RawState = 'raw_state',
  /** column name */
  StateId = 'state_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  id?: Maybe<Scalars['Float']>;
  postal_code_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  state_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  postal_code_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  state_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "attribute" */
export type Attribute = {
  __typename?: 'attribute';
  /** An object relationship */
  attribute_type: Attribute_Type;
  attribute_type_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  entity_to_raw_response: Entity_To_Raw_Response;
  entity_to_raw_response_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
  value_boolean?: Maybe<Scalars['Boolean']>;
  value_date?: Maybe<Scalars['timestamptz']>;
  value_float?: Maybe<Scalars['float8']>;
  value_integer?: Maybe<Scalars['Int']>;
  value_json?: Maybe<Scalars['jsonb']>;
  value_string?: Maybe<Scalars['String']>;
  /** An object relationship */
  value_type: Value_Type;
  value_type_id: Scalars['Int'];
};


/** columns and relationships of "attribute" */
export type AttributeValue_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "attribute" */
export type Attribute_Aggregate = {
  __typename?: 'attribute_aggregate';
  aggregate?: Maybe<Attribute_Aggregate_Fields>;
  nodes: Array<Attribute>;
};

export type Attribute_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Attribute_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Attribute_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Attribute_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Attribute_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Attribute_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Attribute_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Attribute_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Attribute_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Attribute_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Attribute_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Attribute_Aggregate_Bool_Exp_Var_Samp>;
};

export type Attribute_Aggregate_Bool_Exp_Avg = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Bool_And = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Corr = {
  arguments: Attribute_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Attribute_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Attribute_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Attribute_Aggregate_Bool_Exp_Max = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Min = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Sum = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Attribute_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attribute_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "attribute" */
export type Attribute_Aggregate_Fields = {
  __typename?: 'attribute_aggregate_fields';
  avg?: Maybe<Attribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attribute_Max_Fields>;
  min?: Maybe<Attribute_Min_Fields>;
  stddev?: Maybe<Attribute_Stddev_Fields>;
  stddev_pop?: Maybe<Attribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attribute_Stddev_Samp_Fields>;
  sum?: Maybe<Attribute_Sum_Fields>;
  var_pop?: Maybe<Attribute_Var_Pop_Fields>;
  var_samp?: Maybe<Attribute_Var_Samp_Fields>;
  variance?: Maybe<Attribute_Variance_Fields>;
};


/** aggregate fields of "attribute" */
export type Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "attribute" */
export type Attribute_Aggregate_Order_By = {
  avg?: InputMaybe<Attribute_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attribute_Max_Order_By>;
  min?: InputMaybe<Attribute_Min_Order_By>;
  stddev?: InputMaybe<Attribute_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attribute_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attribute_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attribute_Sum_Order_By>;
  var_pop?: InputMaybe<Attribute_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attribute_Var_Samp_Order_By>;
  variance?: InputMaybe<Attribute_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Attribute_Append_Input = {
  value_json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "attribute" */
export type Attribute_Arr_Rel_Insert_Input = {
  data: Array<Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attribute_On_Conflict>;
};

/** aggregate avg on columns */
export type Attribute_Avg_Fields = {
  __typename?: 'attribute_avg_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "attribute" */
export type Attribute_Avg_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attribute". All fields are combined with a logical 'AND'. */
export type Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Attribute_Bool_Exp>>;
  _not?: InputMaybe<Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Attribute_Bool_Exp>>;
  attribute_type?: InputMaybe<Attribute_Type_Bool_Exp>;
  attribute_type_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_to_raw_response?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_response_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  value_boolean?: InputMaybe<Boolean_Comparison_Exp>;
  value_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  value_float?: InputMaybe<Float8_Comparison_Exp>;
  value_integer?: InputMaybe<Int_Comparison_Exp>;
  value_json?: InputMaybe<Jsonb_Comparison_Exp>;
  value_string?: InputMaybe<String_Comparison_Exp>;
  value_type?: InputMaybe<Value_Type_Bool_Exp>;
  value_type_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "attribute" */
export enum Attribute_Constraint {
  /** unique or primary key constraint on columns "id" */
  AttributePkey = 'attribute_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Attribute_Delete_At_Path_Input = {
  value_json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Attribute_Delete_Elem_Input = {
  value_json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Attribute_Delete_Key_Input = {
  value_json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "attribute" */
export type Attribute_Inc_Input = {
  attribute_type_id?: InputMaybe<Scalars['Int']>;
  entity_to_raw_response_id?: InputMaybe<Scalars['Int']>;
  value_float?: InputMaybe<Scalars['float8']>;
  value_integer?: InputMaybe<Scalars['Int']>;
  value_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "attribute" */
export type Attribute_Insert_Input = {
  attribute_type?: InputMaybe<Attribute_Type_Obj_Rel_Insert_Input>;
  attribute_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_to_raw_response?: InputMaybe<Entity_To_Raw_Response_Obj_Rel_Insert_Input>;
  entity_to_raw_response_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
  value_boolean?: InputMaybe<Scalars['Boolean']>;
  value_date?: InputMaybe<Scalars['timestamptz']>;
  value_float?: InputMaybe<Scalars['float8']>;
  value_integer?: InputMaybe<Scalars['Int']>;
  value_json?: InputMaybe<Scalars['jsonb']>;
  value_string?: InputMaybe<Scalars['String']>;
  value_type?: InputMaybe<Value_Type_Obj_Rel_Insert_Input>;
  value_type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Attribute_Max_Fields = {
  __typename?: 'attribute_max_fields';
  attribute_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_to_raw_response_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
  value_date?: Maybe<Scalars['timestamptz']>;
  value_float?: Maybe<Scalars['float8']>;
  value_integer?: Maybe<Scalars['Int']>;
  value_string?: Maybe<Scalars['String']>;
  value_type_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "attribute" */
export type Attribute_Max_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value_date?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_string?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attribute_Min_Fields = {
  __typename?: 'attribute_min_fields';
  attribute_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_to_raw_response_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
  value_date?: Maybe<Scalars['timestamptz']>;
  value_float?: Maybe<Scalars['float8']>;
  value_integer?: Maybe<Scalars['Int']>;
  value_string?: Maybe<Scalars['String']>;
  value_type_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "attribute" */
export type Attribute_Min_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value_date?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_string?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "attribute" */
export type Attribute_Mutation_Response = {
  __typename?: 'attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attribute>;
};

/** on_conflict condition type for table "attribute" */
export type Attribute_On_Conflict = {
  constraint: Attribute_Constraint;
  update_columns?: Array<Attribute_Update_Column>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "attribute". */
export type Attribute_Order_By = {
  attribute_type?: InputMaybe<Attribute_Type_Order_By>;
  attribute_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_to_raw_response?: InputMaybe<Entity_To_Raw_Response_Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value_boolean?: InputMaybe<Order_By>;
  value_date?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_json?: InputMaybe<Order_By>;
  value_string?: InputMaybe<Order_By>;
  value_type?: InputMaybe<Value_Type_Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attribute */
export type Attribute_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Attribute_Prepend_Input = {
  value_json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "attribute" */
export enum Attribute_Select_Column {
  /** column name */
  AttributeTypeId = 'attribute_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityToRawResponseId = 'entity_to_raw_response_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidAt = 'valid_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to',
  /** column name */
  ValueBoolean = 'value_boolean',
  /** column name */
  ValueDate = 'value_date',
  /** column name */
  ValueFloat = 'value_float',
  /** column name */
  ValueInteger = 'value_integer',
  /** column name */
  ValueJson = 'value_json',
  /** column name */
  ValueString = 'value_string',
  /** column name */
  ValueTypeId = 'value_type_id'
}

/** select "attribute_aggregate_bool_exp_avg_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_bool_and_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValueBoolean = 'value_boolean'
}

/** select "attribute_aggregate_bool_exp_bool_or_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValueBoolean = 'value_boolean'
}

/** select "attribute_aggregate_bool_exp_corr_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_max_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_min_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_sum_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** select "attribute_aggregate_bool_exp_var_samp_arguments_columns" columns of table "attribute" */
export enum Attribute_Select_Column_Attribute_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ValueFloat = 'value_float'
}

/** input type for updating data in table "attribute" */
export type Attribute_Set_Input = {
  attribute_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_to_raw_response_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
  value_boolean?: InputMaybe<Scalars['Boolean']>;
  value_date?: InputMaybe<Scalars['timestamptz']>;
  value_float?: InputMaybe<Scalars['float8']>;
  value_integer?: InputMaybe<Scalars['Int']>;
  value_json?: InputMaybe<Scalars['jsonb']>;
  value_string?: InputMaybe<Scalars['String']>;
  value_type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Attribute_Stddev_Fields = {
  __typename?: 'attribute_stddev_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "attribute" */
export type Attribute_Stddev_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attribute_Stddev_Pop_Fields = {
  __typename?: 'attribute_stddev_pop_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "attribute" */
export type Attribute_Stddev_Pop_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attribute_Stddev_Samp_Fields = {
  __typename?: 'attribute_stddev_samp_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "attribute" */
export type Attribute_Stddev_Samp_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attribute" */
export type Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attribute_Stream_Cursor_Value_Input = {
  attribute_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_to_raw_response_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
  value_boolean?: InputMaybe<Scalars['Boolean']>;
  value_date?: InputMaybe<Scalars['timestamptz']>;
  value_float?: InputMaybe<Scalars['float8']>;
  value_integer?: InputMaybe<Scalars['Int']>;
  value_json?: InputMaybe<Scalars['jsonb']>;
  value_string?: InputMaybe<Scalars['String']>;
  value_type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Attribute_Sum_Fields = {
  __typename?: 'attribute_sum_fields';
  attribute_type_id?: Maybe<Scalars['Int']>;
  entity_to_raw_response_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  value_float?: Maybe<Scalars['float8']>;
  value_integer?: Maybe<Scalars['Int']>;
  value_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "attribute" */
export type Attribute_Sum_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "attribute_type" */
export type Attribute_Type = {
  __typename?: 'attribute_type';
  /** An array relationship */
  attributes: Array<Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: Attribute_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "attribute_type" */
export type Attribute_TypeAttributesArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


/** columns and relationships of "attribute_type" */
export type Attribute_TypeAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};

/** aggregated selection of "attribute_type" */
export type Attribute_Type_Aggregate = {
  __typename?: 'attribute_type_aggregate';
  aggregate?: Maybe<Attribute_Type_Aggregate_Fields>;
  nodes: Array<Attribute_Type>;
};

/** aggregate fields of "attribute_type" */
export type Attribute_Type_Aggregate_Fields = {
  __typename?: 'attribute_type_aggregate_fields';
  avg?: Maybe<Attribute_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attribute_Type_Max_Fields>;
  min?: Maybe<Attribute_Type_Min_Fields>;
  stddev?: Maybe<Attribute_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Attribute_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attribute_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Attribute_Type_Sum_Fields>;
  var_pop?: Maybe<Attribute_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Attribute_Type_Var_Samp_Fields>;
  variance?: Maybe<Attribute_Type_Variance_Fields>;
};


/** aggregate fields of "attribute_type" */
export type Attribute_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attribute_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Attribute_Type_Avg_Fields = {
  __typename?: 'attribute_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "attribute_type". All fields are combined with a logical 'AND'. */
export type Attribute_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Attribute_Type_Bool_Exp>>;
  _not?: InputMaybe<Attribute_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Attribute_Type_Bool_Exp>>;
  attributes?: InputMaybe<Attribute_Bool_Exp>;
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "attribute_type" */
export enum Attribute_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  AttributeTypeNameKey = 'attribute_type_name_key',
  /** unique or primary key constraint on columns "id" */
  AttributeTypePkey = 'attribute_type_pkey'
}

/** input type for incrementing numeric columns in table "attribute_type" */
export type Attribute_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "attribute_type" */
export type Attribute_Type_Insert_Input = {
  attributes?: InputMaybe<Attribute_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Attribute_Type_Max_Fields = {
  __typename?: 'attribute_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Attribute_Type_Min_Fields = {
  __typename?: 'attribute_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "attribute_type" */
export type Attribute_Type_Mutation_Response = {
  __typename?: 'attribute_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attribute_Type>;
};

/** input type for inserting object relation for remote table "attribute_type" */
export type Attribute_Type_Obj_Rel_Insert_Input = {
  data: Attribute_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attribute_Type_On_Conflict>;
};

/** on_conflict condition type for table "attribute_type" */
export type Attribute_Type_On_Conflict = {
  constraint: Attribute_Type_Constraint;
  update_columns?: Array<Attribute_Type_Update_Column>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "attribute_type". */
export type Attribute_Type_Order_By = {
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attribute_type */
export type Attribute_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "attribute_type" */
export enum Attribute_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "attribute_type" */
export type Attribute_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Attribute_Type_Stddev_Fields = {
  __typename?: 'attribute_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attribute_Type_Stddev_Pop_Fields = {
  __typename?: 'attribute_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attribute_Type_Stddev_Samp_Fields = {
  __typename?: 'attribute_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "attribute_type" */
export type Attribute_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attribute_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attribute_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Attribute_Type_Sum_Fields = {
  __typename?: 'attribute_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "attribute_type" */
export enum Attribute_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Attribute_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attribute_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attribute_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attribute_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attribute_Type_Var_Pop_Fields = {
  __typename?: 'attribute_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attribute_Type_Var_Samp_Fields = {
  __typename?: 'attribute_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attribute_Type_Variance_Fields = {
  __typename?: 'attribute_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "attribute" */
export enum Attribute_Update_Column {
  /** column name */
  AttributeTypeId = 'attribute_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityToRawResponseId = 'entity_to_raw_response_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidAt = 'valid_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to',
  /** column name */
  ValueBoolean = 'value_boolean',
  /** column name */
  ValueDate = 'value_date',
  /** column name */
  ValueFloat = 'value_float',
  /** column name */
  ValueInteger = 'value_integer',
  /** column name */
  ValueJson = 'value_json',
  /** column name */
  ValueString = 'value_string',
  /** column name */
  ValueTypeId = 'value_type_id'
}

export type Attribute_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Attribute_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Attribute_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Attribute_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Attribute_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attribute_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Attribute_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attribute_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attribute_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attribute_Var_Pop_Fields = {
  __typename?: 'attribute_var_pop_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "attribute" */
export type Attribute_Var_Pop_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attribute_Var_Samp_Fields = {
  __typename?: 'attribute_var_samp_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "attribute" */
export type Attribute_Var_Samp_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Attribute_Variance_Fields = {
  __typename?: 'attribute_variance_fields';
  attribute_type_id?: Maybe<Scalars['Float']>;
  entity_to_raw_response_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value_float?: Maybe<Scalars['Float']>;
  value_integer?: Maybe<Scalars['Float']>;
  value_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "attribute" */
export type Attribute_Variance_Order_By = {
  attribute_type_id?: InputMaybe<Order_By>;
  entity_to_raw_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value_float?: InputMaybe<Order_By>;
  value_integer?: InputMaybe<Order_By>;
  value_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  postal_codes: Array<Postal_Code>;
  /** An aggregate relationship */
  postal_codes_aggregate: Postal_Code_Aggregate;
  /** An object relationship */
  provider?: Maybe<Provider>;
  provider_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  states: Array<State>;
  /** An aggregate relationship */
  states_aggregate: State_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "country" */
export type CountryPostal_CodesArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryPostal_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryStatesArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

export type Country_Aggregate_Bool_Exp = {
  count?: InputMaybe<Country_Aggregate_Bool_Exp_Count>;
};

export type Country_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Country_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  avg?: Maybe<Country_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
  stddev?: Maybe<Country_Stddev_Fields>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Fields>;
  sum?: Maybe<Country_Sum_Fields>;
  var_pop?: Maybe<Country_Var_Pop_Fields>;
  var_samp?: Maybe<Country_Var_Samp_Fields>;
  variance?: Maybe<Country_Variance_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "country" */
export type Country_Aggregate_Order_By = {
  avg?: InputMaybe<Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Country_Max_Order_By>;
  min?: InputMaybe<Country_Min_Order_By>;
  stddev?: InputMaybe<Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Country_Sum_Order_By>;
  var_pop?: InputMaybe<Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "country" */
export type Country_Arr_Rel_Insert_Input = {
  data: Array<Country_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** aggregate avg on columns */
export type Country_Avg_Fields = {
  __typename?: 'country_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "country" */
export type Country_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  postal_codes?: InputMaybe<Postal_Code_Bool_Exp>;
  postal_codes_aggregate?: InputMaybe<Postal_Code_Aggregate_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  states?: InputMaybe<State_Bool_Exp>;
  states_aggregate?: InputMaybe<State_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "code" */
  CountryCodeKey = 'country_code_key',
  /** unique or primary key constraint on columns "name" */
  CountryNameKey = 'country_name_key',
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for incrementing numeric columns in table "country" */
export type Country_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  postal_codes?: InputMaybe<Postal_Code_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<State_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "country" */
export type Country_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "country" */
export type Country_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  postal_codes_aggregate?: InputMaybe<Postal_Code_Aggregate_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  states_aggregate?: InputMaybe<State_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Country_Stddev_Fields = {
  __typename?: 'country_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "country" */
export type Country_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Country_Stddev_Pop_Fields = {
  __typename?: 'country_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "country" */
export type Country_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Country_Stddev_Samp_Fields = {
  __typename?: 'country_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "country" */
export type Country_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Country_Sum_Fields = {
  __typename?: 'country_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "country" */
export type Country_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Country_Var_Pop_Fields = {
  __typename?: 'country_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "country" */
export type Country_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Country_Var_Samp_Fields = {
  __typename?: 'country_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "country" */
export type Country_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Country_Variance_Fields = {
  __typename?: 'country_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "country" */
export type Country_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "digital_profile" */
export type Digital_Profile = {
  __typename?: 'digital_profile';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  digital_profile_to_people: Array<Digital_Profile_To_Person>;
  /** An aggregate relationship */
  digital_profile_to_people_aggregate: Digital_Profile_To_Person_Aggregate;
  /** An object relationship */
  digital_profile_type: Digital_Profile_Type;
  digital_profile_type_id: Scalars['Int'];
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  external_id: Scalars['String'];
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  primary_subtitle?: Maybe<Scalars['String']>;
  /** An object relationship */
  provider: Provider;
  provider_id: Scalars['Int'];
  /** An array relationship */
  raw_responses: Array<Raw_Response>;
  /** An aggregate relationship */
  raw_responses_aggregate: Raw_Response_Aggregate;
  secondary_subtitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  valid_to?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileDigital_Profile_To_PeopleArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileDigital_Profile_To_People_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileRaw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


/** columns and relationships of "digital_profile" */
export type Digital_ProfileRaw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** aggregated selection of "digital_profile" */
export type Digital_Profile_Aggregate = {
  __typename?: 'digital_profile_aggregate';
  aggregate?: Maybe<Digital_Profile_Aggregate_Fields>;
  nodes: Array<Digital_Profile>;
};

export type Digital_Profile_Aggregate_Bool_Exp = {
  count?: InputMaybe<Digital_Profile_Aggregate_Bool_Exp_Count>;
};

export type Digital_Profile_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Digital_Profile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "digital_profile" */
export type Digital_Profile_Aggregate_Fields = {
  __typename?: 'digital_profile_aggregate_fields';
  avg?: Maybe<Digital_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Digital_Profile_Max_Fields>;
  min?: Maybe<Digital_Profile_Min_Fields>;
  stddev?: Maybe<Digital_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Digital_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Digital_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Digital_Profile_Sum_Fields>;
  var_pop?: Maybe<Digital_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Digital_Profile_Var_Samp_Fields>;
  variance?: Maybe<Digital_Profile_Variance_Fields>;
};


/** aggregate fields of "digital_profile" */
export type Digital_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "digital_profile" */
export type Digital_Profile_Aggregate_Order_By = {
  avg?: InputMaybe<Digital_Profile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Digital_Profile_Max_Order_By>;
  min?: InputMaybe<Digital_Profile_Min_Order_By>;
  stddev?: InputMaybe<Digital_Profile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Digital_Profile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Digital_Profile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Digital_Profile_Sum_Order_By>;
  var_pop?: InputMaybe<Digital_Profile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Digital_Profile_Var_Samp_Order_By>;
  variance?: InputMaybe<Digital_Profile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "digital_profile" */
export type Digital_Profile_Arr_Rel_Insert_Input = {
  data: Array<Digital_Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Digital_Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Digital_Profile_Avg_Fields = {
  __typename?: 'digital_profile_avg_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "digital_profile" */
export type Digital_Profile_Avg_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "digital_profile". All fields are combined with a logical 'AND'. */
export type Digital_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Digital_Profile_Bool_Exp>>;
  _not?: InputMaybe<Digital_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Digital_Profile_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Bool_Exp>;
  digital_profile_type?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
  digital_profile_type_id?: InputMaybe<Int_Comparison_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  primary_subtitle?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  raw_responses?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Bool_Exp>;
  secondary_subtitle?: InputMaybe<String_Comparison_Exp>;
  summary?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_to?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "digital_profile" */
export enum Digital_Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  DigitalProfilePkey = 'digital_profile_pkey',
  /** unique or primary key constraint on columns "external_id", "digital_profile_type_id" */
  UqDigitalProfileExternalId = 'uq_digital_profile_external_id'
}

/** input type for incrementing numeric columns in table "digital_profile" */
export type Digital_Profile_Inc_Input = {
  digital_profile_type_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "digital_profile" */
export type Digital_Profile_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Arr_Rel_Insert_Input>;
  digital_profile_type?: InputMaybe<Digital_Profile_Type_Obj_Rel_Insert_Input>;
  digital_profile_type_id?: InputMaybe<Scalars['Int']>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  image_url?: InputMaybe<Scalars['String']>;
  primary_subtitle?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  raw_responses?: InputMaybe<Raw_Response_Arr_Rel_Insert_Input>;
  secondary_subtitle?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Digital_Profile_Max_Fields = {
  __typename?: 'digital_profile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_type_id?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  primary_subtitle?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  secondary_subtitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "digital_profile" */
export type Digital_Profile_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_type_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  primary_subtitle?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  secondary_subtitle?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Digital_Profile_Min_Fields = {
  __typename?: 'digital_profile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_type_id?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  primary_subtitle?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  secondary_subtitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "digital_profile" */
export type Digital_Profile_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_type_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  primary_subtitle?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  secondary_subtitle?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "digital_profile" */
export type Digital_Profile_Mutation_Response = {
  __typename?: 'digital_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Digital_Profile>;
};

/** input type for inserting object relation for remote table "digital_profile" */
export type Digital_Profile_Obj_Rel_Insert_Input = {
  data: Digital_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Digital_Profile_On_Conflict>;
};

/** on_conflict condition type for table "digital_profile" */
export type Digital_Profile_On_Conflict = {
  constraint: Digital_Profile_Constraint;
  update_columns?: Array<Digital_Profile_Update_Column>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "digital_profile". */
export type Digital_Profile_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Order_By>;
  digital_profile_type?: InputMaybe<Digital_Profile_Type_Order_By>;
  digital_profile_type_id?: InputMaybe<Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  primary_subtitle?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Order_By>;
  secondary_subtitle?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: digital_profile */
export type Digital_Profile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "digital_profile" */
export enum Digital_Profile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileTypeId = 'digital_profile_type_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  PrimarySubtitle = 'primary_subtitle',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SecondarySubtitle = 'secondary_subtitle',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidTo = 'valid_to'
}

/** input type for updating data in table "digital_profile" */
export type Digital_Profile_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_type_id?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  image_url?: InputMaybe<Scalars['String']>;
  primary_subtitle?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  secondary_subtitle?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Digital_Profile_Stddev_Fields = {
  __typename?: 'digital_profile_stddev_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "digital_profile" */
export type Digital_Profile_Stddev_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Digital_Profile_Stddev_Pop_Fields = {
  __typename?: 'digital_profile_stddev_pop_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "digital_profile" */
export type Digital_Profile_Stddev_Pop_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Digital_Profile_Stddev_Samp_Fields = {
  __typename?: 'digital_profile_stddev_samp_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "digital_profile" */
export type Digital_Profile_Stddev_Samp_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "digital_profile" */
export type Digital_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Digital_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Digital_Profile_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_type_id?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  primary_subtitle?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  secondary_subtitle?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Digital_Profile_Sum_Fields = {
  __typename?: 'digital_profile_sum_fields';
  digital_profile_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "digital_profile" */
export type Digital_Profile_Sum_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "digital_profile_to_address" */
export type Digital_Profile_To_Address = {
  __typename?: 'digital_profile_to_address';
  /** An object relationship */
  address: Address;
  address_id: Scalars['Int'];
  computed_matching_aid?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  digital_profile: Digital_Profile;
  digital_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  is_linked_to_address_confidence: Scalars['Int'];
  is_linked_to_address_confidence_category_id: Scalars['Int'];
  is_linked_to_address_precision_type_id: Scalars['Int'];
  is_linked_to_address_provider_id: Scalars['Int'];
  is_profile_of_address_confidence: Scalars['Int'];
  is_profile_of_address_provider_id: Scalars['Int'];
  is_profile_of_address_status_id: Scalars['Int'];
  /** An object relationship */
  link_confidence_category: Link_Confidence_Category;
  /** An object relationship */
  link_precision_type: Link_Precision_Type;
  /** An object relationship */
  link_relationship_status: Link_Relationship_Status;
  /** An object relationship */
  provider: Provider;
  /** An object relationship */
  providerByIsProfileOfAddressProviderId: Provider;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "digital_profile_to_address" */
export type Digital_Profile_To_AddressComputed_Matching_AidArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "digital_profile_to_address" */
export type Digital_Profile_To_Address_Aggregate = {
  __typename?: 'digital_profile_to_address_aggregate';
  aggregate?: Maybe<Digital_Profile_To_Address_Aggregate_Fields>;
  nodes: Array<Digital_Profile_To_Address>;
};

export type Digital_Profile_To_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp_Count>;
};

export type Digital_Profile_To_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "digital_profile_to_address" */
export type Digital_Profile_To_Address_Aggregate_Fields = {
  __typename?: 'digital_profile_to_address_aggregate_fields';
  avg?: Maybe<Digital_Profile_To_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Digital_Profile_To_Address_Max_Fields>;
  min?: Maybe<Digital_Profile_To_Address_Min_Fields>;
  stddev?: Maybe<Digital_Profile_To_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Digital_Profile_To_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Digital_Profile_To_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Digital_Profile_To_Address_Sum_Fields>;
  var_pop?: Maybe<Digital_Profile_To_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Digital_Profile_To_Address_Var_Samp_Fields>;
  variance?: Maybe<Digital_Profile_To_Address_Variance_Fields>;
};


/** aggregate fields of "digital_profile_to_address" */
export type Digital_Profile_To_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Aggregate_Order_By = {
  avg?: InputMaybe<Digital_Profile_To_Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Digital_Profile_To_Address_Max_Order_By>;
  min?: InputMaybe<Digital_Profile_To_Address_Min_Order_By>;
  stddev?: InputMaybe<Digital_Profile_To_Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Digital_Profile_To_Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Digital_Profile_To_Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Digital_Profile_To_Address_Sum_Order_By>;
  var_pop?: InputMaybe<Digital_Profile_To_Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Digital_Profile_To_Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Digital_Profile_To_Address_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Digital_Profile_To_Address_Append_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Arr_Rel_Insert_Input = {
  data: Array<Digital_Profile_To_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Digital_Profile_To_Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Digital_Profile_To_Address_Avg_Fields = {
  __typename?: 'digital_profile_to_address_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "digital_profile_to_address". All fields are combined with a logical 'AND'. */
export type Digital_Profile_To_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Digital_Profile_To_Address_Bool_Exp>>;
  _not?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Digital_Profile_To_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  computed_matching_aid?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile?: InputMaybe<Digital_Profile_Bool_Exp>;
  digital_profile_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_precision_type_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_address_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_address_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_address_status_id?: InputMaybe<Int_Comparison_Exp>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Bool_Exp>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerByIsProfileOfAddressProviderId?: InputMaybe<Provider_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "digital_profile_to_address" */
export enum Digital_Profile_To_Address_Constraint {
  /** unique or primary key constraint on columns "address_id", "digital_profile_id" */
  DigitalProfileToAddressDigitalProfileIdAddressIdKey = 'digital_profile_to_address_digital_profile_id_address_id_key',
  /** unique or primary key constraint on columns "id" */
  DigitalProfileToAddressPkey = 'digital_profile_to_address_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Digital_Profile_To_Address_Delete_At_Path_Input = {
  computed_matching_aid?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Digital_Profile_To_Address_Delete_Elem_Input = {
  computed_matching_aid?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Digital_Profile_To_Address_Delete_Key_Input = {
  computed_matching_aid?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Inc_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_status_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile?: InputMaybe<Digital_Profile_Obj_Rel_Insert_Input>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_status_id?: InputMaybe<Scalars['Int']>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Obj_Rel_Insert_Input>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Obj_Rel_Insert_Input>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Obj_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerByIsProfileOfAddressProviderId?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Digital_Profile_To_Address_Max_Fields = {
  __typename?: 'digital_profile_to_address_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Digital_Profile_To_Address_Min_Fields = {
  __typename?: 'digital_profile_to_address_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Mutation_Response = {
  __typename?: 'digital_profile_to_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Digital_Profile_To_Address>;
};

/** on_conflict condition type for table "digital_profile_to_address" */
export type Digital_Profile_To_Address_On_Conflict = {
  constraint: Digital_Profile_To_Address_Constraint;
  update_columns?: Array<Digital_Profile_To_Address_Update_Column>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "digital_profile_to_address". */
export type Digital_Profile_To_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  computed_matching_aid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile?: InputMaybe<Digital_Profile_Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Order_By>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Order_By>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerByIsProfileOfAddressProviderId?: InputMaybe<Provider_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: digital_profile_to_address */
export type Digital_Profile_To_Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Digital_Profile_To_Address_Prepend_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "digital_profile_to_address" */
export enum Digital_Profile_To_Address_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLinkedToAddressConfidence = 'is_linked_to_address_confidence',
  /** column name */
  IsLinkedToAddressConfidenceCategoryId = 'is_linked_to_address_confidence_category_id',
  /** column name */
  IsLinkedToAddressPrecisionTypeId = 'is_linked_to_address_precision_type_id',
  /** column name */
  IsLinkedToAddressProviderId = 'is_linked_to_address_provider_id',
  /** column name */
  IsProfileOfAddressConfidence = 'is_profile_of_address_confidence',
  /** column name */
  IsProfileOfAddressProviderId = 'is_profile_of_address_provider_id',
  /** column name */
  IsProfileOfAddressStatusId = 'is_profile_of_address_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Set_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_status_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Digital_Profile_To_Address_Stddev_Fields = {
  __typename?: 'digital_profile_to_address_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Digital_Profile_To_Address_Stddev_Pop_Fields = {
  __typename?: 'digital_profile_to_address_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Digital_Profile_To_Address_Stddev_Samp_Fields = {
  __typename?: 'digital_profile_to_address_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Digital_Profile_To_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Digital_Profile_To_Address_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_address_status_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Digital_Profile_To_Address_Sum_Fields = {
  __typename?: 'digital_profile_to_address_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** update columns of table "digital_profile_to_address" */
export enum Digital_Profile_To_Address_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  IsLinkedToAddressConfidence = 'is_linked_to_address_confidence',
  /** column name */
  IsLinkedToAddressConfidenceCategoryId = 'is_linked_to_address_confidence_category_id',
  /** column name */
  IsLinkedToAddressPrecisionTypeId = 'is_linked_to_address_precision_type_id',
  /** column name */
  IsLinkedToAddressProviderId = 'is_linked_to_address_provider_id',
  /** column name */
  IsProfileOfAddressConfidence = 'is_profile_of_address_confidence',
  /** column name */
  IsProfileOfAddressProviderId = 'is_profile_of_address_provider_id',
  /** column name */
  IsProfileOfAddressStatusId = 'is_profile_of_address_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Digital_Profile_To_Address_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Digital_Profile_To_Address_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Digital_Profile_To_Address_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Digital_Profile_To_Address_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Digital_Profile_To_Address_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Digital_Profile_To_Address_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Digital_Profile_To_Address_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Digital_Profile_To_Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Digital_Profile_To_Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Digital_Profile_To_Address_Var_Pop_Fields = {
  __typename?: 'digital_profile_to_address_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Digital_Profile_To_Address_Var_Samp_Fields = {
  __typename?: 'digital_profile_to_address_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Digital_Profile_To_Address_Variance_Fields = {
  __typename?: 'digital_profile_to_address_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_address_status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "digital_profile_to_address" */
export type Digital_Profile_To_Address_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_confidence?: InputMaybe<Order_By>;
  is_profile_of_address_provider_id?: InputMaybe<Order_By>;
  is_profile_of_address_status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "digital_profile_to_person" */
export type Digital_Profile_To_Person = {
  __typename?: 'digital_profile_to_person';
  computed_matching_aid?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  digital_profile: Digital_Profile;
  digital_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  is_profile_of_person_confidence: Scalars['Int'];
  is_profile_of_person_provider_id: Scalars['Int'];
  is_profile_of_person_status_id: Scalars['Int'];
  /** An object relationship */
  link_relationship_status: Link_Relationship_Status;
  /** An object relationship */
  person: Person;
  person_id: Scalars['Int'];
  /** An object relationship */
  provider: Provider;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "digital_profile_to_person" */
export type Digital_Profile_To_PersonComputed_Matching_AidArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "digital_profile_to_person" */
export type Digital_Profile_To_Person_Aggregate = {
  __typename?: 'digital_profile_to_person_aggregate';
  aggregate?: Maybe<Digital_Profile_To_Person_Aggregate_Fields>;
  nodes: Array<Digital_Profile_To_Person>;
};

export type Digital_Profile_To_Person_Aggregate_Bool_Exp = {
  count?: InputMaybe<Digital_Profile_To_Person_Aggregate_Bool_Exp_Count>;
};

export type Digital_Profile_To_Person_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "digital_profile_to_person" */
export type Digital_Profile_To_Person_Aggregate_Fields = {
  __typename?: 'digital_profile_to_person_aggregate_fields';
  avg?: Maybe<Digital_Profile_To_Person_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Digital_Profile_To_Person_Max_Fields>;
  min?: Maybe<Digital_Profile_To_Person_Min_Fields>;
  stddev?: Maybe<Digital_Profile_To_Person_Stddev_Fields>;
  stddev_pop?: Maybe<Digital_Profile_To_Person_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Digital_Profile_To_Person_Stddev_Samp_Fields>;
  sum?: Maybe<Digital_Profile_To_Person_Sum_Fields>;
  var_pop?: Maybe<Digital_Profile_To_Person_Var_Pop_Fields>;
  var_samp?: Maybe<Digital_Profile_To_Person_Var_Samp_Fields>;
  variance?: Maybe<Digital_Profile_To_Person_Variance_Fields>;
};


/** aggregate fields of "digital_profile_to_person" */
export type Digital_Profile_To_Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Aggregate_Order_By = {
  avg?: InputMaybe<Digital_Profile_To_Person_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Digital_Profile_To_Person_Max_Order_By>;
  min?: InputMaybe<Digital_Profile_To_Person_Min_Order_By>;
  stddev?: InputMaybe<Digital_Profile_To_Person_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Digital_Profile_To_Person_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Digital_Profile_To_Person_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Digital_Profile_To_Person_Sum_Order_By>;
  var_pop?: InputMaybe<Digital_Profile_To_Person_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Digital_Profile_To_Person_Var_Samp_Order_By>;
  variance?: InputMaybe<Digital_Profile_To_Person_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Digital_Profile_To_Person_Append_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Arr_Rel_Insert_Input = {
  data: Array<Digital_Profile_To_Person_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Digital_Profile_To_Person_On_Conflict>;
};

/** aggregate avg on columns */
export type Digital_Profile_To_Person_Avg_Fields = {
  __typename?: 'digital_profile_to_person_avg_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Avg_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "digital_profile_to_person". All fields are combined with a logical 'AND'. */
export type Digital_Profile_To_Person_Bool_Exp = {
  _and?: InputMaybe<Array<Digital_Profile_To_Person_Bool_Exp>>;
  _not?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  _or?: InputMaybe<Array<Digital_Profile_To_Person_Bool_Exp>>;
  computed_matching_aid?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile?: InputMaybe<Digital_Profile_Bool_Exp>;
  digital_profile_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_person_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_person_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_profile_of_person_status_id?: InputMaybe<Int_Comparison_Exp>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  person_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "digital_profile_to_person" */
export enum Digital_Profile_To_Person_Constraint {
  /** unique or primary key constraint on columns "digital_profile_id", "person_id" */
  DigitalProfileToPersonDigitalProfileIdPersonIdKey = 'digital_profile_to_person_digital_profile_id_person_id_key',
  /** unique or primary key constraint on columns "id" */
  DigitalProfileToPersonPkey = 'digital_profile_to_person_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Digital_Profile_To_Person_Delete_At_Path_Input = {
  computed_matching_aid?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Digital_Profile_To_Person_Delete_Elem_Input = {
  computed_matching_aid?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Digital_Profile_To_Person_Delete_Key_Input = {
  computed_matching_aid?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Inc_Input = {
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Insert_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile?: InputMaybe<Digital_Profile_Obj_Rel_Insert_Input>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_status_id?: InputMaybe<Scalars['Int']>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Obj_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Digital_Profile_To_Person_Max_Fields = {
  __typename?: 'digital_profile_to_person_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Digital_Profile_To_Person_Min_Fields = {
  __typename?: 'digital_profile_to_person_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Mutation_Response = {
  __typename?: 'digital_profile_to_person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Digital_Profile_To_Person>;
};

/** on_conflict condition type for table "digital_profile_to_person" */
export type Digital_Profile_To_Person_On_Conflict = {
  constraint: Digital_Profile_To_Person_Constraint;
  update_columns?: Array<Digital_Profile_To_Person_Update_Column>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};

/** Ordering options when selecting data from "digital_profile_to_person". */
export type Digital_Profile_To_Person_Order_By = {
  computed_matching_aid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile?: InputMaybe<Digital_Profile_Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  person_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: digital_profile_to_person */
export type Digital_Profile_To_Person_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Digital_Profile_To_Person_Prepend_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "digital_profile_to_person" */
export enum Digital_Profile_To_Person_Select_Column {
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsProfileOfPersonConfidence = 'is_profile_of_person_confidence',
  /** column name */
  IsProfileOfPersonProviderId = 'is_profile_of_person_provider_id',
  /** column name */
  IsProfileOfPersonStatusId = 'is_profile_of_person_status_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Set_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Digital_Profile_To_Person_Stddev_Fields = {
  __typename?: 'digital_profile_to_person_stddev_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Stddev_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Digital_Profile_To_Person_Stddev_Pop_Fields = {
  __typename?: 'digital_profile_to_person_stddev_pop_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Stddev_Pop_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Digital_Profile_To_Person_Stddev_Samp_Fields = {
  __typename?: 'digital_profile_to_person_stddev_samp_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Stddev_Samp_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Digital_Profile_To_Person_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Digital_Profile_To_Person_Stream_Cursor_Value_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_confidence?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: InputMaybe<Scalars['Int']>;
  is_profile_of_person_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Digital_Profile_To_Person_Sum_Fields = {
  __typename?: 'digital_profile_to_person_sum_fields';
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Int']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Int']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Sum_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** update columns of table "digital_profile_to_person" */
export enum Digital_Profile_To_Person_Update_Column {
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  IsProfileOfPersonConfidence = 'is_profile_of_person_confidence',
  /** column name */
  IsProfileOfPersonProviderId = 'is_profile_of_person_provider_id',
  /** column name */
  IsProfileOfPersonStatusId = 'is_profile_of_person_status_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Digital_Profile_To_Person_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Digital_Profile_To_Person_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Digital_Profile_To_Person_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Digital_Profile_To_Person_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Digital_Profile_To_Person_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Digital_Profile_To_Person_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Digital_Profile_To_Person_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Digital_Profile_To_Person_Set_Input>;
  /** filter the rows which have to be updated */
  where: Digital_Profile_To_Person_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Digital_Profile_To_Person_Var_Pop_Fields = {
  __typename?: 'digital_profile_to_person_var_pop_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Var_Pop_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Digital_Profile_To_Person_Var_Samp_Fields = {
  __typename?: 'digital_profile_to_person_var_samp_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Var_Samp_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Digital_Profile_To_Person_Variance_Fields = {
  __typename?: 'digital_profile_to_person_variance_fields';
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_profile_of_person_confidence?: Maybe<Scalars['Float']>;
  is_profile_of_person_provider_id?: Maybe<Scalars['Float']>;
  is_profile_of_person_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "digital_profile_to_person" */
export type Digital_Profile_To_Person_Variance_Order_By = {
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_profile_of_person_confidence?: InputMaybe<Order_By>;
  is_profile_of_person_provider_id?: InputMaybe<Order_By>;
  is_profile_of_person_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "digital_profile_type" */
export type Digital_Profile_Type = {
  __typename?: 'digital_profile_type';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profiles: Array<Digital_Profile>;
  /** An aggregate relationship */
  digital_profiles_aggregate: Digital_Profile_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "digital_profile_type" */
export type Digital_Profile_TypeDigital_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


/** columns and relationships of "digital_profile_type" */
export type Digital_Profile_TypeDigital_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};

/** aggregated selection of "digital_profile_type" */
export type Digital_Profile_Type_Aggregate = {
  __typename?: 'digital_profile_type_aggregate';
  aggregate?: Maybe<Digital_Profile_Type_Aggregate_Fields>;
  nodes: Array<Digital_Profile_Type>;
};

/** aggregate fields of "digital_profile_type" */
export type Digital_Profile_Type_Aggregate_Fields = {
  __typename?: 'digital_profile_type_aggregate_fields';
  avg?: Maybe<Digital_Profile_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Digital_Profile_Type_Max_Fields>;
  min?: Maybe<Digital_Profile_Type_Min_Fields>;
  stddev?: Maybe<Digital_Profile_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Digital_Profile_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Digital_Profile_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Digital_Profile_Type_Sum_Fields>;
  var_pop?: Maybe<Digital_Profile_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Digital_Profile_Type_Var_Samp_Fields>;
  variance?: Maybe<Digital_Profile_Type_Variance_Fields>;
};


/** aggregate fields of "digital_profile_type" */
export type Digital_Profile_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Digital_Profile_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Digital_Profile_Type_Avg_Fields = {
  __typename?: 'digital_profile_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "digital_profile_type". All fields are combined with a logical 'AND'. */
export type Digital_Profile_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Digital_Profile_Type_Bool_Exp>>;
  _not?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Digital_Profile_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profiles?: InputMaybe<Digital_Profile_Bool_Exp>;
  digital_profiles_aggregate?: InputMaybe<Digital_Profile_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "digital_profile_type" */
export enum Digital_Profile_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  DigitalProfileTypeNameKey = 'digital_profile_type_name_key',
  /** unique or primary key constraint on columns "id" */
  DigitalProfileTypePkey = 'digital_profile_type_pkey'
}

/** input type for incrementing numeric columns in table "digital_profile_type" */
export type Digital_Profile_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "digital_profile_type" */
export type Digital_Profile_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profiles?: InputMaybe<Digital_Profile_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Digital_Profile_Type_Max_Fields = {
  __typename?: 'digital_profile_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Digital_Profile_Type_Min_Fields = {
  __typename?: 'digital_profile_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "digital_profile_type" */
export type Digital_Profile_Type_Mutation_Response = {
  __typename?: 'digital_profile_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Digital_Profile_Type>;
};

/** input type for inserting object relation for remote table "digital_profile_type" */
export type Digital_Profile_Type_Obj_Rel_Insert_Input = {
  data: Digital_Profile_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Digital_Profile_Type_On_Conflict>;
};

/** on_conflict condition type for table "digital_profile_type" */
export type Digital_Profile_Type_On_Conflict = {
  constraint: Digital_Profile_Type_Constraint;
  update_columns?: Array<Digital_Profile_Type_Update_Column>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "digital_profile_type". */
export type Digital_Profile_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profiles_aggregate?: InputMaybe<Digital_Profile_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: digital_profile_type */
export type Digital_Profile_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "digital_profile_type" */
export enum Digital_Profile_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "digital_profile_type" */
export type Digital_Profile_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Digital_Profile_Type_Stddev_Fields = {
  __typename?: 'digital_profile_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Digital_Profile_Type_Stddev_Pop_Fields = {
  __typename?: 'digital_profile_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Digital_Profile_Type_Stddev_Samp_Fields = {
  __typename?: 'digital_profile_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "digital_profile_type" */
export type Digital_Profile_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Digital_Profile_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Digital_Profile_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Digital_Profile_Type_Sum_Fields = {
  __typename?: 'digital_profile_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "digital_profile_type" */
export enum Digital_Profile_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Digital_Profile_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Digital_Profile_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Digital_Profile_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Digital_Profile_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Digital_Profile_Type_Var_Pop_Fields = {
  __typename?: 'digital_profile_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Digital_Profile_Type_Var_Samp_Fields = {
  __typename?: 'digital_profile_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Digital_Profile_Type_Variance_Fields = {
  __typename?: 'digital_profile_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "digital_profile" */
export enum Digital_Profile_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileTypeId = 'digital_profile_type_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  PrimarySubtitle = 'primary_subtitle',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SecondarySubtitle = 'secondary_subtitle',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidTo = 'valid_to'
}

export type Digital_Profile_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Digital_Profile_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Digital_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Digital_Profile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Digital_Profile_Var_Pop_Fields = {
  __typename?: 'digital_profile_var_pop_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "digital_profile" */
export type Digital_Profile_Var_Pop_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Digital_Profile_Var_Samp_Fields = {
  __typename?: 'digital_profile_var_samp_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "digital_profile" */
export type Digital_Profile_Var_Samp_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Digital_Profile_Variance_Fields = {
  __typename?: 'digital_profile_variance_fields';
  digital_profile_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "digital_profile" */
export type Digital_Profile_Variance_Order_By = {
  digital_profile_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "entity_to_raw_response" */
export type Entity_To_Raw_Response = {
  __typename?: 'entity_to_raw_response';
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  attributes: Array<Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: Attribute_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  digital_profile?: Maybe<Digital_Profile>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_attribute_creation: Scalars['Boolean'];
  is_entity_creation: Scalars['Boolean'];
  is_entity_update: Scalars['Boolean'];
  is_linked_to_entity_confidence: Scalars['Int'];
  is_linked_to_entity_confidence_category_id: Scalars['Int'];
  is_linked_to_entity_precision_type_id: Scalars['Int'];
  is_linked_to_entity_provider_id: Scalars['Int'];
  /** An object relationship */
  link_confidence_category: Link_Confidence_Category;
  /** An object relationship */
  link_precision_type: Link_Precision_Type;
  /** An object relationship */
  person?: Maybe<Person>;
  person_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  provider: Provider;
  /** An object relationship */
  raw_response: Raw_Response;
  raw_response_id: Scalars['Int'];
  raw_response_pathway?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "entity_to_raw_response" */
export type Entity_To_Raw_ResponseAttributesArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


/** columns and relationships of "entity_to_raw_response" */
export type Entity_To_Raw_ResponseAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};

/** aggregated selection of "entity_to_raw_response" */
export type Entity_To_Raw_Response_Aggregate = {
  __typename?: 'entity_to_raw_response_aggregate';
  aggregate?: Maybe<Entity_To_Raw_Response_Aggregate_Fields>;
  nodes: Array<Entity_To_Raw_Response>;
};

export type Entity_To_Raw_Response_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp_Count>;
};

export type Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_And = {
  arguments: Entity_To_Raw_Response_Select_Column_Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Entity_To_Raw_Response_Select_Column_Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Entity_To_Raw_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "entity_to_raw_response" */
export type Entity_To_Raw_Response_Aggregate_Fields = {
  __typename?: 'entity_to_raw_response_aggregate_fields';
  avg?: Maybe<Entity_To_Raw_Response_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Entity_To_Raw_Response_Max_Fields>;
  min?: Maybe<Entity_To_Raw_Response_Min_Fields>;
  stddev?: Maybe<Entity_To_Raw_Response_Stddev_Fields>;
  stddev_pop?: Maybe<Entity_To_Raw_Response_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Entity_To_Raw_Response_Stddev_Samp_Fields>;
  sum?: Maybe<Entity_To_Raw_Response_Sum_Fields>;
  var_pop?: Maybe<Entity_To_Raw_Response_Var_Pop_Fields>;
  var_samp?: Maybe<Entity_To_Raw_Response_Var_Samp_Fields>;
  variance?: Maybe<Entity_To_Raw_Response_Variance_Fields>;
};


/** aggregate fields of "entity_to_raw_response" */
export type Entity_To_Raw_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Aggregate_Order_By = {
  avg?: InputMaybe<Entity_To_Raw_Response_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entity_To_Raw_Response_Max_Order_By>;
  min?: InputMaybe<Entity_To_Raw_Response_Min_Order_By>;
  stddev?: InputMaybe<Entity_To_Raw_Response_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Entity_To_Raw_Response_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Entity_To_Raw_Response_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Entity_To_Raw_Response_Sum_Order_By>;
  var_pop?: InputMaybe<Entity_To_Raw_Response_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Entity_To_Raw_Response_Var_Samp_Order_By>;
  variance?: InputMaybe<Entity_To_Raw_Response_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Arr_Rel_Insert_Input = {
  data: Array<Entity_To_Raw_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_To_Raw_Response_On_Conflict>;
};

/** aggregate avg on columns */
export type Entity_To_Raw_Response_Avg_Fields = {
  __typename?: 'entity_to_raw_response_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "entity_to_raw_response". All fields are combined with a logical 'AND'. */
export type Entity_To_Raw_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_To_Raw_Response_Bool_Exp>>;
  _not?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_To_Raw_Response_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  attributes?: InputMaybe<Attribute_Bool_Exp>;
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile?: InputMaybe<Digital_Profile_Bool_Exp>;
  digital_profile_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_attribute_creation?: InputMaybe<Boolean_Comparison_Exp>;
  is_entity_creation?: InputMaybe<Boolean_Comparison_Exp>;
  is_entity_update?: InputMaybe<Boolean_Comparison_Exp>;
  is_linked_to_entity_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_entity_provider_id?: InputMaybe<Int_Comparison_Exp>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  person_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  raw_response?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_response_id?: InputMaybe<Int_Comparison_Exp>;
  raw_response_pathway?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_to_raw_response" */
export enum Entity_To_Raw_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityToRawResponsePkey = 'entity_to_raw_response_pkey'
}

/** input type for incrementing numeric columns in table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Inc_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  raw_response_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['Int']>;
  attributes?: InputMaybe<Attribute_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile?: InputMaybe<Digital_Profile_Obj_Rel_Insert_Input>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_attribute_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_update?: InputMaybe<Scalars['Boolean']>;
  is_linked_to_entity_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: InputMaybe<Scalars['Int']>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Obj_Rel_Insert_Input>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Obj_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  raw_response?: InputMaybe<Raw_Response_Obj_Rel_Insert_Input>;
  raw_response_id?: InputMaybe<Scalars['Int']>;
  raw_response_pathway?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Entity_To_Raw_Response_Max_Fields = {
  __typename?: 'entity_to_raw_response_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  raw_response_id?: Maybe<Scalars['Int']>;
  raw_response_pathway?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
  raw_response_pathway?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entity_To_Raw_Response_Min_Fields = {
  __typename?: 'entity_to_raw_response_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  raw_response_id?: Maybe<Scalars['Int']>;
  raw_response_pathway?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
  raw_response_pathway?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Mutation_Response = {
  __typename?: 'entity_to_raw_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_To_Raw_Response>;
};

/** input type for inserting object relation for remote table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Obj_Rel_Insert_Input = {
  data: Entity_To_Raw_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_To_Raw_Response_On_Conflict>;
};

/** on_conflict condition type for table "entity_to_raw_response" */
export type Entity_To_Raw_Response_On_Conflict = {
  constraint: Entity_To_Raw_Response_Constraint;
  update_columns?: Array<Entity_To_Raw_Response_Update_Column>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_to_raw_response". */
export type Entity_To_Raw_Response_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile?: InputMaybe<Digital_Profile_Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_attribute_creation?: InputMaybe<Order_By>;
  is_entity_creation?: InputMaybe<Order_By>;
  is_entity_update?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Order_By>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  person_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  raw_response?: InputMaybe<Raw_Response_Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
  raw_response_pathway?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_to_raw_response */
export type Entity_To_Raw_Response_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "entity_to_raw_response" */
export enum Entity_To_Raw_Response_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAttributeCreation = 'is_attribute_creation',
  /** column name */
  IsEntityCreation = 'is_entity_creation',
  /** column name */
  IsEntityUpdate = 'is_entity_update',
  /** column name */
  IsLinkedToEntityConfidence = 'is_linked_to_entity_confidence',
  /** column name */
  IsLinkedToEntityConfidenceCategoryId = 'is_linked_to_entity_confidence_category_id',
  /** column name */
  IsLinkedToEntityPrecisionTypeId = 'is_linked_to_entity_precision_type_id',
  /** column name */
  IsLinkedToEntityProviderId = 'is_linked_to_entity_provider_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  RawResponseId = 'raw_response_id',
  /** column name */
  RawResponsePathway = 'raw_response_pathway',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "entity_to_raw_response_aggregate_bool_exp_bool_and_arguments_columns" columns of table "entity_to_raw_response" */
export enum Entity_To_Raw_Response_Select_Column_Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAttributeCreation = 'is_attribute_creation',
  /** column name */
  IsEntityCreation = 'is_entity_creation',
  /** column name */
  IsEntityUpdate = 'is_entity_update'
}

/** select "entity_to_raw_response_aggregate_bool_exp_bool_or_arguments_columns" columns of table "entity_to_raw_response" */
export enum Entity_To_Raw_Response_Select_Column_Entity_To_Raw_Response_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAttributeCreation = 'is_attribute_creation',
  /** column name */
  IsEntityCreation = 'is_entity_creation',
  /** column name */
  IsEntityUpdate = 'is_entity_update'
}

/** input type for updating data in table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Set_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  is_attribute_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_update?: InputMaybe<Scalars['Boolean']>;
  is_linked_to_entity_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  raw_response_id?: InputMaybe<Scalars['Int']>;
  raw_response_pathway?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Entity_To_Raw_Response_Stddev_Fields = {
  __typename?: 'entity_to_raw_response_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Entity_To_Raw_Response_Stddev_Pop_Fields = {
  __typename?: 'entity_to_raw_response_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Entity_To_Raw_Response_Stddev_Samp_Fields = {
  __typename?: 'entity_to_raw_response_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_To_Raw_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_To_Raw_Response_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_attribute_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_creation?: InputMaybe<Scalars['Boolean']>;
  is_entity_update?: InputMaybe<Scalars['Boolean']>;
  is_linked_to_entity_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  raw_response_id?: InputMaybe<Scalars['Int']>;
  raw_response_pathway?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Entity_To_Raw_Response_Sum_Fields = {
  __typename?: 'entity_to_raw_response_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  digital_profile_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  raw_response_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** update columns of table "entity_to_raw_response" */
export enum Entity_To_Raw_Response_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DigitalProfileId = 'digital_profile_id',
  /** column name */
  IsAttributeCreation = 'is_attribute_creation',
  /** column name */
  IsEntityCreation = 'is_entity_creation',
  /** column name */
  IsEntityUpdate = 'is_entity_update',
  /** column name */
  IsLinkedToEntityConfidence = 'is_linked_to_entity_confidence',
  /** column name */
  IsLinkedToEntityConfidenceCategoryId = 'is_linked_to_entity_confidence_category_id',
  /** column name */
  IsLinkedToEntityPrecisionTypeId = 'is_linked_to_entity_precision_type_id',
  /** column name */
  IsLinkedToEntityProviderId = 'is_linked_to_entity_provider_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  RawResponseId = 'raw_response_id',
  /** column name */
  RawResponsePathway = 'raw_response_pathway',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Entity_To_Raw_Response_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Entity_To_Raw_Response_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_To_Raw_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_To_Raw_Response_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Entity_To_Raw_Response_Var_Pop_Fields = {
  __typename?: 'entity_to_raw_response_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Entity_To_Raw_Response_Var_Samp_Fields = {
  __typename?: 'entity_to_raw_response_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Entity_To_Raw_Response_Variance_Fields = {
  __typename?: 'entity_to_raw_response_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  digital_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_entity_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_entity_provider_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
  raw_response_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "entity_to_raw_response" */
export type Entity_To_Raw_Response_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  digital_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence?: InputMaybe<Order_By>;
  is_linked_to_entity_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_entity_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_entity_provider_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  raw_response_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_Category = {
  __typename?: 'link_confidence_category';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  /** An array relationship */
  person_to_person_groups: Array<Person_To_Person_Group>;
  /** An aggregate relationship */
  person_to_person_groups_aggregate: Person_To_Person_Group_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryPerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryPerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryPerson_To_Person_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "link_confidence_category" */
export type Link_Confidence_CategoryPerson_To_Person_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};

/** aggregated selection of "link_confidence_category" */
export type Link_Confidence_Category_Aggregate = {
  __typename?: 'link_confidence_category_aggregate';
  aggregate?: Maybe<Link_Confidence_Category_Aggregate_Fields>;
  nodes: Array<Link_Confidence_Category>;
};

/** aggregate fields of "link_confidence_category" */
export type Link_Confidence_Category_Aggregate_Fields = {
  __typename?: 'link_confidence_category_aggregate_fields';
  avg?: Maybe<Link_Confidence_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Link_Confidence_Category_Max_Fields>;
  min?: Maybe<Link_Confidence_Category_Min_Fields>;
  stddev?: Maybe<Link_Confidence_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Link_Confidence_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Link_Confidence_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Link_Confidence_Category_Sum_Fields>;
  var_pop?: Maybe<Link_Confidence_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Link_Confidence_Category_Var_Samp_Fields>;
  variance?: Maybe<Link_Confidence_Category_Variance_Fields>;
};


/** aggregate fields of "link_confidence_category" */
export type Link_Confidence_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Link_Confidence_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Link_Confidence_Category_Avg_Fields = {
  __typename?: 'link_confidence_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "link_confidence_category". All fields are combined with a logical 'AND'. */
export type Link_Confidence_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Link_Confidence_Category_Bool_Exp>>;
  _not?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Link_Confidence_Category_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "link_confidence_category" */
export enum Link_Confidence_Category_Constraint {
  /** unique or primary key constraint on columns "name" */
  LinkConfidenceCategoryNameKey = 'link_confidence_category_name_key',
  /** unique or primary key constraint on columns "id" */
  LinkConfidenceCategoryPkey = 'link_confidence_category_pkey'
}

/** input type for incrementing numeric columns in table "link_confidence_category" */
export type Link_Confidence_Category_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "link_confidence_category" */
export type Link_Confidence_Category_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Link_Confidence_Category_Max_Fields = {
  __typename?: 'link_confidence_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Link_Confidence_Category_Min_Fields = {
  __typename?: 'link_confidence_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "link_confidence_category" */
export type Link_Confidence_Category_Mutation_Response = {
  __typename?: 'link_confidence_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Link_Confidence_Category>;
};

/** input type for inserting object relation for remote table "link_confidence_category" */
export type Link_Confidence_Category_Obj_Rel_Insert_Input = {
  data: Link_Confidence_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Link_Confidence_Category_On_Conflict>;
};

/** on_conflict condition type for table "link_confidence_category" */
export type Link_Confidence_Category_On_Conflict = {
  constraint: Link_Confidence_Category_Constraint;
  update_columns?: Array<Link_Confidence_Category_Update_Column>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "link_confidence_category". */
export type Link_Confidence_Category_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: link_confidence_category */
export type Link_Confidence_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "link_confidence_category" */
export enum Link_Confidence_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "link_confidence_category" */
export type Link_Confidence_Category_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Link_Confidence_Category_Stddev_Fields = {
  __typename?: 'link_confidence_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Link_Confidence_Category_Stddev_Pop_Fields = {
  __typename?: 'link_confidence_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Link_Confidence_Category_Stddev_Samp_Fields = {
  __typename?: 'link_confidence_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "link_confidence_category" */
export type Link_Confidence_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Link_Confidence_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Link_Confidence_Category_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Link_Confidence_Category_Sum_Fields = {
  __typename?: 'link_confidence_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "link_confidence_category" */
export enum Link_Confidence_Category_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Link_Confidence_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Link_Confidence_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Link_Confidence_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Link_Confidence_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Link_Confidence_Category_Var_Pop_Fields = {
  __typename?: 'link_confidence_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Link_Confidence_Category_Var_Samp_Fields = {
  __typename?: 'link_confidence_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Link_Confidence_Category_Variance_Fields = {
  __typename?: 'link_confidence_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "link_precision_type" */
export type Link_Precision_Type = {
  __typename?: 'link_precision_type';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypeDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypeDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypeEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypeEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypePerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_precision_type" */
export type Link_Precision_TypePerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};

/** aggregated selection of "link_precision_type" */
export type Link_Precision_Type_Aggregate = {
  __typename?: 'link_precision_type_aggregate';
  aggregate?: Maybe<Link_Precision_Type_Aggregate_Fields>;
  nodes: Array<Link_Precision_Type>;
};

/** aggregate fields of "link_precision_type" */
export type Link_Precision_Type_Aggregate_Fields = {
  __typename?: 'link_precision_type_aggregate_fields';
  avg?: Maybe<Link_Precision_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Link_Precision_Type_Max_Fields>;
  min?: Maybe<Link_Precision_Type_Min_Fields>;
  stddev?: Maybe<Link_Precision_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Link_Precision_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Link_Precision_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Link_Precision_Type_Sum_Fields>;
  var_pop?: Maybe<Link_Precision_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Link_Precision_Type_Var_Samp_Fields>;
  variance?: Maybe<Link_Precision_Type_Variance_Fields>;
};


/** aggregate fields of "link_precision_type" */
export type Link_Precision_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Link_Precision_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Link_Precision_Type_Avg_Fields = {
  __typename?: 'link_precision_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "link_precision_type". All fields are combined with a logical 'AND'. */
export type Link_Precision_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Link_Precision_Type_Bool_Exp>>;
  _not?: InputMaybe<Link_Precision_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Link_Precision_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "link_precision_type" */
export enum Link_Precision_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  LinkPrecisionTypeNameKey = 'link_precision_type_name_key',
  /** unique or primary key constraint on columns "id" */
  LinkPrecisionTypePkey = 'link_precision_type_pkey'
}

/** input type for incrementing numeric columns in table "link_precision_type" */
export type Link_Precision_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "link_precision_type" */
export type Link_Precision_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Link_Precision_Type_Max_Fields = {
  __typename?: 'link_precision_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Link_Precision_Type_Min_Fields = {
  __typename?: 'link_precision_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "link_precision_type" */
export type Link_Precision_Type_Mutation_Response = {
  __typename?: 'link_precision_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Link_Precision_Type>;
};

/** input type for inserting object relation for remote table "link_precision_type" */
export type Link_Precision_Type_Obj_Rel_Insert_Input = {
  data: Link_Precision_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Link_Precision_Type_On_Conflict>;
};

/** on_conflict condition type for table "link_precision_type" */
export type Link_Precision_Type_On_Conflict = {
  constraint: Link_Precision_Type_Constraint;
  update_columns?: Array<Link_Precision_Type_Update_Column>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "link_precision_type". */
export type Link_Precision_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: link_precision_type */
export type Link_Precision_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "link_precision_type" */
export enum Link_Precision_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "link_precision_type" */
export type Link_Precision_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Link_Precision_Type_Stddev_Fields = {
  __typename?: 'link_precision_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Link_Precision_Type_Stddev_Pop_Fields = {
  __typename?: 'link_precision_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Link_Precision_Type_Stddev_Samp_Fields = {
  __typename?: 'link_precision_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "link_precision_type" */
export type Link_Precision_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Link_Precision_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Link_Precision_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Link_Precision_Type_Sum_Fields = {
  __typename?: 'link_precision_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "link_precision_type" */
export enum Link_Precision_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Link_Precision_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Link_Precision_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Link_Precision_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Link_Precision_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Link_Precision_Type_Var_Pop_Fields = {
  __typename?: 'link_precision_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Link_Precision_Type_Var_Samp_Fields = {
  __typename?: 'link_precision_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Link_Precision_Type_Variance_Fields = {
  __typename?: 'link_precision_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_Status = {
  __typename?: 'link_relationship_status';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  digital_profile_to_people: Array<Digital_Profile_To_Person>;
  /** An aggregate relationship */
  digital_profile_to_people_aggregate: Digital_Profile_To_Person_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusDigital_Profile_To_PeopleArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusDigital_Profile_To_People_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusPerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "link_relationship_status" */
export type Link_Relationship_StatusPerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};

/** aggregated selection of "link_relationship_status" */
export type Link_Relationship_Status_Aggregate = {
  __typename?: 'link_relationship_status_aggregate';
  aggregate?: Maybe<Link_Relationship_Status_Aggregate_Fields>;
  nodes: Array<Link_Relationship_Status>;
};

/** aggregate fields of "link_relationship_status" */
export type Link_Relationship_Status_Aggregate_Fields = {
  __typename?: 'link_relationship_status_aggregate_fields';
  avg?: Maybe<Link_Relationship_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Link_Relationship_Status_Max_Fields>;
  min?: Maybe<Link_Relationship_Status_Min_Fields>;
  stddev?: Maybe<Link_Relationship_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Link_Relationship_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Link_Relationship_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Link_Relationship_Status_Sum_Fields>;
  var_pop?: Maybe<Link_Relationship_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Link_Relationship_Status_Var_Samp_Fields>;
  variance?: Maybe<Link_Relationship_Status_Variance_Fields>;
};


/** aggregate fields of "link_relationship_status" */
export type Link_Relationship_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Link_Relationship_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Link_Relationship_Status_Avg_Fields = {
  __typename?: 'link_relationship_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "link_relationship_status". All fields are combined with a logical 'AND'. */
export type Link_Relationship_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Link_Relationship_Status_Bool_Exp>>;
  _not?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Link_Relationship_Status_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "link_relationship_status" */
export enum Link_Relationship_Status_Constraint {
  /** unique or primary key constraint on columns "name" */
  LinkRelationshipStatusNameKey = 'link_relationship_status_name_key',
  /** unique or primary key constraint on columns "id" */
  LinkRelationshipStatusPkey = 'link_relationship_status_pkey'
}

/** input type for incrementing numeric columns in table "link_relationship_status" */
export type Link_Relationship_Status_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "link_relationship_status" */
export type Link_Relationship_Status_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Link_Relationship_Status_Max_Fields = {
  __typename?: 'link_relationship_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Link_Relationship_Status_Min_Fields = {
  __typename?: 'link_relationship_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "link_relationship_status" */
export type Link_Relationship_Status_Mutation_Response = {
  __typename?: 'link_relationship_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Link_Relationship_Status>;
};

/** input type for inserting object relation for remote table "link_relationship_status" */
export type Link_Relationship_Status_Obj_Rel_Insert_Input = {
  data: Link_Relationship_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Link_Relationship_Status_On_Conflict>;
};

/** on_conflict condition type for table "link_relationship_status" */
export type Link_Relationship_Status_On_Conflict = {
  constraint: Link_Relationship_Status_Constraint;
  update_columns?: Array<Link_Relationship_Status_Update_Column>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "link_relationship_status". */
export type Link_Relationship_Status_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: link_relationship_status */
export type Link_Relationship_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "link_relationship_status" */
export enum Link_Relationship_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "link_relationship_status" */
export type Link_Relationship_Status_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Link_Relationship_Status_Stddev_Fields = {
  __typename?: 'link_relationship_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Link_Relationship_Status_Stddev_Pop_Fields = {
  __typename?: 'link_relationship_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Link_Relationship_Status_Stddev_Samp_Fields = {
  __typename?: 'link_relationship_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "link_relationship_status" */
export type Link_Relationship_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Link_Relationship_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Link_Relationship_Status_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Link_Relationship_Status_Sum_Fields = {
  __typename?: 'link_relationship_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "link_relationship_status" */
export enum Link_Relationship_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Link_Relationship_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Link_Relationship_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Link_Relationship_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Link_Relationship_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Link_Relationship_Status_Var_Pop_Fields = {
  __typename?: 'link_relationship_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Link_Relationship_Status_Var_Samp_Fields = {
  __typename?: 'link_relationship_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Link_Relationship_Status_Variance_Fields = {
  __typename?: 'link_relationship_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "_yoyo_log" */
  delete__yoyo_log?: Maybe<_Yoyo_Log_Mutation_Response>;
  /** delete single row from the table: "_yoyo_log" */
  delete__yoyo_log_by_pk?: Maybe<_Yoyo_Log>;
  /** delete data from the table: "_yoyo_migration" */
  delete__yoyo_migration?: Maybe<_Yoyo_Migration_Mutation_Response>;
  /** delete single row from the table: "_yoyo_migration" */
  delete__yoyo_migration_by_pk?: Maybe<_Yoyo_Migration>;
  /** delete data from the table: "_yoyo_version" */
  delete__yoyo_version?: Maybe<_Yoyo_Version_Mutation_Response>;
  /** delete single row from the table: "_yoyo_version" */
  delete__yoyo_version_by_pk?: Maybe<_Yoyo_Version>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "attribute" */
  delete_attribute?: Maybe<Attribute_Mutation_Response>;
  /** delete single row from the table: "attribute" */
  delete_attribute_by_pk?: Maybe<Attribute>;
  /** delete data from the table: "attribute_type" */
  delete_attribute_type?: Maybe<Attribute_Type_Mutation_Response>;
  /** delete single row from the table: "attribute_type" */
  delete_attribute_type_by_pk?: Maybe<Attribute_Type>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "digital_profile" */
  delete_digital_profile?: Maybe<Digital_Profile_Mutation_Response>;
  /** delete single row from the table: "digital_profile" */
  delete_digital_profile_by_pk?: Maybe<Digital_Profile>;
  /** delete data from the table: "digital_profile_to_address" */
  delete_digital_profile_to_address?: Maybe<Digital_Profile_To_Address_Mutation_Response>;
  /** delete single row from the table: "digital_profile_to_address" */
  delete_digital_profile_to_address_by_pk?: Maybe<Digital_Profile_To_Address>;
  /** delete data from the table: "digital_profile_to_person" */
  delete_digital_profile_to_person?: Maybe<Digital_Profile_To_Person_Mutation_Response>;
  /** delete single row from the table: "digital_profile_to_person" */
  delete_digital_profile_to_person_by_pk?: Maybe<Digital_Profile_To_Person>;
  /** delete data from the table: "digital_profile_type" */
  delete_digital_profile_type?: Maybe<Digital_Profile_Type_Mutation_Response>;
  /** delete single row from the table: "digital_profile_type" */
  delete_digital_profile_type_by_pk?: Maybe<Digital_Profile_Type>;
  /** delete data from the table: "entity_to_raw_response" */
  delete_entity_to_raw_response?: Maybe<Entity_To_Raw_Response_Mutation_Response>;
  /** delete single row from the table: "entity_to_raw_response" */
  delete_entity_to_raw_response_by_pk?: Maybe<Entity_To_Raw_Response>;
  /** delete data from the table: "link_confidence_category" */
  delete_link_confidence_category?: Maybe<Link_Confidence_Category_Mutation_Response>;
  /** delete single row from the table: "link_confidence_category" */
  delete_link_confidence_category_by_pk?: Maybe<Link_Confidence_Category>;
  /** delete data from the table: "link_precision_type" */
  delete_link_precision_type?: Maybe<Link_Precision_Type_Mutation_Response>;
  /** delete single row from the table: "link_precision_type" */
  delete_link_precision_type_by_pk?: Maybe<Link_Precision_Type>;
  /** delete data from the table: "link_relationship_status" */
  delete_link_relationship_status?: Maybe<Link_Relationship_Status_Mutation_Response>;
  /** delete single row from the table: "link_relationship_status" */
  delete_link_relationship_status_by_pk?: Maybe<Link_Relationship_Status>;
  /** delete data from the table: "operation" */
  delete_operation?: Maybe<Operation_Mutation_Response>;
  /** delete single row from the table: "operation" */
  delete_operation_by_pk?: Maybe<Operation>;
  /** delete data from the table: "operation_progress_status" */
  delete_operation_progress_status?: Maybe<Operation_Progress_Status_Mutation_Response>;
  /** delete single row from the table: "operation_progress_status" */
  delete_operation_progress_status_by_pk?: Maybe<Operation_Progress_Status>;
  /** delete data from the table: "operation_request_item" */
  delete_operation_request_item?: Maybe<Operation_Request_Item_Mutation_Response>;
  /** delete single row from the table: "operation_request_item" */
  delete_operation_request_item_by_pk?: Maybe<Operation_Request_Item>;
  /** delete data from the table: "operation_request_item_type" */
  delete_operation_request_item_type?: Maybe<Operation_Request_Item_Type_Mutation_Response>;
  /** delete single row from the table: "operation_request_item_type" */
  delete_operation_request_item_type_by_pk?: Maybe<Operation_Request_Item_Type>;
  /** delete data from the table: "operation_state" */
  delete_operation_state?: Maybe<Operation_State_Mutation_Response>;
  /** delete single row from the table: "operation_state" */
  delete_operation_state_by_pk?: Maybe<Operation_State>;
  /** delete data from the table: "operation_trigger_type" */
  delete_operation_trigger_type?: Maybe<Operation_Trigger_Type_Mutation_Response>;
  /** delete single row from the table: "operation_trigger_type" */
  delete_operation_trigger_type_by_pk?: Maybe<Operation_Trigger_Type>;
  /** delete data from the table: "operation_type" */
  delete_operation_type?: Maybe<Operation_Type_Mutation_Response>;
  /** delete single row from the table: "operation_type" */
  delete_operation_type_by_pk?: Maybe<Operation_Type>;
  /** delete data from the table: "outlook_linkedin_credential" */
  delete_outlook_linkedin_credential?: Maybe<Outlook_Linkedin_Credential_Mutation_Response>;
  /** delete single row from the table: "outlook_linkedin_credential" */
  delete_outlook_linkedin_credential_by_pk?: Maybe<Outlook_Linkedin_Credential>;
  /** delete data from the table: "person" */
  delete_person?: Maybe<Person_Mutation_Response>;
  /** delete single row from the table: "person" */
  delete_person_by_pk?: Maybe<Person>;
  /** delete data from the table: "person_group" */
  delete_person_group?: Maybe<Person_Group_Mutation_Response>;
  /** delete single row from the table: "person_group" */
  delete_person_group_by_pk?: Maybe<Person_Group>;
  /** delete data from the table: "person_to_address" */
  delete_person_to_address?: Maybe<Person_To_Address_Mutation_Response>;
  /** delete single row from the table: "person_to_address" */
  delete_person_to_address_by_pk?: Maybe<Person_To_Address>;
  /** delete data from the table: "person_to_person_group" */
  delete_person_to_person_group?: Maybe<Person_To_Person_Group_Mutation_Response>;
  /** delete single row from the table: "person_to_person_group" */
  delete_person_to_person_group_by_pk?: Maybe<Person_To_Person_Group>;
  /** delete data from the table: "postal_code" */
  delete_postal_code?: Maybe<Postal_Code_Mutation_Response>;
  /** delete single row from the table: "postal_code" */
  delete_postal_code_by_pk?: Maybe<Postal_Code>;
  /** delete data from the table: "provider" */
  delete_provider?: Maybe<Provider_Mutation_Response>;
  /** delete single row from the table: "provider" */
  delete_provider_by_pk?: Maybe<Provider>;
  /** delete data from the table: "raw_response" */
  delete_raw_response?: Maybe<Raw_Response_Mutation_Response>;
  /** delete single row from the table: "raw_response" */
  delete_raw_response_by_pk?: Maybe<Raw_Response>;
  /** delete data from the table: "raw_response_formulation_type" */
  delete_raw_response_formulation_type?: Maybe<Raw_Response_Formulation_Type_Mutation_Response>;
  /** delete single row from the table: "raw_response_formulation_type" */
  delete_raw_response_formulation_type_by_pk?: Maybe<Raw_Response_Formulation_Type>;
  /** delete data from the table: "raw_response_search_action" */
  delete_raw_response_search_action?: Maybe<Raw_Response_Search_Action_Mutation_Response>;
  /** delete single row from the table: "raw_response_search_action" */
  delete_raw_response_search_action_by_pk?: Maybe<Raw_Response_Search_Action>;
  /** delete data from the table: "state" */
  delete_state?: Maybe<State_Mutation_Response>;
  /** delete single row from the table: "state" */
  delete_state_by_pk?: Maybe<State>;
  /** delete data from the table: "stated_address_source" */
  delete_stated_address_source?: Maybe<Stated_Address_Source_Mutation_Response>;
  /** delete single row from the table: "stated_address_source" */
  delete_stated_address_source_by_pk?: Maybe<Stated_Address_Source>;
  /** delete data from the table: "tracking_group" */
  delete_tracking_group?: Maybe<Tracking_Group_Mutation_Response>;
  /** delete single row from the table: "tracking_group" */
  delete_tracking_group_by_pk?: Maybe<Tracking_Group>;
  /** delete data from the table: "tracking_group_item" */
  delete_tracking_group_item?: Maybe<Tracking_Group_Item_Mutation_Response>;
  /** delete single row from the table: "tracking_group_item" */
  delete_tracking_group_item_by_pk?: Maybe<Tracking_Group_Item>;
  /** delete data from the table: "tracking_group_item_type" */
  delete_tracking_group_item_type?: Maybe<Tracking_Group_Item_Type_Mutation_Response>;
  /** delete single row from the table: "tracking_group_item_type" */
  delete_tracking_group_item_type_by_pk?: Maybe<Tracking_Group_Item_Type>;
  /** delete data from the table: "tracking_group_type" */
  delete_tracking_group_type?: Maybe<Tracking_Group_Type_Mutation_Response>;
  /** delete single row from the table: "tracking_group_type" */
  delete_tracking_group_type_by_pk?: Maybe<Tracking_Group_Type>;
  /** delete data from the table: "value_type" */
  delete_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** delete single row from the table: "value_type" */
  delete_value_type_by_pk?: Maybe<Value_Type>;
  /** delete data from the table: "yoyo_lock" */
  delete_yoyo_lock?: Maybe<Yoyo_Lock_Mutation_Response>;
  /** delete single row from the table: "yoyo_lock" */
  delete_yoyo_lock_by_pk?: Maybe<Yoyo_Lock>;
  /** insert data into the table: "_yoyo_log" */
  insert__yoyo_log?: Maybe<_Yoyo_Log_Mutation_Response>;
  /** insert a single row into the table: "_yoyo_log" */
  insert__yoyo_log_one?: Maybe<_Yoyo_Log>;
  /** insert data into the table: "_yoyo_migration" */
  insert__yoyo_migration?: Maybe<_Yoyo_Migration_Mutation_Response>;
  /** insert a single row into the table: "_yoyo_migration" */
  insert__yoyo_migration_one?: Maybe<_Yoyo_Migration>;
  /** insert data into the table: "_yoyo_version" */
  insert__yoyo_version?: Maybe<_Yoyo_Version_Mutation_Response>;
  /** insert a single row into the table: "_yoyo_version" */
  insert__yoyo_version_one?: Maybe<_Yoyo_Version>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "attribute" */
  insert_attribute?: Maybe<Attribute_Mutation_Response>;
  /** insert a single row into the table: "attribute" */
  insert_attribute_one?: Maybe<Attribute>;
  /** insert data into the table: "attribute_type" */
  insert_attribute_type?: Maybe<Attribute_Type_Mutation_Response>;
  /** insert a single row into the table: "attribute_type" */
  insert_attribute_type_one?: Maybe<Attribute_Type>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "digital_profile" */
  insert_digital_profile?: Maybe<Digital_Profile_Mutation_Response>;
  /** insert a single row into the table: "digital_profile" */
  insert_digital_profile_one?: Maybe<Digital_Profile>;
  /** insert data into the table: "digital_profile_to_address" */
  insert_digital_profile_to_address?: Maybe<Digital_Profile_To_Address_Mutation_Response>;
  /** insert a single row into the table: "digital_profile_to_address" */
  insert_digital_profile_to_address_one?: Maybe<Digital_Profile_To_Address>;
  /** insert data into the table: "digital_profile_to_person" */
  insert_digital_profile_to_person?: Maybe<Digital_Profile_To_Person_Mutation_Response>;
  /** insert a single row into the table: "digital_profile_to_person" */
  insert_digital_profile_to_person_one?: Maybe<Digital_Profile_To_Person>;
  /** insert data into the table: "digital_profile_type" */
  insert_digital_profile_type?: Maybe<Digital_Profile_Type_Mutation_Response>;
  /** insert a single row into the table: "digital_profile_type" */
  insert_digital_profile_type_one?: Maybe<Digital_Profile_Type>;
  /** insert data into the table: "entity_to_raw_response" */
  insert_entity_to_raw_response?: Maybe<Entity_To_Raw_Response_Mutation_Response>;
  /** insert a single row into the table: "entity_to_raw_response" */
  insert_entity_to_raw_response_one?: Maybe<Entity_To_Raw_Response>;
  /** insert data into the table: "link_confidence_category" */
  insert_link_confidence_category?: Maybe<Link_Confidence_Category_Mutation_Response>;
  /** insert a single row into the table: "link_confidence_category" */
  insert_link_confidence_category_one?: Maybe<Link_Confidence_Category>;
  /** insert data into the table: "link_precision_type" */
  insert_link_precision_type?: Maybe<Link_Precision_Type_Mutation_Response>;
  /** insert a single row into the table: "link_precision_type" */
  insert_link_precision_type_one?: Maybe<Link_Precision_Type>;
  /** insert data into the table: "link_relationship_status" */
  insert_link_relationship_status?: Maybe<Link_Relationship_Status_Mutation_Response>;
  /** insert a single row into the table: "link_relationship_status" */
  insert_link_relationship_status_one?: Maybe<Link_Relationship_Status>;
  /** insert data into the table: "operation" */
  insert_operation?: Maybe<Operation_Mutation_Response>;
  /** insert a single row into the table: "operation" */
  insert_operation_one?: Maybe<Operation>;
  /** insert data into the table: "operation_progress_status" */
  insert_operation_progress_status?: Maybe<Operation_Progress_Status_Mutation_Response>;
  /** insert a single row into the table: "operation_progress_status" */
  insert_operation_progress_status_one?: Maybe<Operation_Progress_Status>;
  /** insert data into the table: "operation_request_item" */
  insert_operation_request_item?: Maybe<Operation_Request_Item_Mutation_Response>;
  /** insert a single row into the table: "operation_request_item" */
  insert_operation_request_item_one?: Maybe<Operation_Request_Item>;
  /** insert data into the table: "operation_request_item_type" */
  insert_operation_request_item_type?: Maybe<Operation_Request_Item_Type_Mutation_Response>;
  /** insert a single row into the table: "operation_request_item_type" */
  insert_operation_request_item_type_one?: Maybe<Operation_Request_Item_Type>;
  /** insert data into the table: "operation_state" */
  insert_operation_state?: Maybe<Operation_State_Mutation_Response>;
  /** insert a single row into the table: "operation_state" */
  insert_operation_state_one?: Maybe<Operation_State>;
  /** insert data into the table: "operation_trigger_type" */
  insert_operation_trigger_type?: Maybe<Operation_Trigger_Type_Mutation_Response>;
  /** insert a single row into the table: "operation_trigger_type" */
  insert_operation_trigger_type_one?: Maybe<Operation_Trigger_Type>;
  /** insert data into the table: "operation_type" */
  insert_operation_type?: Maybe<Operation_Type_Mutation_Response>;
  /** insert a single row into the table: "operation_type" */
  insert_operation_type_one?: Maybe<Operation_Type>;
  /** insert data into the table: "outlook_linkedin_credential" */
  insert_outlook_linkedin_credential?: Maybe<Outlook_Linkedin_Credential_Mutation_Response>;
  /** insert a single row into the table: "outlook_linkedin_credential" */
  insert_outlook_linkedin_credential_one?: Maybe<Outlook_Linkedin_Credential>;
  /** insert data into the table: "person" */
  insert_person?: Maybe<Person_Mutation_Response>;
  /** insert data into the table: "person_group" */
  insert_person_group?: Maybe<Person_Group_Mutation_Response>;
  /** insert a single row into the table: "person_group" */
  insert_person_group_one?: Maybe<Person_Group>;
  /** insert a single row into the table: "person" */
  insert_person_one?: Maybe<Person>;
  /** insert data into the table: "person_to_address" */
  insert_person_to_address?: Maybe<Person_To_Address_Mutation_Response>;
  /** insert a single row into the table: "person_to_address" */
  insert_person_to_address_one?: Maybe<Person_To_Address>;
  /** insert data into the table: "person_to_person_group" */
  insert_person_to_person_group?: Maybe<Person_To_Person_Group_Mutation_Response>;
  /** insert a single row into the table: "person_to_person_group" */
  insert_person_to_person_group_one?: Maybe<Person_To_Person_Group>;
  /** insert data into the table: "postal_code" */
  insert_postal_code?: Maybe<Postal_Code_Mutation_Response>;
  /** insert a single row into the table: "postal_code" */
  insert_postal_code_one?: Maybe<Postal_Code>;
  /** insert data into the table: "provider" */
  insert_provider?: Maybe<Provider_Mutation_Response>;
  /** insert a single row into the table: "provider" */
  insert_provider_one?: Maybe<Provider>;
  /** insert data into the table: "raw_response" */
  insert_raw_response?: Maybe<Raw_Response_Mutation_Response>;
  /** insert data into the table: "raw_response_formulation_type" */
  insert_raw_response_formulation_type?: Maybe<Raw_Response_Formulation_Type_Mutation_Response>;
  /** insert a single row into the table: "raw_response_formulation_type" */
  insert_raw_response_formulation_type_one?: Maybe<Raw_Response_Formulation_Type>;
  /** insert a single row into the table: "raw_response" */
  insert_raw_response_one?: Maybe<Raw_Response>;
  /** insert data into the table: "raw_response_search_action" */
  insert_raw_response_search_action?: Maybe<Raw_Response_Search_Action_Mutation_Response>;
  /** insert a single row into the table: "raw_response_search_action" */
  insert_raw_response_search_action_one?: Maybe<Raw_Response_Search_Action>;
  /** insert data into the table: "state" */
  insert_state?: Maybe<State_Mutation_Response>;
  /** insert a single row into the table: "state" */
  insert_state_one?: Maybe<State>;
  /** insert data into the table: "stated_address_source" */
  insert_stated_address_source?: Maybe<Stated_Address_Source_Mutation_Response>;
  /** insert a single row into the table: "stated_address_source" */
  insert_stated_address_source_one?: Maybe<Stated_Address_Source>;
  /** insert data into the table: "tracking_group" */
  insert_tracking_group?: Maybe<Tracking_Group_Mutation_Response>;
  /** insert data into the table: "tracking_group_item" */
  insert_tracking_group_item?: Maybe<Tracking_Group_Item_Mutation_Response>;
  /** insert a single row into the table: "tracking_group_item" */
  insert_tracking_group_item_one?: Maybe<Tracking_Group_Item>;
  /** insert data into the table: "tracking_group_item_type" */
  insert_tracking_group_item_type?: Maybe<Tracking_Group_Item_Type_Mutation_Response>;
  /** insert a single row into the table: "tracking_group_item_type" */
  insert_tracking_group_item_type_one?: Maybe<Tracking_Group_Item_Type>;
  /** insert a single row into the table: "tracking_group" */
  insert_tracking_group_one?: Maybe<Tracking_Group>;
  /** insert data into the table: "tracking_group_type" */
  insert_tracking_group_type?: Maybe<Tracking_Group_Type_Mutation_Response>;
  /** insert a single row into the table: "tracking_group_type" */
  insert_tracking_group_type_one?: Maybe<Tracking_Group_Type>;
  /** insert data into the table: "value_type" */
  insert_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** insert a single row into the table: "value_type" */
  insert_value_type_one?: Maybe<Value_Type>;
  /** insert data into the table: "yoyo_lock" */
  insert_yoyo_lock?: Maybe<Yoyo_Lock_Mutation_Response>;
  /** insert a single row into the table: "yoyo_lock" */
  insert_yoyo_lock_one?: Maybe<Yoyo_Lock>;
  /** update data of the table: "_yoyo_log" */
  update__yoyo_log?: Maybe<_Yoyo_Log_Mutation_Response>;
  /** update single row of the table: "_yoyo_log" */
  update__yoyo_log_by_pk?: Maybe<_Yoyo_Log>;
  /** update multiples rows of table: "_yoyo_log" */
  update__yoyo_log_many?: Maybe<Array<Maybe<_Yoyo_Log_Mutation_Response>>>;
  /** update data of the table: "_yoyo_migration" */
  update__yoyo_migration?: Maybe<_Yoyo_Migration_Mutation_Response>;
  /** update single row of the table: "_yoyo_migration" */
  update__yoyo_migration_by_pk?: Maybe<_Yoyo_Migration>;
  /** update multiples rows of table: "_yoyo_migration" */
  update__yoyo_migration_many?: Maybe<Array<Maybe<_Yoyo_Migration_Mutation_Response>>>;
  /** update data of the table: "_yoyo_version" */
  update__yoyo_version?: Maybe<_Yoyo_Version_Mutation_Response>;
  /** update single row of the table: "_yoyo_version" */
  update__yoyo_version_by_pk?: Maybe<_Yoyo_Version>;
  /** update multiples rows of table: "_yoyo_version" */
  update__yoyo_version_many?: Maybe<Array<Maybe<_Yoyo_Version_Mutation_Response>>>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "attribute" */
  update_attribute?: Maybe<Attribute_Mutation_Response>;
  /** update single row of the table: "attribute" */
  update_attribute_by_pk?: Maybe<Attribute>;
  /** update multiples rows of table: "attribute" */
  update_attribute_many?: Maybe<Array<Maybe<Attribute_Mutation_Response>>>;
  /** update data of the table: "attribute_type" */
  update_attribute_type?: Maybe<Attribute_Type_Mutation_Response>;
  /** update single row of the table: "attribute_type" */
  update_attribute_type_by_pk?: Maybe<Attribute_Type>;
  /** update multiples rows of table: "attribute_type" */
  update_attribute_type_many?: Maybe<Array<Maybe<Attribute_Type_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "digital_profile" */
  update_digital_profile?: Maybe<Digital_Profile_Mutation_Response>;
  /** update single row of the table: "digital_profile" */
  update_digital_profile_by_pk?: Maybe<Digital_Profile>;
  /** update multiples rows of table: "digital_profile" */
  update_digital_profile_many?: Maybe<Array<Maybe<Digital_Profile_Mutation_Response>>>;
  /** update data of the table: "digital_profile_to_address" */
  update_digital_profile_to_address?: Maybe<Digital_Profile_To_Address_Mutation_Response>;
  /** update single row of the table: "digital_profile_to_address" */
  update_digital_profile_to_address_by_pk?: Maybe<Digital_Profile_To_Address>;
  /** update multiples rows of table: "digital_profile_to_address" */
  update_digital_profile_to_address_many?: Maybe<Array<Maybe<Digital_Profile_To_Address_Mutation_Response>>>;
  /** update data of the table: "digital_profile_to_person" */
  update_digital_profile_to_person?: Maybe<Digital_Profile_To_Person_Mutation_Response>;
  /** update single row of the table: "digital_profile_to_person" */
  update_digital_profile_to_person_by_pk?: Maybe<Digital_Profile_To_Person>;
  /** update multiples rows of table: "digital_profile_to_person" */
  update_digital_profile_to_person_many?: Maybe<Array<Maybe<Digital_Profile_To_Person_Mutation_Response>>>;
  /** update data of the table: "digital_profile_type" */
  update_digital_profile_type?: Maybe<Digital_Profile_Type_Mutation_Response>;
  /** update single row of the table: "digital_profile_type" */
  update_digital_profile_type_by_pk?: Maybe<Digital_Profile_Type>;
  /** update multiples rows of table: "digital_profile_type" */
  update_digital_profile_type_many?: Maybe<Array<Maybe<Digital_Profile_Type_Mutation_Response>>>;
  /** update data of the table: "entity_to_raw_response" */
  update_entity_to_raw_response?: Maybe<Entity_To_Raw_Response_Mutation_Response>;
  /** update single row of the table: "entity_to_raw_response" */
  update_entity_to_raw_response_by_pk?: Maybe<Entity_To_Raw_Response>;
  /** update multiples rows of table: "entity_to_raw_response" */
  update_entity_to_raw_response_many?: Maybe<Array<Maybe<Entity_To_Raw_Response_Mutation_Response>>>;
  /** update data of the table: "link_confidence_category" */
  update_link_confidence_category?: Maybe<Link_Confidence_Category_Mutation_Response>;
  /** update single row of the table: "link_confidence_category" */
  update_link_confidence_category_by_pk?: Maybe<Link_Confidence_Category>;
  /** update multiples rows of table: "link_confidence_category" */
  update_link_confidence_category_many?: Maybe<Array<Maybe<Link_Confidence_Category_Mutation_Response>>>;
  /** update data of the table: "link_precision_type" */
  update_link_precision_type?: Maybe<Link_Precision_Type_Mutation_Response>;
  /** update single row of the table: "link_precision_type" */
  update_link_precision_type_by_pk?: Maybe<Link_Precision_Type>;
  /** update multiples rows of table: "link_precision_type" */
  update_link_precision_type_many?: Maybe<Array<Maybe<Link_Precision_Type_Mutation_Response>>>;
  /** update data of the table: "link_relationship_status" */
  update_link_relationship_status?: Maybe<Link_Relationship_Status_Mutation_Response>;
  /** update single row of the table: "link_relationship_status" */
  update_link_relationship_status_by_pk?: Maybe<Link_Relationship_Status>;
  /** update multiples rows of table: "link_relationship_status" */
  update_link_relationship_status_many?: Maybe<Array<Maybe<Link_Relationship_Status_Mutation_Response>>>;
  /** update data of the table: "operation" */
  update_operation?: Maybe<Operation_Mutation_Response>;
  /** update single row of the table: "operation" */
  update_operation_by_pk?: Maybe<Operation>;
  /** update multiples rows of table: "operation" */
  update_operation_many?: Maybe<Array<Maybe<Operation_Mutation_Response>>>;
  /** update data of the table: "operation_progress_status" */
  update_operation_progress_status?: Maybe<Operation_Progress_Status_Mutation_Response>;
  /** update single row of the table: "operation_progress_status" */
  update_operation_progress_status_by_pk?: Maybe<Operation_Progress_Status>;
  /** update multiples rows of table: "operation_progress_status" */
  update_operation_progress_status_many?: Maybe<Array<Maybe<Operation_Progress_Status_Mutation_Response>>>;
  /** update data of the table: "operation_request_item" */
  update_operation_request_item?: Maybe<Operation_Request_Item_Mutation_Response>;
  /** update single row of the table: "operation_request_item" */
  update_operation_request_item_by_pk?: Maybe<Operation_Request_Item>;
  /** update multiples rows of table: "operation_request_item" */
  update_operation_request_item_many?: Maybe<Array<Maybe<Operation_Request_Item_Mutation_Response>>>;
  /** update data of the table: "operation_request_item_type" */
  update_operation_request_item_type?: Maybe<Operation_Request_Item_Type_Mutation_Response>;
  /** update single row of the table: "operation_request_item_type" */
  update_operation_request_item_type_by_pk?: Maybe<Operation_Request_Item_Type>;
  /** update multiples rows of table: "operation_request_item_type" */
  update_operation_request_item_type_many?: Maybe<Array<Maybe<Operation_Request_Item_Type_Mutation_Response>>>;
  /** update data of the table: "operation_state" */
  update_operation_state?: Maybe<Operation_State_Mutation_Response>;
  /** update single row of the table: "operation_state" */
  update_operation_state_by_pk?: Maybe<Operation_State>;
  /** update multiples rows of table: "operation_state" */
  update_operation_state_many?: Maybe<Array<Maybe<Operation_State_Mutation_Response>>>;
  /** update data of the table: "operation_trigger_type" */
  update_operation_trigger_type?: Maybe<Operation_Trigger_Type_Mutation_Response>;
  /** update single row of the table: "operation_trigger_type" */
  update_operation_trigger_type_by_pk?: Maybe<Operation_Trigger_Type>;
  /** update multiples rows of table: "operation_trigger_type" */
  update_operation_trigger_type_many?: Maybe<Array<Maybe<Operation_Trigger_Type_Mutation_Response>>>;
  /** update data of the table: "operation_type" */
  update_operation_type?: Maybe<Operation_Type_Mutation_Response>;
  /** update single row of the table: "operation_type" */
  update_operation_type_by_pk?: Maybe<Operation_Type>;
  /** update multiples rows of table: "operation_type" */
  update_operation_type_many?: Maybe<Array<Maybe<Operation_Type_Mutation_Response>>>;
  /** update data of the table: "outlook_linkedin_credential" */
  update_outlook_linkedin_credential?: Maybe<Outlook_Linkedin_Credential_Mutation_Response>;
  /** update single row of the table: "outlook_linkedin_credential" */
  update_outlook_linkedin_credential_by_pk?: Maybe<Outlook_Linkedin_Credential>;
  /** update multiples rows of table: "outlook_linkedin_credential" */
  update_outlook_linkedin_credential_many?: Maybe<Array<Maybe<Outlook_Linkedin_Credential_Mutation_Response>>>;
  /** update data of the table: "person" */
  update_person?: Maybe<Person_Mutation_Response>;
  /** update single row of the table: "person" */
  update_person_by_pk?: Maybe<Person>;
  /** update data of the table: "person_group" */
  update_person_group?: Maybe<Person_Group_Mutation_Response>;
  /** update single row of the table: "person_group" */
  update_person_group_by_pk?: Maybe<Person_Group>;
  /** update multiples rows of table: "person_group" */
  update_person_group_many?: Maybe<Array<Maybe<Person_Group_Mutation_Response>>>;
  /** update multiples rows of table: "person" */
  update_person_many?: Maybe<Array<Maybe<Person_Mutation_Response>>>;
  /** update data of the table: "person_to_address" */
  update_person_to_address?: Maybe<Person_To_Address_Mutation_Response>;
  /** update single row of the table: "person_to_address" */
  update_person_to_address_by_pk?: Maybe<Person_To_Address>;
  /** update multiples rows of table: "person_to_address" */
  update_person_to_address_many?: Maybe<Array<Maybe<Person_To_Address_Mutation_Response>>>;
  /** update data of the table: "person_to_person_group" */
  update_person_to_person_group?: Maybe<Person_To_Person_Group_Mutation_Response>;
  /** update single row of the table: "person_to_person_group" */
  update_person_to_person_group_by_pk?: Maybe<Person_To_Person_Group>;
  /** update multiples rows of table: "person_to_person_group" */
  update_person_to_person_group_many?: Maybe<Array<Maybe<Person_To_Person_Group_Mutation_Response>>>;
  /** update data of the table: "postal_code" */
  update_postal_code?: Maybe<Postal_Code_Mutation_Response>;
  /** update single row of the table: "postal_code" */
  update_postal_code_by_pk?: Maybe<Postal_Code>;
  /** update multiples rows of table: "postal_code" */
  update_postal_code_many?: Maybe<Array<Maybe<Postal_Code_Mutation_Response>>>;
  /** update data of the table: "provider" */
  update_provider?: Maybe<Provider_Mutation_Response>;
  /** update single row of the table: "provider" */
  update_provider_by_pk?: Maybe<Provider>;
  /** update multiples rows of table: "provider" */
  update_provider_many?: Maybe<Array<Maybe<Provider_Mutation_Response>>>;
  /** update data of the table: "raw_response" */
  update_raw_response?: Maybe<Raw_Response_Mutation_Response>;
  /** update single row of the table: "raw_response" */
  update_raw_response_by_pk?: Maybe<Raw_Response>;
  /** update data of the table: "raw_response_formulation_type" */
  update_raw_response_formulation_type?: Maybe<Raw_Response_Formulation_Type_Mutation_Response>;
  /** update single row of the table: "raw_response_formulation_type" */
  update_raw_response_formulation_type_by_pk?: Maybe<Raw_Response_Formulation_Type>;
  /** update multiples rows of table: "raw_response_formulation_type" */
  update_raw_response_formulation_type_many?: Maybe<Array<Maybe<Raw_Response_Formulation_Type_Mutation_Response>>>;
  /** update multiples rows of table: "raw_response" */
  update_raw_response_many?: Maybe<Array<Maybe<Raw_Response_Mutation_Response>>>;
  /** update data of the table: "raw_response_search_action" */
  update_raw_response_search_action?: Maybe<Raw_Response_Search_Action_Mutation_Response>;
  /** update single row of the table: "raw_response_search_action" */
  update_raw_response_search_action_by_pk?: Maybe<Raw_Response_Search_Action>;
  /** update multiples rows of table: "raw_response_search_action" */
  update_raw_response_search_action_many?: Maybe<Array<Maybe<Raw_Response_Search_Action_Mutation_Response>>>;
  /** update data of the table: "state" */
  update_state?: Maybe<State_Mutation_Response>;
  /** update single row of the table: "state" */
  update_state_by_pk?: Maybe<State>;
  /** update multiples rows of table: "state" */
  update_state_many?: Maybe<Array<Maybe<State_Mutation_Response>>>;
  /** update data of the table: "stated_address_source" */
  update_stated_address_source?: Maybe<Stated_Address_Source_Mutation_Response>;
  /** update single row of the table: "stated_address_source" */
  update_stated_address_source_by_pk?: Maybe<Stated_Address_Source>;
  /** update multiples rows of table: "stated_address_source" */
  update_stated_address_source_many?: Maybe<Array<Maybe<Stated_Address_Source_Mutation_Response>>>;
  /** update data of the table: "tracking_group" */
  update_tracking_group?: Maybe<Tracking_Group_Mutation_Response>;
  /** update single row of the table: "tracking_group" */
  update_tracking_group_by_pk?: Maybe<Tracking_Group>;
  /** update data of the table: "tracking_group_item" */
  update_tracking_group_item?: Maybe<Tracking_Group_Item_Mutation_Response>;
  /** update single row of the table: "tracking_group_item" */
  update_tracking_group_item_by_pk?: Maybe<Tracking_Group_Item>;
  /** update multiples rows of table: "tracking_group_item" */
  update_tracking_group_item_many?: Maybe<Array<Maybe<Tracking_Group_Item_Mutation_Response>>>;
  /** update data of the table: "tracking_group_item_type" */
  update_tracking_group_item_type?: Maybe<Tracking_Group_Item_Type_Mutation_Response>;
  /** update single row of the table: "tracking_group_item_type" */
  update_tracking_group_item_type_by_pk?: Maybe<Tracking_Group_Item_Type>;
  /** update multiples rows of table: "tracking_group_item_type" */
  update_tracking_group_item_type_many?: Maybe<Array<Maybe<Tracking_Group_Item_Type_Mutation_Response>>>;
  /** update multiples rows of table: "tracking_group" */
  update_tracking_group_many?: Maybe<Array<Maybe<Tracking_Group_Mutation_Response>>>;
  /** update data of the table: "tracking_group_type" */
  update_tracking_group_type?: Maybe<Tracking_Group_Type_Mutation_Response>;
  /** update single row of the table: "tracking_group_type" */
  update_tracking_group_type_by_pk?: Maybe<Tracking_Group_Type>;
  /** update multiples rows of table: "tracking_group_type" */
  update_tracking_group_type_many?: Maybe<Array<Maybe<Tracking_Group_Type_Mutation_Response>>>;
  /** update data of the table: "value_type" */
  update_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** update single row of the table: "value_type" */
  update_value_type_by_pk?: Maybe<Value_Type>;
  /** update multiples rows of table: "value_type" */
  update_value_type_many?: Maybe<Array<Maybe<Value_Type_Mutation_Response>>>;
  /** update data of the table: "yoyo_lock" */
  update_yoyo_lock?: Maybe<Yoyo_Lock_Mutation_Response>;
  /** update single row of the table: "yoyo_lock" */
  update_yoyo_lock_by_pk?: Maybe<Yoyo_Lock>;
  /** update multiples rows of table: "yoyo_lock" */
  update_yoyo_lock_many?: Maybe<Array<Maybe<Yoyo_Lock_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_LogArgs = {
  where: _Yoyo_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_Log_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_MigrationArgs = {
  where: _Yoyo_Migration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_Migration_By_PkArgs = {
  migration_hash: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_VersionArgs = {
  where: _Yoyo_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Yoyo_Version_By_PkArgs = {
  version: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AttributeArgs = {
  where: Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attribute_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Attribute_TypeArgs = {
  where: Attribute_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attribute_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Digital_ProfileArgs = {
  where: Digital_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_To_AddressArgs = {
  where: Digital_Profile_To_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_To_PersonArgs = {
  where: Digital_Profile_To_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_To_Person_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_TypeArgs = {
  where: Digital_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Digital_Profile_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Entity_To_Raw_ResponseArgs = {
  where: Entity_To_Raw_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entity_To_Raw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Link_Confidence_CategoryArgs = {
  where: Link_Confidence_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Link_Confidence_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Link_Precision_TypeArgs = {
  where: Link_Precision_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Link_Precision_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Link_Relationship_StatusArgs = {
  where: Link_Relationship_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Link_Relationship_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OperationArgs = {
  where: Operation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_Progress_StatusArgs = {
  where: Operation_Progress_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Progress_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_Request_ItemArgs = {
  where: Operation_Request_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Request_Item_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_Request_Item_TypeArgs = {
  where: Operation_Request_Item_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Request_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_StateArgs = {
  where: Operation_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_State_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_Trigger_TypeArgs = {
  where: Operation_Trigger_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Trigger_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_TypeArgs = {
  where: Operation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outlook_Linkedin_CredentialArgs = {
  where: Outlook_Linkedin_Credential_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outlook_Linkedin_Credential_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PersonArgs = {
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Person_GroupArgs = {
  where: Person_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_Group_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Person_To_AddressArgs = {
  where: Person_To_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Person_To_Person_GroupArgs = {
  where: Person_To_Person_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_To_Person_Group_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Postal_CodeArgs = {
  where: Postal_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Postal_Code_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProviderArgs = {
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Raw_ResponseArgs = {
  where: Raw_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Raw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Raw_Response_Formulation_TypeArgs = {
  where: Raw_Response_Formulation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Raw_Response_Formulation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Raw_Response_Search_ActionArgs = {
  where: Raw_Response_Search_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Raw_Response_Search_Action_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StateArgs = {
  where: State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_State_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stated_Address_SourceArgs = {
  where: Stated_Address_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stated_Address_Source_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tracking_GroupArgs = {
  where: Tracking_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_ItemArgs = {
  where: Tracking_Group_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_Item_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_Item_TypeArgs = {
  where: Tracking_Group_Item_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_TypeArgs = {
  where: Tracking_Group_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Group_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Value_TypeArgs = {
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Value_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Yoyo_LockArgs = {
  where: Yoyo_Lock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Yoyo_Lock_By_PkArgs = {
  locked: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_LogArgs = {
  objects: Array<_Yoyo_Log_Insert_Input>;
  on_conflict?: InputMaybe<_Yoyo_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_Log_OneArgs = {
  object: _Yoyo_Log_Insert_Input;
  on_conflict?: InputMaybe<_Yoyo_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_MigrationArgs = {
  objects: Array<_Yoyo_Migration_Insert_Input>;
  on_conflict?: InputMaybe<_Yoyo_Migration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_Migration_OneArgs = {
  object: _Yoyo_Migration_Insert_Input;
  on_conflict?: InputMaybe<_Yoyo_Migration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_VersionArgs = {
  objects: Array<_Yoyo_Version_Insert_Input>;
  on_conflict?: InputMaybe<_Yoyo_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Yoyo_Version_OneArgs = {
  object: _Yoyo_Version_Insert_Input;
  on_conflict?: InputMaybe<_Yoyo_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttributeArgs = {
  objects: Array<Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attribute_OneArgs = {
  object: Attribute_Insert_Input;
  on_conflict?: InputMaybe<Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attribute_TypeArgs = {
  objects: Array<Attribute_Type_Insert_Input>;
  on_conflict?: InputMaybe<Attribute_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attribute_Type_OneArgs = {
  object: Attribute_Type_Insert_Input;
  on_conflict?: InputMaybe<Attribute_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_ProfileArgs = {
  objects: Array<Digital_Profile_Insert_Input>;
  on_conflict?: InputMaybe<Digital_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_OneArgs = {
  object: Digital_Profile_Insert_Input;
  on_conflict?: InputMaybe<Digital_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_To_AddressArgs = {
  objects: Array<Digital_Profile_To_Address_Insert_Input>;
  on_conflict?: InputMaybe<Digital_Profile_To_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_To_Address_OneArgs = {
  object: Digital_Profile_To_Address_Insert_Input;
  on_conflict?: InputMaybe<Digital_Profile_To_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_To_PersonArgs = {
  objects: Array<Digital_Profile_To_Person_Insert_Input>;
  on_conflict?: InputMaybe<Digital_Profile_To_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_To_Person_OneArgs = {
  object: Digital_Profile_To_Person_Insert_Input;
  on_conflict?: InputMaybe<Digital_Profile_To_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_TypeArgs = {
  objects: Array<Digital_Profile_Type_Insert_Input>;
  on_conflict?: InputMaybe<Digital_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Profile_Type_OneArgs = {
  object: Digital_Profile_Type_Insert_Input;
  on_conflict?: InputMaybe<Digital_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_To_Raw_ResponseArgs = {
  objects: Array<Entity_To_Raw_Response_Insert_Input>;
  on_conflict?: InputMaybe<Entity_To_Raw_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_To_Raw_Response_OneArgs = {
  object: Entity_To_Raw_Response_Insert_Input;
  on_conflict?: InputMaybe<Entity_To_Raw_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Confidence_CategoryArgs = {
  objects: Array<Link_Confidence_Category_Insert_Input>;
  on_conflict?: InputMaybe<Link_Confidence_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Confidence_Category_OneArgs = {
  object: Link_Confidence_Category_Insert_Input;
  on_conflict?: InputMaybe<Link_Confidence_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Precision_TypeArgs = {
  objects: Array<Link_Precision_Type_Insert_Input>;
  on_conflict?: InputMaybe<Link_Precision_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Precision_Type_OneArgs = {
  object: Link_Precision_Type_Insert_Input;
  on_conflict?: InputMaybe<Link_Precision_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Relationship_StatusArgs = {
  objects: Array<Link_Relationship_Status_Insert_Input>;
  on_conflict?: InputMaybe<Link_Relationship_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Link_Relationship_Status_OneArgs = {
  object: Link_Relationship_Status_Insert_Input;
  on_conflict?: InputMaybe<Link_Relationship_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OperationArgs = {
  objects: Array<Operation_Insert_Input>;
  on_conflict?: InputMaybe<Operation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_OneArgs = {
  object: Operation_Insert_Input;
  on_conflict?: InputMaybe<Operation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Progress_StatusArgs = {
  objects: Array<Operation_Progress_Status_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Progress_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Progress_Status_OneArgs = {
  object: Operation_Progress_Status_Insert_Input;
  on_conflict?: InputMaybe<Operation_Progress_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Request_ItemArgs = {
  objects: Array<Operation_Request_Item_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Request_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Request_Item_OneArgs = {
  object: Operation_Request_Item_Insert_Input;
  on_conflict?: InputMaybe<Operation_Request_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Request_Item_TypeArgs = {
  objects: Array<Operation_Request_Item_Type_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Request_Item_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Request_Item_Type_OneArgs = {
  object: Operation_Request_Item_Type_Insert_Input;
  on_conflict?: InputMaybe<Operation_Request_Item_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_StateArgs = {
  objects: Array<Operation_State_Insert_Input>;
  on_conflict?: InputMaybe<Operation_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_State_OneArgs = {
  object: Operation_State_Insert_Input;
  on_conflict?: InputMaybe<Operation_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Trigger_TypeArgs = {
  objects: Array<Operation_Trigger_Type_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Trigger_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Trigger_Type_OneArgs = {
  object: Operation_Trigger_Type_Insert_Input;
  on_conflict?: InputMaybe<Operation_Trigger_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_TypeArgs = {
  objects: Array<Operation_Type_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Type_OneArgs = {
  object: Operation_Type_Insert_Input;
  on_conflict?: InputMaybe<Operation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outlook_Linkedin_CredentialArgs = {
  objects: Array<Outlook_Linkedin_Credential_Insert_Input>;
  on_conflict?: InputMaybe<Outlook_Linkedin_Credential_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outlook_Linkedin_Credential_OneArgs = {
  object: Outlook_Linkedin_Credential_Insert_Input;
  on_conflict?: InputMaybe<Outlook_Linkedin_Credential_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonArgs = {
  objects: Array<Person_Insert_Input>;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_GroupArgs = {
  objects: Array<Person_Group_Insert_Input>;
  on_conflict?: InputMaybe<Person_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_Group_OneArgs = {
  object: Person_Group_Insert_Input;
  on_conflict?: InputMaybe<Person_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_OneArgs = {
  object: Person_Insert_Input;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_To_AddressArgs = {
  objects: Array<Person_To_Address_Insert_Input>;
  on_conflict?: InputMaybe<Person_To_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_To_Address_OneArgs = {
  object: Person_To_Address_Insert_Input;
  on_conflict?: InputMaybe<Person_To_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_To_Person_GroupArgs = {
  objects: Array<Person_To_Person_Group_Insert_Input>;
  on_conflict?: InputMaybe<Person_To_Person_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_To_Person_Group_OneArgs = {
  object: Person_To_Person_Group_Insert_Input;
  on_conflict?: InputMaybe<Person_To_Person_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Postal_CodeArgs = {
  objects: Array<Postal_Code_Insert_Input>;
  on_conflict?: InputMaybe<Postal_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Postal_Code_OneArgs = {
  object: Postal_Code_Insert_Input;
  on_conflict?: InputMaybe<Postal_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProviderArgs = {
  objects: Array<Provider_Insert_Input>;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_OneArgs = {
  object: Provider_Insert_Input;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_ResponseArgs = {
  objects: Array<Raw_Response_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Response_Formulation_TypeArgs = {
  objects: Array<Raw_Response_Formulation_Type_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Response_Formulation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Response_Formulation_Type_OneArgs = {
  object: Raw_Response_Formulation_Type_Insert_Input;
  on_conflict?: InputMaybe<Raw_Response_Formulation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Response_OneArgs = {
  object: Raw_Response_Insert_Input;
  on_conflict?: InputMaybe<Raw_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Response_Search_ActionArgs = {
  objects: Array<Raw_Response_Search_Action_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Response_Search_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Response_Search_Action_OneArgs = {
  object: Raw_Response_Search_Action_Insert_Input;
  on_conflict?: InputMaybe<Raw_Response_Search_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StateArgs = {
  objects: Array<State_Insert_Input>;
  on_conflict?: InputMaybe<State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_State_OneArgs = {
  object: State_Insert_Input;
  on_conflict?: InputMaybe<State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stated_Address_SourceArgs = {
  objects: Array<Stated_Address_Source_Insert_Input>;
  on_conflict?: InputMaybe<Stated_Address_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stated_Address_Source_OneArgs = {
  object: Stated_Address_Source_Insert_Input;
  on_conflict?: InputMaybe<Stated_Address_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_GroupArgs = {
  objects: Array<Tracking_Group_Insert_Input>;
  on_conflict?: InputMaybe<Tracking_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_ItemArgs = {
  objects: Array<Tracking_Group_Item_Insert_Input>;
  on_conflict?: InputMaybe<Tracking_Group_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_Item_OneArgs = {
  object: Tracking_Group_Item_Insert_Input;
  on_conflict?: InputMaybe<Tracking_Group_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_Item_TypeArgs = {
  objects: Array<Tracking_Group_Item_Type_Insert_Input>;
  on_conflict?: InputMaybe<Tracking_Group_Item_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_Item_Type_OneArgs = {
  object: Tracking_Group_Item_Type_Insert_Input;
  on_conflict?: InputMaybe<Tracking_Group_Item_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_OneArgs = {
  object: Tracking_Group_Insert_Input;
  on_conflict?: InputMaybe<Tracking_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_TypeArgs = {
  objects: Array<Tracking_Group_Type_Insert_Input>;
  on_conflict?: InputMaybe<Tracking_Group_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Group_Type_OneArgs = {
  object: Tracking_Group_Type_Insert_Input;
  on_conflict?: InputMaybe<Tracking_Group_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_TypeArgs = {
  objects: Array<Value_Type_Insert_Input>;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_Type_OneArgs = {
  object: Value_Type_Insert_Input;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yoyo_LockArgs = {
  objects: Array<Yoyo_Lock_Insert_Input>;
  on_conflict?: InputMaybe<Yoyo_Lock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yoyo_Lock_OneArgs = {
  object: Yoyo_Lock_Insert_Input;
  on_conflict?: InputMaybe<Yoyo_Lock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_LogArgs = {
  _set?: InputMaybe<_Yoyo_Log_Set_Input>;
  where: _Yoyo_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Log_By_PkArgs = {
  _set?: InputMaybe<_Yoyo_Log_Set_Input>;
  pk_columns: _Yoyo_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Log_ManyArgs = {
  updates: Array<_Yoyo_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_MigrationArgs = {
  _set?: InputMaybe<_Yoyo_Migration_Set_Input>;
  where: _Yoyo_Migration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Migration_By_PkArgs = {
  _set?: InputMaybe<_Yoyo_Migration_Set_Input>;
  pk_columns: _Yoyo_Migration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Migration_ManyArgs = {
  updates: Array<_Yoyo_Migration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_VersionArgs = {
  _inc?: InputMaybe<_Yoyo_Version_Inc_Input>;
  _set?: InputMaybe<_Yoyo_Version_Set_Input>;
  where: _Yoyo_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Version_By_PkArgs = {
  _inc?: InputMaybe<_Yoyo_Version_Inc_Input>;
  _set?: InputMaybe<_Yoyo_Version_Set_Input>;
  pk_columns: _Yoyo_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Yoyo_Version_ManyArgs = {
  updates: Array<_Yoyo_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AttributeArgs = {
  _append?: InputMaybe<Attribute_Append_Input>;
  _delete_at_path?: InputMaybe<Attribute_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Attribute_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Attribute_Delete_Key_Input>;
  _inc?: InputMaybe<Attribute_Inc_Input>;
  _prepend?: InputMaybe<Attribute_Prepend_Input>;
  _set?: InputMaybe<Attribute_Set_Input>;
  where: Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attribute_By_PkArgs = {
  _append?: InputMaybe<Attribute_Append_Input>;
  _delete_at_path?: InputMaybe<Attribute_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Attribute_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Attribute_Delete_Key_Input>;
  _inc?: InputMaybe<Attribute_Inc_Input>;
  _prepend?: InputMaybe<Attribute_Prepend_Input>;
  _set?: InputMaybe<Attribute_Set_Input>;
  pk_columns: Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attribute_ManyArgs = {
  updates: Array<Attribute_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Attribute_TypeArgs = {
  _inc?: InputMaybe<Attribute_Type_Inc_Input>;
  _set?: InputMaybe<Attribute_Type_Set_Input>;
  where: Attribute_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attribute_Type_By_PkArgs = {
  _inc?: InputMaybe<Attribute_Type_Inc_Input>;
  _set?: InputMaybe<Attribute_Type_Set_Input>;
  pk_columns: Attribute_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attribute_Type_ManyArgs = {
  updates: Array<Attribute_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_ProfileArgs = {
  _inc?: InputMaybe<Digital_Profile_Inc_Input>;
  _set?: InputMaybe<Digital_Profile_Set_Input>;
  where: Digital_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_By_PkArgs = {
  _inc?: InputMaybe<Digital_Profile_Inc_Input>;
  _set?: InputMaybe<Digital_Profile_Set_Input>;
  pk_columns: Digital_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_ManyArgs = {
  updates: Array<Digital_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_AddressArgs = {
  _append?: InputMaybe<Digital_Profile_To_Address_Append_Input>;
  _delete_at_path?: InputMaybe<Digital_Profile_To_Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Digital_Profile_To_Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Digital_Profile_To_Address_Delete_Key_Input>;
  _inc?: InputMaybe<Digital_Profile_To_Address_Inc_Input>;
  _prepend?: InputMaybe<Digital_Profile_To_Address_Prepend_Input>;
  _set?: InputMaybe<Digital_Profile_To_Address_Set_Input>;
  where: Digital_Profile_To_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_Address_By_PkArgs = {
  _append?: InputMaybe<Digital_Profile_To_Address_Append_Input>;
  _delete_at_path?: InputMaybe<Digital_Profile_To_Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Digital_Profile_To_Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Digital_Profile_To_Address_Delete_Key_Input>;
  _inc?: InputMaybe<Digital_Profile_To_Address_Inc_Input>;
  _prepend?: InputMaybe<Digital_Profile_To_Address_Prepend_Input>;
  _set?: InputMaybe<Digital_Profile_To_Address_Set_Input>;
  pk_columns: Digital_Profile_To_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_Address_ManyArgs = {
  updates: Array<Digital_Profile_To_Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_PersonArgs = {
  _append?: InputMaybe<Digital_Profile_To_Person_Append_Input>;
  _delete_at_path?: InputMaybe<Digital_Profile_To_Person_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Digital_Profile_To_Person_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Digital_Profile_To_Person_Delete_Key_Input>;
  _inc?: InputMaybe<Digital_Profile_To_Person_Inc_Input>;
  _prepend?: InputMaybe<Digital_Profile_To_Person_Prepend_Input>;
  _set?: InputMaybe<Digital_Profile_To_Person_Set_Input>;
  where: Digital_Profile_To_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_Person_By_PkArgs = {
  _append?: InputMaybe<Digital_Profile_To_Person_Append_Input>;
  _delete_at_path?: InputMaybe<Digital_Profile_To_Person_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Digital_Profile_To_Person_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Digital_Profile_To_Person_Delete_Key_Input>;
  _inc?: InputMaybe<Digital_Profile_To_Person_Inc_Input>;
  _prepend?: InputMaybe<Digital_Profile_To_Person_Prepend_Input>;
  _set?: InputMaybe<Digital_Profile_To_Person_Set_Input>;
  pk_columns: Digital_Profile_To_Person_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_To_Person_ManyArgs = {
  updates: Array<Digital_Profile_To_Person_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_TypeArgs = {
  _inc?: InputMaybe<Digital_Profile_Type_Inc_Input>;
  _set?: InputMaybe<Digital_Profile_Type_Set_Input>;
  where: Digital_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_Type_By_PkArgs = {
  _inc?: InputMaybe<Digital_Profile_Type_Inc_Input>;
  _set?: InputMaybe<Digital_Profile_Type_Set_Input>;
  pk_columns: Digital_Profile_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Profile_Type_ManyArgs = {
  updates: Array<Digital_Profile_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_To_Raw_ResponseArgs = {
  _inc?: InputMaybe<Entity_To_Raw_Response_Inc_Input>;
  _set?: InputMaybe<Entity_To_Raw_Response_Set_Input>;
  where: Entity_To_Raw_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_To_Raw_Response_By_PkArgs = {
  _inc?: InputMaybe<Entity_To_Raw_Response_Inc_Input>;
  _set?: InputMaybe<Entity_To_Raw_Response_Set_Input>;
  pk_columns: Entity_To_Raw_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_To_Raw_Response_ManyArgs = {
  updates: Array<Entity_To_Raw_Response_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Confidence_CategoryArgs = {
  _inc?: InputMaybe<Link_Confidence_Category_Inc_Input>;
  _set?: InputMaybe<Link_Confidence_Category_Set_Input>;
  where: Link_Confidence_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Confidence_Category_By_PkArgs = {
  _inc?: InputMaybe<Link_Confidence_Category_Inc_Input>;
  _set?: InputMaybe<Link_Confidence_Category_Set_Input>;
  pk_columns: Link_Confidence_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Confidence_Category_ManyArgs = {
  updates: Array<Link_Confidence_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Precision_TypeArgs = {
  _inc?: InputMaybe<Link_Precision_Type_Inc_Input>;
  _set?: InputMaybe<Link_Precision_Type_Set_Input>;
  where: Link_Precision_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Precision_Type_By_PkArgs = {
  _inc?: InputMaybe<Link_Precision_Type_Inc_Input>;
  _set?: InputMaybe<Link_Precision_Type_Set_Input>;
  pk_columns: Link_Precision_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Precision_Type_ManyArgs = {
  updates: Array<Link_Precision_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Relationship_StatusArgs = {
  _inc?: InputMaybe<Link_Relationship_Status_Inc_Input>;
  _set?: InputMaybe<Link_Relationship_Status_Set_Input>;
  where: Link_Relationship_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Relationship_Status_By_PkArgs = {
  _inc?: InputMaybe<Link_Relationship_Status_Inc_Input>;
  _set?: InputMaybe<Link_Relationship_Status_Set_Input>;
  pk_columns: Link_Relationship_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Link_Relationship_Status_ManyArgs = {
  updates: Array<Link_Relationship_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OperationArgs = {
  _inc?: InputMaybe<Operation_Inc_Input>;
  _set?: InputMaybe<Operation_Set_Input>;
  where: Operation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_By_PkArgs = {
  _inc?: InputMaybe<Operation_Inc_Input>;
  _set?: InputMaybe<Operation_Set_Input>;
  pk_columns: Operation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_ManyArgs = {
  updates: Array<Operation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Progress_StatusArgs = {
  _inc?: InputMaybe<Operation_Progress_Status_Inc_Input>;
  _set?: InputMaybe<Operation_Progress_Status_Set_Input>;
  where: Operation_Progress_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Progress_Status_By_PkArgs = {
  _inc?: InputMaybe<Operation_Progress_Status_Inc_Input>;
  _set?: InputMaybe<Operation_Progress_Status_Set_Input>;
  pk_columns: Operation_Progress_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Progress_Status_ManyArgs = {
  updates: Array<Operation_Progress_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_ItemArgs = {
  _inc?: InputMaybe<Operation_Request_Item_Inc_Input>;
  _set?: InputMaybe<Operation_Request_Item_Set_Input>;
  where: Operation_Request_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_Item_By_PkArgs = {
  _inc?: InputMaybe<Operation_Request_Item_Inc_Input>;
  _set?: InputMaybe<Operation_Request_Item_Set_Input>;
  pk_columns: Operation_Request_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_Item_ManyArgs = {
  updates: Array<Operation_Request_Item_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_Item_TypeArgs = {
  _inc?: InputMaybe<Operation_Request_Item_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Request_Item_Type_Set_Input>;
  where: Operation_Request_Item_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_Item_Type_By_PkArgs = {
  _inc?: InputMaybe<Operation_Request_Item_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Request_Item_Type_Set_Input>;
  pk_columns: Operation_Request_Item_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Request_Item_Type_ManyArgs = {
  updates: Array<Operation_Request_Item_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_StateArgs = {
  _inc?: InputMaybe<Operation_State_Inc_Input>;
  _set?: InputMaybe<Operation_State_Set_Input>;
  where: Operation_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_State_By_PkArgs = {
  _inc?: InputMaybe<Operation_State_Inc_Input>;
  _set?: InputMaybe<Operation_State_Set_Input>;
  pk_columns: Operation_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_State_ManyArgs = {
  updates: Array<Operation_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Trigger_TypeArgs = {
  _inc?: InputMaybe<Operation_Trigger_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Trigger_Type_Set_Input>;
  where: Operation_Trigger_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Trigger_Type_By_PkArgs = {
  _inc?: InputMaybe<Operation_Trigger_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Trigger_Type_Set_Input>;
  pk_columns: Operation_Trigger_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Trigger_Type_ManyArgs = {
  updates: Array<Operation_Trigger_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_TypeArgs = {
  _inc?: InputMaybe<Operation_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Type_Set_Input>;
  where: Operation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Type_By_PkArgs = {
  _inc?: InputMaybe<Operation_Type_Inc_Input>;
  _set?: InputMaybe<Operation_Type_Set_Input>;
  pk_columns: Operation_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Type_ManyArgs = {
  updates: Array<Operation_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outlook_Linkedin_CredentialArgs = {
  _inc?: InputMaybe<Outlook_Linkedin_Credential_Inc_Input>;
  _set?: InputMaybe<Outlook_Linkedin_Credential_Set_Input>;
  where: Outlook_Linkedin_Credential_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outlook_Linkedin_Credential_By_PkArgs = {
  _inc?: InputMaybe<Outlook_Linkedin_Credential_Inc_Input>;
  _set?: InputMaybe<Outlook_Linkedin_Credential_Set_Input>;
  pk_columns: Outlook_Linkedin_Credential_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outlook_Linkedin_Credential_ManyArgs = {
  updates: Array<Outlook_Linkedin_Credential_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PersonArgs = {
  _inc?: InputMaybe<Person_Inc_Input>;
  _set?: InputMaybe<Person_Set_Input>;
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_By_PkArgs = {
  _inc?: InputMaybe<Person_Inc_Input>;
  _set?: InputMaybe<Person_Set_Input>;
  pk_columns: Person_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_GroupArgs = {
  _set?: InputMaybe<Person_Group_Set_Input>;
  where: Person_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_Group_By_PkArgs = {
  _set?: InputMaybe<Person_Group_Set_Input>;
  pk_columns: Person_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_Group_ManyArgs = {
  updates: Array<Person_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Person_ManyArgs = {
  updates: Array<Person_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_AddressArgs = {
  _append?: InputMaybe<Person_To_Address_Append_Input>;
  _delete_at_path?: InputMaybe<Person_To_Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Person_To_Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Person_To_Address_Delete_Key_Input>;
  _inc?: InputMaybe<Person_To_Address_Inc_Input>;
  _prepend?: InputMaybe<Person_To_Address_Prepend_Input>;
  _set?: InputMaybe<Person_To_Address_Set_Input>;
  where: Person_To_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_Address_By_PkArgs = {
  _append?: InputMaybe<Person_To_Address_Append_Input>;
  _delete_at_path?: InputMaybe<Person_To_Address_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Person_To_Address_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Person_To_Address_Delete_Key_Input>;
  _inc?: InputMaybe<Person_To_Address_Inc_Input>;
  _prepend?: InputMaybe<Person_To_Address_Prepend_Input>;
  _set?: InputMaybe<Person_To_Address_Set_Input>;
  pk_columns: Person_To_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_Address_ManyArgs = {
  updates: Array<Person_To_Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_Person_GroupArgs = {
  _inc?: InputMaybe<Person_To_Person_Group_Inc_Input>;
  _set?: InputMaybe<Person_To_Person_Group_Set_Input>;
  where: Person_To_Person_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_Person_Group_By_PkArgs = {
  _inc?: InputMaybe<Person_To_Person_Group_Inc_Input>;
  _set?: InputMaybe<Person_To_Person_Group_Set_Input>;
  pk_columns: Person_To_Person_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_To_Person_Group_ManyArgs = {
  updates: Array<Person_To_Person_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Postal_CodeArgs = {
  _inc?: InputMaybe<Postal_Code_Inc_Input>;
  _set?: InputMaybe<Postal_Code_Set_Input>;
  where: Postal_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Postal_Code_By_PkArgs = {
  _inc?: InputMaybe<Postal_Code_Inc_Input>;
  _set?: InputMaybe<Postal_Code_Set_Input>;
  pk_columns: Postal_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Postal_Code_ManyArgs = {
  updates: Array<Postal_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProviderArgs = {
  _inc?: InputMaybe<Provider_Inc_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  where: Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_By_PkArgs = {
  _inc?: InputMaybe<Provider_Inc_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  pk_columns: Provider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_ManyArgs = {
  updates: Array<Provider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_ResponseArgs = {
  _append?: InputMaybe<Raw_Response_Append_Input>;
  _delete_at_path?: InputMaybe<Raw_Response_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Raw_Response_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Raw_Response_Delete_Key_Input>;
  _inc?: InputMaybe<Raw_Response_Inc_Input>;
  _prepend?: InputMaybe<Raw_Response_Prepend_Input>;
  _set?: InputMaybe<Raw_Response_Set_Input>;
  where: Raw_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_By_PkArgs = {
  _append?: InputMaybe<Raw_Response_Append_Input>;
  _delete_at_path?: InputMaybe<Raw_Response_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Raw_Response_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Raw_Response_Delete_Key_Input>;
  _inc?: InputMaybe<Raw_Response_Inc_Input>;
  _prepend?: InputMaybe<Raw_Response_Prepend_Input>;
  _set?: InputMaybe<Raw_Response_Set_Input>;
  pk_columns: Raw_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Formulation_TypeArgs = {
  _inc?: InputMaybe<Raw_Response_Formulation_Type_Inc_Input>;
  _set?: InputMaybe<Raw_Response_Formulation_Type_Set_Input>;
  where: Raw_Response_Formulation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Formulation_Type_By_PkArgs = {
  _inc?: InputMaybe<Raw_Response_Formulation_Type_Inc_Input>;
  _set?: InputMaybe<Raw_Response_Formulation_Type_Set_Input>;
  pk_columns: Raw_Response_Formulation_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Formulation_Type_ManyArgs = {
  updates: Array<Raw_Response_Formulation_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_ManyArgs = {
  updates: Array<Raw_Response_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Search_ActionArgs = {
  _inc?: InputMaybe<Raw_Response_Search_Action_Inc_Input>;
  _set?: InputMaybe<Raw_Response_Search_Action_Set_Input>;
  where: Raw_Response_Search_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Search_Action_By_PkArgs = {
  _inc?: InputMaybe<Raw_Response_Search_Action_Inc_Input>;
  _set?: InputMaybe<Raw_Response_Search_Action_Set_Input>;
  pk_columns: Raw_Response_Search_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Response_Search_Action_ManyArgs = {
  updates: Array<Raw_Response_Search_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StateArgs = {
  _inc?: InputMaybe<State_Inc_Input>;
  _set?: InputMaybe<State_Set_Input>;
  where: State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_State_By_PkArgs = {
  _inc?: InputMaybe<State_Inc_Input>;
  _set?: InputMaybe<State_Set_Input>;
  pk_columns: State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_State_ManyArgs = {
  updates: Array<State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stated_Address_SourceArgs = {
  _inc?: InputMaybe<Stated_Address_Source_Inc_Input>;
  _set?: InputMaybe<Stated_Address_Source_Set_Input>;
  where: Stated_Address_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stated_Address_Source_By_PkArgs = {
  _inc?: InputMaybe<Stated_Address_Source_Inc_Input>;
  _set?: InputMaybe<Stated_Address_Source_Set_Input>;
  pk_columns: Stated_Address_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stated_Address_Source_ManyArgs = {
  updates: Array<Stated_Address_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_GroupArgs = {
  _inc?: InputMaybe<Tracking_Group_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Set_Input>;
  where: Tracking_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_By_PkArgs = {
  _inc?: InputMaybe<Tracking_Group_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Set_Input>;
  pk_columns: Tracking_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_ItemArgs = {
  _inc?: InputMaybe<Tracking_Group_Item_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Item_Set_Input>;
  where: Tracking_Group_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Item_By_PkArgs = {
  _inc?: InputMaybe<Tracking_Group_Item_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Item_Set_Input>;
  pk_columns: Tracking_Group_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Item_ManyArgs = {
  updates: Array<Tracking_Group_Item_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Item_TypeArgs = {
  _inc?: InputMaybe<Tracking_Group_Item_Type_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Item_Type_Set_Input>;
  where: Tracking_Group_Item_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Item_Type_By_PkArgs = {
  _inc?: InputMaybe<Tracking_Group_Item_Type_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Item_Type_Set_Input>;
  pk_columns: Tracking_Group_Item_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Item_Type_ManyArgs = {
  updates: Array<Tracking_Group_Item_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_ManyArgs = {
  updates: Array<Tracking_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_TypeArgs = {
  _inc?: InputMaybe<Tracking_Group_Type_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Type_Set_Input>;
  where: Tracking_Group_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Type_By_PkArgs = {
  _inc?: InputMaybe<Tracking_Group_Type_Inc_Input>;
  _set?: InputMaybe<Tracking_Group_Type_Set_Input>;
  pk_columns: Tracking_Group_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Group_Type_ManyArgs = {
  updates: Array<Tracking_Group_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Value_TypeArgs = {
  _inc?: InputMaybe<Value_Type_Inc_Input>;
  _set?: InputMaybe<Value_Type_Set_Input>;
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Value_Type_By_PkArgs = {
  _inc?: InputMaybe<Value_Type_Inc_Input>;
  _set?: InputMaybe<Value_Type_Set_Input>;
  pk_columns: Value_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Value_Type_ManyArgs = {
  updates: Array<Value_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Yoyo_LockArgs = {
  _inc?: InputMaybe<Yoyo_Lock_Inc_Input>;
  _set?: InputMaybe<Yoyo_Lock_Set_Input>;
  where: Yoyo_Lock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Yoyo_Lock_By_PkArgs = {
  _inc?: InputMaybe<Yoyo_Lock_Inc_Input>;
  _set?: InputMaybe<Yoyo_Lock_Set_Input>;
  pk_columns: Yoyo_Lock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Yoyo_Lock_ManyArgs = {
  updates: Array<Yoyo_Lock_Updates>;
};

/** columns and relationships of "operation" */
export type Operation = {
  __typename?: 'operation';
  created_at: Scalars['timestamptz'];
  external_event_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  operation?: Maybe<Operation>;
  /** An object relationship */
  operation_progress_status?: Maybe<Operation_Progress_Status>;
  operation_progress_status_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  operation_request_items: Array<Operation_Request_Item>;
  /** An aggregate relationship */
  operation_request_items_aggregate: Operation_Request_Item_Aggregate;
  /** An object relationship */
  operation_state: Operation_State;
  operation_state_id: Scalars['Int'];
  /** An object relationship */
  operation_trigger_type: Operation_Trigger_Type;
  operation_trigger_type_id: Scalars['Int'];
  /** An object relationship */
  operation_type: Operation_Type;
  operation_type_id: Scalars['Int'];
  /** An array relationship */
  operations: Array<Operation>;
  /** An aggregate relationship */
  operations_aggregate: Operation_Aggregate;
  parent_operation_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation" */
export type OperationOperation_Request_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


/** columns and relationships of "operation" */
export type OperationOperation_Request_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


/** columns and relationships of "operation" */
export type OperationOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


/** columns and relationships of "operation" */
export type OperationOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** aggregated selection of "operation" */
export type Operation_Aggregate = {
  __typename?: 'operation_aggregate';
  aggregate?: Maybe<Operation_Aggregate_Fields>;
  nodes: Array<Operation>;
};

export type Operation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Operation_Aggregate_Bool_Exp_Count>;
};

export type Operation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Operation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Operation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "operation" */
export type Operation_Aggregate_Fields = {
  __typename?: 'operation_aggregate_fields';
  avg?: Maybe<Operation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Max_Fields>;
  min?: Maybe<Operation_Min_Fields>;
  stddev?: Maybe<Operation_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Sum_Fields>;
  var_pop?: Maybe<Operation_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Var_Samp_Fields>;
  variance?: Maybe<Operation_Variance_Fields>;
};


/** aggregate fields of "operation" */
export type Operation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "operation" */
export type Operation_Aggregate_Order_By = {
  avg?: InputMaybe<Operation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Operation_Max_Order_By>;
  min?: InputMaybe<Operation_Min_Order_By>;
  stddev?: InputMaybe<Operation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Operation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Operation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Operation_Sum_Order_By>;
  var_pop?: InputMaybe<Operation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Operation_Var_Samp_Order_By>;
  variance?: InputMaybe<Operation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "operation" */
export type Operation_Arr_Rel_Insert_Input = {
  data: Array<Operation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_On_Conflict>;
};

/** aggregate avg on columns */
export type Operation_Avg_Fields = {
  __typename?: 'operation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "operation" */
export type Operation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "operation". All fields are combined with a logical 'AND'. */
export type Operation_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Bool_Exp>>;
  _not?: InputMaybe<Operation_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_event_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  operation?: InputMaybe<Operation_Bool_Exp>;
  operation_progress_status?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
  operation_progress_status_id?: InputMaybe<Int_Comparison_Exp>;
  operation_request_items?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  operation_request_items_aggregate?: InputMaybe<Operation_Request_Item_Aggregate_Bool_Exp>;
  operation_state?: InputMaybe<Operation_State_Bool_Exp>;
  operation_state_id?: InputMaybe<Int_Comparison_Exp>;
  operation_trigger_type?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
  operation_trigger_type_id?: InputMaybe<Int_Comparison_Exp>;
  operation_type?: InputMaybe<Operation_Type_Bool_Exp>;
  operation_type_id?: InputMaybe<Int_Comparison_Exp>;
  operations?: InputMaybe<Operation_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Bool_Exp>;
  parent_operation_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation" */
export enum Operation_Constraint {
  /** unique or primary key constraint on columns "id" */
  OperationPkey = 'operation_pkey'
}

/** input type for incrementing numeric columns in table "operation" */
export type Operation_Inc_Input = {
  operation_progress_status_id?: InputMaybe<Scalars['Int']>;
  operation_state_id?: InputMaybe<Scalars['Int']>;
  operation_trigger_type_id?: InputMaybe<Scalars['Int']>;
  operation_type_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation" */
export type Operation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Operation_Obj_Rel_Insert_Input>;
  operation_progress_status?: InputMaybe<Operation_Progress_Status_Obj_Rel_Insert_Input>;
  operation_progress_status_id?: InputMaybe<Scalars['Int']>;
  operation_request_items?: InputMaybe<Operation_Request_Item_Arr_Rel_Insert_Input>;
  operation_state?: InputMaybe<Operation_State_Obj_Rel_Insert_Input>;
  operation_state_id?: InputMaybe<Scalars['Int']>;
  operation_trigger_type?: InputMaybe<Operation_Trigger_Type_Obj_Rel_Insert_Input>;
  operation_trigger_type_id?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Operation_Type_Obj_Rel_Insert_Input>;
  operation_type_id?: InputMaybe<Scalars['Int']>;
  operations?: InputMaybe<Operation_Arr_Rel_Insert_Input>;
  parent_operation_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Max_Fields = {
  __typename?: 'operation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  operation_progress_status_id?: Maybe<Scalars['Int']>;
  operation_state_id?: Maybe<Scalars['Int']>;
  operation_trigger_type_id?: Maybe<Scalars['Int']>;
  operation_type_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "operation" */
export type Operation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Operation_Min_Fields = {
  __typename?: 'operation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  operation_progress_status_id?: Maybe<Scalars['Int']>;
  operation_state_id?: Maybe<Scalars['Int']>;
  operation_trigger_type_id?: Maybe<Scalars['Int']>;
  operation_type_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "operation" */
export type Operation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "operation" */
export type Operation_Mutation_Response = {
  __typename?: 'operation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation>;
};

/** input type for inserting object relation for remote table "operation" */
export type Operation_Obj_Rel_Insert_Input = {
  data: Operation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_On_Conflict>;
};

/** on_conflict condition type for table "operation" */
export type Operation_On_Conflict = {
  constraint: Operation_Constraint;
  update_columns?: Array<Operation_Update_Column>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** Ordering options when selecting data from "operation". */
export type Operation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation?: InputMaybe<Operation_Order_By>;
  operation_progress_status?: InputMaybe<Operation_Progress_Status_Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_request_items_aggregate?: InputMaybe<Operation_Request_Item_Aggregate_Order_By>;
  operation_state?: InputMaybe<Operation_State_Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type?: InputMaybe<Operation_Trigger_Type_Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Operation_Type_Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation */
export type Operation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "operation_progress_status" */
export type Operation_Progress_Status = {
  __typename?: 'operation_progress_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operations: Array<Operation>;
  /** An aggregate relationship */
  operations_aggregate: Operation_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation_progress_status" */
export type Operation_Progress_StatusOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


/** columns and relationships of "operation_progress_status" */
export type Operation_Progress_StatusOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** aggregated selection of "operation_progress_status" */
export type Operation_Progress_Status_Aggregate = {
  __typename?: 'operation_progress_status_aggregate';
  aggregate?: Maybe<Operation_Progress_Status_Aggregate_Fields>;
  nodes: Array<Operation_Progress_Status>;
};

/** aggregate fields of "operation_progress_status" */
export type Operation_Progress_Status_Aggregate_Fields = {
  __typename?: 'operation_progress_status_aggregate_fields';
  avg?: Maybe<Operation_Progress_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Progress_Status_Max_Fields>;
  min?: Maybe<Operation_Progress_Status_Min_Fields>;
  stddev?: Maybe<Operation_Progress_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Progress_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Progress_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Progress_Status_Sum_Fields>;
  var_pop?: Maybe<Operation_Progress_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Progress_Status_Var_Samp_Fields>;
  variance?: Maybe<Operation_Progress_Status_Variance_Fields>;
};


/** aggregate fields of "operation_progress_status" */
export type Operation_Progress_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Progress_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_Progress_Status_Avg_Fields = {
  __typename?: 'operation_progress_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_progress_status". All fields are combined with a logical 'AND'. */
export type Operation_Progress_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Progress_Status_Bool_Exp>>;
  _not?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Progress_Status_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operations?: InputMaybe<Operation_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_progress_status" */
export enum Operation_Progress_Status_Constraint {
  /** unique or primary key constraint on columns "name" */
  OperationProgressStatusNameKey = 'operation_progress_status_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationProgressStatusPkey = 'operation_progress_status_pkey'
}

/** input type for incrementing numeric columns in table "operation_progress_status" */
export type Operation_Progress_Status_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_progress_status" */
export type Operation_Progress_Status_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operations?: InputMaybe<Operation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Progress_Status_Max_Fields = {
  __typename?: 'operation_progress_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Operation_Progress_Status_Min_Fields = {
  __typename?: 'operation_progress_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "operation_progress_status" */
export type Operation_Progress_Status_Mutation_Response = {
  __typename?: 'operation_progress_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Progress_Status>;
};

/** input type for inserting object relation for remote table "operation_progress_status" */
export type Operation_Progress_Status_Obj_Rel_Insert_Input = {
  data: Operation_Progress_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Progress_Status_On_Conflict>;
};

/** on_conflict condition type for table "operation_progress_status" */
export type Operation_Progress_Status_On_Conflict = {
  constraint: Operation_Progress_Status_Constraint;
  update_columns?: Array<Operation_Progress_Status_Update_Column>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_progress_status". */
export type Operation_Progress_Status_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_progress_status */
export type Operation_Progress_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_progress_status" */
export enum Operation_Progress_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation_progress_status" */
export type Operation_Progress_Status_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_Progress_Status_Stddev_Fields = {
  __typename?: 'operation_progress_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_Progress_Status_Stddev_Pop_Fields = {
  __typename?: 'operation_progress_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_Progress_Status_Stddev_Samp_Fields = {
  __typename?: 'operation_progress_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_progress_status" */
export type Operation_Progress_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Progress_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Progress_Status_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Progress_Status_Sum_Fields = {
  __typename?: 'operation_progress_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_progress_status" */
export enum Operation_Progress_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Progress_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Progress_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Progress_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Progress_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Progress_Status_Var_Pop_Fields = {
  __typename?: 'operation_progress_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_Progress_Status_Var_Samp_Fields = {
  __typename?: 'operation_progress_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_Progress_Status_Variance_Fields = {
  __typename?: 'operation_progress_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "operation_request_item" */
export type Operation_Request_Item = {
  __typename?: 'operation_request_item';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_completed: Scalars['Boolean'];
  /** An object relationship */
  operation: Operation;
  operation_id: Scalars['Int'];
  /** An object relationship */
  operation_request_item_type: Operation_Request_Item_Type;
  operation_request_item_type_id: Scalars['Int'];
  operation_request_item_value: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "operation_request_item" */
export type Operation_Request_Item_Aggregate = {
  __typename?: 'operation_request_item_aggregate';
  aggregate?: Maybe<Operation_Request_Item_Aggregate_Fields>;
  nodes: Array<Operation_Request_Item>;
};

export type Operation_Request_Item_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Operation_Request_Item_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Operation_Request_Item_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Operation_Request_Item_Aggregate_Bool_Exp_Count>;
};

export type Operation_Request_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Operation_Request_Item_Select_Column_Operation_Request_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Operation_Request_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Operation_Request_Item_Select_Column_Operation_Request_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Operation_Request_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "operation_request_item" */
export type Operation_Request_Item_Aggregate_Fields = {
  __typename?: 'operation_request_item_aggregate_fields';
  avg?: Maybe<Operation_Request_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Request_Item_Max_Fields>;
  min?: Maybe<Operation_Request_Item_Min_Fields>;
  stddev?: Maybe<Operation_Request_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Request_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Request_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Request_Item_Sum_Fields>;
  var_pop?: Maybe<Operation_Request_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Request_Item_Var_Samp_Fields>;
  variance?: Maybe<Operation_Request_Item_Variance_Fields>;
};


/** aggregate fields of "operation_request_item" */
export type Operation_Request_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "operation_request_item" */
export type Operation_Request_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Operation_Request_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Operation_Request_Item_Max_Order_By>;
  min?: InputMaybe<Operation_Request_Item_Min_Order_By>;
  stddev?: InputMaybe<Operation_Request_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Operation_Request_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Operation_Request_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Operation_Request_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Operation_Request_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Operation_Request_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Operation_Request_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "operation_request_item" */
export type Operation_Request_Item_Arr_Rel_Insert_Input = {
  data: Array<Operation_Request_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Request_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Operation_Request_Item_Avg_Fields = {
  __typename?: 'operation_request_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "operation_request_item" */
export type Operation_Request_Item_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "operation_request_item". All fields are combined with a logical 'AND'. */
export type Operation_Request_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Request_Item_Bool_Exp>>;
  _not?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Request_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  operation?: InputMaybe<Operation_Bool_Exp>;
  operation_id?: InputMaybe<Int_Comparison_Exp>;
  operation_request_item_type?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
  operation_request_item_type_id?: InputMaybe<Int_Comparison_Exp>;
  operation_request_item_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_request_item" */
export enum Operation_Request_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  OperationRequestItemPkey = 'operation_request_item_pkey'
}

/** input type for incrementing numeric columns in table "operation_request_item" */
export type Operation_Request_Item_Inc_Input = {
  operation_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_request_item" */
export type Operation_Request_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  operation?: InputMaybe<Operation_Obj_Rel_Insert_Input>;
  operation_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_type?: InputMaybe<Operation_Request_Item_Type_Obj_Rel_Insert_Input>;
  operation_request_item_type_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Request_Item_Max_Fields = {
  __typename?: 'operation_request_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  operation_request_item_type_id?: Maybe<Scalars['Int']>;
  operation_request_item_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "operation_request_item" */
export type Operation_Request_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
  operation_request_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Operation_Request_Item_Min_Fields = {
  __typename?: 'operation_request_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  operation_request_item_type_id?: Maybe<Scalars['Int']>;
  operation_request_item_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "operation_request_item" */
export type Operation_Request_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
  operation_request_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "operation_request_item" */
export type Operation_Request_Item_Mutation_Response = {
  __typename?: 'operation_request_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Request_Item>;
};

/** on_conflict condition type for table "operation_request_item" */
export type Operation_Request_Item_On_Conflict = {
  constraint: Operation_Request_Item_Constraint;
  update_columns?: Array<Operation_Request_Item_Update_Column>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_request_item". */
export type Operation_Request_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  operation?: InputMaybe<Operation_Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type?: InputMaybe<Operation_Request_Item_Type_Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
  operation_request_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_request_item */
export type Operation_Request_Item_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_request_item" */
export enum Operation_Request_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationRequestItemTypeId = 'operation_request_item_type_id',
  /** column name */
  OperationRequestItemValue = 'operation_request_item_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "operation_request_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "operation_request_item" */
export enum Operation_Request_Item_Select_Column_Operation_Request_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "operation_request_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "operation_request_item" */
export enum Operation_Request_Item_Select_Column_Operation_Request_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "operation_request_item" */
export type Operation_Request_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_type_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_Request_Item_Stddev_Fields = {
  __typename?: 'operation_request_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "operation_request_item" */
export type Operation_Request_Item_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Operation_Request_Item_Stddev_Pop_Fields = {
  __typename?: 'operation_request_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "operation_request_item" */
export type Operation_Request_Item_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Operation_Request_Item_Stddev_Samp_Fields = {
  __typename?: 'operation_request_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "operation_request_item" */
export type Operation_Request_Item_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "operation_request_item" */
export type Operation_Request_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Request_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Request_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_type_id?: InputMaybe<Scalars['Int']>;
  operation_request_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Request_Item_Sum_Fields = {
  __typename?: 'operation_request_item_sum_fields';
  id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  operation_request_item_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "operation_request_item" */
export type Operation_Request_Item_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "operation_request_item_type" */
export type Operation_Request_Item_Type = {
  __typename?: 'operation_request_item_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operation_request_items: Array<Operation_Request_Item>;
  /** An aggregate relationship */
  operation_request_items_aggregate: Operation_Request_Item_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation_request_item_type" */
export type Operation_Request_Item_TypeOperation_Request_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


/** columns and relationships of "operation_request_item_type" */
export type Operation_Request_Item_TypeOperation_Request_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};

/** aggregated selection of "operation_request_item_type" */
export type Operation_Request_Item_Type_Aggregate = {
  __typename?: 'operation_request_item_type_aggregate';
  aggregate?: Maybe<Operation_Request_Item_Type_Aggregate_Fields>;
  nodes: Array<Operation_Request_Item_Type>;
};

/** aggregate fields of "operation_request_item_type" */
export type Operation_Request_Item_Type_Aggregate_Fields = {
  __typename?: 'operation_request_item_type_aggregate_fields';
  avg?: Maybe<Operation_Request_Item_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Request_Item_Type_Max_Fields>;
  min?: Maybe<Operation_Request_Item_Type_Min_Fields>;
  stddev?: Maybe<Operation_Request_Item_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Request_Item_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Request_Item_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Request_Item_Type_Sum_Fields>;
  var_pop?: Maybe<Operation_Request_Item_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Request_Item_Type_Var_Samp_Fields>;
  variance?: Maybe<Operation_Request_Item_Type_Variance_Fields>;
};


/** aggregate fields of "operation_request_item_type" */
export type Operation_Request_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Request_Item_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_Request_Item_Type_Avg_Fields = {
  __typename?: 'operation_request_item_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_request_item_type". All fields are combined with a logical 'AND'. */
export type Operation_Request_Item_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Request_Item_Type_Bool_Exp>>;
  _not?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Request_Item_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operation_request_items?: InputMaybe<Operation_Request_Item_Bool_Exp>;
  operation_request_items_aggregate?: InputMaybe<Operation_Request_Item_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_request_item_type" */
export enum Operation_Request_Item_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  OperationRequestItemTypeNameKey = 'operation_request_item_type_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationRequestItemTypePkey = 'operation_request_item_type_pkey'
}

/** input type for incrementing numeric columns in table "operation_request_item_type" */
export type Operation_Request_Item_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_request_item_type" */
export type Operation_Request_Item_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operation_request_items?: InputMaybe<Operation_Request_Item_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Request_Item_Type_Max_Fields = {
  __typename?: 'operation_request_item_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Operation_Request_Item_Type_Min_Fields = {
  __typename?: 'operation_request_item_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "operation_request_item_type" */
export type Operation_Request_Item_Type_Mutation_Response = {
  __typename?: 'operation_request_item_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Request_Item_Type>;
};

/** input type for inserting object relation for remote table "operation_request_item_type" */
export type Operation_Request_Item_Type_Obj_Rel_Insert_Input = {
  data: Operation_Request_Item_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Request_Item_Type_On_Conflict>;
};

/** on_conflict condition type for table "operation_request_item_type" */
export type Operation_Request_Item_Type_On_Conflict = {
  constraint: Operation_Request_Item_Type_Constraint;
  update_columns?: Array<Operation_Request_Item_Type_Update_Column>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_request_item_type". */
export type Operation_Request_Item_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operation_request_items_aggregate?: InputMaybe<Operation_Request_Item_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_request_item_type */
export type Operation_Request_Item_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_request_item_type" */
export enum Operation_Request_Item_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation_request_item_type" */
export type Operation_Request_Item_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_Request_Item_Type_Stddev_Fields = {
  __typename?: 'operation_request_item_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_Request_Item_Type_Stddev_Pop_Fields = {
  __typename?: 'operation_request_item_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_Request_Item_Type_Stddev_Samp_Fields = {
  __typename?: 'operation_request_item_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_request_item_type" */
export type Operation_Request_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Request_Item_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Request_Item_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Request_Item_Type_Sum_Fields = {
  __typename?: 'operation_request_item_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_request_item_type" */
export enum Operation_Request_Item_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Request_Item_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Request_Item_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Request_Item_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Request_Item_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Request_Item_Type_Var_Pop_Fields = {
  __typename?: 'operation_request_item_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_Request_Item_Type_Var_Samp_Fields = {
  __typename?: 'operation_request_item_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_Request_Item_Type_Variance_Fields = {
  __typename?: 'operation_request_item_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "operation_request_item" */
export enum Operation_Request_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationRequestItemTypeId = 'operation_request_item_type_id',
  /** column name */
  OperationRequestItemValue = 'operation_request_item_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Request_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Request_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Request_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Request_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Request_Item_Var_Pop_Fields = {
  __typename?: 'operation_request_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "operation_request_item" */
export type Operation_Request_Item_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Operation_Request_Item_Var_Samp_Fields = {
  __typename?: 'operation_request_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "operation_request_item" */
export type Operation_Request_Item_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Operation_Request_Item_Variance_Fields = {
  __typename?: 'operation_request_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  operation_request_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "operation_request_item" */
export type Operation_Request_Item_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_request_item_type_id?: InputMaybe<Order_By>;
};

/** select columns of table "operation" */
export enum Operation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalEventId = 'external_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  OperationProgressStatusId = 'operation_progress_status_id',
  /** column name */
  OperationStateId = 'operation_state_id',
  /** column name */
  OperationTriggerTypeId = 'operation_trigger_type_id',
  /** column name */
  OperationTypeId = 'operation_type_id',
  /** column name */
  ParentOperationId = 'parent_operation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation" */
export type Operation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  operation_progress_status_id?: InputMaybe<Scalars['Int']>;
  operation_state_id?: InputMaybe<Scalars['Int']>;
  operation_trigger_type_id?: InputMaybe<Scalars['Int']>;
  operation_type_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "operation_state" */
export type Operation_State = {
  __typename?: 'operation_state';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operations: Array<Operation>;
  /** An aggregate relationship */
  operations_aggregate: Operation_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation_state" */
export type Operation_StateOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


/** columns and relationships of "operation_state" */
export type Operation_StateOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** aggregated selection of "operation_state" */
export type Operation_State_Aggregate = {
  __typename?: 'operation_state_aggregate';
  aggregate?: Maybe<Operation_State_Aggregate_Fields>;
  nodes: Array<Operation_State>;
};

/** aggregate fields of "operation_state" */
export type Operation_State_Aggregate_Fields = {
  __typename?: 'operation_state_aggregate_fields';
  avg?: Maybe<Operation_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_State_Max_Fields>;
  min?: Maybe<Operation_State_Min_Fields>;
  stddev?: Maybe<Operation_State_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_State_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_State_Sum_Fields>;
  var_pop?: Maybe<Operation_State_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_State_Var_Samp_Fields>;
  variance?: Maybe<Operation_State_Variance_Fields>;
};


/** aggregate fields of "operation_state" */
export type Operation_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_State_Avg_Fields = {
  __typename?: 'operation_state_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_state". All fields are combined with a logical 'AND'. */
export type Operation_State_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_State_Bool_Exp>>;
  _not?: InputMaybe<Operation_State_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_State_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operations?: InputMaybe<Operation_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_state" */
export enum Operation_State_Constraint {
  /** unique or primary key constraint on columns "name" */
  OperationStateNameKey = 'operation_state_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationStatePkey = 'operation_state_pkey'
}

/** input type for incrementing numeric columns in table "operation_state" */
export type Operation_State_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_state" */
export type Operation_State_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operations?: InputMaybe<Operation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_State_Max_Fields = {
  __typename?: 'operation_state_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Operation_State_Min_Fields = {
  __typename?: 'operation_state_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "operation_state" */
export type Operation_State_Mutation_Response = {
  __typename?: 'operation_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_State>;
};

/** input type for inserting object relation for remote table "operation_state" */
export type Operation_State_Obj_Rel_Insert_Input = {
  data: Operation_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_State_On_Conflict>;
};

/** on_conflict condition type for table "operation_state" */
export type Operation_State_On_Conflict = {
  constraint: Operation_State_Constraint;
  update_columns?: Array<Operation_State_Update_Column>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_state". */
export type Operation_State_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_state */
export type Operation_State_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_state" */
export enum Operation_State_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation_state" */
export type Operation_State_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_State_Stddev_Fields = {
  __typename?: 'operation_state_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_State_Stddev_Pop_Fields = {
  __typename?: 'operation_state_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_State_Stddev_Samp_Fields = {
  __typename?: 'operation_state_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_state" */
export type Operation_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_State_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_State_Sum_Fields = {
  __typename?: 'operation_state_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_state" */
export enum Operation_State_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_State_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_State_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_State_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_State_Var_Pop_Fields = {
  __typename?: 'operation_state_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_State_Var_Samp_Fields = {
  __typename?: 'operation_state_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_State_Variance_Fields = {
  __typename?: 'operation_state_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Operation_Stddev_Fields = {
  __typename?: 'operation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "operation" */
export type Operation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Operation_Stddev_Pop_Fields = {
  __typename?: 'operation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "operation" */
export type Operation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Operation_Stddev_Samp_Fields = {
  __typename?: 'operation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "operation" */
export type Operation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "operation" */
export type Operation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  operation_progress_status_id?: InputMaybe<Scalars['Int']>;
  operation_state_id?: InputMaybe<Scalars['Int']>;
  operation_trigger_type_id?: InputMaybe<Scalars['Int']>;
  operation_type_id?: InputMaybe<Scalars['Int']>;
  parent_operation_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Sum_Fields = {
  __typename?: 'operation_sum_fields';
  id?: Maybe<Scalars['Int']>;
  operation_progress_status_id?: Maybe<Scalars['Int']>;
  operation_state_id?: Maybe<Scalars['Int']>;
  operation_trigger_type_id?: Maybe<Scalars['Int']>;
  operation_type_id?: Maybe<Scalars['Int']>;
  parent_operation_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "operation" */
export type Operation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "operation_trigger_type" */
export type Operation_Trigger_Type = {
  __typename?: 'operation_trigger_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operations: Array<Operation>;
  /** An aggregate relationship */
  operations_aggregate: Operation_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation_trigger_type" */
export type Operation_Trigger_TypeOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


/** columns and relationships of "operation_trigger_type" */
export type Operation_Trigger_TypeOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** aggregated selection of "operation_trigger_type" */
export type Operation_Trigger_Type_Aggregate = {
  __typename?: 'operation_trigger_type_aggregate';
  aggregate?: Maybe<Operation_Trigger_Type_Aggregate_Fields>;
  nodes: Array<Operation_Trigger_Type>;
};

/** aggregate fields of "operation_trigger_type" */
export type Operation_Trigger_Type_Aggregate_Fields = {
  __typename?: 'operation_trigger_type_aggregate_fields';
  avg?: Maybe<Operation_Trigger_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Trigger_Type_Max_Fields>;
  min?: Maybe<Operation_Trigger_Type_Min_Fields>;
  stddev?: Maybe<Operation_Trigger_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Trigger_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Trigger_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Trigger_Type_Sum_Fields>;
  var_pop?: Maybe<Operation_Trigger_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Trigger_Type_Var_Samp_Fields>;
  variance?: Maybe<Operation_Trigger_Type_Variance_Fields>;
};


/** aggregate fields of "operation_trigger_type" */
export type Operation_Trigger_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Trigger_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_Trigger_Type_Avg_Fields = {
  __typename?: 'operation_trigger_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_trigger_type". All fields are combined with a logical 'AND'. */
export type Operation_Trigger_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Trigger_Type_Bool_Exp>>;
  _not?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Trigger_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operations?: InputMaybe<Operation_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_trigger_type" */
export enum Operation_Trigger_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  OperationTriggerTypeNameKey = 'operation_trigger_type_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationTriggerTypePkey = 'operation_trigger_type_pkey'
}

/** input type for incrementing numeric columns in table "operation_trigger_type" */
export type Operation_Trigger_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_trigger_type" */
export type Operation_Trigger_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operations?: InputMaybe<Operation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Trigger_Type_Max_Fields = {
  __typename?: 'operation_trigger_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Operation_Trigger_Type_Min_Fields = {
  __typename?: 'operation_trigger_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "operation_trigger_type" */
export type Operation_Trigger_Type_Mutation_Response = {
  __typename?: 'operation_trigger_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Trigger_Type>;
};

/** input type for inserting object relation for remote table "operation_trigger_type" */
export type Operation_Trigger_Type_Obj_Rel_Insert_Input = {
  data: Operation_Trigger_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Trigger_Type_On_Conflict>;
};

/** on_conflict condition type for table "operation_trigger_type" */
export type Operation_Trigger_Type_On_Conflict = {
  constraint: Operation_Trigger_Type_Constraint;
  update_columns?: Array<Operation_Trigger_Type_Update_Column>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_trigger_type". */
export type Operation_Trigger_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_trigger_type */
export type Operation_Trigger_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_trigger_type" */
export enum Operation_Trigger_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation_trigger_type" */
export type Operation_Trigger_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_Trigger_Type_Stddev_Fields = {
  __typename?: 'operation_trigger_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_Trigger_Type_Stddev_Pop_Fields = {
  __typename?: 'operation_trigger_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_Trigger_Type_Stddev_Samp_Fields = {
  __typename?: 'operation_trigger_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_trigger_type" */
export type Operation_Trigger_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Trigger_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Trigger_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Trigger_Type_Sum_Fields = {
  __typename?: 'operation_trigger_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_trigger_type" */
export enum Operation_Trigger_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Trigger_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Trigger_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Trigger_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Trigger_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Trigger_Type_Var_Pop_Fields = {
  __typename?: 'operation_trigger_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_Trigger_Type_Var_Samp_Fields = {
  __typename?: 'operation_trigger_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_Trigger_Type_Variance_Fields = {
  __typename?: 'operation_trigger_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "operation_type" */
export type Operation_Type = {
  __typename?: 'operation_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operations: Array<Operation>;
  /** An aggregate relationship */
  operations_aggregate: Operation_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "operation_type" */
export type Operation_TypeOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


/** columns and relationships of "operation_type" */
export type Operation_TypeOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};

/** aggregated selection of "operation_type" */
export type Operation_Type_Aggregate = {
  __typename?: 'operation_type_aggregate';
  aggregate?: Maybe<Operation_Type_Aggregate_Fields>;
  nodes: Array<Operation_Type>;
};

/** aggregate fields of "operation_type" */
export type Operation_Type_Aggregate_Fields = {
  __typename?: 'operation_type_aggregate_fields';
  avg?: Maybe<Operation_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Type_Max_Fields>;
  min?: Maybe<Operation_Type_Min_Fields>;
  stddev?: Maybe<Operation_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Type_Sum_Fields>;
  var_pop?: Maybe<Operation_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Type_Var_Samp_Fields>;
  variance?: Maybe<Operation_Type_Variance_Fields>;
};


/** aggregate fields of "operation_type" */
export type Operation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_Type_Avg_Fields = {
  __typename?: 'operation_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_type". All fields are combined with a logical 'AND'. */
export type Operation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Type_Bool_Exp>>;
  _not?: InputMaybe<Operation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operations?: InputMaybe<Operation_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operation_type" */
export enum Operation_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  OperationTypeNameKey = 'operation_type_name_key',
  /** unique or primary key constraint on columns "id" */
  OperationTypePkey = 'operation_type_pkey'
}

/** input type for incrementing numeric columns in table "operation_type" */
export type Operation_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_type" */
export type Operation_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operations?: InputMaybe<Operation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operation_Type_Max_Fields = {
  __typename?: 'operation_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Operation_Type_Min_Fields = {
  __typename?: 'operation_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "operation_type" */
export type Operation_Type_Mutation_Response = {
  __typename?: 'operation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Type>;
};

/** input type for inserting object relation for remote table "operation_type" */
export type Operation_Type_Obj_Rel_Insert_Input = {
  data: Operation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Type_On_Conflict>;
};

/** on_conflict condition type for table "operation_type" */
export type Operation_Type_On_Conflict = {
  constraint: Operation_Type_Constraint;
  update_columns?: Array<Operation_Type_Update_Column>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_type". */
export type Operation_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operation_type */
export type Operation_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operation_type" */
export enum Operation_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "operation_type" */
export type Operation_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operation_Type_Stddev_Fields = {
  __typename?: 'operation_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_Type_Stddev_Pop_Fields = {
  __typename?: 'operation_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_Type_Stddev_Samp_Fields = {
  __typename?: 'operation_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_type" */
export type Operation_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operation_Type_Sum_Fields = {
  __typename?: 'operation_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_type" */
export enum Operation_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Type_Var_Pop_Fields = {
  __typename?: 'operation_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_Type_Var_Samp_Fields = {
  __typename?: 'operation_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_Type_Variance_Fields = {
  __typename?: 'operation_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "operation" */
export enum Operation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalEventId = 'external_event_id',
  /** column name */
  OperationProgressStatusId = 'operation_progress_status_id',
  /** column name */
  OperationStateId = 'operation_state_id',
  /** column name */
  OperationTriggerTypeId = 'operation_trigger_type_id',
  /** column name */
  OperationTypeId = 'operation_type_id',
  /** column name */
  ParentOperationId = 'parent_operation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Operation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Var_Pop_Fields = {
  __typename?: 'operation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "operation" */
export type Operation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Operation_Var_Samp_Fields = {
  __typename?: 'operation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "operation" */
export type Operation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Operation_Variance_Fields = {
  __typename?: 'operation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  operation_progress_status_id?: Maybe<Scalars['Float']>;
  operation_state_id?: Maybe<Scalars['Float']>;
  operation_trigger_type_id?: Maybe<Scalars['Float']>;
  operation_type_id?: Maybe<Scalars['Float']>;
  parent_operation_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "operation" */
export type Operation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  operation_progress_status_id?: InputMaybe<Order_By>;
  operation_state_id?: InputMaybe<Order_By>;
  operation_trigger_type_id?: InputMaybe<Order_By>;
  operation_type_id?: InputMaybe<Order_By>;
  parent_operation_id?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential = {
  __typename?: 'outlook_linkedin_credential';
  access_token?: Maybe<Scalars['String']>;
  access_token_expires_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email_address: Scalars['String'];
  email_address_password: Scalars['String'];
  id: Scalars['Int'];
  is_currently_in_use: Scalars['Boolean'];
  linkedin_2fa_code?: Maybe<Scalars['String']>;
  linkedin_email_address: Scalars['String'];
  linkedin_password: Scalars['String'];
  request_count: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Aggregate = {
  __typename?: 'outlook_linkedin_credential_aggregate';
  aggregate?: Maybe<Outlook_Linkedin_Credential_Aggregate_Fields>;
  nodes: Array<Outlook_Linkedin_Credential>;
};

/** aggregate fields of "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Aggregate_Fields = {
  __typename?: 'outlook_linkedin_credential_aggregate_fields';
  avg?: Maybe<Outlook_Linkedin_Credential_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outlook_Linkedin_Credential_Max_Fields>;
  min?: Maybe<Outlook_Linkedin_Credential_Min_Fields>;
  stddev?: Maybe<Outlook_Linkedin_Credential_Stddev_Fields>;
  stddev_pop?: Maybe<Outlook_Linkedin_Credential_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outlook_Linkedin_Credential_Stddev_Samp_Fields>;
  sum?: Maybe<Outlook_Linkedin_Credential_Sum_Fields>;
  var_pop?: Maybe<Outlook_Linkedin_Credential_Var_Pop_Fields>;
  var_samp?: Maybe<Outlook_Linkedin_Credential_Var_Samp_Fields>;
  variance?: Maybe<Outlook_Linkedin_Credential_Variance_Fields>;
};


/** aggregate fields of "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outlook_Linkedin_Credential_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Outlook_Linkedin_Credential_Avg_Fields = {
  __typename?: 'outlook_linkedin_credential_avg_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "outlook_linkedin_credential". All fields are combined with a logical 'AND'. */
export type Outlook_Linkedin_Credential_Bool_Exp = {
  _and?: InputMaybe<Array<Outlook_Linkedin_Credential_Bool_Exp>>;
  _not?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
  _or?: InputMaybe<Array<Outlook_Linkedin_Credential_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  access_token_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  email_address_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_currently_in_use?: InputMaybe<Boolean_Comparison_Exp>;
  linkedin_2fa_code?: InputMaybe<String_Comparison_Exp>;
  linkedin_email_address?: InputMaybe<String_Comparison_Exp>;
  linkedin_password?: InputMaybe<String_Comparison_Exp>;
  request_count?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outlook_linkedin_credential" */
export enum Outlook_Linkedin_Credential_Constraint {
  /** unique or primary key constraint on columns "email_address" */
  OutlookLinkedinCredentialEmailAddressKey = 'outlook_linkedin_credential_email_address_key',
  /** unique or primary key constraint on columns "linkedin_email_address" */
  OutlookLinkedinCredentialLinkedinEmailAddressKey = 'outlook_linkedin_credential_linkedin_email_address_key',
  /** unique or primary key constraint on columns "id" */
  OutlookLinkedinCredentialPkey = 'outlook_linkedin_credential_pkey'
}

/** input type for incrementing numeric columns in table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Inc_Input = {
  request_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  access_token_expires_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  email_address_password?: InputMaybe<Scalars['String']>;
  is_currently_in_use?: InputMaybe<Scalars['Boolean']>;
  linkedin_2fa_code?: InputMaybe<Scalars['String']>;
  linkedin_email_address?: InputMaybe<Scalars['String']>;
  linkedin_password?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Outlook_Linkedin_Credential_Max_Fields = {
  __typename?: 'outlook_linkedin_credential_max_fields';
  access_token?: Maybe<Scalars['String']>;
  access_token_expires_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  email_address_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  linkedin_2fa_code?: Maybe<Scalars['String']>;
  linkedin_email_address?: Maybe<Scalars['String']>;
  linkedin_password?: Maybe<Scalars['String']>;
  request_count?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Outlook_Linkedin_Credential_Min_Fields = {
  __typename?: 'outlook_linkedin_credential_min_fields';
  access_token?: Maybe<Scalars['String']>;
  access_token_expires_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  email_address_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  linkedin_2fa_code?: Maybe<Scalars['String']>;
  linkedin_email_address?: Maybe<Scalars['String']>;
  linkedin_password?: Maybe<Scalars['String']>;
  request_count?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Mutation_Response = {
  __typename?: 'outlook_linkedin_credential_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outlook_Linkedin_Credential>;
};

/** on_conflict condition type for table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_On_Conflict = {
  constraint: Outlook_Linkedin_Credential_Constraint;
  update_columns?: Array<Outlook_Linkedin_Credential_Update_Column>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};

/** Ordering options when selecting data from "outlook_linkedin_credential". */
export type Outlook_Linkedin_Credential_Order_By = {
  access_token?: InputMaybe<Order_By>;
  access_token_expires_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  email_address_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_currently_in_use?: InputMaybe<Order_By>;
  linkedin_2fa_code?: InputMaybe<Order_By>;
  linkedin_email_address?: InputMaybe<Order_By>;
  linkedin_password?: InputMaybe<Order_By>;
  request_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outlook_linkedin_credential */
export type Outlook_Linkedin_Credential_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outlook_linkedin_credential" */
export enum Outlook_Linkedin_Credential_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AccessTokenExpiresAt = 'access_token_expires_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  EmailAddressPassword = 'email_address_password',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrentlyInUse = 'is_currently_in_use',
  /** column name */
  Linkedin_2faCode = 'linkedin_2fa_code',
  /** column name */
  LinkedinEmailAddress = 'linkedin_email_address',
  /** column name */
  LinkedinPassword = 'linkedin_password',
  /** column name */
  RequestCount = 'request_count',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  access_token_expires_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  email_address_password?: InputMaybe<Scalars['String']>;
  is_currently_in_use?: InputMaybe<Scalars['Boolean']>;
  linkedin_2fa_code?: InputMaybe<Scalars['String']>;
  linkedin_email_address?: InputMaybe<Scalars['String']>;
  linkedin_password?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Outlook_Linkedin_Credential_Stddev_Fields = {
  __typename?: 'outlook_linkedin_credential_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Outlook_Linkedin_Credential_Stddev_Pop_Fields = {
  __typename?: 'outlook_linkedin_credential_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Outlook_Linkedin_Credential_Stddev_Samp_Fields = {
  __typename?: 'outlook_linkedin_credential_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "outlook_linkedin_credential" */
export type Outlook_Linkedin_Credential_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outlook_Linkedin_Credential_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outlook_Linkedin_Credential_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  access_token_expires_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  email_address_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_currently_in_use?: InputMaybe<Scalars['Boolean']>;
  linkedin_2fa_code?: InputMaybe<Scalars['String']>;
  linkedin_email_address?: InputMaybe<Scalars['String']>;
  linkedin_password?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Outlook_Linkedin_Credential_Sum_Fields = {
  __typename?: 'outlook_linkedin_credential_sum_fields';
  id?: Maybe<Scalars['Int']>;
  request_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "outlook_linkedin_credential" */
export enum Outlook_Linkedin_Credential_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AccessTokenExpiresAt = 'access_token_expires_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  EmailAddressPassword = 'email_address_password',
  /** column name */
  IsCurrentlyInUse = 'is_currently_in_use',
  /** column name */
  Linkedin_2faCode = 'linkedin_2fa_code',
  /** column name */
  LinkedinEmailAddress = 'linkedin_email_address',
  /** column name */
  LinkedinPassword = 'linkedin_password',
  /** column name */
  RequestCount = 'request_count',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Outlook_Linkedin_Credential_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Outlook_Linkedin_Credential_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Outlook_Linkedin_Credential_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outlook_Linkedin_Credential_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outlook_Linkedin_Credential_Var_Pop_Fields = {
  __typename?: 'outlook_linkedin_credential_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Outlook_Linkedin_Credential_Var_Samp_Fields = {
  __typename?: 'outlook_linkedin_credential_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Outlook_Linkedin_Credential_Variance_Fields = {
  __typename?: 'outlook_linkedin_credential_variance_fields';
  id?: Maybe<Scalars['Float']>;
  request_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "person" */
export type Person = {
  __typename?: 'person';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digital_profile_to_people: Array<Digital_Profile_To_Person>;
  /** An aggregate relationship */
  digital_profile_to_people_aggregate: Digital_Profile_To_Person_Aggregate;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  external_id?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  id: Scalars['Int'];
  is_organisation: Scalars['Boolean'];
  last_name: Scalars['String'];
  middle_names?: Maybe<Scalars['String']>;
  org_raw_response_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  /** An array relationship */
  person_to_person_groups: Array<Person_To_Person_Group>;
  /** An aggregate relationship */
  person_to_person_groups_aggregate: Person_To_Person_Group_Aggregate;
  /** An object relationship */
  raw_response?: Maybe<Raw_Response>;
  /** An array relationship */
  raw_responses: Array<Raw_Response>;
  /** An aggregate relationship */
  raw_responses_aggregate: Raw_Response_Aggregate;
  stated_address: Scalars['String'];
  /** An object relationship */
  stated_address_source: Stated_Address_Source;
  stated_address_source_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "person" */
export type PersonDigital_Profile_To_PeopleArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonDigital_Profile_To_People_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonPerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonPerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonPerson_To_Person_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonPerson_To_Person_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonRaw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonRaw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** aggregated selection of "person" */
export type Person_Aggregate = {
  __typename?: 'person_aggregate';
  aggregate?: Maybe<Person_Aggregate_Fields>;
  nodes: Array<Person>;
};

export type Person_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Person_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Person_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Person_Aggregate_Bool_Exp_Count>;
};

export type Person_Aggregate_Bool_Exp_Bool_And = {
  arguments: Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "person" */
export type Person_Aggregate_Fields = {
  __typename?: 'person_aggregate_fields';
  avg?: Maybe<Person_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Person_Max_Fields>;
  min?: Maybe<Person_Min_Fields>;
  stddev?: Maybe<Person_Stddev_Fields>;
  stddev_pop?: Maybe<Person_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Person_Stddev_Samp_Fields>;
  sum?: Maybe<Person_Sum_Fields>;
  var_pop?: Maybe<Person_Var_Pop_Fields>;
  var_samp?: Maybe<Person_Var_Samp_Fields>;
  variance?: Maybe<Person_Variance_Fields>;
};


/** aggregate fields of "person" */
export type Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "person" */
export type Person_Aggregate_Order_By = {
  avg?: InputMaybe<Person_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Person_Max_Order_By>;
  min?: InputMaybe<Person_Min_Order_By>;
  stddev?: InputMaybe<Person_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Person_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Person_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Person_Sum_Order_By>;
  var_pop?: InputMaybe<Person_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Person_Var_Samp_Order_By>;
  variance?: InputMaybe<Person_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "person" */
export type Person_Arr_Rel_Insert_Input = {
  data: Array<Person_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** aggregate avg on columns */
export type Person_Avg_Fields = {
  __typename?: 'person_avg_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "person" */
export type Person_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "person". All fields are combined with a logical 'AND'. */
export type Person_Bool_Exp = {
  _and?: InputMaybe<Array<Person_Bool_Exp>>;
  _not?: InputMaybe<Person_Bool_Exp>;
  _or?: InputMaybe<Array<Person_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_organisation?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  middle_names?: InputMaybe<String_Comparison_Exp>;
  org_raw_response_id?: InputMaybe<Int_Comparison_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp>;
  raw_response?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Bool_Exp>;
  stated_address?: InputMaybe<String_Comparison_Exp>;
  stated_address_source?: InputMaybe<Stated_Address_Source_Bool_Exp>;
  stated_address_source_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_to?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "person" */
export enum Person_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonPkey = 'person_pkey'
}

/** columns and relationships of "person_group" */
export type Person_Group = {
  __typename?: 'person_group';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  person_to_person_groups: Array<Person_To_Person_Group>;
  /** An aggregate relationship */
  person_to_person_groups_aggregate: Person_To_Person_Group_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "person_group" */
export type Person_GroupPerson_To_Person_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "person_group" */
export type Person_GroupPerson_To_Person_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};

/** aggregated selection of "person_group" */
export type Person_Group_Aggregate = {
  __typename?: 'person_group_aggregate';
  aggregate?: Maybe<Person_Group_Aggregate_Fields>;
  nodes: Array<Person_Group>;
};

/** aggregate fields of "person_group" */
export type Person_Group_Aggregate_Fields = {
  __typename?: 'person_group_aggregate_fields';
  avg?: Maybe<Person_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Person_Group_Max_Fields>;
  min?: Maybe<Person_Group_Min_Fields>;
  stddev?: Maybe<Person_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Person_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Person_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Person_Group_Sum_Fields>;
  var_pop?: Maybe<Person_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Person_Group_Var_Samp_Fields>;
  variance?: Maybe<Person_Group_Variance_Fields>;
};


/** aggregate fields of "person_group" */
export type Person_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Person_Group_Avg_Fields = {
  __typename?: 'person_group_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "person_group". All fields are combined with a logical 'AND'. */
export type Person_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Person_Group_Bool_Exp>>;
  _not?: InputMaybe<Person_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Person_Group_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "person_group" */
export enum Person_Group_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonGroupPkey = 'person_group_pkey'
}

/** input type for inserting data into table "person_group" */
export type Person_Group_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Person_Group_Max_Fields = {
  __typename?: 'person_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Person_Group_Min_Fields = {
  __typename?: 'person_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "person_group" */
export type Person_Group_Mutation_Response = {
  __typename?: 'person_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person_Group>;
};

/** input type for inserting object relation for remote table "person_group" */
export type Person_Group_Obj_Rel_Insert_Input = {
  data: Person_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_Group_On_Conflict>;
};

/** on_conflict condition type for table "person_group" */
export type Person_Group_On_Conflict = {
  constraint: Person_Group_Constraint;
  update_columns?: Array<Person_Group_Update_Column>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "person_group". */
export type Person_Group_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: person_group */
export type Person_Group_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "person_group" */
export enum Person_Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "person_group" */
export type Person_Group_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Person_Group_Stddev_Fields = {
  __typename?: 'person_group_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Person_Group_Stddev_Pop_Fields = {
  __typename?: 'person_group_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Person_Group_Stddev_Samp_Fields = {
  __typename?: 'person_group_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "person_group" */
export type Person_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Person_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Person_Group_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Person_Group_Sum_Fields = {
  __typename?: 'person_group_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "person_group" */
export enum Person_Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Person_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Person_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Person_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Person_Group_Var_Pop_Fields = {
  __typename?: 'person_group_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Person_Group_Var_Samp_Fields = {
  __typename?: 'person_group_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Person_Group_Variance_Fields = {
  __typename?: 'person_group_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "person" */
export type Person_Inc_Input = {
  org_raw_response_id?: InputMaybe<Scalars['Int']>;
  stated_address_source_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "person" */
export type Person_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Arr_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  is_organisation?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_names?: InputMaybe<Scalars['String']>;
  org_raw_response_id?: InputMaybe<Scalars['Int']>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Arr_Rel_Insert_Input>;
  raw_response?: InputMaybe<Raw_Response_Obj_Rel_Insert_Input>;
  raw_responses?: InputMaybe<Raw_Response_Arr_Rel_Insert_Input>;
  stated_address?: InputMaybe<Scalars['String']>;
  stated_address_source?: InputMaybe<Stated_Address_Source_Obj_Rel_Insert_Input>;
  stated_address_source_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Person_Max_Fields = {
  __typename?: 'person_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  middle_names?: Maybe<Scalars['String']>;
  org_raw_response_id?: Maybe<Scalars['Int']>;
  stated_address?: Maybe<Scalars['String']>;
  stated_address_source_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "person" */
export type Person_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_names?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Person_Min_Fields = {
  __typename?: 'person_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  middle_names?: Maybe<Scalars['String']>;
  org_raw_response_id?: Maybe<Scalars['Int']>;
  stated_address?: Maybe<Scalars['String']>;
  stated_address_source_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "person" */
export type Person_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_names?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "person" */
export type Person_Mutation_Response = {
  __typename?: 'person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person>;
};

/** input type for inserting object relation for remote table "person" */
export type Person_Obj_Rel_Insert_Input = {
  data: Person_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** on_conflict condition type for table "person" */
export type Person_On_Conflict = {
  constraint: Person_Constraint;
  update_columns?: Array<Person_Update_Column>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** Ordering options when selecting data from "person". */
export type Person_Order_By = {
  created_at?: InputMaybe<Order_By>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  external_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_organisation?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_names?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Order_By>;
  raw_response?: InputMaybe<Raw_Response_Order_By>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Order_By>;
  stated_address?: InputMaybe<Order_By>;
  stated_address_source?: InputMaybe<Stated_Address_Source_Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_at?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: person */
export type Person_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "person" */
export enum Person_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsOrganisation = 'is_organisation',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleNames = 'middle_names',
  /** column name */
  OrgRawResponseId = 'org_raw_response_id',
  /** column name */
  StatedAddress = 'stated_address',
  /** column name */
  StatedAddressSourceId = 'stated_address_source_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidAt = 'valid_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

/** select "person_aggregate_bool_exp_bool_and_arguments_columns" columns of table "person" */
export enum Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsOrganisation = 'is_organisation'
}

/** select "person_aggregate_bool_exp_bool_or_arguments_columns" columns of table "person" */
export enum Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsOrganisation = 'is_organisation'
}

/** input type for updating data in table "person" */
export type Person_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  is_organisation?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_names?: InputMaybe<Scalars['String']>;
  org_raw_response_id?: InputMaybe<Scalars['Int']>;
  stated_address?: InputMaybe<Scalars['String']>;
  stated_address_source_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Person_Stddev_Fields = {
  __typename?: 'person_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "person" */
export type Person_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Person_Stddev_Pop_Fields = {
  __typename?: 'person_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "person" */
export type Person_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Person_Stddev_Samp_Fields = {
  __typename?: 'person_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "person" */
export type Person_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "person" */
export type Person_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Person_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Person_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_organisation?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_names?: InputMaybe<Scalars['String']>;
  org_raw_response_id?: InputMaybe<Scalars['Int']>;
  stated_address?: InputMaybe<Scalars['String']>;
  stated_address_source_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_at?: InputMaybe<Scalars['timestamptz']>;
  valid_from?: InputMaybe<Scalars['timestamptz']>;
  valid_to?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Person_Sum_Fields = {
  __typename?: 'person_sum_fields';
  id?: Maybe<Scalars['Int']>;
  org_raw_response_id?: Maybe<Scalars['Int']>;
  stated_address_source_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "person" */
export type Person_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "person_to_address" */
export type Person_To_Address = {
  __typename?: 'person_to_address';
  /** An object relationship */
  address: Address;
  address_id: Scalars['Int'];
  computed_matching_aid?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_linked_to_address_confidence: Scalars['Int'];
  is_linked_to_address_confidence_category_id: Scalars['Int'];
  is_linked_to_address_precision_type_id: Scalars['Int'];
  is_linked_to_address_provider_id: Scalars['Int'];
  is_owner_of_address_confidence: Scalars['Int'];
  is_owner_of_address_provider_id: Scalars['Int'];
  is_owner_of_address_status_id: Scalars['Int'];
  /** An object relationship */
  link_confidence_category: Link_Confidence_Category;
  /** An object relationship */
  link_precision_type: Link_Precision_Type;
  /** An object relationship */
  link_relationship_status: Link_Relationship_Status;
  /** An object relationship */
  person: Person;
  person_id: Scalars['Int'];
  /** An object relationship */
  provider: Provider;
  /** An object relationship */
  providerByIsOwnerOfAddressProviderId: Provider;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "person_to_address" */
export type Person_To_AddressComputed_Matching_AidArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "person_to_address" */
export type Person_To_Address_Aggregate = {
  __typename?: 'person_to_address_aggregate';
  aggregate?: Maybe<Person_To_Address_Aggregate_Fields>;
  nodes: Array<Person_To_Address>;
};

export type Person_To_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp_Count>;
};

export type Person_To_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_To_Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "person_to_address" */
export type Person_To_Address_Aggregate_Fields = {
  __typename?: 'person_to_address_aggregate_fields';
  avg?: Maybe<Person_To_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Person_To_Address_Max_Fields>;
  min?: Maybe<Person_To_Address_Min_Fields>;
  stddev?: Maybe<Person_To_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Person_To_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Person_To_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Person_To_Address_Sum_Fields>;
  var_pop?: Maybe<Person_To_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Person_To_Address_Var_Samp_Fields>;
  variance?: Maybe<Person_To_Address_Variance_Fields>;
};


/** aggregate fields of "person_to_address" */
export type Person_To_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "person_to_address" */
export type Person_To_Address_Aggregate_Order_By = {
  avg?: InputMaybe<Person_To_Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Person_To_Address_Max_Order_By>;
  min?: InputMaybe<Person_To_Address_Min_Order_By>;
  stddev?: InputMaybe<Person_To_Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Person_To_Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Person_To_Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Person_To_Address_Sum_Order_By>;
  var_pop?: InputMaybe<Person_To_Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Person_To_Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Person_To_Address_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Person_To_Address_Append_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "person_to_address" */
export type Person_To_Address_Arr_Rel_Insert_Input = {
  data: Array<Person_To_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_To_Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Person_To_Address_Avg_Fields = {
  __typename?: 'person_to_address_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "person_to_address" */
export type Person_To_Address_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "person_to_address". All fields are combined with a logical 'AND'. */
export type Person_To_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Person_To_Address_Bool_Exp>>;
  _not?: InputMaybe<Person_To_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Person_To_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  computed_matching_aid?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_precision_type_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_address_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_owner_of_address_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_owner_of_address_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_owner_of_address_status_id?: InputMaybe<Int_Comparison_Exp>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Bool_Exp>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  person_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  providerByIsOwnerOfAddressProviderId?: InputMaybe<Provider_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "person_to_address" */
export enum Person_To_Address_Constraint {
  /** unique or primary key constraint on columns "address_id", "person_id" */
  PersonToAddressPersonIdAddressIdKey = 'person_to_address_person_id_address_id_key',
  /** unique or primary key constraint on columns "id" */
  PersonToAddressPkey = 'person_to_address_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Person_To_Address_Delete_At_Path_Input = {
  computed_matching_aid?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Person_To_Address_Delete_Elem_Input = {
  computed_matching_aid?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Person_To_Address_Delete_Key_Input = {
  computed_matching_aid?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "person_to_address" */
export type Person_To_Address_Inc_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "person_to_address" */
export type Person_To_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_status_id?: InputMaybe<Scalars['Int']>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Obj_Rel_Insert_Input>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Obj_Rel_Insert_Input>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Obj_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  providerByIsOwnerOfAddressProviderId?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Person_To_Address_Max_Fields = {
  __typename?: 'person_to_address_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "person_to_address" */
export type Person_To_Address_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Person_To_Address_Min_Fields = {
  __typename?: 'person_to_address_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "person_to_address" */
export type Person_To_Address_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "person_to_address" */
export type Person_To_Address_Mutation_Response = {
  __typename?: 'person_to_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person_To_Address>;
};

/** on_conflict condition type for table "person_to_address" */
export type Person_To_Address_On_Conflict = {
  constraint: Person_To_Address_Constraint;
  update_columns?: Array<Person_To_Address_Update_Column>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "person_to_address". */
export type Person_To_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  computed_matching_aid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Order_By>;
  link_precision_type?: InputMaybe<Link_Precision_Type_Order_By>;
  link_relationship_status?: InputMaybe<Link_Relationship_Status_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  person_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  providerByIsOwnerOfAddressProviderId?: InputMaybe<Provider_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: person_to_address */
export type Person_To_Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Person_To_Address_Prepend_Input = {
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "person_to_address" */
export enum Person_To_Address_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLinkedToAddressConfidence = 'is_linked_to_address_confidence',
  /** column name */
  IsLinkedToAddressConfidenceCategoryId = 'is_linked_to_address_confidence_category_id',
  /** column name */
  IsLinkedToAddressPrecisionTypeId = 'is_linked_to_address_precision_type_id',
  /** column name */
  IsLinkedToAddressProviderId = 'is_linked_to_address_provider_id',
  /** column name */
  IsOwnerOfAddressConfidence = 'is_owner_of_address_confidence',
  /** column name */
  IsOwnerOfAddressProviderId = 'is_owner_of_address_provider_id',
  /** column name */
  IsOwnerOfAddressStatusId = 'is_owner_of_address_status_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "person_to_address" */
export type Person_To_Address_Set_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Person_To_Address_Stddev_Fields = {
  __typename?: 'person_to_address_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "person_to_address" */
export type Person_To_Address_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Person_To_Address_Stddev_Pop_Fields = {
  __typename?: 'person_to_address_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "person_to_address" */
export type Person_To_Address_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Person_To_Address_Stddev_Samp_Fields = {
  __typename?: 'person_to_address_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "person_to_address" */
export type Person_To_Address_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "person_to_address" */
export type Person_To_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Person_To_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Person_To_Address_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  computed_matching_aid?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_confidence?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: InputMaybe<Scalars['Int']>;
  is_owner_of_address_status_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Person_To_Address_Sum_Fields = {
  __typename?: 'person_to_address_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Int']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Int']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Int']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "person_to_address" */
export type Person_To_Address_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** update columns of table "person_to_address" */
export enum Person_To_Address_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ComputedMatchingAid = 'computed_matching_aid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsLinkedToAddressConfidence = 'is_linked_to_address_confidence',
  /** column name */
  IsLinkedToAddressConfidenceCategoryId = 'is_linked_to_address_confidence_category_id',
  /** column name */
  IsLinkedToAddressPrecisionTypeId = 'is_linked_to_address_precision_type_id',
  /** column name */
  IsLinkedToAddressProviderId = 'is_linked_to_address_provider_id',
  /** column name */
  IsOwnerOfAddressConfidence = 'is_owner_of_address_confidence',
  /** column name */
  IsOwnerOfAddressProviderId = 'is_owner_of_address_provider_id',
  /** column name */
  IsOwnerOfAddressStatusId = 'is_owner_of_address_status_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Person_To_Address_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Person_To_Address_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Person_To_Address_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Person_To_Address_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Person_To_Address_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Person_To_Address_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Person_To_Address_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Person_To_Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Person_To_Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Person_To_Address_Var_Pop_Fields = {
  __typename?: 'person_to_address_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "person_to_address" */
export type Person_To_Address_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Person_To_Address_Var_Samp_Fields = {
  __typename?: 'person_to_address_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "person_to_address" */
export type Person_To_Address_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Person_To_Address_Variance_Fields = {
  __typename?: 'person_to_address_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_address_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_precision_type_id?: Maybe<Scalars['Float']>;
  is_linked_to_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_confidence?: Maybe<Scalars['Float']>;
  is_owner_of_address_provider_id?: Maybe<Scalars['Float']>;
  is_owner_of_address_status_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "person_to_address" */
export type Person_To_Address_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_address_confidence?: InputMaybe<Order_By>;
  is_linked_to_address_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_address_precision_type_id?: InputMaybe<Order_By>;
  is_linked_to_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_confidence?: InputMaybe<Order_By>;
  is_owner_of_address_provider_id?: InputMaybe<Order_By>;
  is_owner_of_address_status_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "person_to_person_group" */
export type Person_To_Person_Group = {
  __typename?: 'person_to_person_group';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_linked_to_person_group_confidence: Scalars['Int'];
  is_linked_to_person_group_confidence_category_id: Scalars['Int'];
  is_linked_to_person_group_provider_id: Scalars['Int'];
  is_primary_person: Scalars['Boolean'];
  /** An object relationship */
  link_confidence_category: Link_Confidence_Category;
  /** An object relationship */
  person: Person;
  /** An object relationship */
  person_group: Person_Group;
  person_group_id: Scalars['Int'];
  person_id: Scalars['Int'];
  /** An object relationship */
  provider: Provider;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "person_to_person_group" */
export type Person_To_Person_Group_Aggregate = {
  __typename?: 'person_to_person_group_aggregate';
  aggregate?: Maybe<Person_To_Person_Group_Aggregate_Fields>;
  nodes: Array<Person_To_Person_Group>;
};

export type Person_To_Person_Group_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp_Count>;
};

export type Person_To_Person_Group_Aggregate_Bool_Exp_Bool_And = {
  arguments: Person_To_Person_Group_Select_Column_Person_To_Person_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_To_Person_Group_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Person_To_Person_Group_Select_Column_Person_To_Person_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_To_Person_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "person_to_person_group" */
export type Person_To_Person_Group_Aggregate_Fields = {
  __typename?: 'person_to_person_group_aggregate_fields';
  avg?: Maybe<Person_To_Person_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Person_To_Person_Group_Max_Fields>;
  min?: Maybe<Person_To_Person_Group_Min_Fields>;
  stddev?: Maybe<Person_To_Person_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Person_To_Person_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Person_To_Person_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Person_To_Person_Group_Sum_Fields>;
  var_pop?: Maybe<Person_To_Person_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Person_To_Person_Group_Var_Samp_Fields>;
  variance?: Maybe<Person_To_Person_Group_Variance_Fields>;
};


/** aggregate fields of "person_to_person_group" */
export type Person_To_Person_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "person_to_person_group" */
export type Person_To_Person_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Person_To_Person_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Person_To_Person_Group_Max_Order_By>;
  min?: InputMaybe<Person_To_Person_Group_Min_Order_By>;
  stddev?: InputMaybe<Person_To_Person_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Person_To_Person_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Person_To_Person_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Person_To_Person_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Person_To_Person_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Person_To_Person_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Person_To_Person_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "person_to_person_group" */
export type Person_To_Person_Group_Arr_Rel_Insert_Input = {
  data: Array<Person_To_Person_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_To_Person_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Person_To_Person_Group_Avg_Fields = {
  __typename?: 'person_to_person_group_avg_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "person_to_person_group". All fields are combined with a logical 'AND'. */
export type Person_To_Person_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Person_To_Person_Group_Bool_Exp>>;
  _not?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Person_To_Person_Group_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_person_group_confidence?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Int_Comparison_Exp>;
  is_linked_to_person_group_provider_id?: InputMaybe<Int_Comparison_Exp>;
  is_primary_person?: InputMaybe<Boolean_Comparison_Exp>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  person_group?: InputMaybe<Person_Group_Bool_Exp>;
  person_group_id?: InputMaybe<Int_Comparison_Exp>;
  person_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "person_to_person_group" */
export enum Person_To_Person_Group_Constraint {
  /** unique or primary key constraint on columns "person_id", "person_group_id" */
  PersonToPersonGroupPersonIdPersonGroupIdKey = 'person_to_person_group_person_id_person_group_id_key',
  /** unique or primary key constraint on columns "id" */
  PersonToPersonGroupPkey = 'person_to_person_group_pkey',
  /** unique or primary key constraint on columns "is_primary_person", "person_group_id" */
  UqPrimaryPersonPerGroup = 'uq_primary_person_per_group'
}

/** input type for incrementing numeric columns in table "person_to_person_group" */
export type Person_To_Person_Group_Inc_Input = {
  is_linked_to_person_group_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: InputMaybe<Scalars['Int']>;
  person_group_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "person_to_person_group" */
export type Person_To_Person_Group_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_linked_to_person_group_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: InputMaybe<Scalars['Int']>;
  is_primary_person?: InputMaybe<Scalars['Boolean']>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Obj_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_group?: InputMaybe<Person_Group_Obj_Rel_Insert_Input>;
  person_group_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Person_To_Person_Group_Max_Fields = {
  __typename?: 'person_to_person_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Int']>;
  person_group_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Person_To_Person_Group_Min_Fields = {
  __typename?: 'person_to_person_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Int']>;
  person_group_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "person_to_person_group" */
export type Person_To_Person_Group_Mutation_Response = {
  __typename?: 'person_to_person_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person_To_Person_Group>;
};

/** on_conflict condition type for table "person_to_person_group" */
export type Person_To_Person_Group_On_Conflict = {
  constraint: Person_To_Person_Group_Constraint;
  update_columns?: Array<Person_To_Person_Group_Update_Column>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "person_to_person_group". */
export type Person_To_Person_Group_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  is_primary_person?: InputMaybe<Order_By>;
  link_confidence_category?: InputMaybe<Link_Confidence_Category_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  person_group?: InputMaybe<Person_Group_Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: person_to_person_group */
export type Person_To_Person_Group_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "person_to_person_group" */
export enum Person_To_Person_Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLinkedToPersonGroupConfidence = 'is_linked_to_person_group_confidence',
  /** column name */
  IsLinkedToPersonGroupConfidenceCategoryId = 'is_linked_to_person_group_confidence_category_id',
  /** column name */
  IsLinkedToPersonGroupProviderId = 'is_linked_to_person_group_provider_id',
  /** column name */
  IsPrimaryPerson = 'is_primary_person',
  /** column name */
  PersonGroupId = 'person_group_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "person_to_person_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "person_to_person_group" */
export enum Person_To_Person_Group_Select_Column_Person_To_Person_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimaryPerson = 'is_primary_person'
}

/** select "person_to_person_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "person_to_person_group" */
export enum Person_To_Person_Group_Select_Column_Person_To_Person_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimaryPerson = 'is_primary_person'
}

/** input type for updating data in table "person_to_person_group" */
export type Person_To_Person_Group_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_linked_to_person_group_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: InputMaybe<Scalars['Int']>;
  is_primary_person?: InputMaybe<Scalars['Boolean']>;
  person_group_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Person_To_Person_Group_Stddev_Fields = {
  __typename?: 'person_to_person_group_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Person_To_Person_Group_Stddev_Pop_Fields = {
  __typename?: 'person_to_person_group_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Person_To_Person_Group_Stddev_Samp_Fields = {
  __typename?: 'person_to_person_group_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "person_to_person_group" */
export type Person_To_Person_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Person_To_Person_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Person_To_Person_Group_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_confidence?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: InputMaybe<Scalars['Int']>;
  is_primary_person?: InputMaybe<Scalars['Boolean']>;
  person_group_id?: InputMaybe<Scalars['Int']>;
  person_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Person_To_Person_Group_Sum_Fields = {
  __typename?: 'person_to_person_group_sum_fields';
  id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Int']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Int']>;
  person_group_id?: Maybe<Scalars['Int']>;
  person_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** update columns of table "person_to_person_group" */
export enum Person_To_Person_Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsLinkedToPersonGroupConfidence = 'is_linked_to_person_group_confidence',
  /** column name */
  IsLinkedToPersonGroupConfidenceCategoryId = 'is_linked_to_person_group_confidence_category_id',
  /** column name */
  IsLinkedToPersonGroupProviderId = 'is_linked_to_person_group_provider_id',
  /** column name */
  IsPrimaryPerson = 'is_primary_person',
  /** column name */
  PersonGroupId = 'person_group_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Person_To_Person_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Person_To_Person_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Person_To_Person_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Person_To_Person_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Person_To_Person_Group_Var_Pop_Fields = {
  __typename?: 'person_to_person_group_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Person_To_Person_Group_Var_Samp_Fields = {
  __typename?: 'person_to_person_group_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Person_To_Person_Group_Variance_Fields = {
  __typename?: 'person_to_person_group_variance_fields';
  id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_confidence_category_id?: Maybe<Scalars['Float']>;
  is_linked_to_person_group_provider_id?: Maybe<Scalars['Float']>;
  person_group_id?: Maybe<Scalars['Float']>;
  person_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "person_to_person_group" */
export type Person_To_Person_Group_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence?: InputMaybe<Order_By>;
  is_linked_to_person_group_confidence_category_id?: InputMaybe<Order_By>;
  is_linked_to_person_group_provider_id?: InputMaybe<Order_By>;
  person_group_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** update columns of table "person" */
export enum Person_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  IsOrganisation = 'is_organisation',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleNames = 'middle_names',
  /** column name */
  OrgRawResponseId = 'org_raw_response_id',
  /** column name */
  StatedAddress = 'stated_address',
  /** column name */
  StatedAddressSourceId = 'stated_address_source_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidAt = 'valid_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

export type Person_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Person_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Person_Set_Input>;
  /** filter the rows which have to be updated */
  where: Person_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Person_Var_Pop_Fields = {
  __typename?: 'person_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "person" */
export type Person_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Person_Var_Samp_Fields = {
  __typename?: 'person_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "person" */
export type Person_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Person_Variance_Fields = {
  __typename?: 'person_variance_fields';
  id?: Maybe<Scalars['Float']>;
  org_raw_response_id?: Maybe<Scalars['Float']>;
  stated_address_source_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "person" */
export type Person_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  org_raw_response_id?: InputMaybe<Order_By>;
  stated_address_source_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "postal_code" */
export type Postal_Code = {
  __typename?: 'postal_code';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  code: Scalars['String'];
  /** An object relationship */
  country: Country;
  country_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  provider?: Maybe<Provider>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "postal_code" */
export type Postal_CodeAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "postal_code" */
export type Postal_CodeAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** aggregated selection of "postal_code" */
export type Postal_Code_Aggregate = {
  __typename?: 'postal_code_aggregate';
  aggregate?: Maybe<Postal_Code_Aggregate_Fields>;
  nodes: Array<Postal_Code>;
};

export type Postal_Code_Aggregate_Bool_Exp = {
  count?: InputMaybe<Postal_Code_Aggregate_Bool_Exp_Count>;
};

export type Postal_Code_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Postal_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Postal_Code_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "postal_code" */
export type Postal_Code_Aggregate_Fields = {
  __typename?: 'postal_code_aggregate_fields';
  avg?: Maybe<Postal_Code_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Postal_Code_Max_Fields>;
  min?: Maybe<Postal_Code_Min_Fields>;
  stddev?: Maybe<Postal_Code_Stddev_Fields>;
  stddev_pop?: Maybe<Postal_Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Postal_Code_Stddev_Samp_Fields>;
  sum?: Maybe<Postal_Code_Sum_Fields>;
  var_pop?: Maybe<Postal_Code_Var_Pop_Fields>;
  var_samp?: Maybe<Postal_Code_Var_Samp_Fields>;
  variance?: Maybe<Postal_Code_Variance_Fields>;
};


/** aggregate fields of "postal_code" */
export type Postal_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Postal_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "postal_code" */
export type Postal_Code_Aggregate_Order_By = {
  avg?: InputMaybe<Postal_Code_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Postal_Code_Max_Order_By>;
  min?: InputMaybe<Postal_Code_Min_Order_By>;
  stddev?: InputMaybe<Postal_Code_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Postal_Code_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Postal_Code_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Postal_Code_Sum_Order_By>;
  var_pop?: InputMaybe<Postal_Code_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Postal_Code_Var_Samp_Order_By>;
  variance?: InputMaybe<Postal_Code_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "postal_code" */
export type Postal_Code_Arr_Rel_Insert_Input = {
  data: Array<Postal_Code_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Postal_Code_On_Conflict>;
};

/** aggregate avg on columns */
export type Postal_Code_Avg_Fields = {
  __typename?: 'postal_code_avg_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "postal_code" */
export type Postal_Code_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "postal_code". All fields are combined with a logical 'AND'. */
export type Postal_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Postal_Code_Bool_Exp>>;
  _not?: InputMaybe<Postal_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Postal_Code_Bool_Exp>>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "postal_code" */
export enum Postal_Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostalCodePkey = 'postal_code_pkey',
  /** unique or primary key constraint on columns "code", "country_id" */
  UqPostalCodeCodeCountry = 'uq_postal_code_code_country'
}

/** input type for incrementing numeric columns in table "postal_code" */
export type Postal_Code_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "postal_code" */
export type Postal_Code_Insert_Input = {
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Postal_Code_Max_Fields = {
  __typename?: 'postal_code_max_fields';
  code?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "postal_code" */
export type Postal_Code_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Postal_Code_Min_Fields = {
  __typename?: 'postal_code_min_fields';
  code?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "postal_code" */
export type Postal_Code_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "postal_code" */
export type Postal_Code_Mutation_Response = {
  __typename?: 'postal_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Postal_Code>;
};

/** input type for inserting object relation for remote table "postal_code" */
export type Postal_Code_Obj_Rel_Insert_Input = {
  data: Postal_Code_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Postal_Code_On_Conflict>;
};

/** on_conflict condition type for table "postal_code" */
export type Postal_Code_On_Conflict = {
  constraint: Postal_Code_Constraint;
  update_columns?: Array<Postal_Code_Update_Column>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "postal_code". */
export type Postal_Code_Order_By = {
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  code?: InputMaybe<Order_By>;
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: postal_code */
export type Postal_Code_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "postal_code" */
export enum Postal_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "postal_code" */
export type Postal_Code_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Postal_Code_Stddev_Fields = {
  __typename?: 'postal_code_stddev_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "postal_code" */
export type Postal_Code_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Postal_Code_Stddev_Pop_Fields = {
  __typename?: 'postal_code_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "postal_code" */
export type Postal_Code_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Postal_Code_Stddev_Samp_Fields = {
  __typename?: 'postal_code_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "postal_code" */
export type Postal_Code_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "postal_code" */
export type Postal_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Postal_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Postal_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Postal_Code_Sum_Fields = {
  __typename?: 'postal_code_sum_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "postal_code" */
export type Postal_Code_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "postal_code" */
export enum Postal_Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Postal_Code_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Postal_Code_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Postal_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Postal_Code_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Postal_Code_Var_Pop_Fields = {
  __typename?: 'postal_code_var_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "postal_code" */
export type Postal_Code_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Postal_Code_Var_Samp_Fields = {
  __typename?: 'postal_code_var_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "postal_code" */
export type Postal_Code_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Postal_Code_Variance_Fields = {
  __typename?: 'postal_code_variance_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "postal_code" */
export type Postal_Code_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "provider" */
export type Provider = {
  __typename?: 'provider';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  /** An array relationship */
  countries: Array<Country>;
  /** An aggregate relationship */
  countries_aggregate: Country_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  digitalProfileToAddressesByIsProfileOfAddressProviderId: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digitalProfileToAddressesByIsProfileOfAddressProviderId_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  digital_profile_to_addresses: Array<Digital_Profile_To_Address>;
  /** An aggregate relationship */
  digital_profile_to_addresses_aggregate: Digital_Profile_To_Address_Aggregate;
  /** An array relationship */
  digital_profile_to_people: Array<Digital_Profile_To_Person>;
  /** An aggregate relationship */
  digital_profile_to_people_aggregate: Digital_Profile_To_Person_Aggregate;
  /** An array relationship */
  digital_profiles: Array<Digital_Profile>;
  /** An aggregate relationship */
  digital_profiles_aggregate: Digital_Profile_Aggregate;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  personToAddressesByIsOwnerOfAddressProviderId: Array<Person_To_Address>;
  /** An aggregate relationship */
  personToAddressesByIsOwnerOfAddressProviderId_aggregate: Person_To_Address_Aggregate;
  /** An array relationship */
  person_to_addresses: Array<Person_To_Address>;
  /** An aggregate relationship */
  person_to_addresses_aggregate: Person_To_Address_Aggregate;
  /** An array relationship */
  person_to_person_groups: Array<Person_To_Person_Group>;
  /** An aggregate relationship */
  person_to_person_groups_aggregate: Person_To_Person_Group_Aggregate;
  /** An array relationship */
  postal_codes: Array<Postal_Code>;
  /** An aggregate relationship */
  postal_codes_aggregate: Postal_Code_Aggregate;
  /** An array relationship */
  raw_response_search_actions: Array<Raw_Response_Search_Action>;
  /** An aggregate relationship */
  raw_response_search_actions_aggregate: Raw_Response_Search_Action_Aggregate;
  /** An array relationship */
  stated_address_sources: Array<Stated_Address_Source>;
  /** An aggregate relationship */
  stated_address_sources_aggregate: Stated_Address_Source_Aggregate;
  /** An array relationship */
  states: Array<State>;
  /** An aggregate relationship */
  states_aggregate: State_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "provider" */
export type ProviderAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderCountriesArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigitalProfileToAddressesByIsProfileOfAddressProviderIdArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigitalProfileToAddressesByIsProfileOfAddressProviderId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_Profile_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_Profile_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_Profile_To_PeopleArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_Profile_To_People_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderDigital_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPersonToAddressesByIsOwnerOfAddressProviderIdArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPersonToAddressesByIsOwnerOfAddressProviderId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPerson_To_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPerson_To_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPerson_To_Person_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPerson_To_Person_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPostal_CodesArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderPostal_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderRaw_Response_Search_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderRaw_Response_Search_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderStated_Address_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderStated_Address_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderStatesArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};

/** aggregated selection of "provider" */
export type Provider_Aggregate = {
  __typename?: 'provider_aggregate';
  aggregate?: Maybe<Provider_Aggregate_Fields>;
  nodes: Array<Provider>;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_Fields = {
  __typename?: 'provider_aggregate_fields';
  avg?: Maybe<Provider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Provider_Max_Fields>;
  min?: Maybe<Provider_Min_Fields>;
  stddev?: Maybe<Provider_Stddev_Fields>;
  stddev_pop?: Maybe<Provider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Provider_Stddev_Samp_Fields>;
  sum?: Maybe<Provider_Sum_Fields>;
  var_pop?: Maybe<Provider_Var_Pop_Fields>;
  var_samp?: Maybe<Provider_Var_Samp_Fields>;
  variance?: Maybe<Provider_Variance_Fields>;
};


/** aggregate fields of "provider" */
export type Provider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Provider_Avg_Fields = {
  __typename?: 'provider_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "provider". All fields are combined with a logical 'AND'. */
export type Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Provider_Bool_Exp>>;
  _not?: InputMaybe<Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Provider_Bool_Exp>>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  countries?: InputMaybe<Country_Bool_Exp>;
  countries_aggregate?: InputMaybe<Country_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digitalProfileToAddressesByIsProfileOfAddressProviderId?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digitalProfileToAddressesByIsProfileOfAddressProviderId_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Bool_Exp>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Bool_Exp>;
  digital_profiles?: InputMaybe<Digital_Profile_Bool_Exp>;
  digital_profiles_aggregate?: InputMaybe<Digital_Profile_Aggregate_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  personToAddressesByIsOwnerOfAddressProviderId?: InputMaybe<Person_To_Address_Bool_Exp>;
  personToAddressesByIsOwnerOfAddressProviderId_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  person_to_addresses?: InputMaybe<Person_To_Address_Bool_Exp>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Bool_Exp>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Bool_Exp>;
  postal_codes?: InputMaybe<Postal_Code_Bool_Exp>;
  postal_codes_aggregate?: InputMaybe<Postal_Code_Aggregate_Bool_Exp>;
  raw_response_search_actions?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
  raw_response_search_actions_aggregate?: InputMaybe<Raw_Response_Search_Action_Aggregate_Bool_Exp>;
  stated_address_sources?: InputMaybe<Stated_Address_Source_Bool_Exp>;
  stated_address_sources_aggregate?: InputMaybe<Stated_Address_Source_Aggregate_Bool_Exp>;
  states?: InputMaybe<State_Bool_Exp>;
  states_aggregate?: InputMaybe<State_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider" */
export enum Provider_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProviderNameKey = 'provider_name_key',
  /** unique or primary key constraint on columns "id" */
  ProviderPkey = 'provider_pkey'
}

/** input type for incrementing numeric columns in table "provider" */
export type Provider_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "provider" */
export type Provider_Insert_Input = {
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  countries?: InputMaybe<Country_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digitalProfileToAddressesByIsProfileOfAddressProviderId?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  digital_profile_to_addresses?: InputMaybe<Digital_Profile_To_Address_Arr_Rel_Insert_Input>;
  digital_profile_to_people?: InputMaybe<Digital_Profile_To_Person_Arr_Rel_Insert_Input>;
  digital_profiles?: InputMaybe<Digital_Profile_Arr_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  personToAddressesByIsOwnerOfAddressProviderId?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  person_to_addresses?: InputMaybe<Person_To_Address_Arr_Rel_Insert_Input>;
  person_to_person_groups?: InputMaybe<Person_To_Person_Group_Arr_Rel_Insert_Input>;
  postal_codes?: InputMaybe<Postal_Code_Arr_Rel_Insert_Input>;
  raw_response_search_actions?: InputMaybe<Raw_Response_Search_Action_Arr_Rel_Insert_Input>;
  stated_address_sources?: InputMaybe<Stated_Address_Source_Arr_Rel_Insert_Input>;
  states?: InputMaybe<State_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Provider_Max_Fields = {
  __typename?: 'provider_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Provider_Min_Fields = {
  __typename?: 'provider_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "provider" */
export type Provider_Mutation_Response = {
  __typename?: 'provider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provider>;
};

/** input type for inserting object relation for remote table "provider" */
export type Provider_Obj_Rel_Insert_Input = {
  data: Provider_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};

/** on_conflict condition type for table "provider" */
export type Provider_On_Conflict = {
  constraint: Provider_Constraint;
  update_columns?: Array<Provider_Update_Column>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "provider". */
export type Provider_Order_By = {
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  countries_aggregate?: InputMaybe<Country_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  digitalProfileToAddressesByIsProfileOfAddressProviderId_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  digital_profile_to_addresses_aggregate?: InputMaybe<Digital_Profile_To_Address_Aggregate_Order_By>;
  digital_profile_to_people_aggregate?: InputMaybe<Digital_Profile_To_Person_Aggregate_Order_By>;
  digital_profiles_aggregate?: InputMaybe<Digital_Profile_Aggregate_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  personToAddressesByIsOwnerOfAddressProviderId_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  person_to_addresses_aggregate?: InputMaybe<Person_To_Address_Aggregate_Order_By>;
  person_to_person_groups_aggregate?: InputMaybe<Person_To_Person_Group_Aggregate_Order_By>;
  postal_codes_aggregate?: InputMaybe<Postal_Code_Aggregate_Order_By>;
  raw_response_search_actions_aggregate?: InputMaybe<Raw_Response_Search_Action_Aggregate_Order_By>;
  stated_address_sources_aggregate?: InputMaybe<Stated_Address_Source_Aggregate_Order_By>;
  states_aggregate?: InputMaybe<State_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: provider */
export type Provider_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "provider" */
export enum Provider_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "provider" */
export type Provider_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Provider_Stddev_Fields = {
  __typename?: 'provider_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Provider_Stddev_Pop_Fields = {
  __typename?: 'provider_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Provider_Stddev_Samp_Fields = {
  __typename?: 'provider_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "provider" */
export type Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Provider_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Provider_Sum_Fields = {
  __typename?: 'provider_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "provider" */
export enum Provider_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Provider_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Provider_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Provider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Provider_Var_Pop_Fields = {
  __typename?: 'provider_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Provider_Var_Samp_Fields = {
  __typename?: 'provider_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Provider_Variance_Fields = {
  __typename?: 'provider_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "_yoyo_log" */
  _yoyo_log: Array<_Yoyo_Log>;
  /** fetch aggregated fields from the table: "_yoyo_log" */
  _yoyo_log_aggregate: _Yoyo_Log_Aggregate;
  /** fetch data from the table: "_yoyo_log" using primary key columns */
  _yoyo_log_by_pk?: Maybe<_Yoyo_Log>;
  /** fetch data from the table: "_yoyo_migration" */
  _yoyo_migration: Array<_Yoyo_Migration>;
  /** fetch aggregated fields from the table: "_yoyo_migration" */
  _yoyo_migration_aggregate: _Yoyo_Migration_Aggregate;
  /** fetch data from the table: "_yoyo_migration" using primary key columns */
  _yoyo_migration_by_pk?: Maybe<_Yoyo_Migration>;
  /** fetch data from the table: "_yoyo_version" */
  _yoyo_version: Array<_Yoyo_Version>;
  /** fetch aggregated fields from the table: "_yoyo_version" */
  _yoyo_version_aggregate: _Yoyo_Version_Aggregate;
  /** fetch data from the table: "_yoyo_version" using primary key columns */
  _yoyo_version_by_pk?: Maybe<_Yoyo_Version>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "attribute" */
  attribute: Array<Attribute>;
  /** fetch aggregated fields from the table: "attribute" */
  attribute_aggregate: Attribute_Aggregate;
  /** fetch data from the table: "attribute" using primary key columns */
  attribute_by_pk?: Maybe<Attribute>;
  /** fetch data from the table: "attribute_type" */
  attribute_type: Array<Attribute_Type>;
  /** fetch aggregated fields from the table: "attribute_type" */
  attribute_type_aggregate: Attribute_Type_Aggregate;
  /** fetch data from the table: "attribute_type" using primary key columns */
  attribute_type_by_pk?: Maybe<Attribute_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "digital_profile" */
  digital_profile: Array<Digital_Profile>;
  /** fetch aggregated fields from the table: "digital_profile" */
  digital_profile_aggregate: Digital_Profile_Aggregate;
  /** fetch data from the table: "digital_profile" using primary key columns */
  digital_profile_by_pk?: Maybe<Digital_Profile>;
  /** fetch data from the table: "digital_profile_to_address" */
  digital_profile_to_address: Array<Digital_Profile_To_Address>;
  /** fetch aggregated fields from the table: "digital_profile_to_address" */
  digital_profile_to_address_aggregate: Digital_Profile_To_Address_Aggregate;
  /** fetch data from the table: "digital_profile_to_address" using primary key columns */
  digital_profile_to_address_by_pk?: Maybe<Digital_Profile_To_Address>;
  /** fetch data from the table: "digital_profile_to_person" */
  digital_profile_to_person: Array<Digital_Profile_To_Person>;
  /** fetch aggregated fields from the table: "digital_profile_to_person" */
  digital_profile_to_person_aggregate: Digital_Profile_To_Person_Aggregate;
  /** fetch data from the table: "digital_profile_to_person" using primary key columns */
  digital_profile_to_person_by_pk?: Maybe<Digital_Profile_To_Person>;
  /** fetch data from the table: "digital_profile_type" */
  digital_profile_type: Array<Digital_Profile_Type>;
  /** fetch aggregated fields from the table: "digital_profile_type" */
  digital_profile_type_aggregate: Digital_Profile_Type_Aggregate;
  /** fetch data from the table: "digital_profile_type" using primary key columns */
  digital_profile_type_by_pk?: Maybe<Digital_Profile_Type>;
  /** fetch data from the table: "entity_to_raw_response" */
  entity_to_raw_response: Array<Entity_To_Raw_Response>;
  /** fetch aggregated fields from the table: "entity_to_raw_response" */
  entity_to_raw_response_aggregate: Entity_To_Raw_Response_Aggregate;
  /** fetch data from the table: "entity_to_raw_response" using primary key columns */
  entity_to_raw_response_by_pk?: Maybe<Entity_To_Raw_Response>;
  /** fetch data from the table: "link_confidence_category" */
  link_confidence_category: Array<Link_Confidence_Category>;
  /** fetch aggregated fields from the table: "link_confidence_category" */
  link_confidence_category_aggregate: Link_Confidence_Category_Aggregate;
  /** fetch data from the table: "link_confidence_category" using primary key columns */
  link_confidence_category_by_pk?: Maybe<Link_Confidence_Category>;
  /** fetch data from the table: "link_precision_type" */
  link_precision_type: Array<Link_Precision_Type>;
  /** fetch aggregated fields from the table: "link_precision_type" */
  link_precision_type_aggregate: Link_Precision_Type_Aggregate;
  /** fetch data from the table: "link_precision_type" using primary key columns */
  link_precision_type_by_pk?: Maybe<Link_Precision_Type>;
  /** fetch data from the table: "link_relationship_status" */
  link_relationship_status: Array<Link_Relationship_Status>;
  /** fetch aggregated fields from the table: "link_relationship_status" */
  link_relationship_status_aggregate: Link_Relationship_Status_Aggregate;
  /** fetch data from the table: "link_relationship_status" using primary key columns */
  link_relationship_status_by_pk?: Maybe<Link_Relationship_Status>;
  /** fetch data from the table: "operation" */
  operation: Array<Operation>;
  /** fetch aggregated fields from the table: "operation" */
  operation_aggregate: Operation_Aggregate;
  /** fetch data from the table: "operation" using primary key columns */
  operation_by_pk?: Maybe<Operation>;
  /** fetch data from the table: "operation_progress_status" */
  operation_progress_status: Array<Operation_Progress_Status>;
  /** fetch aggregated fields from the table: "operation_progress_status" */
  operation_progress_status_aggregate: Operation_Progress_Status_Aggregate;
  /** fetch data from the table: "operation_progress_status" using primary key columns */
  operation_progress_status_by_pk?: Maybe<Operation_Progress_Status>;
  /** fetch data from the table: "operation_request_item" */
  operation_request_item: Array<Operation_Request_Item>;
  /** fetch aggregated fields from the table: "operation_request_item" */
  operation_request_item_aggregate: Operation_Request_Item_Aggregate;
  /** fetch data from the table: "operation_request_item" using primary key columns */
  operation_request_item_by_pk?: Maybe<Operation_Request_Item>;
  /** fetch data from the table: "operation_request_item_type" */
  operation_request_item_type: Array<Operation_Request_Item_Type>;
  /** fetch aggregated fields from the table: "operation_request_item_type" */
  operation_request_item_type_aggregate: Operation_Request_Item_Type_Aggregate;
  /** fetch data from the table: "operation_request_item_type" using primary key columns */
  operation_request_item_type_by_pk?: Maybe<Operation_Request_Item_Type>;
  /** fetch data from the table: "operation_state" */
  operation_state: Array<Operation_State>;
  /** fetch aggregated fields from the table: "operation_state" */
  operation_state_aggregate: Operation_State_Aggregate;
  /** fetch data from the table: "operation_state" using primary key columns */
  operation_state_by_pk?: Maybe<Operation_State>;
  /** fetch data from the table: "operation_trigger_type" */
  operation_trigger_type: Array<Operation_Trigger_Type>;
  /** fetch aggregated fields from the table: "operation_trigger_type" */
  operation_trigger_type_aggregate: Operation_Trigger_Type_Aggregate;
  /** fetch data from the table: "operation_trigger_type" using primary key columns */
  operation_trigger_type_by_pk?: Maybe<Operation_Trigger_Type>;
  /** fetch data from the table: "operation_type" */
  operation_type: Array<Operation_Type>;
  /** fetch aggregated fields from the table: "operation_type" */
  operation_type_aggregate: Operation_Type_Aggregate;
  /** fetch data from the table: "operation_type" using primary key columns */
  operation_type_by_pk?: Maybe<Operation_Type>;
  /** fetch data from the table: "outlook_linkedin_credential" */
  outlook_linkedin_credential: Array<Outlook_Linkedin_Credential>;
  /** fetch aggregated fields from the table: "outlook_linkedin_credential" */
  outlook_linkedin_credential_aggregate: Outlook_Linkedin_Credential_Aggregate;
  /** fetch data from the table: "outlook_linkedin_credential" using primary key columns */
  outlook_linkedin_credential_by_pk?: Maybe<Outlook_Linkedin_Credential>;
  /** fetch data from the table: "person" */
  person: Array<Person>;
  /** fetch aggregated fields from the table: "person" */
  person_aggregate: Person_Aggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk?: Maybe<Person>;
  /** fetch data from the table: "person_group" */
  person_group: Array<Person_Group>;
  /** fetch aggregated fields from the table: "person_group" */
  person_group_aggregate: Person_Group_Aggregate;
  /** fetch data from the table: "person_group" using primary key columns */
  person_group_by_pk?: Maybe<Person_Group>;
  /** fetch data from the table: "person_to_address" */
  person_to_address: Array<Person_To_Address>;
  /** fetch aggregated fields from the table: "person_to_address" */
  person_to_address_aggregate: Person_To_Address_Aggregate;
  /** fetch data from the table: "person_to_address" using primary key columns */
  person_to_address_by_pk?: Maybe<Person_To_Address>;
  /** fetch data from the table: "person_to_person_group" */
  person_to_person_group: Array<Person_To_Person_Group>;
  /** fetch aggregated fields from the table: "person_to_person_group" */
  person_to_person_group_aggregate: Person_To_Person_Group_Aggregate;
  /** fetch data from the table: "person_to_person_group" using primary key columns */
  person_to_person_group_by_pk?: Maybe<Person_To_Person_Group>;
  /** fetch data from the table: "postal_code" */
  postal_code: Array<Postal_Code>;
  /** fetch aggregated fields from the table: "postal_code" */
  postal_code_aggregate: Postal_Code_Aggregate;
  /** fetch data from the table: "postal_code" using primary key columns */
  postal_code_by_pk?: Maybe<Postal_Code>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "raw_response" */
  raw_response: Array<Raw_Response>;
  /** fetch aggregated fields from the table: "raw_response" */
  raw_response_aggregate: Raw_Response_Aggregate;
  /** fetch data from the table: "raw_response" using primary key columns */
  raw_response_by_pk?: Maybe<Raw_Response>;
  /** fetch data from the table: "raw_response_formulation_type" */
  raw_response_formulation_type: Array<Raw_Response_Formulation_Type>;
  /** fetch aggregated fields from the table: "raw_response_formulation_type" */
  raw_response_formulation_type_aggregate: Raw_Response_Formulation_Type_Aggregate;
  /** fetch data from the table: "raw_response_formulation_type" using primary key columns */
  raw_response_formulation_type_by_pk?: Maybe<Raw_Response_Formulation_Type>;
  /** fetch data from the table: "raw_response_search_action" */
  raw_response_search_action: Array<Raw_Response_Search_Action>;
  /** fetch aggregated fields from the table: "raw_response_search_action" */
  raw_response_search_action_aggregate: Raw_Response_Search_Action_Aggregate;
  /** fetch data from the table: "raw_response_search_action" using primary key columns */
  raw_response_search_action_by_pk?: Maybe<Raw_Response_Search_Action>;
  /** fetch data from the table: "state" */
  state: Array<State>;
  /** fetch aggregated fields from the table: "state" */
  state_aggregate: State_Aggregate;
  /** fetch data from the table: "state" using primary key columns */
  state_by_pk?: Maybe<State>;
  /** fetch data from the table: "stated_address_source" */
  stated_address_source: Array<Stated_Address_Source>;
  /** fetch aggregated fields from the table: "stated_address_source" */
  stated_address_source_aggregate: Stated_Address_Source_Aggregate;
  /** fetch data from the table: "stated_address_source" using primary key columns */
  stated_address_source_by_pk?: Maybe<Stated_Address_Source>;
  /** fetch data from the table: "tracking_group" */
  tracking_group: Array<Tracking_Group>;
  /** fetch aggregated fields from the table: "tracking_group" */
  tracking_group_aggregate: Tracking_Group_Aggregate;
  /** fetch data from the table: "tracking_group" using primary key columns */
  tracking_group_by_pk?: Maybe<Tracking_Group>;
  /** fetch data from the table: "tracking_group_item" */
  tracking_group_item: Array<Tracking_Group_Item>;
  /** fetch aggregated fields from the table: "tracking_group_item" */
  tracking_group_item_aggregate: Tracking_Group_Item_Aggregate;
  /** fetch data from the table: "tracking_group_item" using primary key columns */
  tracking_group_item_by_pk?: Maybe<Tracking_Group_Item>;
  /** fetch data from the table: "tracking_group_item_type" */
  tracking_group_item_type: Array<Tracking_Group_Item_Type>;
  /** fetch aggregated fields from the table: "tracking_group_item_type" */
  tracking_group_item_type_aggregate: Tracking_Group_Item_Type_Aggregate;
  /** fetch data from the table: "tracking_group_item_type" using primary key columns */
  tracking_group_item_type_by_pk?: Maybe<Tracking_Group_Item_Type>;
  /** fetch data from the table: "tracking_group_type" */
  tracking_group_type: Array<Tracking_Group_Type>;
  /** fetch aggregated fields from the table: "tracking_group_type" */
  tracking_group_type_aggregate: Tracking_Group_Type_Aggregate;
  /** fetch data from the table: "tracking_group_type" using primary key columns */
  tracking_group_type_by_pk?: Maybe<Tracking_Group_Type>;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
  /** fetch data from the table: "yoyo_lock" */
  yoyo_lock: Array<Yoyo_Lock>;
  /** fetch aggregated fields from the table: "yoyo_lock" */
  yoyo_lock_aggregate: Yoyo_Lock_Aggregate;
  /** fetch data from the table: "yoyo_lock" using primary key columns */
  yoyo_lock_by_pk?: Maybe<Yoyo_Lock>;
};


export type Query_Root_Yoyo_LogArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Log_Order_By>>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};


export type Query_Root_Yoyo_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Log_Order_By>>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};


export type Query_Root_Yoyo_Log_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_Root_Yoyo_MigrationArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Migration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Migration_Order_By>>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};


export type Query_Root_Yoyo_Migration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Migration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Migration_Order_By>>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};


export type Query_Root_Yoyo_Migration_By_PkArgs = {
  migration_hash: Scalars['String'];
};


export type Query_Root_Yoyo_VersionArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Version_Order_By>>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};


export type Query_Root_Yoyo_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Version_Order_By>>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};


export type Query_Root_Yoyo_Version_By_PkArgs = {
  version: Scalars['Int'];
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAttributeArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


export type Query_RootAttribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


export type Query_RootAttribute_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAttribute_TypeArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Type_Order_By>>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};


export type Query_RootAttribute_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Type_Order_By>>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};


export type Query_RootAttribute_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDigital_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


export type Query_RootDigital_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


export type Query_RootDigital_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDigital_Profile_To_AddressArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


export type Query_RootDigital_Profile_To_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


export type Query_RootDigital_Profile_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDigital_Profile_To_PersonArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


export type Query_RootDigital_Profile_To_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


export type Query_RootDigital_Profile_To_Person_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDigital_Profile_TypeArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Type_Order_By>>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};


export type Query_RootDigital_Profile_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Type_Order_By>>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};


export type Query_RootDigital_Profile_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEntity_To_Raw_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


export type Query_RootEntity_To_Raw_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


export type Query_RootEntity_To_Raw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLink_Confidence_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Link_Confidence_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Confidence_Category_Order_By>>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};


export type Query_RootLink_Confidence_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Confidence_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Confidence_Category_Order_By>>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};


export type Query_RootLink_Confidence_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLink_Precision_TypeArgs = {
  distinct_on?: InputMaybe<Array<Link_Precision_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Precision_Type_Order_By>>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};


export type Query_RootLink_Precision_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Precision_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Precision_Type_Order_By>>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};


export type Query_RootLink_Precision_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLink_Relationship_StatusArgs = {
  distinct_on?: InputMaybe<Array<Link_Relationship_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Relationship_Status_Order_By>>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};


export type Query_RootLink_Relationship_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Relationship_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Relationship_Status_Order_By>>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};


export type Query_RootLink_Relationship_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperationArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


export type Query_RootOperation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


export type Query_RootOperation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_Progress_StatusArgs = {
  distinct_on?: InputMaybe<Array<Operation_Progress_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Progress_Status_Order_By>>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};


export type Query_RootOperation_Progress_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Progress_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Progress_Status_Order_By>>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};


export type Query_RootOperation_Progress_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_Request_ItemArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


export type Query_RootOperation_Request_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


export type Query_RootOperation_Request_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_Request_Item_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Type_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};


export type Query_RootOperation_Request_Item_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Type_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};


export type Query_RootOperation_Request_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_StateArgs = {
  distinct_on?: InputMaybe<Array<Operation_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_State_Order_By>>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};


export type Query_RootOperation_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_State_Order_By>>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};


export type Query_RootOperation_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_Trigger_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Trigger_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Trigger_Type_Order_By>>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};


export type Query_RootOperation_Trigger_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Trigger_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Trigger_Type_Order_By>>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};


export type Query_RootOperation_Trigger_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOperation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Type_Order_By>>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};


export type Query_RootOperation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Type_Order_By>>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};


export type Query_RootOperation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutlook_Linkedin_CredentialArgs = {
  distinct_on?: InputMaybe<Array<Outlook_Linkedin_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outlook_Linkedin_Credential_Order_By>>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};


export type Query_RootOutlook_Linkedin_Credential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outlook_Linkedin_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outlook_Linkedin_Credential_Order_By>>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};


export type Query_RootOutlook_Linkedin_Credential_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Query_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Query_RootPerson_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPerson_GroupArgs = {
  distinct_on?: InputMaybe<Array<Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Group_Order_By>>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};


export type Query_RootPerson_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Group_Order_By>>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};


export type Query_RootPerson_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPerson_To_AddressArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


export type Query_RootPerson_To_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


export type Query_RootPerson_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPerson_To_Person_GroupArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


export type Query_RootPerson_To_Person_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


export type Query_RootPerson_To_Person_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPostal_CodeArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


export type Query_RootPostal_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


export type Query_RootPostal_Code_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Query_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Query_RootProvider_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRaw_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


export type Query_RootRaw_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


export type Query_RootRaw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRaw_Response_Formulation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Formulation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Formulation_Type_Order_By>>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};


export type Query_RootRaw_Response_Formulation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Formulation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Formulation_Type_Order_By>>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};


export type Query_RootRaw_Response_Formulation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRaw_Response_Search_ActionArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


export type Query_RootRaw_Response_Search_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


export type Query_RootRaw_Response_Search_Action_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


export type Query_RootState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


export type Query_RootState_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStated_Address_SourceArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


export type Query_RootStated_Address_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


export type Query_RootStated_Address_Source_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTracking_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


export type Query_RootTracking_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


export type Query_RootTracking_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTracking_Group_ItemArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


export type Query_RootTracking_Group_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


export type Query_RootTracking_Group_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTracking_Group_Item_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};


export type Query_RootTracking_Group_Item_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};


export type Query_RootTracking_Group_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTracking_Group_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};


export type Query_RootTracking_Group_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};


export type Query_RootTracking_Group_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootYoyo_LockArgs = {
  distinct_on?: InputMaybe<Array<Yoyo_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yoyo_Lock_Order_By>>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};


export type Query_RootYoyo_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yoyo_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yoyo_Lock_Order_By>>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};


export type Query_RootYoyo_Lock_By_PkArgs = {
  locked: Scalars['Int'];
};

/** columns and relationships of "raw_response" */
export type Raw_Response = {
  __typename?: 'raw_response';
  /** An object relationship */
  address?: Maybe<Address>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  digital_profile?: Maybe<Digital_Profile>;
  /** An array relationship */
  entity_to_raw_responses: Array<Entity_To_Raw_Response>;
  /** An aggregate relationship */
  entity_to_raw_responses_aggregate: Entity_To_Raw_Response_Aggregate;
  id: Scalars['Int'];
  input_address_id?: Maybe<Scalars['Int']>;
  input_digital_profile_id?: Maybe<Scalars['Int']>;
  input_person_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  people: Array<Person>;
  /** An aggregate relationship */
  people_aggregate: Person_Aggregate;
  /** An object relationship */
  person?: Maybe<Person>;
  /** An object relationship */
  raw_response_formulation_type: Raw_Response_Formulation_Type;
  raw_response_formulation_type_id: Scalars['Int'];
  /** An object relationship */
  raw_response_search_action: Raw_Response_Search_Action;
  raw_response_search_action_id: Scalars['Int'];
  request: Scalars['jsonb'];
  response: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "raw_response" */
export type Raw_ResponseEntity_To_Raw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "raw_response" */
export type Raw_ResponseEntity_To_Raw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


/** columns and relationships of "raw_response" */
export type Raw_ResponsePeopleArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "raw_response" */
export type Raw_ResponsePeople_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "raw_response" */
export type Raw_ResponseRequestArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "raw_response" */
export type Raw_ResponseResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "raw_response" */
export type Raw_Response_Aggregate = {
  __typename?: 'raw_response_aggregate';
  aggregate?: Maybe<Raw_Response_Aggregate_Fields>;
  nodes: Array<Raw_Response>;
};

export type Raw_Response_Aggregate_Bool_Exp = {
  count?: InputMaybe<Raw_Response_Aggregate_Bool_Exp_Count>;
};

export type Raw_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Raw_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Raw_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "raw_response" */
export type Raw_Response_Aggregate_Fields = {
  __typename?: 'raw_response_aggregate_fields';
  avg?: Maybe<Raw_Response_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raw_Response_Max_Fields>;
  min?: Maybe<Raw_Response_Min_Fields>;
  stddev?: Maybe<Raw_Response_Stddev_Fields>;
  stddev_pop?: Maybe<Raw_Response_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raw_Response_Stddev_Samp_Fields>;
  sum?: Maybe<Raw_Response_Sum_Fields>;
  var_pop?: Maybe<Raw_Response_Var_Pop_Fields>;
  var_samp?: Maybe<Raw_Response_Var_Samp_Fields>;
  variance?: Maybe<Raw_Response_Variance_Fields>;
};


/** aggregate fields of "raw_response" */
export type Raw_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "raw_response" */
export type Raw_Response_Aggregate_Order_By = {
  avg?: InputMaybe<Raw_Response_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Raw_Response_Max_Order_By>;
  min?: InputMaybe<Raw_Response_Min_Order_By>;
  stddev?: InputMaybe<Raw_Response_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Raw_Response_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Raw_Response_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Raw_Response_Sum_Order_By>;
  var_pop?: InputMaybe<Raw_Response_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Raw_Response_Var_Samp_Order_By>;
  variance?: InputMaybe<Raw_Response_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Raw_Response_Append_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "raw_response" */
export type Raw_Response_Arr_Rel_Insert_Input = {
  data: Array<Raw_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Raw_Response_On_Conflict>;
};

/** aggregate avg on columns */
export type Raw_Response_Avg_Fields = {
  __typename?: 'raw_response_avg_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "raw_response" */
export type Raw_Response_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "raw_response". All fields are combined with a logical 'AND'. */
export type Raw_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Response_Bool_Exp>>;
  _not?: InputMaybe<Raw_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Response_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  digital_profile?: InputMaybe<Digital_Profile_Bool_Exp>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  input_address_id?: InputMaybe<Int_Comparison_Exp>;
  input_digital_profile_id?: InputMaybe<Int_Comparison_Exp>;
  input_person_id?: InputMaybe<Int_Comparison_Exp>;
  people?: InputMaybe<Person_Bool_Exp>;
  people_aggregate?: InputMaybe<Person_Aggregate_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  raw_response_formulation_type?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
  raw_response_formulation_type_id?: InputMaybe<Int_Comparison_Exp>;
  raw_response_search_action?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
  raw_response_search_action_id?: InputMaybe<Int_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_response" */
export enum Raw_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawResponsePkey = 'raw_response_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Raw_Response_Delete_At_Path_Input = {
  request?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Raw_Response_Delete_Elem_Input = {
  request?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Raw_Response_Delete_Key_Input = {
  request?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type = {
  __typename?: 'raw_response_formulation_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  raw_responses: Array<Raw_Response>;
  /** An aggregate relationship */
  raw_responses_aggregate: Raw_Response_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "raw_response_formulation_type" */
export type Raw_Response_Formulation_TypeRaw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


/** columns and relationships of "raw_response_formulation_type" */
export type Raw_Response_Formulation_TypeRaw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** aggregated selection of "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Aggregate = {
  __typename?: 'raw_response_formulation_type_aggregate';
  aggregate?: Maybe<Raw_Response_Formulation_Type_Aggregate_Fields>;
  nodes: Array<Raw_Response_Formulation_Type>;
};

/** aggregate fields of "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Aggregate_Fields = {
  __typename?: 'raw_response_formulation_type_aggregate_fields';
  avg?: Maybe<Raw_Response_Formulation_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raw_Response_Formulation_Type_Max_Fields>;
  min?: Maybe<Raw_Response_Formulation_Type_Min_Fields>;
  stddev?: Maybe<Raw_Response_Formulation_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Raw_Response_Formulation_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raw_Response_Formulation_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Raw_Response_Formulation_Type_Sum_Fields>;
  var_pop?: Maybe<Raw_Response_Formulation_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Raw_Response_Formulation_Type_Var_Samp_Fields>;
  variance?: Maybe<Raw_Response_Formulation_Type_Variance_Fields>;
};


/** aggregate fields of "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Response_Formulation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Raw_Response_Formulation_Type_Avg_Fields = {
  __typename?: 'raw_response_formulation_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "raw_response_formulation_type". All fields are combined with a logical 'AND'. */
export type Raw_Response_Formulation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Response_Formulation_Type_Bool_Exp>>;
  _not?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Response_Formulation_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  raw_responses?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_response_formulation_type" */
export enum Raw_Response_Formulation_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  RawResponseFormulationTypeNameKey = 'raw_response_formulation_type_name_key',
  /** unique or primary key constraint on columns "id" */
  RawResponseFormulationTypePkey = 'raw_response_formulation_type_pkey'
}

/** input type for incrementing numeric columns in table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  raw_responses?: InputMaybe<Raw_Response_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Raw_Response_Formulation_Type_Max_Fields = {
  __typename?: 'raw_response_formulation_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Raw_Response_Formulation_Type_Min_Fields = {
  __typename?: 'raw_response_formulation_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Mutation_Response = {
  __typename?: 'raw_response_formulation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Response_Formulation_Type>;
};

/** input type for inserting object relation for remote table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Obj_Rel_Insert_Input = {
  data: Raw_Response_Formulation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Raw_Response_Formulation_Type_On_Conflict>;
};

/** on_conflict condition type for table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_On_Conflict = {
  constraint: Raw_Response_Formulation_Type_Constraint;
  update_columns?: Array<Raw_Response_Formulation_Type_Update_Column>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_response_formulation_type". */
export type Raw_Response_Formulation_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_response_formulation_type */
export type Raw_Response_Formulation_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "raw_response_formulation_type" */
export enum Raw_Response_Formulation_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Raw_Response_Formulation_Type_Stddev_Fields = {
  __typename?: 'raw_response_formulation_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Raw_Response_Formulation_Type_Stddev_Pop_Fields = {
  __typename?: 'raw_response_formulation_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Raw_Response_Formulation_Type_Stddev_Samp_Fields = {
  __typename?: 'raw_response_formulation_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "raw_response_formulation_type" */
export type Raw_Response_Formulation_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Response_Formulation_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Response_Formulation_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Raw_Response_Formulation_Type_Sum_Fields = {
  __typename?: 'raw_response_formulation_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "raw_response_formulation_type" */
export enum Raw_Response_Formulation_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Raw_Response_Formulation_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Raw_Response_Formulation_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Response_Formulation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Response_Formulation_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Raw_Response_Formulation_Type_Var_Pop_Fields = {
  __typename?: 'raw_response_formulation_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Raw_Response_Formulation_Type_Var_Samp_Fields = {
  __typename?: 'raw_response_formulation_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Raw_Response_Formulation_Type_Variance_Fields = {
  __typename?: 'raw_response_formulation_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "raw_response" */
export type Raw_Response_Inc_Input = {
  input_address_id?: InputMaybe<Scalars['Int']>;
  input_digital_profile_id?: InputMaybe<Scalars['Int']>;
  input_person_id?: InputMaybe<Scalars['Int']>;
  raw_response_formulation_type_id?: InputMaybe<Scalars['Int']>;
  raw_response_search_action_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "raw_response" */
export type Raw_Response_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_profile?: InputMaybe<Digital_Profile_Obj_Rel_Insert_Input>;
  entity_to_raw_responses?: InputMaybe<Entity_To_Raw_Response_Arr_Rel_Insert_Input>;
  input_address_id?: InputMaybe<Scalars['Int']>;
  input_digital_profile_id?: InputMaybe<Scalars['Int']>;
  input_person_id?: InputMaybe<Scalars['Int']>;
  people?: InputMaybe<Person_Arr_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  raw_response_formulation_type?: InputMaybe<Raw_Response_Formulation_Type_Obj_Rel_Insert_Input>;
  raw_response_formulation_type_id?: InputMaybe<Scalars['Int']>;
  raw_response_search_action?: InputMaybe<Raw_Response_Search_Action_Obj_Rel_Insert_Input>;
  raw_response_search_action_id?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Raw_Response_Max_Fields = {
  __typename?: 'raw_response_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  input_address_id?: Maybe<Scalars['Int']>;
  input_digital_profile_id?: Maybe<Scalars['Int']>;
  input_person_id?: Maybe<Scalars['Int']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Int']>;
  raw_response_search_action_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "raw_response" */
export type Raw_Response_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Raw_Response_Min_Fields = {
  __typename?: 'raw_response_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  input_address_id?: Maybe<Scalars['Int']>;
  input_digital_profile_id?: Maybe<Scalars['Int']>;
  input_person_id?: Maybe<Scalars['Int']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Int']>;
  raw_response_search_action_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "raw_response" */
export type Raw_Response_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "raw_response" */
export type Raw_Response_Mutation_Response = {
  __typename?: 'raw_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Response>;
};

/** input type for inserting object relation for remote table "raw_response" */
export type Raw_Response_Obj_Rel_Insert_Input = {
  data: Raw_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Raw_Response_On_Conflict>;
};

/** on_conflict condition type for table "raw_response" */
export type Raw_Response_On_Conflict = {
  constraint: Raw_Response_Constraint;
  update_columns?: Array<Raw_Response_Update_Column>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_response". */
export type Raw_Response_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  created_at?: InputMaybe<Order_By>;
  digital_profile?: InputMaybe<Digital_Profile_Order_By>;
  entity_to_raw_responses_aggregate?: InputMaybe<Entity_To_Raw_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  people_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  raw_response_formulation_type?: InputMaybe<Raw_Response_Formulation_Type_Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action?: InputMaybe<Raw_Response_Search_Action_Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_response */
export type Raw_Response_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Raw_Response_Prepend_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "raw_response_search_action" */
export type Raw_Response_Search_Action = {
  __typename?: 'raw_response_search_action';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  provider: Provider;
  provider_id: Scalars['Int'];
  /** An array relationship */
  raw_responses: Array<Raw_Response>;
  /** An aggregate relationship */
  raw_responses_aggregate: Raw_Response_Aggregate;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "raw_response_search_action" */
export type Raw_Response_Search_ActionRaw_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


/** columns and relationships of "raw_response_search_action" */
export type Raw_Response_Search_ActionRaw_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};

/** aggregated selection of "raw_response_search_action" */
export type Raw_Response_Search_Action_Aggregate = {
  __typename?: 'raw_response_search_action_aggregate';
  aggregate?: Maybe<Raw_Response_Search_Action_Aggregate_Fields>;
  nodes: Array<Raw_Response_Search_Action>;
};

export type Raw_Response_Search_Action_Aggregate_Bool_Exp = {
  count?: InputMaybe<Raw_Response_Search_Action_Aggregate_Bool_Exp_Count>;
};

export type Raw_Response_Search_Action_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "raw_response_search_action" */
export type Raw_Response_Search_Action_Aggregate_Fields = {
  __typename?: 'raw_response_search_action_aggregate_fields';
  avg?: Maybe<Raw_Response_Search_Action_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Raw_Response_Search_Action_Max_Fields>;
  min?: Maybe<Raw_Response_Search_Action_Min_Fields>;
  stddev?: Maybe<Raw_Response_Search_Action_Stddev_Fields>;
  stddev_pop?: Maybe<Raw_Response_Search_Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raw_Response_Search_Action_Stddev_Samp_Fields>;
  sum?: Maybe<Raw_Response_Search_Action_Sum_Fields>;
  var_pop?: Maybe<Raw_Response_Search_Action_Var_Pop_Fields>;
  var_samp?: Maybe<Raw_Response_Search_Action_Var_Samp_Fields>;
  variance?: Maybe<Raw_Response_Search_Action_Variance_Fields>;
};


/** aggregate fields of "raw_response_search_action" */
export type Raw_Response_Search_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Aggregate_Order_By = {
  avg?: InputMaybe<Raw_Response_Search_Action_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Raw_Response_Search_Action_Max_Order_By>;
  min?: InputMaybe<Raw_Response_Search_Action_Min_Order_By>;
  stddev?: InputMaybe<Raw_Response_Search_Action_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Raw_Response_Search_Action_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Raw_Response_Search_Action_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Raw_Response_Search_Action_Sum_Order_By>;
  var_pop?: InputMaybe<Raw_Response_Search_Action_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Raw_Response_Search_Action_Var_Samp_Order_By>;
  variance?: InputMaybe<Raw_Response_Search_Action_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "raw_response_search_action" */
export type Raw_Response_Search_Action_Arr_Rel_Insert_Input = {
  data: Array<Raw_Response_Search_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Raw_Response_Search_Action_On_Conflict>;
};

/** aggregate avg on columns */
export type Raw_Response_Search_Action_Avg_Fields = {
  __typename?: 'raw_response_search_action_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "raw_response_search_action". All fields are combined with a logical 'AND'. */
export type Raw_Response_Search_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Response_Search_Action_Bool_Exp>>;
  _not?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Response_Search_Action_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  raw_responses?: InputMaybe<Raw_Response_Bool_Exp>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_response_search_action" */
export enum Raw_Response_Search_Action_Constraint {
  /** unique or primary key constraint on columns "name" */
  RawResponseSearchActionNameKey = 'raw_response_search_action_name_key',
  /** unique or primary key constraint on columns "id" */
  RawResponseSearchActionPkey = 'raw_response_search_action_pkey'
}

/** input type for incrementing numeric columns in table "raw_response_search_action" */
export type Raw_Response_Search_Action_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "raw_response_search_action" */
export type Raw_Response_Search_Action_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  raw_responses?: InputMaybe<Raw_Response_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Raw_Response_Search_Action_Max_Fields = {
  __typename?: 'raw_response_search_action_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Raw_Response_Search_Action_Min_Fields = {
  __typename?: 'raw_response_search_action_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "raw_response_search_action" */
export type Raw_Response_Search_Action_Mutation_Response = {
  __typename?: 'raw_response_search_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Response_Search_Action>;
};

/** input type for inserting object relation for remote table "raw_response_search_action" */
export type Raw_Response_Search_Action_Obj_Rel_Insert_Input = {
  data: Raw_Response_Search_Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Raw_Response_Search_Action_On_Conflict>;
};

/** on_conflict condition type for table "raw_response_search_action" */
export type Raw_Response_Search_Action_On_Conflict = {
  constraint: Raw_Response_Search_Action_Constraint;
  update_columns?: Array<Raw_Response_Search_Action_Update_Column>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_response_search_action". */
export type Raw_Response_Search_Action_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  raw_responses_aggregate?: InputMaybe<Raw_Response_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_response_search_action */
export type Raw_Response_Search_Action_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "raw_response_search_action" */
export enum Raw_Response_Search_Action_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "raw_response_search_action" */
export type Raw_Response_Search_Action_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Raw_Response_Search_Action_Stddev_Fields = {
  __typename?: 'raw_response_search_action_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Raw_Response_Search_Action_Stddev_Pop_Fields = {
  __typename?: 'raw_response_search_action_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Raw_Response_Search_Action_Stddev_Samp_Fields = {
  __typename?: 'raw_response_search_action_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "raw_response_search_action" */
export type Raw_Response_Search_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Response_Search_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Response_Search_Action_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Raw_Response_Search_Action_Sum_Fields = {
  __typename?: 'raw_response_search_action_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "raw_response_search_action" */
export enum Raw_Response_Search_Action_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Raw_Response_Search_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Raw_Response_Search_Action_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Response_Search_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Response_Search_Action_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Raw_Response_Search_Action_Var_Pop_Fields = {
  __typename?: 'raw_response_search_action_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Raw_Response_Search_Action_Var_Samp_Fields = {
  __typename?: 'raw_response_search_action_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Raw_Response_Search_Action_Variance_Fields = {
  __typename?: 'raw_response_search_action_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "raw_response_search_action" */
export type Raw_Response_Search_Action_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** select columns of table "raw_response" */
export enum Raw_Response_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InputAddressId = 'input_address_id',
  /** column name */
  InputDigitalProfileId = 'input_digital_profile_id',
  /** column name */
  InputPersonId = 'input_person_id',
  /** column name */
  RawResponseFormulationTypeId = 'raw_response_formulation_type_id',
  /** column name */
  RawResponseSearchActionId = 'raw_response_search_action_id',
  /** column name */
  Request = 'request',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "raw_response" */
export type Raw_Response_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  input_address_id?: InputMaybe<Scalars['Int']>;
  input_digital_profile_id?: InputMaybe<Scalars['Int']>;
  input_person_id?: InputMaybe<Scalars['Int']>;
  raw_response_formulation_type_id?: InputMaybe<Scalars['Int']>;
  raw_response_search_action_id?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Raw_Response_Stddev_Fields = {
  __typename?: 'raw_response_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "raw_response" */
export type Raw_Response_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Raw_Response_Stddev_Pop_Fields = {
  __typename?: 'raw_response_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "raw_response" */
export type Raw_Response_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Raw_Response_Stddev_Samp_Fields = {
  __typename?: 'raw_response_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "raw_response" */
export type Raw_Response_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "raw_response" */
export type Raw_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Response_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  input_address_id?: InputMaybe<Scalars['Int']>;
  input_digital_profile_id?: InputMaybe<Scalars['Int']>;
  input_person_id?: InputMaybe<Scalars['Int']>;
  raw_response_formulation_type_id?: InputMaybe<Scalars['Int']>;
  raw_response_search_action_id?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Raw_Response_Sum_Fields = {
  __typename?: 'raw_response_sum_fields';
  id?: Maybe<Scalars['Int']>;
  input_address_id?: Maybe<Scalars['Int']>;
  input_digital_profile_id?: Maybe<Scalars['Int']>;
  input_person_id?: Maybe<Scalars['Int']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Int']>;
  raw_response_search_action_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "raw_response" */
export type Raw_Response_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** update columns of table "raw_response" */
export enum Raw_Response_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InputAddressId = 'input_address_id',
  /** column name */
  InputDigitalProfileId = 'input_digital_profile_id',
  /** column name */
  InputPersonId = 'input_person_id',
  /** column name */
  RawResponseFormulationTypeId = 'raw_response_formulation_type_id',
  /** column name */
  RawResponseSearchActionId = 'raw_response_search_action_id',
  /** column name */
  Request = 'request',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Raw_Response_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Raw_Response_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Raw_Response_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Raw_Response_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Raw_Response_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Raw_Response_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Raw_Response_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Response_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Raw_Response_Var_Pop_Fields = {
  __typename?: 'raw_response_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "raw_response" */
export type Raw_Response_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Raw_Response_Var_Samp_Fields = {
  __typename?: 'raw_response_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "raw_response" */
export type Raw_Response_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Raw_Response_Variance_Fields = {
  __typename?: 'raw_response_variance_fields';
  id?: Maybe<Scalars['Float']>;
  input_address_id?: Maybe<Scalars['Float']>;
  input_digital_profile_id?: Maybe<Scalars['Float']>;
  input_person_id?: Maybe<Scalars['Float']>;
  raw_response_formulation_type_id?: Maybe<Scalars['Float']>;
  raw_response_search_action_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "raw_response" */
export type Raw_Response_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  input_address_id?: InputMaybe<Order_By>;
  input_digital_profile_id?: InputMaybe<Order_By>;
  input_person_id?: InputMaybe<Order_By>;
  raw_response_formulation_type_id?: InputMaybe<Order_By>;
  raw_response_search_action_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "state" */
export type State = {
  __typename?: 'state';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  code: Scalars['String'];
  /** An object relationship */
  country: Country;
  country_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  provider?: Maybe<Provider>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "state" */
export type StateAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "state" */
export type StateAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** aggregated selection of "state" */
export type State_Aggregate = {
  __typename?: 'state_aggregate';
  aggregate?: Maybe<State_Aggregate_Fields>;
  nodes: Array<State>;
};

export type State_Aggregate_Bool_Exp = {
  count?: InputMaybe<State_Aggregate_Bool_Exp_Count>;
};

export type State_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<State_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "state" */
export type State_Aggregate_Fields = {
  __typename?: 'state_aggregate_fields';
  avg?: Maybe<State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<State_Max_Fields>;
  min?: Maybe<State_Min_Fields>;
  stddev?: Maybe<State_Stddev_Fields>;
  stddev_pop?: Maybe<State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<State_Stddev_Samp_Fields>;
  sum?: Maybe<State_Sum_Fields>;
  var_pop?: Maybe<State_Var_Pop_Fields>;
  var_samp?: Maybe<State_Var_Samp_Fields>;
  variance?: Maybe<State_Variance_Fields>;
};


/** aggregate fields of "state" */
export type State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "state" */
export type State_Aggregate_Order_By = {
  avg?: InputMaybe<State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<State_Max_Order_By>;
  min?: InputMaybe<State_Min_Order_By>;
  stddev?: InputMaybe<State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<State_Sum_Order_By>;
  var_pop?: InputMaybe<State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<State_Var_Samp_Order_By>;
  variance?: InputMaybe<State_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "state" */
export type State_Arr_Rel_Insert_Input = {
  data: Array<State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<State_On_Conflict>;
};

/** aggregate avg on columns */
export type State_Avg_Fields = {
  __typename?: 'state_avg_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "state" */
export type State_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "state". All fields are combined with a logical 'AND'. */
export type State_Bool_Exp = {
  _and?: InputMaybe<Array<State_Bool_Exp>>;
  _not?: InputMaybe<State_Bool_Exp>;
  _or?: InputMaybe<Array<State_Bool_Exp>>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "state" */
export enum State_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatePkey = 'state_pkey',
  /** unique or primary key constraint on columns "code", "name", "country_id" */
  UqStateNameCodeCountry = 'uq_state_name_code_country'
}

/** input type for incrementing numeric columns in table "state" */
export type State_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "state" */
export type State_Insert_Input = {
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type State_Max_Fields = {
  __typename?: 'state_max_fields';
  code?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "state" */
export type State_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type State_Min_Fields = {
  __typename?: 'state_min_fields';
  code?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "state" */
export type State_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "state" */
export type State_Mutation_Response = {
  __typename?: 'state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<State>;
};

/** input type for inserting object relation for remote table "state" */
export type State_Obj_Rel_Insert_Input = {
  data: State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<State_On_Conflict>;
};

/** on_conflict condition type for table "state" */
export type State_On_Conflict = {
  constraint: State_Constraint;
  update_columns?: Array<State_Update_Column>;
  where?: InputMaybe<State_Bool_Exp>;
};

/** Ordering options when selecting data from "state". */
export type State_Order_By = {
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  code?: InputMaybe<Order_By>;
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: state */
export type State_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "state" */
export enum State_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "state" */
export type State_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type State_Stddev_Fields = {
  __typename?: 'state_stddev_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "state" */
export type State_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type State_Stddev_Pop_Fields = {
  __typename?: 'state_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "state" */
export type State_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type State_Stddev_Samp_Fields = {
  __typename?: 'state_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "state" */
export type State_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "state" */
export type State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type State_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type State_Sum_Fields = {
  __typename?: 'state_sum_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "state" */
export type State_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "state" */
export enum State_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type State_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<State_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<State_Set_Input>;
  /** filter the rows which have to be updated */
  where: State_Bool_Exp;
};

/** aggregate var_pop on columns */
export type State_Var_Pop_Fields = {
  __typename?: 'state_var_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "state" */
export type State_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type State_Var_Samp_Fields = {
  __typename?: 'state_var_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "state" */
export type State_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type State_Variance_Fields = {
  __typename?: 'state_variance_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "state" */
export type State_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "stated_address_source" */
export type Stated_Address_Source = {
  __typename?: 'stated_address_source';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  people: Array<Person>;
  /** An aggregate relationship */
  people_aggregate: Person_Aggregate;
  /** An object relationship */
  provider: Provider;
  provider_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "stated_address_source" */
export type Stated_Address_SourcePeopleArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "stated_address_source" */
export type Stated_Address_SourcePeople_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** aggregated selection of "stated_address_source" */
export type Stated_Address_Source_Aggregate = {
  __typename?: 'stated_address_source_aggregate';
  aggregate?: Maybe<Stated_Address_Source_Aggregate_Fields>;
  nodes: Array<Stated_Address_Source>;
};

export type Stated_Address_Source_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stated_Address_Source_Aggregate_Bool_Exp_Count>;
};

export type Stated_Address_Source_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Stated_Address_Source_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stated_address_source" */
export type Stated_Address_Source_Aggregate_Fields = {
  __typename?: 'stated_address_source_aggregate_fields';
  avg?: Maybe<Stated_Address_Source_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stated_Address_Source_Max_Fields>;
  min?: Maybe<Stated_Address_Source_Min_Fields>;
  stddev?: Maybe<Stated_Address_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Stated_Address_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stated_Address_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Stated_Address_Source_Sum_Fields>;
  var_pop?: Maybe<Stated_Address_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Stated_Address_Source_Var_Samp_Fields>;
  variance?: Maybe<Stated_Address_Source_Variance_Fields>;
};


/** aggregate fields of "stated_address_source" */
export type Stated_Address_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stated_address_source" */
export type Stated_Address_Source_Aggregate_Order_By = {
  avg?: InputMaybe<Stated_Address_Source_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stated_Address_Source_Max_Order_By>;
  min?: InputMaybe<Stated_Address_Source_Min_Order_By>;
  stddev?: InputMaybe<Stated_Address_Source_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stated_Address_Source_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stated_Address_Source_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stated_Address_Source_Sum_Order_By>;
  var_pop?: InputMaybe<Stated_Address_Source_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stated_Address_Source_Var_Samp_Order_By>;
  variance?: InputMaybe<Stated_Address_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stated_address_source" */
export type Stated_Address_Source_Arr_Rel_Insert_Input = {
  data: Array<Stated_Address_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stated_Address_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Stated_Address_Source_Avg_Fields = {
  __typename?: 'stated_address_source_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stated_address_source" */
export type Stated_Address_Source_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stated_address_source". All fields are combined with a logical 'AND'. */
export type Stated_Address_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Stated_Address_Source_Bool_Exp>>;
  _not?: InputMaybe<Stated_Address_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Stated_Address_Source_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  people?: InputMaybe<Person_Bool_Exp>;
  people_aggregate?: InputMaybe<Person_Aggregate_Bool_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stated_address_source" */
export enum Stated_Address_Source_Constraint {
  /** unique or primary key constraint on columns "name" */
  StatedAddressSourceNameKey = 'stated_address_source_name_key',
  /** unique or primary key constraint on columns "id" */
  StatedAddressSourcePkey = 'stated_address_source_pkey'
}

/** input type for incrementing numeric columns in table "stated_address_source" */
export type Stated_Address_Source_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stated_address_source" */
export type Stated_Address_Source_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  people?: InputMaybe<Person_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stated_Address_Source_Max_Fields = {
  __typename?: 'stated_address_source_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stated_address_source" */
export type Stated_Address_Source_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stated_Address_Source_Min_Fields = {
  __typename?: 'stated_address_source_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stated_address_source" */
export type Stated_Address_Source_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stated_address_source" */
export type Stated_Address_Source_Mutation_Response = {
  __typename?: 'stated_address_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stated_Address_Source>;
};

/** input type for inserting object relation for remote table "stated_address_source" */
export type Stated_Address_Source_Obj_Rel_Insert_Input = {
  data: Stated_Address_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stated_Address_Source_On_Conflict>;
};

/** on_conflict condition type for table "stated_address_source" */
export type Stated_Address_Source_On_Conflict = {
  constraint: Stated_Address_Source_Constraint;
  update_columns?: Array<Stated_Address_Source_Update_Column>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "stated_address_source". */
export type Stated_Address_Source_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  people_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stated_address_source */
export type Stated_Address_Source_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stated_address_source" */
export enum Stated_Address_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stated_address_source" */
export type Stated_Address_Source_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stated_Address_Source_Stddev_Fields = {
  __typename?: 'stated_address_source_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stated_address_source" */
export type Stated_Address_Source_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stated_Address_Source_Stddev_Pop_Fields = {
  __typename?: 'stated_address_source_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stated_address_source" */
export type Stated_Address_Source_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stated_Address_Source_Stddev_Samp_Fields = {
  __typename?: 'stated_address_source_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stated_address_source" */
export type Stated_Address_Source_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stated_address_source" */
export type Stated_Address_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stated_Address_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stated_Address_Source_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Stated_Address_Source_Sum_Fields = {
  __typename?: 'stated_address_source_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stated_address_source" */
export type Stated_Address_Source_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** update columns of table "stated_address_source" */
export enum Stated_Address_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stated_Address_Source_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stated_Address_Source_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stated_Address_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stated_Address_Source_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stated_Address_Source_Var_Pop_Fields = {
  __typename?: 'stated_address_source_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stated_address_source" */
export type Stated_Address_Source_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stated_Address_Source_Var_Samp_Fields = {
  __typename?: 'stated_address_source_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stated_address_source" */
export type Stated_Address_Source_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stated_Address_Source_Variance_Fields = {
  __typename?: 'stated_address_source_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stated_address_source" */
export type Stated_Address_Source_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "_yoyo_log" */
  _yoyo_log: Array<_Yoyo_Log>;
  /** fetch aggregated fields from the table: "_yoyo_log" */
  _yoyo_log_aggregate: _Yoyo_Log_Aggregate;
  /** fetch data from the table: "_yoyo_log" using primary key columns */
  _yoyo_log_by_pk?: Maybe<_Yoyo_Log>;
  /** fetch data from the table in a streaming manner: "_yoyo_log" */
  _yoyo_log_stream: Array<_Yoyo_Log>;
  /** fetch data from the table: "_yoyo_migration" */
  _yoyo_migration: Array<_Yoyo_Migration>;
  /** fetch aggregated fields from the table: "_yoyo_migration" */
  _yoyo_migration_aggregate: _Yoyo_Migration_Aggregate;
  /** fetch data from the table: "_yoyo_migration" using primary key columns */
  _yoyo_migration_by_pk?: Maybe<_Yoyo_Migration>;
  /** fetch data from the table in a streaming manner: "_yoyo_migration" */
  _yoyo_migration_stream: Array<_Yoyo_Migration>;
  /** fetch data from the table: "_yoyo_version" */
  _yoyo_version: Array<_Yoyo_Version>;
  /** fetch aggregated fields from the table: "_yoyo_version" */
  _yoyo_version_aggregate: _Yoyo_Version_Aggregate;
  /** fetch data from the table: "_yoyo_version" using primary key columns */
  _yoyo_version_by_pk?: Maybe<_Yoyo_Version>;
  /** fetch data from the table in a streaming manner: "_yoyo_version" */
  _yoyo_version_stream: Array<_Yoyo_Version>;
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "attribute" */
  attribute: Array<Attribute>;
  /** fetch aggregated fields from the table: "attribute" */
  attribute_aggregate: Attribute_Aggregate;
  /** fetch data from the table: "attribute" using primary key columns */
  attribute_by_pk?: Maybe<Attribute>;
  /** fetch data from the table in a streaming manner: "attribute" */
  attribute_stream: Array<Attribute>;
  /** fetch data from the table: "attribute_type" */
  attribute_type: Array<Attribute_Type>;
  /** fetch aggregated fields from the table: "attribute_type" */
  attribute_type_aggregate: Attribute_Type_Aggregate;
  /** fetch data from the table: "attribute_type" using primary key columns */
  attribute_type_by_pk?: Maybe<Attribute_Type>;
  /** fetch data from the table in a streaming manner: "attribute_type" */
  attribute_type_stream: Array<Attribute_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "digital_profile" */
  digital_profile: Array<Digital_Profile>;
  /** fetch aggregated fields from the table: "digital_profile" */
  digital_profile_aggregate: Digital_Profile_Aggregate;
  /** fetch data from the table: "digital_profile" using primary key columns */
  digital_profile_by_pk?: Maybe<Digital_Profile>;
  /** fetch data from the table in a streaming manner: "digital_profile" */
  digital_profile_stream: Array<Digital_Profile>;
  /** fetch data from the table: "digital_profile_to_address" */
  digital_profile_to_address: Array<Digital_Profile_To_Address>;
  /** fetch aggregated fields from the table: "digital_profile_to_address" */
  digital_profile_to_address_aggregate: Digital_Profile_To_Address_Aggregate;
  /** fetch data from the table: "digital_profile_to_address" using primary key columns */
  digital_profile_to_address_by_pk?: Maybe<Digital_Profile_To_Address>;
  /** fetch data from the table in a streaming manner: "digital_profile_to_address" */
  digital_profile_to_address_stream: Array<Digital_Profile_To_Address>;
  /** fetch data from the table: "digital_profile_to_person" */
  digital_profile_to_person: Array<Digital_Profile_To_Person>;
  /** fetch aggregated fields from the table: "digital_profile_to_person" */
  digital_profile_to_person_aggregate: Digital_Profile_To_Person_Aggregate;
  /** fetch data from the table: "digital_profile_to_person" using primary key columns */
  digital_profile_to_person_by_pk?: Maybe<Digital_Profile_To_Person>;
  /** fetch data from the table in a streaming manner: "digital_profile_to_person" */
  digital_profile_to_person_stream: Array<Digital_Profile_To_Person>;
  /** fetch data from the table: "digital_profile_type" */
  digital_profile_type: Array<Digital_Profile_Type>;
  /** fetch aggregated fields from the table: "digital_profile_type" */
  digital_profile_type_aggregate: Digital_Profile_Type_Aggregate;
  /** fetch data from the table: "digital_profile_type" using primary key columns */
  digital_profile_type_by_pk?: Maybe<Digital_Profile_Type>;
  /** fetch data from the table in a streaming manner: "digital_profile_type" */
  digital_profile_type_stream: Array<Digital_Profile_Type>;
  /** fetch data from the table: "entity_to_raw_response" */
  entity_to_raw_response: Array<Entity_To_Raw_Response>;
  /** fetch aggregated fields from the table: "entity_to_raw_response" */
  entity_to_raw_response_aggregate: Entity_To_Raw_Response_Aggregate;
  /** fetch data from the table: "entity_to_raw_response" using primary key columns */
  entity_to_raw_response_by_pk?: Maybe<Entity_To_Raw_Response>;
  /** fetch data from the table in a streaming manner: "entity_to_raw_response" */
  entity_to_raw_response_stream: Array<Entity_To_Raw_Response>;
  /** fetch data from the table: "link_confidence_category" */
  link_confidence_category: Array<Link_Confidence_Category>;
  /** fetch aggregated fields from the table: "link_confidence_category" */
  link_confidence_category_aggregate: Link_Confidence_Category_Aggregate;
  /** fetch data from the table: "link_confidence_category" using primary key columns */
  link_confidence_category_by_pk?: Maybe<Link_Confidence_Category>;
  /** fetch data from the table in a streaming manner: "link_confidence_category" */
  link_confidence_category_stream: Array<Link_Confidence_Category>;
  /** fetch data from the table: "link_precision_type" */
  link_precision_type: Array<Link_Precision_Type>;
  /** fetch aggregated fields from the table: "link_precision_type" */
  link_precision_type_aggregate: Link_Precision_Type_Aggregate;
  /** fetch data from the table: "link_precision_type" using primary key columns */
  link_precision_type_by_pk?: Maybe<Link_Precision_Type>;
  /** fetch data from the table in a streaming manner: "link_precision_type" */
  link_precision_type_stream: Array<Link_Precision_Type>;
  /** fetch data from the table: "link_relationship_status" */
  link_relationship_status: Array<Link_Relationship_Status>;
  /** fetch aggregated fields from the table: "link_relationship_status" */
  link_relationship_status_aggregate: Link_Relationship_Status_Aggregate;
  /** fetch data from the table: "link_relationship_status" using primary key columns */
  link_relationship_status_by_pk?: Maybe<Link_Relationship_Status>;
  /** fetch data from the table in a streaming manner: "link_relationship_status" */
  link_relationship_status_stream: Array<Link_Relationship_Status>;
  /** fetch data from the table: "operation" */
  operation: Array<Operation>;
  /** fetch aggregated fields from the table: "operation" */
  operation_aggregate: Operation_Aggregate;
  /** fetch data from the table: "operation" using primary key columns */
  operation_by_pk?: Maybe<Operation>;
  /** fetch data from the table: "operation_progress_status" */
  operation_progress_status: Array<Operation_Progress_Status>;
  /** fetch aggregated fields from the table: "operation_progress_status" */
  operation_progress_status_aggregate: Operation_Progress_Status_Aggregate;
  /** fetch data from the table: "operation_progress_status" using primary key columns */
  operation_progress_status_by_pk?: Maybe<Operation_Progress_Status>;
  /** fetch data from the table in a streaming manner: "operation_progress_status" */
  operation_progress_status_stream: Array<Operation_Progress_Status>;
  /** fetch data from the table: "operation_request_item" */
  operation_request_item: Array<Operation_Request_Item>;
  /** fetch aggregated fields from the table: "operation_request_item" */
  operation_request_item_aggregate: Operation_Request_Item_Aggregate;
  /** fetch data from the table: "operation_request_item" using primary key columns */
  operation_request_item_by_pk?: Maybe<Operation_Request_Item>;
  /** fetch data from the table in a streaming manner: "operation_request_item" */
  operation_request_item_stream: Array<Operation_Request_Item>;
  /** fetch data from the table: "operation_request_item_type" */
  operation_request_item_type: Array<Operation_Request_Item_Type>;
  /** fetch aggregated fields from the table: "operation_request_item_type" */
  operation_request_item_type_aggregate: Operation_Request_Item_Type_Aggregate;
  /** fetch data from the table: "operation_request_item_type" using primary key columns */
  operation_request_item_type_by_pk?: Maybe<Operation_Request_Item_Type>;
  /** fetch data from the table in a streaming manner: "operation_request_item_type" */
  operation_request_item_type_stream: Array<Operation_Request_Item_Type>;
  /** fetch data from the table: "operation_state" */
  operation_state: Array<Operation_State>;
  /** fetch aggregated fields from the table: "operation_state" */
  operation_state_aggregate: Operation_State_Aggregate;
  /** fetch data from the table: "operation_state" using primary key columns */
  operation_state_by_pk?: Maybe<Operation_State>;
  /** fetch data from the table in a streaming manner: "operation_state" */
  operation_state_stream: Array<Operation_State>;
  /** fetch data from the table in a streaming manner: "operation" */
  operation_stream: Array<Operation>;
  /** fetch data from the table: "operation_trigger_type" */
  operation_trigger_type: Array<Operation_Trigger_Type>;
  /** fetch aggregated fields from the table: "operation_trigger_type" */
  operation_trigger_type_aggregate: Operation_Trigger_Type_Aggregate;
  /** fetch data from the table: "operation_trigger_type" using primary key columns */
  operation_trigger_type_by_pk?: Maybe<Operation_Trigger_Type>;
  /** fetch data from the table in a streaming manner: "operation_trigger_type" */
  operation_trigger_type_stream: Array<Operation_Trigger_Type>;
  /** fetch data from the table: "operation_type" */
  operation_type: Array<Operation_Type>;
  /** fetch aggregated fields from the table: "operation_type" */
  operation_type_aggregate: Operation_Type_Aggregate;
  /** fetch data from the table: "operation_type" using primary key columns */
  operation_type_by_pk?: Maybe<Operation_Type>;
  /** fetch data from the table in a streaming manner: "operation_type" */
  operation_type_stream: Array<Operation_Type>;
  /** fetch data from the table: "outlook_linkedin_credential" */
  outlook_linkedin_credential: Array<Outlook_Linkedin_Credential>;
  /** fetch aggregated fields from the table: "outlook_linkedin_credential" */
  outlook_linkedin_credential_aggregate: Outlook_Linkedin_Credential_Aggregate;
  /** fetch data from the table: "outlook_linkedin_credential" using primary key columns */
  outlook_linkedin_credential_by_pk?: Maybe<Outlook_Linkedin_Credential>;
  /** fetch data from the table in a streaming manner: "outlook_linkedin_credential" */
  outlook_linkedin_credential_stream: Array<Outlook_Linkedin_Credential>;
  /** fetch data from the table: "person" */
  person: Array<Person>;
  /** fetch aggregated fields from the table: "person" */
  person_aggregate: Person_Aggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk?: Maybe<Person>;
  /** fetch data from the table: "person_group" */
  person_group: Array<Person_Group>;
  /** fetch aggregated fields from the table: "person_group" */
  person_group_aggregate: Person_Group_Aggregate;
  /** fetch data from the table: "person_group" using primary key columns */
  person_group_by_pk?: Maybe<Person_Group>;
  /** fetch data from the table in a streaming manner: "person_group" */
  person_group_stream: Array<Person_Group>;
  /** fetch data from the table in a streaming manner: "person" */
  person_stream: Array<Person>;
  /** fetch data from the table: "person_to_address" */
  person_to_address: Array<Person_To_Address>;
  /** fetch aggregated fields from the table: "person_to_address" */
  person_to_address_aggregate: Person_To_Address_Aggregate;
  /** fetch data from the table: "person_to_address" using primary key columns */
  person_to_address_by_pk?: Maybe<Person_To_Address>;
  /** fetch data from the table in a streaming manner: "person_to_address" */
  person_to_address_stream: Array<Person_To_Address>;
  /** fetch data from the table: "person_to_person_group" */
  person_to_person_group: Array<Person_To_Person_Group>;
  /** fetch aggregated fields from the table: "person_to_person_group" */
  person_to_person_group_aggregate: Person_To_Person_Group_Aggregate;
  /** fetch data from the table: "person_to_person_group" using primary key columns */
  person_to_person_group_by_pk?: Maybe<Person_To_Person_Group>;
  /** fetch data from the table in a streaming manner: "person_to_person_group" */
  person_to_person_group_stream: Array<Person_To_Person_Group>;
  /** fetch data from the table: "postal_code" */
  postal_code: Array<Postal_Code>;
  /** fetch aggregated fields from the table: "postal_code" */
  postal_code_aggregate: Postal_Code_Aggregate;
  /** fetch data from the table: "postal_code" using primary key columns */
  postal_code_by_pk?: Maybe<Postal_Code>;
  /** fetch data from the table in a streaming manner: "postal_code" */
  postal_code_stream: Array<Postal_Code>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table in a streaming manner: "provider" */
  provider_stream: Array<Provider>;
  /** fetch data from the table: "raw_response" */
  raw_response: Array<Raw_Response>;
  /** fetch aggregated fields from the table: "raw_response" */
  raw_response_aggregate: Raw_Response_Aggregate;
  /** fetch data from the table: "raw_response" using primary key columns */
  raw_response_by_pk?: Maybe<Raw_Response>;
  /** fetch data from the table: "raw_response_formulation_type" */
  raw_response_formulation_type: Array<Raw_Response_Formulation_Type>;
  /** fetch aggregated fields from the table: "raw_response_formulation_type" */
  raw_response_formulation_type_aggregate: Raw_Response_Formulation_Type_Aggregate;
  /** fetch data from the table: "raw_response_formulation_type" using primary key columns */
  raw_response_formulation_type_by_pk?: Maybe<Raw_Response_Formulation_Type>;
  /** fetch data from the table in a streaming manner: "raw_response_formulation_type" */
  raw_response_formulation_type_stream: Array<Raw_Response_Formulation_Type>;
  /** fetch data from the table: "raw_response_search_action" */
  raw_response_search_action: Array<Raw_Response_Search_Action>;
  /** fetch aggregated fields from the table: "raw_response_search_action" */
  raw_response_search_action_aggregate: Raw_Response_Search_Action_Aggregate;
  /** fetch data from the table: "raw_response_search_action" using primary key columns */
  raw_response_search_action_by_pk?: Maybe<Raw_Response_Search_Action>;
  /** fetch data from the table in a streaming manner: "raw_response_search_action" */
  raw_response_search_action_stream: Array<Raw_Response_Search_Action>;
  /** fetch data from the table in a streaming manner: "raw_response" */
  raw_response_stream: Array<Raw_Response>;
  /** fetch data from the table: "state" */
  state: Array<State>;
  /** fetch aggregated fields from the table: "state" */
  state_aggregate: State_Aggregate;
  /** fetch data from the table: "state" using primary key columns */
  state_by_pk?: Maybe<State>;
  /** fetch data from the table in a streaming manner: "state" */
  state_stream: Array<State>;
  /** fetch data from the table: "stated_address_source" */
  stated_address_source: Array<Stated_Address_Source>;
  /** fetch aggregated fields from the table: "stated_address_source" */
  stated_address_source_aggregate: Stated_Address_Source_Aggregate;
  /** fetch data from the table: "stated_address_source" using primary key columns */
  stated_address_source_by_pk?: Maybe<Stated_Address_Source>;
  /** fetch data from the table in a streaming manner: "stated_address_source" */
  stated_address_source_stream: Array<Stated_Address_Source>;
  /** fetch data from the table: "tracking_group" */
  tracking_group: Array<Tracking_Group>;
  /** fetch aggregated fields from the table: "tracking_group" */
  tracking_group_aggregate: Tracking_Group_Aggregate;
  /** fetch data from the table: "tracking_group" using primary key columns */
  tracking_group_by_pk?: Maybe<Tracking_Group>;
  /** fetch data from the table: "tracking_group_item" */
  tracking_group_item: Array<Tracking_Group_Item>;
  /** fetch aggregated fields from the table: "tracking_group_item" */
  tracking_group_item_aggregate: Tracking_Group_Item_Aggregate;
  /** fetch data from the table: "tracking_group_item" using primary key columns */
  tracking_group_item_by_pk?: Maybe<Tracking_Group_Item>;
  /** fetch data from the table in a streaming manner: "tracking_group_item" */
  tracking_group_item_stream: Array<Tracking_Group_Item>;
  /** fetch data from the table: "tracking_group_item_type" */
  tracking_group_item_type: Array<Tracking_Group_Item_Type>;
  /** fetch aggregated fields from the table: "tracking_group_item_type" */
  tracking_group_item_type_aggregate: Tracking_Group_Item_Type_Aggregate;
  /** fetch data from the table: "tracking_group_item_type" using primary key columns */
  tracking_group_item_type_by_pk?: Maybe<Tracking_Group_Item_Type>;
  /** fetch data from the table in a streaming manner: "tracking_group_item_type" */
  tracking_group_item_type_stream: Array<Tracking_Group_Item_Type>;
  /** fetch data from the table in a streaming manner: "tracking_group" */
  tracking_group_stream: Array<Tracking_Group>;
  /** fetch data from the table: "tracking_group_type" */
  tracking_group_type: Array<Tracking_Group_Type>;
  /** fetch aggregated fields from the table: "tracking_group_type" */
  tracking_group_type_aggregate: Tracking_Group_Type_Aggregate;
  /** fetch data from the table: "tracking_group_type" using primary key columns */
  tracking_group_type_by_pk?: Maybe<Tracking_Group_Type>;
  /** fetch data from the table in a streaming manner: "tracking_group_type" */
  tracking_group_type_stream: Array<Tracking_Group_Type>;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
  /** fetch data from the table in a streaming manner: "value_type" */
  value_type_stream: Array<Value_Type>;
  /** fetch data from the table: "yoyo_lock" */
  yoyo_lock: Array<Yoyo_Lock>;
  /** fetch aggregated fields from the table: "yoyo_lock" */
  yoyo_lock_aggregate: Yoyo_Lock_Aggregate;
  /** fetch data from the table: "yoyo_lock" using primary key columns */
  yoyo_lock_by_pk?: Maybe<Yoyo_Lock>;
  /** fetch data from the table in a streaming manner: "yoyo_lock" */
  yoyo_lock_stream: Array<Yoyo_Lock>;
};


export type Subscription_Root_Yoyo_LogArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Log_Order_By>>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Log_Order_By>>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Log_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_Root_Yoyo_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<_Yoyo_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<_Yoyo_Log_Bool_Exp>;
};


export type Subscription_Root_Yoyo_MigrationArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Migration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Migration_Order_By>>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Migration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Migration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Migration_Order_By>>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Migration_By_PkArgs = {
  migration_hash: Scalars['String'];
};


export type Subscription_Root_Yoyo_Migration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<_Yoyo_Migration_Stream_Cursor_Input>>;
  where?: InputMaybe<_Yoyo_Migration_Bool_Exp>;
};


export type Subscription_Root_Yoyo_VersionArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Version_Order_By>>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Yoyo_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Yoyo_Version_Order_By>>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};


export type Subscription_Root_Yoyo_Version_By_PkArgs = {
  version: Scalars['Int'];
};


export type Subscription_Root_Yoyo_Version_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<_Yoyo_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<_Yoyo_Version_Bool_Exp>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAttributeArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


export type Subscription_RootAttribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


export type Subscription_RootAttribute_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAttribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Attribute_Stream_Cursor_Input>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


export type Subscription_RootAttribute_TypeArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Type_Order_By>>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};


export type Subscription_RootAttribute_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Type_Order_By>>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};


export type Subscription_RootAttribute_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAttribute_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Attribute_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Attribute_Type_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootDigital_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Order_By>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDigital_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Digital_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Digital_Profile_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_AddressArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Address_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDigital_Profile_To_Address_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Digital_Profile_To_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Digital_Profile_To_Address_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_PersonArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_To_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_To_Person_Order_By>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_To_Person_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDigital_Profile_To_Person_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Digital_Profile_To_Person_Stream_Cursor_Input>>;
  where?: InputMaybe<Digital_Profile_To_Person_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_TypeArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Type_Order_By>>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Digital_Profile_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Digital_Profile_Type_Order_By>>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};


export type Subscription_RootDigital_Profile_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDigital_Profile_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Digital_Profile_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Digital_Profile_Type_Bool_Exp>;
};


export type Subscription_RootEntity_To_Raw_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


export type Subscription_RootEntity_To_Raw_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_To_Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_To_Raw_Response_Order_By>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


export type Subscription_RootEntity_To_Raw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEntity_To_Raw_Response_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Entity_To_Raw_Response_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_To_Raw_Response_Bool_Exp>;
};


export type Subscription_RootLink_Confidence_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Link_Confidence_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Confidence_Category_Order_By>>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};


export type Subscription_RootLink_Confidence_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Confidence_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Confidence_Category_Order_By>>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};


export type Subscription_RootLink_Confidence_Category_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLink_Confidence_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Link_Confidence_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Link_Confidence_Category_Bool_Exp>;
};


export type Subscription_RootLink_Precision_TypeArgs = {
  distinct_on?: InputMaybe<Array<Link_Precision_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Precision_Type_Order_By>>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};


export type Subscription_RootLink_Precision_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Precision_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Precision_Type_Order_By>>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};


export type Subscription_RootLink_Precision_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLink_Precision_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Link_Precision_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Link_Precision_Type_Bool_Exp>;
};


export type Subscription_RootLink_Relationship_StatusArgs = {
  distinct_on?: InputMaybe<Array<Link_Relationship_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Relationship_Status_Order_By>>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};


export type Subscription_RootLink_Relationship_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Link_Relationship_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Link_Relationship_Status_Order_By>>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};


export type Subscription_RootLink_Relationship_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLink_Relationship_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Link_Relationship_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Link_Relationship_Status_Bool_Exp>;
};


export type Subscription_RootOperationArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


export type Subscription_RootOperation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Order_By>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


export type Subscription_RootOperation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Progress_StatusArgs = {
  distinct_on?: InputMaybe<Array<Operation_Progress_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Progress_Status_Order_By>>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};


export type Subscription_RootOperation_Progress_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Progress_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Progress_Status_Order_By>>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};


export type Subscription_RootOperation_Progress_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Progress_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Progress_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Progress_Status_Bool_Exp>;
};


export type Subscription_RootOperation_Request_ItemArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


export type Subscription_RootOperation_Request_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


export type Subscription_RootOperation_Request_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Request_Item_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Request_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Request_Item_Bool_Exp>;
};


export type Subscription_RootOperation_Request_Item_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Type_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Request_Item_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Request_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Request_Item_Type_Order_By>>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Request_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Request_Item_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Request_Item_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Request_Item_Type_Bool_Exp>;
};


export type Subscription_RootOperation_StateArgs = {
  distinct_on?: InputMaybe<Array<Operation_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_State_Order_By>>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};


export type Subscription_RootOperation_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_State_Order_By>>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};


export type Subscription_RootOperation_State_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_State_Bool_Exp>;
};


export type Subscription_RootOperation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Bool_Exp>;
};


export type Subscription_RootOperation_Trigger_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Trigger_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Trigger_Type_Order_By>>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Trigger_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Trigger_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Trigger_Type_Order_By>>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Trigger_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Trigger_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Trigger_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Trigger_Type_Bool_Exp>;
};


export type Subscription_RootOperation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Type_Order_By>>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Type_Order_By>>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};


export type Subscription_RootOperation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOperation_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Type_Bool_Exp>;
};


export type Subscription_RootOutlook_Linkedin_CredentialArgs = {
  distinct_on?: InputMaybe<Array<Outlook_Linkedin_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outlook_Linkedin_Credential_Order_By>>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};


export type Subscription_RootOutlook_Linkedin_Credential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outlook_Linkedin_Credential_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outlook_Linkedin_Credential_Order_By>>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};


export type Subscription_RootOutlook_Linkedin_Credential_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutlook_Linkedin_Credential_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Outlook_Linkedin_Credential_Stream_Cursor_Input>>;
  where?: InputMaybe<Outlook_Linkedin_Credential_Bool_Exp>;
};


export type Subscription_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPerson_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPerson_GroupArgs = {
  distinct_on?: InputMaybe<Array<Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Group_Order_By>>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};


export type Subscription_RootPerson_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Group_Order_By>>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};


export type Subscription_RootPerson_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPerson_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Person_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Person_Group_Bool_Exp>;
};


export type Subscription_RootPerson_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Person_Stream_Cursor_Input>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPerson_To_AddressArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


export type Subscription_RootPerson_To_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Address_Order_By>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


export type Subscription_RootPerson_To_Address_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPerson_To_Address_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Person_To_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Person_To_Address_Bool_Exp>;
};


export type Subscription_RootPerson_To_Person_GroupArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


export type Subscription_RootPerson_To_Person_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_To_Person_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_To_Person_Group_Order_By>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


export type Subscription_RootPerson_To_Person_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPerson_To_Person_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Person_To_Person_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Person_To_Person_Group_Bool_Exp>;
};


export type Subscription_RootPostal_CodeArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


export type Subscription_RootPostal_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Postal_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Postal_Code_Order_By>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


export type Subscription_RootPostal_Code_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPostal_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Postal_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Postal_Code_Bool_Exp>;
};


export type Subscription_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootProvider_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Provider_Stream_Cursor_Input>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type Subscription_RootRaw_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


export type Subscription_RootRaw_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Order_By>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


export type Subscription_RootRaw_Response_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRaw_Response_Formulation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Formulation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Formulation_Type_Order_By>>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};


export type Subscription_RootRaw_Response_Formulation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Formulation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Formulation_Type_Order_By>>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};


export type Subscription_RootRaw_Response_Formulation_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRaw_Response_Formulation_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Raw_Response_Formulation_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Response_Formulation_Type_Bool_Exp>;
};


export type Subscription_RootRaw_Response_Search_ActionArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


export type Subscription_RootRaw_Response_Search_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Response_Search_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Raw_Response_Search_Action_Order_By>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


export type Subscription_RootRaw_Response_Search_Action_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRaw_Response_Search_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Raw_Response_Search_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Response_Search_Action_Bool_Exp>;
};


export type Subscription_RootRaw_Response_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Raw_Response_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Response_Bool_Exp>;
};


export type Subscription_RootStateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


export type Subscription_RootState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<State_Order_By>>;
  where?: InputMaybe<State_Bool_Exp>;
};


export type Subscription_RootState_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootState_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<State_Stream_Cursor_Input>>;
  where?: InputMaybe<State_Bool_Exp>;
};


export type Subscription_RootStated_Address_SourceArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


export type Subscription_RootStated_Address_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stated_Address_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stated_Address_Source_Order_By>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


export type Subscription_RootStated_Address_Source_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStated_Address_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stated_Address_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Stated_Address_Source_Bool_Exp>;
};


export type Subscription_RootTracking_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


export type Subscription_RootTracking_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


export type Subscription_RootTracking_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTracking_Group_ItemArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTracking_Group_Item_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tracking_Group_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Item_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Item_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Item_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTracking_Group_Item_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tracking_Group_Item_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};


export type Subscription_RootTracking_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tracking_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


export type Subscription_RootTracking_Group_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Type_Order_By>>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};


export type Subscription_RootTracking_Group_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTracking_Group_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tracking_Group_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};


export type Subscription_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootValue_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Value_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootYoyo_LockArgs = {
  distinct_on?: InputMaybe<Array<Yoyo_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yoyo_Lock_Order_By>>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};


export type Subscription_RootYoyo_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yoyo_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yoyo_Lock_Order_By>>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};


export type Subscription_RootYoyo_Lock_By_PkArgs = {
  locked: Scalars['Int'];
};


export type Subscription_RootYoyo_Lock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Yoyo_Lock_Stream_Cursor_Input>>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tracking_group" */
export type Tracking_Group = {
  __typename?: 'tracking_group';
  created_at: Scalars['timestamptz'];
  external_event_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_cancelled: Scalars['Boolean'];
  is_completed: Scalars['Boolean'];
  is_error: Scalars['Boolean'];
  is_paused: Scalars['Boolean'];
  /** An array relationship */
  tracking_group_items: Array<Tracking_Group_Item>;
  /** An aggregate relationship */
  tracking_group_items_aggregate: Tracking_Group_Item_Aggregate;
  /** An object relationship */
  tracking_group_type: Tracking_Group_Type;
  tracking_group_type_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tracking_group" */
export type Tracking_GroupTracking_Group_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


/** columns and relationships of "tracking_group" */
export type Tracking_GroupTracking_Group_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};

/** aggregated selection of "tracking_group" */
export type Tracking_Group_Aggregate = {
  __typename?: 'tracking_group_aggregate';
  aggregate?: Maybe<Tracking_Group_Aggregate_Fields>;
  nodes: Array<Tracking_Group>;
};

export type Tracking_Group_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tracking_Group_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tracking_Group_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tracking_Group_Aggregate_Bool_Exp_Count>;
};

export type Tracking_Group_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tracking_Group_Select_Column_Tracking_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tracking_Group_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tracking_Group_Select_Column_Tracking_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tracking_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tracking_group" */
export type Tracking_Group_Aggregate_Fields = {
  __typename?: 'tracking_group_aggregate_fields';
  avg?: Maybe<Tracking_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tracking_Group_Max_Fields>;
  min?: Maybe<Tracking_Group_Min_Fields>;
  stddev?: Maybe<Tracking_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Tracking_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tracking_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Tracking_Group_Sum_Fields>;
  var_pop?: Maybe<Tracking_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Tracking_Group_Var_Samp_Fields>;
  variance?: Maybe<Tracking_Group_Variance_Fields>;
};


/** aggregate fields of "tracking_group" */
export type Tracking_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tracking_group" */
export type Tracking_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Tracking_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tracking_Group_Max_Order_By>;
  min?: InputMaybe<Tracking_Group_Min_Order_By>;
  stddev?: InputMaybe<Tracking_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tracking_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tracking_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tracking_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Tracking_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tracking_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Tracking_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tracking_group" */
export type Tracking_Group_Arr_Rel_Insert_Input = {
  data: Array<Tracking_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracking_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Tracking_Group_Avg_Fields = {
  __typename?: 'tracking_group_avg_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tracking_group" */
export type Tracking_Group_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tracking_group". All fields are combined with a logical 'AND'. */
export type Tracking_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Tracking_Group_Bool_Exp>>;
  _not?: InputMaybe<Tracking_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Tracking_Group_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_event_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_cancelled?: InputMaybe<Boolean_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  is_error?: InputMaybe<Boolean_Comparison_Exp>;
  is_paused?: InputMaybe<Boolean_Comparison_Exp>;
  tracking_group_items?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  tracking_group_items_aggregate?: InputMaybe<Tracking_Group_Item_Aggregate_Bool_Exp>;
  tracking_group_type?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
  tracking_group_type_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracking_group" */
export enum Tracking_Group_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrackingGroupPkey = 'tracking_group_pkey'
}

/** input type for incrementing numeric columns in table "tracking_group" */
export type Tracking_Group_Inc_Input = {
  tracking_group_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tracking_group" */
export type Tracking_Group_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  is_cancelled?: InputMaybe<Scalars['Boolean']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  is_error?: InputMaybe<Scalars['Boolean']>;
  is_paused?: InputMaybe<Scalars['Boolean']>;
  tracking_group_items?: InputMaybe<Tracking_Group_Item_Arr_Rel_Insert_Input>;
  tracking_group_type?: InputMaybe<Tracking_Group_Type_Obj_Rel_Insert_Input>;
  tracking_group_type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tracking_group_item" */
export type Tracking_Group_Item = {
  __typename?: 'tracking_group_item';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_completed: Scalars['Boolean'];
  /** An object relationship */
  tracking_group: Tracking_Group;
  tracking_group_id: Scalars['Int'];
  /** An object relationship */
  tracking_group_item_type: Tracking_Group_Item_Type;
  tracking_group_item_type_id: Scalars['Int'];
  tracking_group_item_value: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tracking_group_item" */
export type Tracking_Group_Item_Aggregate = {
  __typename?: 'tracking_group_item_aggregate';
  aggregate?: Maybe<Tracking_Group_Item_Aggregate_Fields>;
  nodes: Array<Tracking_Group_Item>;
};

export type Tracking_Group_Item_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tracking_Group_Item_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tracking_Group_Item_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tracking_Group_Item_Aggregate_Bool_Exp_Count>;
};

export type Tracking_Group_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tracking_Group_Item_Select_Column_Tracking_Group_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tracking_Group_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tracking_Group_Item_Select_Column_Tracking_Group_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tracking_Group_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tracking_group_item" */
export type Tracking_Group_Item_Aggregate_Fields = {
  __typename?: 'tracking_group_item_aggregate_fields';
  avg?: Maybe<Tracking_Group_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tracking_Group_Item_Max_Fields>;
  min?: Maybe<Tracking_Group_Item_Min_Fields>;
  stddev?: Maybe<Tracking_Group_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Tracking_Group_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tracking_Group_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Tracking_Group_Item_Sum_Fields>;
  var_pop?: Maybe<Tracking_Group_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Tracking_Group_Item_Var_Samp_Fields>;
  variance?: Maybe<Tracking_Group_Item_Variance_Fields>;
};


/** aggregate fields of "tracking_group_item" */
export type Tracking_Group_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tracking_group_item" */
export type Tracking_Group_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Tracking_Group_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tracking_Group_Item_Max_Order_By>;
  min?: InputMaybe<Tracking_Group_Item_Min_Order_By>;
  stddev?: InputMaybe<Tracking_Group_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tracking_Group_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tracking_Group_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tracking_Group_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Tracking_Group_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tracking_Group_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Tracking_Group_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tracking_group_item" */
export type Tracking_Group_Item_Arr_Rel_Insert_Input = {
  data: Array<Tracking_Group_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracking_Group_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Tracking_Group_Item_Avg_Fields = {
  __typename?: 'tracking_group_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tracking_group_item". All fields are combined with a logical 'AND'. */
export type Tracking_Group_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Tracking_Group_Item_Bool_Exp>>;
  _not?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Tracking_Group_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  tracking_group?: InputMaybe<Tracking_Group_Bool_Exp>;
  tracking_group_id?: InputMaybe<Int_Comparison_Exp>;
  tracking_group_item_type?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
  tracking_group_item_type_id?: InputMaybe<Int_Comparison_Exp>;
  tracking_group_item_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracking_group_item" */
export enum Tracking_Group_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrackingGroupItemPkey = 'tracking_group_item_pkey'
}

/** input type for incrementing numeric columns in table "tracking_group_item" */
export type Tracking_Group_Item_Inc_Input = {
  tracking_group_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tracking_group_item" */
export type Tracking_Group_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  tracking_group?: InputMaybe<Tracking_Group_Obj_Rel_Insert_Input>;
  tracking_group_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_type?: InputMaybe<Tracking_Group_Item_Type_Obj_Rel_Insert_Input>;
  tracking_group_item_type_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tracking_Group_Item_Max_Fields = {
  __typename?: 'tracking_group_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracking_group_id?: Maybe<Scalars['Int']>;
  tracking_group_item_type_id?: Maybe<Scalars['Int']>;
  tracking_group_item_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
  tracking_group_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tracking_Group_Item_Min_Fields = {
  __typename?: 'tracking_group_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tracking_group_id?: Maybe<Scalars['Int']>;
  tracking_group_item_type_id?: Maybe<Scalars['Int']>;
  tracking_group_item_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
  tracking_group_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tracking_group_item" */
export type Tracking_Group_Item_Mutation_Response = {
  __typename?: 'tracking_group_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracking_Group_Item>;
};

/** on_conflict condition type for table "tracking_group_item" */
export type Tracking_Group_Item_On_Conflict = {
  constraint: Tracking_Group_Item_Constraint;
  update_columns?: Array<Tracking_Group_Item_Update_Column>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "tracking_group_item". */
export type Tracking_Group_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  tracking_group?: InputMaybe<Tracking_Group_Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type?: InputMaybe<Tracking_Group_Item_Type_Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
  tracking_group_item_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracking_group_item */
export type Tracking_Group_Item_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tracking_group_item" */
export enum Tracking_Group_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  TrackingGroupId = 'tracking_group_id',
  /** column name */
  TrackingGroupItemTypeId = 'tracking_group_item_type_id',
  /** column name */
  TrackingGroupItemValue = 'tracking_group_item_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "tracking_group_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tracking_group_item" */
export enum Tracking_Group_Item_Select_Column_Tracking_Group_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "tracking_group_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tracking_group_item" */
export enum Tracking_Group_Item_Select_Column_Tracking_Group_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "tracking_group_item" */
export type Tracking_Group_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  tracking_group_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_type_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tracking_Group_Item_Stddev_Fields = {
  __typename?: 'tracking_group_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tracking_Group_Item_Stddev_Pop_Fields = {
  __typename?: 'tracking_group_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tracking_Group_Item_Stddev_Samp_Fields = {
  __typename?: 'tracking_group_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tracking_group_item" */
export type Tracking_Group_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracking_Group_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracking_Group_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  tracking_group_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_type_id?: InputMaybe<Scalars['Int']>;
  tracking_group_item_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tracking_Group_Item_Sum_Fields = {
  __typename?: 'tracking_group_item_sum_fields';
  id?: Maybe<Scalars['Int']>;
  tracking_group_id?: Maybe<Scalars['Int']>;
  tracking_group_item_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tracking_group_item_type" */
export type Tracking_Group_Item_Type = {
  __typename?: 'tracking_group_item_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  tracking_group_items: Array<Tracking_Group_Item>;
  /** An aggregate relationship */
  tracking_group_items_aggregate: Tracking_Group_Item_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tracking_group_item_type" */
export type Tracking_Group_Item_TypeTracking_Group_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};


/** columns and relationships of "tracking_group_item_type" */
export type Tracking_Group_Item_TypeTracking_Group_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Item_Order_By>>;
  where?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
};

/** aggregated selection of "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Aggregate = {
  __typename?: 'tracking_group_item_type_aggregate';
  aggregate?: Maybe<Tracking_Group_Item_Type_Aggregate_Fields>;
  nodes: Array<Tracking_Group_Item_Type>;
};

/** aggregate fields of "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Aggregate_Fields = {
  __typename?: 'tracking_group_item_type_aggregate_fields';
  avg?: Maybe<Tracking_Group_Item_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tracking_Group_Item_Type_Max_Fields>;
  min?: Maybe<Tracking_Group_Item_Type_Min_Fields>;
  stddev?: Maybe<Tracking_Group_Item_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Tracking_Group_Item_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tracking_Group_Item_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Tracking_Group_Item_Type_Sum_Fields>;
  var_pop?: Maybe<Tracking_Group_Item_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Tracking_Group_Item_Type_Var_Samp_Fields>;
  variance?: Maybe<Tracking_Group_Item_Type_Variance_Fields>;
};


/** aggregate fields of "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracking_Group_Item_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tracking_Group_Item_Type_Avg_Fields = {
  __typename?: 'tracking_group_item_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tracking_group_item_type". All fields are combined with a logical 'AND'. */
export type Tracking_Group_Item_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Tracking_Group_Item_Type_Bool_Exp>>;
  _not?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Tracking_Group_Item_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracking_group_items?: InputMaybe<Tracking_Group_Item_Bool_Exp>;
  tracking_group_items_aggregate?: InputMaybe<Tracking_Group_Item_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracking_group_item_type" */
export enum Tracking_Group_Item_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  TrackingGroupItemTypeNameKey = 'tracking_group_item_type_name_key',
  /** unique or primary key constraint on columns "id" */
  TrackingGroupItemTypePkey = 'tracking_group_item_type_pkey'
}

/** input type for incrementing numeric columns in table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracking_group_items?: InputMaybe<Tracking_Group_Item_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tracking_Group_Item_Type_Max_Fields = {
  __typename?: 'tracking_group_item_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tracking_Group_Item_Type_Min_Fields = {
  __typename?: 'tracking_group_item_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Mutation_Response = {
  __typename?: 'tracking_group_item_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracking_Group_Item_Type>;
};

/** input type for inserting object relation for remote table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Obj_Rel_Insert_Input = {
  data: Tracking_Group_Item_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracking_Group_Item_Type_On_Conflict>;
};

/** on_conflict condition type for table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_On_Conflict = {
  constraint: Tracking_Group_Item_Type_Constraint;
  update_columns?: Array<Tracking_Group_Item_Type_Update_Column>;
  where?: InputMaybe<Tracking_Group_Item_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "tracking_group_item_type". */
export type Tracking_Group_Item_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracking_group_items_aggregate?: InputMaybe<Tracking_Group_Item_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracking_group_item_type */
export type Tracking_Group_Item_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tracking_group_item_type" */
export enum Tracking_Group_Item_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tracking_Group_Item_Type_Stddev_Fields = {
  __typename?: 'tracking_group_item_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tracking_Group_Item_Type_Stddev_Pop_Fields = {
  __typename?: 'tracking_group_item_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tracking_Group_Item_Type_Stddev_Samp_Fields = {
  __typename?: 'tracking_group_item_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tracking_group_item_type" */
export type Tracking_Group_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracking_Group_Item_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracking_Group_Item_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tracking_Group_Item_Type_Sum_Fields = {
  __typename?: 'tracking_group_item_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tracking_group_item_type" */
export enum Tracking_Group_Item_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tracking_Group_Item_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tracking_Group_Item_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracking_Group_Item_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracking_Group_Item_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tracking_Group_Item_Type_Var_Pop_Fields = {
  __typename?: 'tracking_group_item_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tracking_Group_Item_Type_Var_Samp_Fields = {
  __typename?: 'tracking_group_item_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tracking_Group_Item_Type_Variance_Fields = {
  __typename?: 'tracking_group_item_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "tracking_group_item" */
export enum Tracking_Group_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  TrackingGroupId = 'tracking_group_id',
  /** column name */
  TrackingGroupItemTypeId = 'tracking_group_item_type_id',
  /** column name */
  TrackingGroupItemValue = 'tracking_group_item_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tracking_Group_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tracking_Group_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracking_Group_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracking_Group_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tracking_Group_Item_Var_Pop_Fields = {
  __typename?: 'tracking_group_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tracking_Group_Item_Var_Samp_Fields = {
  __typename?: 'tracking_group_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tracking_Group_Item_Variance_Fields = {
  __typename?: 'tracking_group_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_id?: Maybe<Scalars['Float']>;
  tracking_group_item_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tracking_group_item" */
export type Tracking_Group_Item_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_id?: InputMaybe<Order_By>;
  tracking_group_item_type_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Tracking_Group_Max_Fields = {
  __typename?: 'tracking_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracking_group_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tracking_group" */
export type Tracking_Group_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tracking_Group_Min_Fields = {
  __typename?: 'tracking_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tracking_group_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tracking_group" */
export type Tracking_Group_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tracking_group" */
export type Tracking_Group_Mutation_Response = {
  __typename?: 'tracking_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracking_Group>;
};

/** input type for inserting object relation for remote table "tracking_group" */
export type Tracking_Group_Obj_Rel_Insert_Input = {
  data: Tracking_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracking_Group_On_Conflict>;
};

/** on_conflict condition type for table "tracking_group" */
export type Tracking_Group_On_Conflict = {
  constraint: Tracking_Group_Constraint;
  update_columns?: Array<Tracking_Group_Update_Column>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "tracking_group". */
export type Tracking_Group_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_cancelled?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  is_error?: InputMaybe<Order_By>;
  is_paused?: InputMaybe<Order_By>;
  tracking_group_items_aggregate?: InputMaybe<Tracking_Group_Item_Aggregate_Order_By>;
  tracking_group_type?: InputMaybe<Tracking_Group_Type_Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracking_group */
export type Tracking_Group_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tracking_group" */
export enum Tracking_Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalEventId = 'external_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsError = 'is_error',
  /** column name */
  IsPaused = 'is_paused',
  /** column name */
  TrackingGroupTypeId = 'tracking_group_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "tracking_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tracking_group" */
export enum Tracking_Group_Select_Column_Tracking_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsError = 'is_error',
  /** column name */
  IsPaused = 'is_paused'
}

/** select "tracking_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tracking_group" */
export enum Tracking_Group_Select_Column_Tracking_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsError = 'is_error',
  /** column name */
  IsPaused = 'is_paused'
}

/** input type for updating data in table "tracking_group" */
export type Tracking_Group_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  is_cancelled?: InputMaybe<Scalars['Boolean']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  is_error?: InputMaybe<Scalars['Boolean']>;
  is_paused?: InputMaybe<Scalars['Boolean']>;
  tracking_group_type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tracking_Group_Stddev_Fields = {
  __typename?: 'tracking_group_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tracking_group" */
export type Tracking_Group_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tracking_Group_Stddev_Pop_Fields = {
  __typename?: 'tracking_group_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tracking_group" */
export type Tracking_Group_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tracking_Group_Stddev_Samp_Fields = {
  __typename?: 'tracking_group_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tracking_group" */
export type Tracking_Group_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tracking_group" */
export type Tracking_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracking_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracking_Group_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_event_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_cancelled?: InputMaybe<Scalars['Boolean']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  is_error?: InputMaybe<Scalars['Boolean']>;
  is_paused?: InputMaybe<Scalars['Boolean']>;
  tracking_group_type_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tracking_Group_Sum_Fields = {
  __typename?: 'tracking_group_sum_fields';
  id?: Maybe<Scalars['Int']>;
  tracking_group_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tracking_group" */
export type Tracking_Group_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tracking_group_type" */
export type Tracking_Group_Type = {
  __typename?: 'tracking_group_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  tracking_groups: Array<Tracking_Group>;
  /** An aggregate relationship */
  tracking_groups_aggregate: Tracking_Group_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tracking_group_type" */
export type Tracking_Group_TypeTracking_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};


/** columns and relationships of "tracking_group_type" */
export type Tracking_Group_TypeTracking_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Group_Order_By>>;
  where?: InputMaybe<Tracking_Group_Bool_Exp>;
};

/** aggregated selection of "tracking_group_type" */
export type Tracking_Group_Type_Aggregate = {
  __typename?: 'tracking_group_type_aggregate';
  aggregate?: Maybe<Tracking_Group_Type_Aggregate_Fields>;
  nodes: Array<Tracking_Group_Type>;
};

/** aggregate fields of "tracking_group_type" */
export type Tracking_Group_Type_Aggregate_Fields = {
  __typename?: 'tracking_group_type_aggregate_fields';
  avg?: Maybe<Tracking_Group_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tracking_Group_Type_Max_Fields>;
  min?: Maybe<Tracking_Group_Type_Min_Fields>;
  stddev?: Maybe<Tracking_Group_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Tracking_Group_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tracking_Group_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Tracking_Group_Type_Sum_Fields>;
  var_pop?: Maybe<Tracking_Group_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Tracking_Group_Type_Var_Samp_Fields>;
  variance?: Maybe<Tracking_Group_Type_Variance_Fields>;
};


/** aggregate fields of "tracking_group_type" */
export type Tracking_Group_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracking_Group_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tracking_Group_Type_Avg_Fields = {
  __typename?: 'tracking_group_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tracking_group_type". All fields are combined with a logical 'AND'. */
export type Tracking_Group_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Tracking_Group_Type_Bool_Exp>>;
  _not?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Tracking_Group_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tracking_groups?: InputMaybe<Tracking_Group_Bool_Exp>;
  tracking_groups_aggregate?: InputMaybe<Tracking_Group_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracking_group_type" */
export enum Tracking_Group_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  TrackingGroupTypeNameKey = 'tracking_group_type_name_key',
  /** unique or primary key constraint on columns "id" */
  TrackingGroupTypePkey = 'tracking_group_type_pkey'
}

/** input type for incrementing numeric columns in table "tracking_group_type" */
export type Tracking_Group_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tracking_group_type" */
export type Tracking_Group_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tracking_groups?: InputMaybe<Tracking_Group_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tracking_Group_Type_Max_Fields = {
  __typename?: 'tracking_group_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tracking_Group_Type_Min_Fields = {
  __typename?: 'tracking_group_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tracking_group_type" */
export type Tracking_Group_Type_Mutation_Response = {
  __typename?: 'tracking_group_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracking_Group_Type>;
};

/** input type for inserting object relation for remote table "tracking_group_type" */
export type Tracking_Group_Type_Obj_Rel_Insert_Input = {
  data: Tracking_Group_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracking_Group_Type_On_Conflict>;
};

/** on_conflict condition type for table "tracking_group_type" */
export type Tracking_Group_Type_On_Conflict = {
  constraint: Tracking_Group_Type_Constraint;
  update_columns?: Array<Tracking_Group_Type_Update_Column>;
  where?: InputMaybe<Tracking_Group_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "tracking_group_type". */
export type Tracking_Group_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tracking_groups_aggregate?: InputMaybe<Tracking_Group_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracking_group_type */
export type Tracking_Group_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tracking_group_type" */
export enum Tracking_Group_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tracking_group_type" */
export type Tracking_Group_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tracking_Group_Type_Stddev_Fields = {
  __typename?: 'tracking_group_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tracking_Group_Type_Stddev_Pop_Fields = {
  __typename?: 'tracking_group_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tracking_Group_Type_Stddev_Samp_Fields = {
  __typename?: 'tracking_group_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tracking_group_type" */
export type Tracking_Group_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracking_Group_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracking_Group_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tracking_Group_Type_Sum_Fields = {
  __typename?: 'tracking_group_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tracking_group_type" */
export enum Tracking_Group_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tracking_Group_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tracking_Group_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracking_Group_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracking_Group_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tracking_Group_Type_Var_Pop_Fields = {
  __typename?: 'tracking_group_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tracking_Group_Type_Var_Samp_Fields = {
  __typename?: 'tracking_group_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tracking_Group_Type_Variance_Fields = {
  __typename?: 'tracking_group_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "tracking_group" */
export enum Tracking_Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalEventId = 'external_event_id',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsError = 'is_error',
  /** column name */
  IsPaused = 'is_paused',
  /** column name */
  TrackingGroupTypeId = 'tracking_group_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tracking_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tracking_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracking_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracking_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tracking_Group_Var_Pop_Fields = {
  __typename?: 'tracking_group_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tracking_group" */
export type Tracking_Group_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tracking_Group_Var_Samp_Fields = {
  __typename?: 'tracking_group_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tracking_group" */
export type Tracking_Group_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tracking_Group_Variance_Fields = {
  __typename?: 'tracking_group_variance_fields';
  id?: Maybe<Scalars['Float']>;
  tracking_group_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tracking_group" */
export type Tracking_Group_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tracking_group_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "value_type" */
export type Value_Type = {
  __typename?: 'value_type';
  /** An array relationship */
  attributes: Array<Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: Attribute_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "value_type" */
export type Value_TypeAttributesArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};


/** columns and relationships of "value_type" */
export type Value_TypeAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attribute_Order_By>>;
  where?: InputMaybe<Attribute_Bool_Exp>;
};

/** aggregated selection of "value_type" */
export type Value_Type_Aggregate = {
  __typename?: 'value_type_aggregate';
  aggregate?: Maybe<Value_Type_Aggregate_Fields>;
  nodes: Array<Value_Type>;
};

/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_Fields = {
  __typename?: 'value_type_aggregate_fields';
  avg?: Maybe<Value_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Value_Type_Max_Fields>;
  min?: Maybe<Value_Type_Min_Fields>;
  stddev?: Maybe<Value_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Value_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Value_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Value_Type_Sum_Fields>;
  var_pop?: Maybe<Value_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Value_Type_Var_Samp_Fields>;
  variance?: Maybe<Value_Type_Variance_Fields>;
};


/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Value_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Value_Type_Avg_Fields = {
  __typename?: 'value_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "value_type". All fields are combined with a logical 'AND'. */
export type Value_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  _not?: InputMaybe<Value_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  attributes?: InputMaybe<Attribute_Bool_Exp>;
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "value_type" */
export enum Value_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  ValueTypeNameKey = 'value_type_name_key',
  /** unique or primary key constraint on columns "id" */
  ValueTypePkey = 'value_type_pkey'
}

/** input type for incrementing numeric columns in table "value_type" */
export type Value_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "value_type" */
export type Value_Type_Insert_Input = {
  attributes?: InputMaybe<Attribute_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Value_Type_Max_Fields = {
  __typename?: 'value_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Value_Type_Min_Fields = {
  __typename?: 'value_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "value_type" */
export type Value_Type_Mutation_Response = {
  __typename?: 'value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Value_Type>;
};

/** input type for inserting object relation for remote table "value_type" */
export type Value_Type_Obj_Rel_Insert_Input = {
  data: Value_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};

/** on_conflict condition type for table "value_type" */
export type Value_Type_On_Conflict = {
  constraint: Value_Type_Constraint;
  update_columns?: Array<Value_Type_Update_Column>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "value_type". */
export type Value_Type_Order_By = {
  attributes_aggregate?: InputMaybe<Attribute_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: value_type */
export type Value_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "value_type" */
export enum Value_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "value_type" */
export type Value_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Value_Type_Stddev_Fields = {
  __typename?: 'value_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Value_Type_Stddev_Pop_Fields = {
  __typename?: 'value_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Value_Type_Stddev_Samp_Fields = {
  __typename?: 'value_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "value_type" */
export type Value_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Value_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Value_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Value_Type_Sum_Fields = {
  __typename?: 'value_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "value_type" */
export enum Value_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Value_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Value_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Value_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Value_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Value_Type_Var_Pop_Fields = {
  __typename?: 'value_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Value_Type_Var_Samp_Fields = {
  __typename?: 'value_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Value_Type_Variance_Fields = {
  __typename?: 'value_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "yoyo_lock" */
export type Yoyo_Lock = {
  __typename?: 'yoyo_lock';
  ctime?: Maybe<Scalars['timestamp']>;
  locked: Scalars['Int'];
  pid: Scalars['Int'];
};

/** aggregated selection of "yoyo_lock" */
export type Yoyo_Lock_Aggregate = {
  __typename?: 'yoyo_lock_aggregate';
  aggregate?: Maybe<Yoyo_Lock_Aggregate_Fields>;
  nodes: Array<Yoyo_Lock>;
};

/** aggregate fields of "yoyo_lock" */
export type Yoyo_Lock_Aggregate_Fields = {
  __typename?: 'yoyo_lock_aggregate_fields';
  avg?: Maybe<Yoyo_Lock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Yoyo_Lock_Max_Fields>;
  min?: Maybe<Yoyo_Lock_Min_Fields>;
  stddev?: Maybe<Yoyo_Lock_Stddev_Fields>;
  stddev_pop?: Maybe<Yoyo_Lock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Yoyo_Lock_Stddev_Samp_Fields>;
  sum?: Maybe<Yoyo_Lock_Sum_Fields>;
  var_pop?: Maybe<Yoyo_Lock_Var_Pop_Fields>;
  var_samp?: Maybe<Yoyo_Lock_Var_Samp_Fields>;
  variance?: Maybe<Yoyo_Lock_Variance_Fields>;
};


/** aggregate fields of "yoyo_lock" */
export type Yoyo_Lock_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Yoyo_Lock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Yoyo_Lock_Avg_Fields = {
  __typename?: 'yoyo_lock_avg_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "yoyo_lock". All fields are combined with a logical 'AND'. */
export type Yoyo_Lock_Bool_Exp = {
  _and?: InputMaybe<Array<Yoyo_Lock_Bool_Exp>>;
  _not?: InputMaybe<Yoyo_Lock_Bool_Exp>;
  _or?: InputMaybe<Array<Yoyo_Lock_Bool_Exp>>;
  ctime?: InputMaybe<Timestamp_Comparison_Exp>;
  locked?: InputMaybe<Int_Comparison_Exp>;
  pid?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "yoyo_lock" */
export enum Yoyo_Lock_Constraint {
  /** unique or primary key constraint on columns "locked" */
  YoyoLockPkey = 'yoyo_lock_pkey'
}

/** input type for incrementing numeric columns in table "yoyo_lock" */
export type Yoyo_Lock_Inc_Input = {
  locked?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "yoyo_lock" */
export type Yoyo_Lock_Insert_Input = {
  ctime?: InputMaybe<Scalars['timestamp']>;
  locked?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Yoyo_Lock_Max_Fields = {
  __typename?: 'yoyo_lock_max_fields';
  ctime?: Maybe<Scalars['timestamp']>;
  locked?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Yoyo_Lock_Min_Fields = {
  __typename?: 'yoyo_lock_min_fields';
  ctime?: Maybe<Scalars['timestamp']>;
  locked?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "yoyo_lock" */
export type Yoyo_Lock_Mutation_Response = {
  __typename?: 'yoyo_lock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Yoyo_Lock>;
};

/** on_conflict condition type for table "yoyo_lock" */
export type Yoyo_Lock_On_Conflict = {
  constraint: Yoyo_Lock_Constraint;
  update_columns?: Array<Yoyo_Lock_Update_Column>;
  where?: InputMaybe<Yoyo_Lock_Bool_Exp>;
};

/** Ordering options when selecting data from "yoyo_lock". */
export type Yoyo_Lock_Order_By = {
  ctime?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  pid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: yoyo_lock */
export type Yoyo_Lock_Pk_Columns_Input = {
  locked: Scalars['Int'];
};

/** select columns of table "yoyo_lock" */
export enum Yoyo_Lock_Select_Column {
  /** column name */
  Ctime = 'ctime',
  /** column name */
  Locked = 'locked',
  /** column name */
  Pid = 'pid'
}

/** input type for updating data in table "yoyo_lock" */
export type Yoyo_Lock_Set_Input = {
  ctime?: InputMaybe<Scalars['timestamp']>;
  locked?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Yoyo_Lock_Stddev_Fields = {
  __typename?: 'yoyo_lock_stddev_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Yoyo_Lock_Stddev_Pop_Fields = {
  __typename?: 'yoyo_lock_stddev_pop_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Yoyo_Lock_Stddev_Samp_Fields = {
  __typename?: 'yoyo_lock_stddev_samp_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "yoyo_lock" */
export type Yoyo_Lock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Yoyo_Lock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Yoyo_Lock_Stream_Cursor_Value_Input = {
  ctime?: InputMaybe<Scalars['timestamp']>;
  locked?: InputMaybe<Scalars['Int']>;
  pid?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Yoyo_Lock_Sum_Fields = {
  __typename?: 'yoyo_lock_sum_fields';
  locked?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['Int']>;
};

/** update columns of table "yoyo_lock" */
export enum Yoyo_Lock_Update_Column {
  /** column name */
  Ctime = 'ctime',
  /** column name */
  Locked = 'locked',
  /** column name */
  Pid = 'pid'
}

export type Yoyo_Lock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Yoyo_Lock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Yoyo_Lock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Yoyo_Lock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Yoyo_Lock_Var_Pop_Fields = {
  __typename?: 'yoyo_lock_var_pop_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Yoyo_Lock_Var_Samp_Fields = {
  __typename?: 'yoyo_lock_var_samp_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Yoyo_Lock_Variance_Fields = {
  __typename?: 'yoyo_lock_variance_fields';
  locked?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['Float']>;
};

export type GetAddressesAndPersonsByPostalCodeIdsQueryVariables = Exact<{
  postalCodeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetAddressesAndPersonsByPostalCodeIdsQuery = { __typename?: 'query_root', address: Array<{ __typename?: 'address', city: string, country: string, county?: string | null, id: number, line_1: string, line_2?: string | null, line_3?: string | null, external_parcel_id?: string | null, full_address: string, raw_state?: string | null, raw_postal_code: string, provider: { __typename?: 'provider', id: number, name: string }, person_to_addresses: Array<{ __typename?: 'person_to_address', id: number, is_linked_to_address_confidence: number, is_owner_of_address_confidence: number, link_relationship_status: { __typename?: 'link_relationship_status', id: number, name: string }, link_precision_type: { __typename?: 'link_precision_type', id: number, name: string }, link_confidence_category: { __typename?: 'link_confidence_category', id: number, name: string }, provider: { __typename?: 'provider', id: number, name: string }, providerByIsOwnerOfAddressProviderId: { __typename?: 'provider', id: number, name: string }, person: { __typename?: 'person', first_name: string, full_name: string, id: number, is_organisation: boolean, last_name: string, middle_names?: string | null, stated_address: string, valid_at?: any | null, valid_from?: any | null, valid_to?: any | null, external_id?: string | null, stated_address_source: { __typename?: 'stated_address_source', id: number, name: string } } }> }> };

export type GetAddressesByPostalCodeIdsQueryVariables = Exact<{
  postalCodeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetAddressesByPostalCodeIdsQuery = { __typename?: 'query_root', address: Array<{ __typename?: 'address', city: string, country: string, county?: string | null, id: number, line_1: string, line_2?: string | null, line_3?: string | null, external_parcel_id?: string | null, full_address: string, raw_state?: string | null, raw_postal_code: string, provider: { __typename?: 'provider', id: number, name: string } }> };

export type GetChildOperationsForParentOperationIdQueryVariables = Exact<{
  parentId: Scalars['Int'];
}>;


export type GetChildOperationsForParentOperationIdQuery = { __typename?: 'query_root', operation: Array<{ __typename?: 'operation', id: number, external_event_id?: string | null, created_at: any, parent_operation_id?: number | null, operation_type: { __typename?: 'operation_type', name: string, id: number }, operation_state: { __typename?: 'operation_state', id: number, name: string }, operation_trigger_type: { __typename?: 'operation_trigger_type', id: number, name: string }, operation_request_items: Array<{ __typename?: 'operation_request_item', id: number, is_completed: boolean, operation_request_item_value: string, operation_request_item_type: { __typename?: 'operation_request_item_type', name: string, id: number } }> }> };

export type GetParentOperationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetParentOperationsQuery = { __typename?: 'query_root', operation: Array<{ __typename?: 'operation', id: number, parent_operation_id?: number | null, operation_type: { __typename?: 'operation_type', id: number, name: string }, operation_request_items: Array<{ __typename?: 'operation_request_item', id: number, operation_request_item_value: string }>, operation_progress_status?: { __typename?: 'operation_progress_status', id: number, name: string } | null, operation_state: { __typename?: 'operation_state', id: number, name: string } }>, operation_aggregate: { __typename?: 'operation_aggregate', aggregate?: { __typename?: 'operation_aggregate_fields', count: number } | null } };

export type GetSingleParentOperationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSingleParentOperationQuery = { __typename?: 'query_root', operation_by_pk?: { __typename?: 'operation', id: number, created_at: any, external_event_id?: string | null, parent_operation_id?: number | null, operation_progress_status?: { __typename?: 'operation_progress_status', id: number, name: string } | null, operation_state: { __typename?: 'operation_state', name: string, id: number }, operation_type: { __typename?: 'operation_type', id: number, name: string }, operation_trigger_type: { __typename?: 'operation_trigger_type', id: number, name: string }, operation_request_items: Array<{ __typename?: 'operation_request_item', id: number, is_completed: boolean, operation_request_item_value: string, operation_request_item_type: { __typename?: 'operation_request_item_type', id: number, name: string } }> } | null };

export type NonOrgPersonAddressesByPostalCodeIdsQueryVariables = Exact<{
  postalCodeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type NonOrgPersonAddressesByPostalCodeIdsQuery = { __typename?: 'query_root', person_to_address: Array<{ __typename?: 'person_to_address', link_relationship_status: { __typename?: 'link_relationship_status', id: number, name: string }, person: { __typename?: 'person', first_name: string, full_name: string, id: number, last_name: string, stated_address: string, digital_profile_to_people: Array<{ __typename?: 'digital_profile_to_person', id: number, digital_profile: { __typename?: 'digital_profile', full_name?: string | null, primary_subtitle?: string | null, secondary_subtitle?: string | null, summary?: string | null, external_id: string, id: number, digital_profile_type: { __typename?: 'digital_profile_type', id: number, name: string } } }> } }> };

export type GetPostalCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostalCodesQuery = { __typename?: 'query_root', postal_code: Array<{ __typename?: 'postal_code', code: string, id: number }> };

export type GetOperationTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOperationTypesQuery = { __typename?: 'query_root', operation_type: Array<{ __typename?: 'operation_type', id: number, name: string }> };


export const GetAddressesAndPersonsByPostalCodeIdsDocument = gql`
    query getAddressesAndPersonsByPostalCodeIds($postalCodeIds: [Int!]!) {
  address(where: {postal_code_id: {_in: $postalCodeIds}}) {
    city
    country
    county
    id
    line_1
    line_2
    line_3
    external_parcel_id
    full_address
    raw_state
    raw_postal_code
    provider {
      id
      name
    }
    person_to_addresses {
      id
      is_linked_to_address_confidence
      is_owner_of_address_confidence
      link_relationship_status {
        id
        name
      }
      link_precision_type {
        id
        name
      }
      link_confidence_category {
        id
        name
      }
      provider {
        id
        name
      }
      providerByIsOwnerOfAddressProviderId {
        id
        name
      }
      person {
        first_name
        full_name
        id
        is_organisation
        last_name
        middle_names
        stated_address
        stated_address_source {
          id
          name
        }
        valid_at
        valid_from
        valid_to
        external_id
      }
    }
  }
}
    `;

/**
 * __useGetAddressesAndPersonsByPostalCodeIdsQuery__
 *
 * To run a query within a React component, call `useGetAddressesAndPersonsByPostalCodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesAndPersonsByPostalCodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesAndPersonsByPostalCodeIdsQuery({
 *   variables: {
 *      postalCodeIds: // value for 'postalCodeIds'
 *   },
 * });
 */
export function useGetAddressesAndPersonsByPostalCodeIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAddressesAndPersonsByPostalCodeIdsQuery, GetAddressesAndPersonsByPostalCodeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressesAndPersonsByPostalCodeIdsQuery, GetAddressesAndPersonsByPostalCodeIdsQueryVariables>(GetAddressesAndPersonsByPostalCodeIdsDocument, options);
      }
export function useGetAddressesAndPersonsByPostalCodeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesAndPersonsByPostalCodeIdsQuery, GetAddressesAndPersonsByPostalCodeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressesAndPersonsByPostalCodeIdsQuery, GetAddressesAndPersonsByPostalCodeIdsQueryVariables>(GetAddressesAndPersonsByPostalCodeIdsDocument, options);
        }
export type GetAddressesAndPersonsByPostalCodeIdsQueryHookResult = ReturnType<typeof useGetAddressesAndPersonsByPostalCodeIdsQuery>;
export type GetAddressesAndPersonsByPostalCodeIdsLazyQueryHookResult = ReturnType<typeof useGetAddressesAndPersonsByPostalCodeIdsLazyQuery>;
export type GetAddressesAndPersonsByPostalCodeIdsQueryResult = Apollo.QueryResult<GetAddressesAndPersonsByPostalCodeIdsQuery, GetAddressesAndPersonsByPostalCodeIdsQueryVariables>;
export const GetAddressesByPostalCodeIdsDocument = gql`
    query getAddressesByPostalCodeIds($postalCodeIds: [Int!]!) {
  address(where: {postal_code_id: {_in: $postalCodeIds}}) {
    city
    country
    county
    id
    line_1
    line_2
    line_3
    external_parcel_id
    full_address
    raw_state
    raw_postal_code
    provider {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAddressesByPostalCodeIdsQuery__
 *
 * To run a query within a React component, call `useGetAddressesByPostalCodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesByPostalCodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesByPostalCodeIdsQuery({
 *   variables: {
 *      postalCodeIds: // value for 'postalCodeIds'
 *   },
 * });
 */
export function useGetAddressesByPostalCodeIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAddressesByPostalCodeIdsQuery, GetAddressesByPostalCodeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressesByPostalCodeIdsQuery, GetAddressesByPostalCodeIdsQueryVariables>(GetAddressesByPostalCodeIdsDocument, options);
      }
export function useGetAddressesByPostalCodeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesByPostalCodeIdsQuery, GetAddressesByPostalCodeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressesByPostalCodeIdsQuery, GetAddressesByPostalCodeIdsQueryVariables>(GetAddressesByPostalCodeIdsDocument, options);
        }
export type GetAddressesByPostalCodeIdsQueryHookResult = ReturnType<typeof useGetAddressesByPostalCodeIdsQuery>;
export type GetAddressesByPostalCodeIdsLazyQueryHookResult = ReturnType<typeof useGetAddressesByPostalCodeIdsLazyQuery>;
export type GetAddressesByPostalCodeIdsQueryResult = Apollo.QueryResult<GetAddressesByPostalCodeIdsQuery, GetAddressesByPostalCodeIdsQueryVariables>;
export const GetChildOperationsForParentOperationIdDocument = gql`
    query getChildOperationsForParentOperationId($parentId: Int!) {
  operation(where: {parent_operation_id: {_eq: $parentId}}) {
    id
    external_event_id
    created_at
    parent_operation_id
    operation_type {
      name
      id
    }
    operation_state {
      id
      name
    }
    operation_trigger_type {
      id
      name
    }
    operation_request_items {
      id
      is_completed
      operation_request_item_value
      operation_request_item_type {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetChildOperationsForParentOperationIdQuery__
 *
 * To run a query within a React component, call `useGetChildOperationsForParentOperationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildOperationsForParentOperationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildOperationsForParentOperationIdQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetChildOperationsForParentOperationIdQuery(baseOptions: Apollo.QueryHookOptions<GetChildOperationsForParentOperationIdQuery, GetChildOperationsForParentOperationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildOperationsForParentOperationIdQuery, GetChildOperationsForParentOperationIdQueryVariables>(GetChildOperationsForParentOperationIdDocument, options);
      }
export function useGetChildOperationsForParentOperationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildOperationsForParentOperationIdQuery, GetChildOperationsForParentOperationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildOperationsForParentOperationIdQuery, GetChildOperationsForParentOperationIdQueryVariables>(GetChildOperationsForParentOperationIdDocument, options);
        }
export type GetChildOperationsForParentOperationIdQueryHookResult = ReturnType<typeof useGetChildOperationsForParentOperationIdQuery>;
export type GetChildOperationsForParentOperationIdLazyQueryHookResult = ReturnType<typeof useGetChildOperationsForParentOperationIdLazyQuery>;
export type GetChildOperationsForParentOperationIdQueryResult = Apollo.QueryResult<GetChildOperationsForParentOperationIdQuery, GetChildOperationsForParentOperationIdQueryVariables>;
export const GetParentOperationsDocument = gql`
    query getParentOperations($offset: Int!, $limit: Int!) {
  operation(
    offset: $offset
    limit: $limit
    where: {parent_operation_id: {_is_null: true}}
    order_by: {id: asc}
  ) {
    id
    operation_type {
      id
      name
    }
    parent_operation_id
    operation_request_items {
      id
      operation_request_item_value
    }
    operation_progress_status {
      id
      name
    }
    operation_state {
      id
      name
    }
    operation_state {
      name
    }
  }
  operation_aggregate(where: {parent_operation_id: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetParentOperationsQuery__
 *
 * To run a query within a React component, call `useGetParentOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentOperationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetParentOperationsQuery(baseOptions: Apollo.QueryHookOptions<GetParentOperationsQuery, GetParentOperationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentOperationsQuery, GetParentOperationsQueryVariables>(GetParentOperationsDocument, options);
      }
export function useGetParentOperationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentOperationsQuery, GetParentOperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentOperationsQuery, GetParentOperationsQueryVariables>(GetParentOperationsDocument, options);
        }
export type GetParentOperationsQueryHookResult = ReturnType<typeof useGetParentOperationsQuery>;
export type GetParentOperationsLazyQueryHookResult = ReturnType<typeof useGetParentOperationsLazyQuery>;
export type GetParentOperationsQueryResult = Apollo.QueryResult<GetParentOperationsQuery, GetParentOperationsQueryVariables>;
export const GetSingleParentOperationDocument = gql`
    query getSingleParentOperation($id: Int!) {
  operation_by_pk(id: $id) {
    id
    created_at
    operation_progress_status {
      id
      name
    }
    operation_state {
      name
      id
    }
    operation_type {
      id
      name
    }
    operation_trigger_type {
      id
      name
    }
    external_event_id
    parent_operation_id
    operation_request_items {
      id
      is_completed
      operation_request_item_value
      operation_request_item_type {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetSingleParentOperationQuery__
 *
 * To run a query within a React component, call `useGetSingleParentOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleParentOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleParentOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleParentOperationQuery(baseOptions: Apollo.QueryHookOptions<GetSingleParentOperationQuery, GetSingleParentOperationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleParentOperationQuery, GetSingleParentOperationQueryVariables>(GetSingleParentOperationDocument, options);
      }
export function useGetSingleParentOperationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleParentOperationQuery, GetSingleParentOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleParentOperationQuery, GetSingleParentOperationQueryVariables>(GetSingleParentOperationDocument, options);
        }
export type GetSingleParentOperationQueryHookResult = ReturnType<typeof useGetSingleParentOperationQuery>;
export type GetSingleParentOperationLazyQueryHookResult = ReturnType<typeof useGetSingleParentOperationLazyQuery>;
export type GetSingleParentOperationQueryResult = Apollo.QueryResult<GetSingleParentOperationQuery, GetSingleParentOperationQueryVariables>;
export const NonOrgPersonAddressesByPostalCodeIdsDocument = gql`
    query NonOrgPersonAddressesByPostalCodeIds($postalCodeIds: [Int!]!) {
  person_to_address(
    where: {address: {postal_code_id: {_in: $postalCodeIds}}, person: {is_organisation: {_eq: false}}, is_owner_of_address_status_id: {_in: [4, 5]}}
  ) {
    link_relationship_status {
      id
      name
    }
    person {
      first_name
      full_name
      id
      last_name
      stated_address
      digital_profile_to_people {
        id
        digital_profile {
          full_name
          primary_subtitle
          secondary_subtitle
          summary
          digital_profile_type {
            id
            name
          }
          external_id
          id
        }
      }
    }
  }
}
    `;

/**
 * __useNonOrgPersonAddressesByPostalCodeIdsQuery__
 *
 * To run a query within a React component, call `useNonOrgPersonAddressesByPostalCodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonOrgPersonAddressesByPostalCodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonOrgPersonAddressesByPostalCodeIdsQuery({
 *   variables: {
 *      postalCodeIds: // value for 'postalCodeIds'
 *   },
 * });
 */
export function useNonOrgPersonAddressesByPostalCodeIdsQuery(baseOptions: Apollo.QueryHookOptions<NonOrgPersonAddressesByPostalCodeIdsQuery, NonOrgPersonAddressesByPostalCodeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NonOrgPersonAddressesByPostalCodeIdsQuery, NonOrgPersonAddressesByPostalCodeIdsQueryVariables>(NonOrgPersonAddressesByPostalCodeIdsDocument, options);
      }
export function useNonOrgPersonAddressesByPostalCodeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NonOrgPersonAddressesByPostalCodeIdsQuery, NonOrgPersonAddressesByPostalCodeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NonOrgPersonAddressesByPostalCodeIdsQuery, NonOrgPersonAddressesByPostalCodeIdsQueryVariables>(NonOrgPersonAddressesByPostalCodeIdsDocument, options);
        }
export type NonOrgPersonAddressesByPostalCodeIdsQueryHookResult = ReturnType<typeof useNonOrgPersonAddressesByPostalCodeIdsQuery>;
export type NonOrgPersonAddressesByPostalCodeIdsLazyQueryHookResult = ReturnType<typeof useNonOrgPersonAddressesByPostalCodeIdsLazyQuery>;
export type NonOrgPersonAddressesByPostalCodeIdsQueryResult = Apollo.QueryResult<NonOrgPersonAddressesByPostalCodeIdsQuery, NonOrgPersonAddressesByPostalCodeIdsQueryVariables>;
export const GetPostalCodesDocument = gql`
    query getPostalCodes {
  postal_code {
    code
    id
  }
}
    `;

/**
 * __useGetPostalCodesQuery__
 *
 * To run a query within a React component, call `useGetPostalCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostalCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostalCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostalCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetPostalCodesQuery, GetPostalCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostalCodesQuery, GetPostalCodesQueryVariables>(GetPostalCodesDocument, options);
      }
export function useGetPostalCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostalCodesQuery, GetPostalCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostalCodesQuery, GetPostalCodesQueryVariables>(GetPostalCodesDocument, options);
        }
export type GetPostalCodesQueryHookResult = ReturnType<typeof useGetPostalCodesQuery>;
export type GetPostalCodesLazyQueryHookResult = ReturnType<typeof useGetPostalCodesLazyQuery>;
export type GetPostalCodesQueryResult = Apollo.QueryResult<GetPostalCodesQuery, GetPostalCodesQueryVariables>;
export const GetOperationTypesDocument = gql`
    query getOperationTypes {
  operation_type {
    id
    name
  }
}
    `;

/**
 * __useGetOperationTypesQuery__
 *
 * To run a query within a React component, call `useGetOperationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperationTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetOperationTypesQuery, GetOperationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperationTypesQuery, GetOperationTypesQueryVariables>(GetOperationTypesDocument, options);
      }
export function useGetOperationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperationTypesQuery, GetOperationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperationTypesQuery, GetOperationTypesQueryVariables>(GetOperationTypesDocument, options);
        }
export type GetOperationTypesQueryHookResult = ReturnType<typeof useGetOperationTypesQuery>;
export type GetOperationTypesLazyQueryHookResult = ReturnType<typeof useGetOperationTypesLazyQuery>;
export type GetOperationTypesQueryResult = Apollo.QueryResult<GetOperationTypesQuery, GetOperationTypesQueryVariables>;