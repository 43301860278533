export const config = {
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiOrigin: process.env.REACT_APP_API_ORIGIN,
  origin: process.env.REACT_APP_ORIGIN,
  hasuraAdminSecret: process.env.REACT_APP_HASURA_ADMIN_SECRET,
  hasuraEndpoint: process.env.REACT_APP_HASURA_ENDPOINT,
  sentryUrl: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  posthogHost: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  posthogKey: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
};

export default config;
